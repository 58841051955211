import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import AlertCircle from '~icons/nol-icons/alert-circle'

import { toast } from '@nolas/design-system/sonner'

import { useUser } from '@auth/composables/use-user'

import { can } from '@nolas/lib/permissions'

interface Options {
	redirectToURL: string
}

export function useCan() {
	const { user } = useUser()
	const router = useRouter()
	const { t } = useI18n()

	return (_args: { roles: ('Workspace Admin' | 'Admin' | 'User')[] }, options?: Options) => {
		if (!user.value) {
			if (options?.redirectToURL) {
				router.push('/')
				toast.error(t('common.not_allowed_to access_page'), {
					icon: AlertCircle,
				})
			}
			return
		}

		return can(_args.roles, user.value.role.name)
	}
}

export function useAdmin(options?: Options) {
	const can = useCan()
	return can({ roles: ['Admin', 'Workspace Admin'] }, options)
}
