<template>
  <Button variant="transparent" type="button" class="h-5 w-5 min-w-5 text-gray-900" @click="togglePasswordVisibility">
    <INolasEyeHidden v-if="modelValue" :class="cn(iconVariants({ size }), 'cursor-pointer')" />
    <INolasEyeVisible v-else :class="cn(iconVariants({ size }), 'cursor-pointer')" />
  </Button>
</template>

<script setup lang="ts">
import { cn } from '../../utils'
import { Button } from '../button'

import { iconVariants } from '.'

interface Props {
	size?: 'md' | 'xs' | 'sm' | null | undefined
	modelValue: boolean
}

const props = withDefaults(defineProps<Props>(), {
	size: 'md',
})

const emit = defineEmits<{
	(e: 'update:modelValue', value: boolean): void
}>()

const togglePasswordVisibility = () => {
	emit('update:modelValue', !props.modelValue)
}
</script>
