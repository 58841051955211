import { DeleteFolder } from '@services/nolas-api'
import { useMutation } from '@tanstack/vue-query'
import { useQueryClient } from '@tanstack/vue-query'
import { type Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServiceError } from '@nolas/lib/errors'
import { toast } from '@nolas/design-system/sonner'
import { foldersQueryKey } from './use-folders'
import { foldersFlatQueryKey } from './use-folders-flat'

import { useUser } from '@auth/composables/use-user'

const selectedFolderIdToBeDeleted = ref<string | undefined>()
const selectFolderIdToBeDeleted = (folderId?: string) => {
	selectedFolderIdToBeDeleted.value = folderId || undefined
}

async function deleteFolderMutation(workspaceId: string) {
	if (!selectedFolderIdToBeDeleted.value) {
		throw new Error('Folder ID is required')
	}
	const { response, data, error } = await DeleteFolder({
		params: {
			path: {
				workspaceId,
				folderId: selectedFolderIdToBeDeleted.value,
			},
		},
	})

	if (!response.ok || !data) {
		throw ServiceError.fromResponse(error)
	}
	return data
}

export function useDeleteFolder() {
	const { workspaceId } = useUser()
	const queryClient = useQueryClient()
	const { t } = useI18n()
	const {
		mutateAsync: deleteFolder,
		error,
		isPending,
	} = useMutation({
		mutationFn: () => deleteFolderMutation(workspaceId.value),
		onSuccess: () => {
			toast.success(t('app.keyflowz.address_book.dialog.folder.delete.notification.success'))
			queryClient.invalidateQueries({ queryKey: foldersQueryKey._def })
			queryClient.invalidateQueries({ queryKey: foldersFlatQueryKey._def })
		},
		onError: () => {
			toast.error(t('app.keyflowz.address_book.dialog.folder.delete.notification.error'))
		},
	})

	return {
		deleteFolder,
		isPending,
		error: error as Ref<ServiceError | null>,
		selectedFolderIdToBeDeleted,
		selectFolderIdToBeDeleted,
	}
}
