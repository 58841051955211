<script setup lang="ts">
import DefaultLayout from '@layouts/dashboard.vue'
import { useHead } from '@unhead/vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { z } from 'zod'
import { useDocumentsCounts } from 'src/common/composables/search/use-search'
import { useUser } from '@auth/composables/use-user'
import { useDocumentSearch } from 'src/common/composables/search/use-search'
import { useZod } from '@helpers/zod-errors'
import { DialogProvider } from '@nolas/design-system/dialog'
import { SidebarProvider } from '@components/sidebar'
import { Toaster } from '@nolas/design-system/sonner'
import { TooltipProvider } from '@nolas/design-system/tooltip'
import { useAuthFlow } from '@auth/composables/use-auth-flow'
import { useSearchKey } from 'src/common/composables/search/use-search-key'

const { isFetchedAfterMount: isFetchedAfterMountSearchKey } = useSearchKey()
const { isFetchedAfterMount } = useUser()
const { isFetchedAfterMount: isFetchedAfterMountDocumentCount } = useDocumentsCounts()
const { isFetchedAfterMount: isFetchedAfterMountDocuments } = useDocumentSearch({
	space: 'INBOX',
	filterByExclude: { status: 'PENDING' },
})
const { errorMap } = useZod()
// @ts-expect-error types are bad but it works
z.setErrorMap(errorMap)

const { t } = useI18n()
const route = useRoute()

const title = route.meta.title
useHead({
	title: title ? `${t(`page_title.app.${title}`)}` : 'nolas',
})

const { state } = useAuthFlow()

const isLoading = computed(() =>
	route.path.includes('auth')
		? state.value === 'pending'
		: state.value === 'pending' ||
			!isFetchedAfterMount.value ||
			!isFetchedAfterMountDocuments.value ||
			!isFetchedAfterMountSearchKey.value ||
			!isFetchedAfterMountDocumentCount.value
)
</script>

<template>
	<SidebarProvider>
		<TooltipProvider>
			<Toaster />
			<div v-if="isLoading" class="flex h-screen flex-row items-center justify-center overflow-hidden bg-white w-full">
				<INolasSpinnerGradient class="!h-14 !w-14 text-[#2D2D2D]" />
			</div>
			<template v-else>
				<RouterView v-if="$route.path.includes('auth')" />
				<template v-else>
					<component :is="$route?.meta.urlState ?? 'div'" :key="$route.path"  class="w-full h-screen">
						<component :is="$route?.meta.provider ?? 'div'" :key="$route.path" class="w-full h-screen">
							<DialogProvider>
								<DefaultLayout>
									<RouterView />
								</DefaultLayout>
							</DialogProvider>
						</component>
					</component>
				</template>
			</template>
		</TooltipProvider>
	</SidebarProvider>
</template>
