import { cva, type VariantProps } from 'class-variance-authority'
import type { HTMLAttributes } from 'vue'

import type { ErrorMessage } from '../form'

export { default as ExpandableSearchInput } from './expandable-search-input.vue'
export { default as InputCopy } from './input-copy.vue'
export { default as InputFileButton } from './input-file-button.vue'
export { default as InputFile } from './input-file.vue'
export { default as InputGroup } from './input-group.vue'
export { default as InputUrl } from './input-url.vue'
export { default as Input } from './input.vue'
export { default as PhoneInputGroup } from './phone-input-group.vue'
export { default as SearchInput } from './search-input.vue'
export { default as PasswordToggleEye } from './password-toggle-eye.vue'

export const inputVariants = cva(
	'peer [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none outline-none !w-full font-medium text-gray-900  overflow-hidden text-elipsis group-hover:bg-gray-200',
	{
		variants: {
			color: {
				beige: 'bg-gray-100 text-sm leading-5 disabled:text-gray-550',
				white: 'bg-white text-sm leading-5',
				gray: 'bg-gray-150 text-xs leading-4 disabled:text-gray-550',
				'gray-warm': 'bg-gray-100 text-sm leading-5 disabled:text-gray-85 !text-gray-900',
			},
			size: {
				xs: 'h-9 text-sm',
				sm: 'h-10',
				md: 'h-13 pt-4 ',
			},
		},
		defaultVariants: {
			color: 'beige',
			size: 'md',
		},
	}
)
export const inputContainerVariants = cva('flex flex-row-reverse items-center gap-x-2.5 h-13 w-full')

export const inputWrapperVariants = cva(
	'group overflow-hidden border-2 border-transparent focus-within:border-primary-500 w-full  rounded-lg hover:bg-gray-200 group-hover:bg-gray-200',
	{
		variants: {
			color: {
				beige: 'bg-gray-100',
				white: 'bg-white',
				gray: 'bg-gray-150',
				'gray-warm': 'bg-gray-100',
			},
			size: {
				xs: 'h-9 px-2 py-1',
				sm: 'h-10 px-3.5 py-2',
				md: 'h-13 px-4 py-1.5',
			},
		},
		defaultVariants: {
			color: 'beige',
			size: 'md',
		},
	}
)

export const inputLabelVariants = cva(
	'absolute left-4 text-xs origin-[0] -translate-y-3 peer-autofill:-translate-y-3 transform font-medium text-gray-600 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-sm peer-focus:text-xs peer-focus:-translate-y-3  rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4 peer-disabled:text-gray-450 pointer-events-none'
)

export const searchtWrapperVariants = cva(
	'relative group border border-transparent focus-within:border-primary-500 w-full h-9 px-3 rounded-lg',
	{
		variants: {
			color: {
				beige: 'bg-gray-150 hover:bg-gray-150',
				white: 'bg-white hover:bg-gray-200',
				gray: 'bg-gray-150',
				'gray-warm': 'bg-gray-100 !border-2 border-transparent',
			},
		},
		defaultVariants: {
			color: 'beige',
		},
	}
)

export const searchVariants = cva(
	'outline-none  w-full font-medium text-gray-900  overflow-hidden text-elipsis  placeholder:font-medium',
	{
		variants: {
			color: {
				beige: 'bg-gray-150 group-hover:bg-gray-150 text-sm leading-5 placeholder:text-gray-600',
				white: 'bg-white group-hover:bg-gray-200 text-sm leading-5 placeholder:text-gray-600',
				gray: 'bg-gray-150 text-xs leading-4 placeholder:text-gray-600',
				'gray-warm': 'bg-gray-100 text-sm leading-5 placeholder:text-gray-600',
			},
		},
		defaultVariants: {
			color: 'beige',
		},
	}
)

export const iconVariants = cva('', {
	variants: {
		size: {
			xs: 'min-w-3 h-3 w-3',
			sm: 'min-w-4 h-4 w-4',
			md: 'min-w-6 h-6 w-6',
		},
	},
	defaultVariants: {
		size: 'md',
	},
})

export const iconWrapperVariants = cva('hover:bg-gray-200 group-hover:bg-gray-200', {
	variants: {
		color: {
			beige: 'bg-gray-100',
			white: 'bg-white',
			gray: 'bg-gray-150',
			'gray-warm': 'bg-gray-100',
		},
	},
})

export type Variants = VariantProps<typeof inputVariants>

export interface InputProps {
	color?: Variants['color']
	size?: Variants['size']
	defaultValue?: string | number
	modelValue?: string | number
	placeholder?: string
	error?: ErrorMessage
	name?: string
	disabled?: boolean
}

export type CustomInputProps = InputProps & {
	type?: HTMLInputElement['type']
	inputmode?: HTMLAttributes['inputmode']
	pattern?: string
	defaultValue?: number
	iconClasses?: string
	min?: number
	readonly?: boolean
	max?: number
	step?: number
	description?: string
	autocomplete?: string
	icon?: unknown
	canCopy?: boolean
	label?: string
	required?: boolean
}
