<script setup lang="ts">
import { SelectContent, type SelectContentEmits, SelectPortal, SelectViewport, useForwardPropsEmits } from 'reka-ui'

import SelectGroup from './_select-group.vue'
import SelectItem from './_select-item.vue'
import Group from './select-group.vue'
import { bindCommonProps, useVirtualItems } from './utils'

import { type CustomSelectContentProps, selectContentVariants, selectViewportVariants } from '.'

const props = withDefaults(defineProps<CustomSelectContentProps>(), {
	position: 'popper',
	sideOffset: 4,
	class: undefined,
})
const emits = defineEmits<SelectContentEmits>()
const forwarded = useForwardPropsEmits(props, emits)

const {
	value: { list, containerProps, wrapperProps },
} = useVirtualItems(props.items)
</script>

<template>
  <SelectPortal>
    <SelectContent
      v-bind="{
        ...forwarded,
        ...$attrs,
        items: undefined,
        class: selectContentVariants({ class: props.class, position: props.position }),
      }"
    >
      <div v-bind="containerProps">
        <SelectViewport v-bind="wrapperProps" :class="selectViewportVariants({ position: props.position })">
          <Group>
            <template v-for="item in list" :key="item.index">
              <SelectGroup v-if="'title' in item.data" v-bind="{ group: item.data, ...bindCommonProps(props) }" />
              <SelectItem v-else v-bind="{ item: item.data, ...bindCommonProps(props) }" />
            </template>
          </Group>
        </SelectViewport>
      </div>
    </SelectContent>
  </SelectPortal>
</template>
