<script setup>
import { useRoute } from 'vue-router'

import { SendingSettingsProvider } from '@sending-settings'
import { ref } from 'vue'

import DocViewState from '@workbench/docview/states/doc-view-state.vue'
import SideDrawerState from '@workbench/docview/side-drawer/side-drawer-state.vue'
import TableState from './table-state.vue'

//Based on route
const route = useRoute()

const routeTypeMap = {
	inbox: 'INBOX',
	drafts: 'DRAFTS',
	sent: 'SENT',
	trash: 'TRASH',
}
</script>

<template>
	<TableState :space="routeTypeMap[route.meta.type]">
		<DocViewState>
			<SideDrawerState>
				<SendingSettingsProvider>
					<slot />
				</SendingSettingsProvider>
			</SideDrawerState>
		</DocViewState>
	</TableState>
</template>
