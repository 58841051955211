<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'

import { cn } from '@nolas/design-system/utils'
import { Heading } from '@nolas/design-system/heading'
import { SidebarRoot, SidebarHeader, SidebarContent, SidebarTrigger, SidebarRail } from '.'
import { Skeleton } from '@nolas/design-system/skeleton'

import { SIDEBAR_WIDTH_ICON } from './utils'

import { type SidebarProps, useSidebarRight } from '.'

defineOptions({
	inheritAttrs: false,
})
const router = useRouter()
const { type, width, close, isOpen, properties } = useSidebarRight()
const routeGuard = ref<() => void>()

onMounted(() => {
	routeGuard.value = router?.beforeEach(async (to, _from, next) => {
		if (!to.params.document) {
			await close()
		}
		next()
	})
})

onBeforeRouteLeave(async () => {
	await close()
	if (routeGuard.value) {
		routeGuard.value()
		routeGuard.value = undefined
	}
})

const props = withDefaults(defineProps<SidebarProps>(), {
	variant: 'sidebar',
})
</script>

<template>
  <div
    :style="{
      '--sidebar-width': isOpen ? width : 0,
      '--sidebar-width-icon': isOpen ? SIDEBAR_WIDTH_ICON : 0,
    }"
    :class="cn('group/sidebar-wrapper has-[[data-variant=inset]]:bg-sidebar flex min-h-svh')"
    v-bind="$attrs"
  >
    <SidebarRoot v-bind="props" :collapsible="type === 'navigation' ? 'icon' : 'offcanvas'">
      <SidebarHeader>
        <SidebarTrigger>
          <slot name="trigger" />
        </SidebarTrigger>
        <Heading v-if="properties.title" as="h3" class="truncate text-sm font-semibold">
          {{ properties.title }}
        </Heading>
        <Skeleton v-else class="h-4 w-full rounded-none" data-sidebar="menu-skeleton-icon" />
        <slot name="header" />
      </SidebarHeader>
      <SidebarContent>
        <slot />
      </SidebarContent>
      <SidebarRail />
    </SidebarRoot>
  </div>
</template>
