import { computed, inject } from 'vue'

import type { DocumentAssignmentID } from '@services/nolas-api'
import { debounce } from 'lodash'
import { useRoute } from 'vue-router'

import { useDocumentsUpdate } from 'src/common/composables/documents/use-documents-update'

import { type DocViewContext, contextKey } from '@workbench/docview/utils/context'
import type { Color, Printing, Variant } from '@workbench/docview/utils/util'

export * from '@workbench/docview/utils/util'

const invitationCoverSrc = '/images/invitation-cover.png'

function scrollPageIntoView(id: number) {
	window.document.querySelector(`#page-${id}`)?.scrollIntoView({ behavior: 'instant', block: 'end' })
}

export function usePagePreview() {
	const state = inject(contextKey)
	if (!state) {
		throw new Error('Please use `useDocView` before using this composable.')
	}

	const { updateDocument } = useDocumentsUpdate()
	const route = useRoute()

	const previewState = state.preview

	function selectThumbnail(pageId: number) {
		const set = new Set(previewState.selection.value)
		if (set.has(pageId)) {
			set.delete(pageId)
		} else {
			set.add(pageId)
		}

		previewState.selection.value = [...set]
	}

	function resetSelectedThumbnails() {
		previewState.selection.value = []
	}

	function setViewElement(element: HTMLElement) {
		previewState.viewElement.value = element
	}

	function setInViewIndex(index: number) {
		previewState.inViewIndex.value = index
	}

	function setActiveLetterhead(letterhead?: string) {
		previewState.activeLetterhead.value = letterhead

		saveDocumentChanges()
	}

	function setLetterhead(letterhead: boolean, idxs?: number[]) {
		if (!state) {
			return
		}

		if (!idxs || idxs.length === 0) {
			// biome-ignore lint/style/useForOf: <explanation>
			for (let i = 0; i < state.pageProperties.length; i++) {
				state.pageProperties[i].letterhead = previewState.letterhead.value ? false : letterhead
			}
		} else {
			for (const idx of idxs) {
				state.pageProperties[idx].letterhead = letterhead
			}
		}
		previewState.letterhead.value = state.pageProperties.some(page => page.letterhead)

		saveDocumentChanges()
	}

	function setColorMode(mode: Color, idxs?: number[]) {
		if (!state) {
			return
		}

		const pages = state.pageProperties

		if (!idxs || idxs.length === 0) {
			for (const page of pages) {
				page.color = mode
			}
			previewState.color.value = mode
		} else {
			for (const page of idxs) {
				pages[page].color = mode
			}
		}

		if (pages.every(page => page.color === 'color')) {
			previewState.color.value = 'color'
		} else if (pages.every(page => page.color === 'black_white')) {
			previewState.color.value = 'black_white'
		} else {
			previewState.color.value = 'mixed'
		}

		saveDocumentChanges()
	}

	function updateSheetsIndexes(mode: DocViewContext['preview']['printing']['value']) {
		if (!state) {
			return
		}

		const pages = state.pages
		let i = 0
		let max = undefined

		switch (mode) {
			case 'simplex': {
				max = pages.value.length
				for (i; i < max; i++) {
					previewState.sheets[i] = {
						front: i,
						back: undefined,
					}
				}

				break
			}

			case 'duplex': {
				max = Math.round(pages.value.length / 2)
				for (i; i < max; i++) {
					previewState.sheets[i] = {
						front: i * 2,
						back: i * 2 + 1,
					}
				}

				break
			}

			case 'mixed': {
				/**
				 * Use case: removing invitation cover from document when the printing mode is set to duplex
				 * Effect: the printing mode will be set automatically to mixed
				 * In this case, the `sheets` array will contain some elements having either `front` or `back` (or both) index higher than the number of pages and they are also duplicates of other pages. We want to remove all the items whose indexes exceed the maximum number of pages.
				 */
				const numPages = pages.value.length
				for (i; i < numPages; i++) {
					const sheet = previewState.sheets[i]
					if (!sheet) {
						continue
					}
					if (sheet.front > numPages || (sheet.back && sheet.back > numPages)) {
						previewState.sheets.splice(i, 1)
					}
				}
				break
			}

			default:
				break
		}

		/**
		 * Delete all the extra items. This applies to `simplex` or `duplex`.
		 */
		if (typeof max === 'number' && max < previewState.sheets.length) {
			previewState.sheets.splice(max)
		}
	}

	function setPrintingMode(mode: Printing, sheetIdxs?: number | [number, number]) {
		const sheets = previewState.sheets

		if (sheetIdxs === undefined) {
			if (mode === 'simplex') {
				updateSheetsIndexes(mode)
				previewState.printing.value = 'simplex'
			}
			if (mode === 'duplex') {
				updateSheetsIndexes(mode)
				previewState.printing.value = 'duplex'
			}
		} else if (typeof sheetIdxs === 'number') {
			if (!sheets[sheetIdxs].back) {
				console.warn('Page is already simplex.')
				return
			}

			// duplex -> simplex just transforms 1 array item into 2 items
			// so this splice will add a new element in front of the old element.
			sheets.splice(sheetIdxs + 1, 0, { front: sheets[sheetIdxs].back })
			sheets[sheetIdxs].back = undefined
		} else {
			// simplex -> duplex reduces 2 array items to 1 item
			// so this splice will add a enw element at the specified index
			// but also replaces the old element and the element in front of it
			sheets.splice(sheetIdxs[0], 2, { front: sheets[sheetIdxs[0]].front, back: sheets[sheetIdxs[1]].front })
		}

		if (previewState.sheets.every(({ front, back }) => typeof front === 'number' && typeof back === 'number')) {
			previewState.printing.value = 'duplex'
		} else if (previewState.sheets.every(({ front, back }) => typeof front === 'number' && typeof back !== 'number')) {
			previewState.printing.value = 'simplex'
		} else {
			previewState.printing.value = 'mixed'
		}

		saveDocumentChanges()
	}

	function changeVariant(value: Variant) {
		previewState.variant.value = value
	}

	const saveDocumentChanges = debounce(async () => {
		const documentID = route.params.document as DocumentAssignmentID

		if (!documentID) {
			return
		}

		await updateDocument({
			body: {
				pageProperties: state.pageProperties,
				activeLetterhead: state.preview.activeLetterhead.value,
				sheets: state.preview.sheets,
			},
			id: documentID,
		})
	}, 500)

	function addInvitationCover() {
		if (!state) {
			return
		}

		const hasInvitationCover = state.pages.value.includes(invitationCoverSrc)
		if (hasInvitationCover) {
			return
		}

		state.pages.value = [invitationCoverSrc, ...state.pages.value]
		state.pageProperties.unshift({
			color: 'black_white',
			letterhead: false,
			signatures: {},
			thumbnail: invitationCoverSrc,
			hideToolbox: true,
		})
		updateSheetsIndexes(state.preview.printing.value)
	}

	function removeInvitationCover() {
		if (!state) {
			return
		}

		const coverIndex = state.pageProperties.findIndex(page => page.thumbnail === invitationCoverSrc)
		if (coverIndex < 0) {
			return
		}

		state.pages.value = state.pages.value.filter(page => page !== invitationCoverSrc)
		state.pageProperties.splice(coverIndex, 1)
		if (state.pages.value.length === 1) {
			state.preview.printing.value = 'simplex'
		}
		updateSheetsIndexes(state.preview.printing.value)
	}

	return {
		color: state.preview.color,
		inViewIndex: state.preview.inViewIndex,
		variant: state.preview.variant,
		letterhead: state.preview.letterhead,
		printing: state.preview.printing,
		selection: state.preview.selection,
		sheets: state.preview.sheets,
		viewElement: state.preview.viewElement,
		zoom: state.preview.zoom,
		pages: state.pages,
		pageProperties: state.pageProperties,
		activeLetterhead: state.preview.activeLetterhead,
		totalPages: computed(() => state.pages.value.length),
		hasInvitationCover: computed(() => state.pages.value.includes(invitationCoverSrc)),
		selectThumbnail,
		resetSelectedThumbnails,
		setViewElement,
		setInViewIndex,
		scrollPageIntoView,
		setLetterhead,
		setColorMode,
		setPrintingMode,
		setActiveLetterhead,
		changeVariant,
		saveDocumentChanges,
		addInvitationCover,
		removeInvitationCover,
	}
}
