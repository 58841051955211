<script setup lang="ts">
import type { ClassValue } from 'clsx'
import {
  DialogClose,
  DialogContent as RadixDialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useEmitAsProps,
} from 'reka-ui'

import { cn } from '../../utils'

// TODO see if we need these class props anymore
const props = defineProps<
  DialogContentProps & {
    class?: ClassValue
    contentClass?: ClassValue
    showCloseButton?: boolean
    hideOverlay?: boolean
    overlayClasses?: ClassValue
    dataE2e?: string
  }
>()
const emits = defineEmits<DialogContentEmits>()

const emitsAsProps = useEmitAsProps(emits)
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      v-if="!hideOverlay"
      class="fixed inset-0 z-50 bg-gray-overlay data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
      :class="overlayClasses"
    />

    <RadixDialogContent
      :class="
        cn('fixed inset-0 z-50 m-auto grid place-items-center', props.class)
      "
      v-bind="{ ...props, ...emitsAsProps }"
    >
      <div
        :class="
          cn(
            'relative flex h-full w-full flex-col overflow-hidden rounded-xl bg-white shadow-xl duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
            props.contentClass
          )
        "
        :data-e2e="dataE2e"
      >
        <slot />

        <DialogClose
          v-if="showCloseButton"
          class="absolute right-4 top-4 mt-0.5 rounded-md p-0.5 transition-colors hover:bg-white hover:text-gray-600"
        >
          <INolasXClose
            class="pointer-events-none h-6 w-6 text-gray-350 hover:text-gray-600"
          />
        </DialogClose>
      </div>
    </RadixDialogContent>
  </DialogPortal>
</template>
