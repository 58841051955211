import { useQuery, useQueryClient } from '@tanstack/vue-query'
import type { SearchParams, SearchResponseHit } from 'typesense/lib/Typesense/Documents'
import type { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch'
import type { MaybeRef, Ref } from 'vue'

import type { ServiceError } from '@nolas/lib/errors'
import type { TypeSenseDocument } from '@services/nolas-api'
import type { Space } from '@services/nolas-api'

import { facetsQuery } from 'src/common/composables/search/use-facets'
import { useSearchClient } from 'src/common/composables/search/use-search-client'

import { QK } from '@queries'
import type { Filter, FilterExclude } from '@workbench/composables/use-table-state'

const documentsCollection = 'document_assignments'
const MISSING = 'MISSING'

const SPACES: Space[] = ['DRAFTS', 'INBOX', 'SENT', 'TRASH']

export interface DocumentsSearchInput {
	q?: string
	page?: number
	limit?: number
	space?: Space
	filterBy?: Filter
	filterByExclude?: FilterExclude
	sortBy?: string
}

function mapChildrenToParents(
	parents: SearchResponseHit<TypeSenseDocument>[] | undefined,
	children: SearchResponseHit<TypeSenseDocument>[] | undefined
) {
	return parents?.map(parent => {
		if (!parent.document.groupKey || parent.document.groupKey === MISSING) {
			return parent
		}

		return {
			...parent,
			document: {
				...parent.document,
				children: children
					?.filter(child => child.document.groupKey === parent.document.groupKey)
					.map(child => child.document),
			},
		}
	})
}

export function buildDocumentParentsFilter(search: DocumentsSearchInput) {
	const filterBy = (filters?: Filter) =>
		Object.entries(filters || {}).flatMap(([key, value]) => (value ? `${key} := ${value}` : []))

	const filterByExclude = (filters?: FilterExclude) =>
		Object.entries(filters || {}).flatMap(([key, value]) => (value ? `${key} :!= ${value}` : []))

	const parent_filters = [
		`groupKey := ${MISSING}`, // has no group, so implicitly it's a parent
		'isGroupParent := true', // has a group and it's also the parent
	]

	const filters = [
		`space := ${search.space ?? 'DRAFTS'}`,
		'hidden := false',
		...filterBy(search.filterBy as Filter),
		...filterByExclude(search.filterByExclude as FilterExclude),
		`(${parent_filters.join(' || ')})`,
	]

	return filters.flat().join(' && ')
}

export function buildDocumentParentsSearch(search: DocumentsSearchInput): SearchParams {
	return {
		q: search.q ?? '*',
		per_page: search.limit,
		page: search.page,
		query_by: 'name, subject, sender.name',
		filter_by: buildDocumentParentsFilter(search),
		num_typos: '1',
		infix: ['fallback', 'off', 'off'],
		sort_by: search.sortBy,
		facet_by: facetsQuery.join(', '),
	}
}

export function useDocumentSearch(search: MaybeRef<DocumentsSearchInput>, options?: { refetch?: boolean }) {
	const { searchCreds, searchClient } = useSearchClient()

	function findParents(_search: DocumentsSearchInput) {
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		return searchClient
			.value!.collections<TypeSenseDocument>(documentsCollection)
			.documents()
			.search(buildDocumentParentsSearch(_search), {})
	}

	function findChildren(parents: Awaited<ReturnType<typeof findParents>>) {
		const groupKeys =
			parents.hits
				?.map(hit => {
					if (hit.document?.groupKey === MISSING) {
						return
					}

					return `groupKey := '${hit.document.groupKey}'`
				})
				.filter(Boolean) || []

		if (groupKeys.length === 0) {
			return
		}

		const filter_by = `isGroupParent := false && (${groupKeys.join(' || ')})`

		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		return searchClient.value!.collections<TypeSenseDocument>(documentsCollection).documents().search({
			q: '*',
			filter_by,
			per_page: 250,
		})
	}

	const {
		data: searchResult,
		error,
		...queryRest
	} = useQuery({
		queryKey: [...QK.search.documents._def, search] as const,
		enabled: () => !!searchCreds.value,
		retry: 2,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: 1000,
		// biome-ignore lint/nursery/noNestedTernary: <explanation>
		refetchInterval: import.meta.env.DEV ? undefined : options?.refetch ? 2000 : undefined,
		queryFn: async ({ queryKey: [, , search] }) => {
			const parents = await findParents(search)
			const children = await findChildren(parents)
			const mappedHits = mapChildrenToParents(parents?.hits, children?.hits)

			return { ...parents, hits: mappedHits }
		},
	})

	return { searchResult, error: error as Ref<ServiceError | null>, ...queryRest }
}

export function useDocumentsCounts() {
	const { searchCreds, searchClient } = useSearchClient()

	const {
		data: documentsCounts,
		error,
		...queryRest
	} = useQuery({
		queryKey: QK.search.counts._def,
		enabled: () => Boolean(searchCreds),
		retry: 2,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		refetchInterval: import.meta.env.DEV ? undefined : 10000,
		queryFn: async () => {
			// biome-ignore lint/style/noNonNullAssertion: <explanation>
			const searchResult = await searchClient.value!.multiSearch.perform<TypeSenseDocument[]>({
				searches: SPACES.map<MultiSearchRequestSchema>(space => ({
					collection: documentsCollection,
					q: '*',
					filter_by: `space := ${space} && hidden := false ${space === 'INBOX' ? ' && status :!= PENDING' : ''}`,
					include_fields: '_', // we don't need any fields, they would just increase the size of the response
				})),
			})
			return searchResult.results
		},
		select: data => {
			const [draftsCount, inboxCount, sentCount, trashCount, folderCount] = data.map(result => result.found)

			return {
				drafts: draftsCount ?? 0,
				inbox: inboxCount ?? 0,
				sent: sentCount ?? 0,
				trash: trashCount ?? 0,
				folder: folderCount ?? 0,
			}
		},
	})

	return { documentsCounts, error: error as Ref<ServiceError | null>, ...queryRest }
}

export function useInvalidateDocuments() {
	const queryClient = useQueryClient()

	const invalidateDocuments = async () => {
		await Promise.allSettled([
			queryClient.invalidateQueries({ queryKey: QK.search.documents._def }),
			queryClient.invalidateQueries({ queryKey: QK.search.counts._def }),
			queryClient.invalidateQueries({ queryKey: QK.search.dynamicFacets._def }),
		])
	}

	return { invalidateDocuments }
}
