import { computed, ref, watch } from 'vue'

import { useLocalStorage } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { useEmailFetchInboxes } from 'src/common/composables/email-integration/use-email-fetch-inboxes'
import { useDocumentsCounts } from 'src/common/composables/search/use-search'
import { useAdmin } from 'src/common/composables/use-can-acces-page'

import AddressBook from '~icons/nol-icons/address-book'
import AdministrationFilled from '~icons/nol-icons/administration-filled'
import AttachmentsFilled from '~icons/nol-icons/attachments-filled'
import Calendar from '~icons/nol-icons/calendar'
import Interfaces from '~icons/nol-icons/console'
import CurrencyEuroCircleFilled from '~icons/nol-icons/currency-euro-circle-filled'
import DataExchangeFilled from '~icons/nol-icons/data-exchange-filled'
import DownloadsFilled from '~icons/nol-icons/downloads-filled'
import Draft from '~icons/nol-icons/draft'
import Help from '~icons/nol-icons/help'
import Inbox from '~icons/nol-icons/inbox'
import LetterheadsFilled from '~icons/nol-icons/letterheads-filled'
import Office from '~icons/nol-icons/office'
import Send from '~icons/nol-icons/send'
import SettingsCog from '~icons/nol-icons/settings-cog'
import SignaturesFilled from '~icons/nol-icons/signatures-filled'
import SSO from '~icons/nol-icons/sso'
import TextTemplatesFilled from '~icons/nol-icons/text-templates-filled'
import Trash from '~icons/nol-icons/trash'
import TutorialsFilled from '~icons/nol-icons/tutorials-filled'
import VirtualOfficeFilled from '~icons/nol-icons/virtual-office-filled'
import Workbench from '~icons/nol-icons/workbench'
import Workflows from '~icons/nol-icons/workflows'

import type { MenuItem } from '../types'

export function useNavigation() {
	const { documentsCounts } = useDocumentsCounts()
	const { fetchEmailInboxes } = useEmailFetchInboxes()

	const isAdmin = useAdmin()

	const { t } = useI18n()

	const router = useRouter()
	const route = useRoute()
	const menuItems = computed<MenuItem[]>(() => {
		return [
			{
				id: 'workbench',
				name: t('page_name.app.workbench'),
				path: '/workbench',
				icon: Workbench,
				type: 'module' as const,
				children: [
					{
						id: 'workbench-inbox',
						name: t('page_name.app.workbench-inbox'),
						icon: Inbox,
						path: '/workbench/inbox',
						type: 'page' as const,
						badge: documentsCounts.value?.inbox,
						itemActions: [
							{
								id: 'refresh',
								label: t('app.keyflowz.documents.document_actions.refresh'),
								callback: async () => {
									await fetchEmailInboxes()
								},
							},
						],
					},
					{
						id: 'workbench-drafts',
						name: t('page_name.app.workbench-drafts'),
						icon: Draft,
						path: '/workbench/drafts',
						type: 'page' as const,
						badge: documentsCounts.value?.drafts,
					},
					{
						id: 'workbench-sent',
						name: t('page_name.app.workbench-sent'),
						icon: Send,
						path: '/workbench/sent',
						type: 'page' as const,
						badge: documentsCounts.value?.sent,
					},
					{
						id: 'workbench-trash',
						name: t('page_name.app.workbench-trash'),
						icon: Trash,
						path: '/workbench/trash',
						type: 'page' as const,
						badge: documentsCounts.value?.trash,
					},
					{ type: 'spacer' as const },
					{
						id: 'workbench-address-book',
						name: t('page_name.app.workbench-address-book'),
						icon: AddressBook,
						path: '/workbench/address-book',
						type: 'page' as const,
					},
					{
						id: 'workbench-calendar',
						name: t('page_name.app.workbench-calendar'),
						icon: Calendar,
						path: '/workbench/calendar',
						type: 'page' as const,
					},
					{
						id: 'workbench-office',
						name: t('page_name.app.workbench-office'),
						icon: Office,
						path: '/workbench/my-office',
						type: 'page' as const,
					},
				],
			},
			{
				id: 'virtual-office',
				name: t('page_name.app.virtual-office'),
				path: '/virtual-office',
				icon: VirtualOfficeFilled,
				type: 'page' as const,
				children: [],
			},
			{ type: 'spacer' as const },
			{
				id: 'workspace-settings',
				name: t('page_name.app.workspace-settings'),
				path: '/settings',
				icon: SettingsCog,
				type: 'page' as const,
			},
			...(isAdmin
				? [
						{
							id: 'workspace-administration',
							name: t('page_name.app.administration'),
							path: '/administration',
							icon: AdministrationFilled,
							type: 'module' as const,
							children: [
								{
									id: 'administration-general',
									name: t('page_name.app.administration-general'),
									icon: SettingsCog,
									path: '/administration/general',
									type: 'page' as const,
								},
								{
									id: 'administration-interfaces',
									name: t('page_name.app.administration-interfaces'),
									icon: Interfaces,
									path: '/administration/interfaces',
									type: 'page' as const,
								},
								{
									id: 'administration-workflows',
									name: t('page_name.app.administration-workflows'),
									icon: Workflows,
									path: '/administration/workflows',
									type: 'page' as const,
								},
								{
									id: 'administration-data-exchange',
									name: t('page_name.app.administration-data-exchange'),
									icon: DataExchangeFilled,
									path: '/administration/data-exchange',
									type: 'page' as const,
								},
								{
									id: 'administration-cost-centers',
									name: t('page_name.app.administration-cost-centers'),
									icon: CurrencyEuroCircleFilled,
									path: '/administration/cost-centers',
									type: 'page' as const,
								},
								{
									id: 'administration-sso-settings',
									name: t('page_name.app.administration-sso'),
									icon: SSO,
									path: '/administration/sso-settings',
									type: 'page' as const,
								},
								{ type: 'spacer' as const },
								{
									id: 'administration-signatures',
									name: t('page_name.app.administration-signatures'),
									icon: SignaturesFilled,
									path: '/administration/signatures',
									type: 'page' as const,
								},
								{
									id: 'administration-letterheads',
									name: t('page_name.app.administration-letterheads'),
									icon: LetterheadsFilled,
									path: '/administration/letterheads',
									type: 'page' as const,
								},
								{
									id: 'administration-attachments',
									name: t('page_name.app.administration-attachments'),
									icon: AttachmentsFilled,
									path: '/administration/attachments',
									type: 'page' as const,
								},
								{
									id: 'administration-text-templates',
									name: t('page_name.app.administration-text-templates'),
									icon: TextTemplatesFilled,
									path: '/administration/text-templates',
									type: 'page' as const,
								},
							],
						},
					]
				: []),
			{
				id: 'workspace-download',
				name: t('page_name.app.workspace-download'),
				icon: DownloadsFilled,
				path: '/download',
				type: 'page' as const,
			},
			{ type: 'spacer' as const },
			{
				id: 'workspace-tutorials',
				name: t('page_name.app.workspace-tutorials'),
				icon: TutorialsFilled,
				path: '/tutorials',
				type: 'page' as const,
			},
			{
				id: 'workspace-support',
				name: t('page_name.app.workspace-support'),
				icon: Help,
				path: '/support',
				type: 'page' as const,
			},
		]
	})

	// Menu fold state (unfolded by default)
	const isMenuUnfolded = useLocalStorage('menu-unfolded-state', true)

	// Switch menu state (closed by default)
	const isSwitchMenuOpen = ref(false)

	const currentModuleId = ref()
	const currentItemId = ref()
	const langPrefix = useLocalStorage<string>('langPrefix', '')

	const supportedPrefixes = ['', '/de']

	const detectPrefix = (path: string): string => {
		const match = supportedPrefixes.find(prefix => prefix !== '' && path.startsWith(prefix))
		return match || ''
	}

	const stripPrefix = (path: string): string => {
		const prefix = detectPrefix(path)
		return prefix ? path.slice(prefix.length) : path
	}

	const findItemByPath = (items: MenuItem[], path: string): MenuItem | null => {
		const strippedPath = stripPrefix(path)
		for (const item of items) {
			if (item?.type === 'spacer') {
				continue
			}
			if (item?.type === 'module' && item.children) {
				const found = findItemByPath(item.children as MenuItem[], strippedPath)
				if (found) {
					return found
				}
			}
			if (item?.type === 'page' && typeof item.path === 'string' && strippedPath.startsWith(stripPrefix(item.path))) {
				return item
			}
		}

		return null
	}

	const updateCurrentIds = () => {
		const detectedPrefix = detectPrefix(route.path)
		if (detectedPrefix !== langPrefix.value) {
			langPrefix.value = detectedPrefix
		}

		const currentItem = findItemByPath(menuItems.value, route.path)
		if (currentItem) {
			if (currentItem.type === 'page') {
				const parentModule = menuItems.value.find(
					item => item.type === 'module' && item.children?.some(child => child?.id === currentItem.id)
				)
				currentModuleId.value = parentModule?.id ?? currentItem.id
				currentItemId.value = currentItem.id
			} else if (currentItem.type === 'module') {
				currentModuleId.value = currentItem.id
				currentItemId.value = currentItem.children?.[0]?.id ?? currentItem.id
			}
		} else if (route.meta?.moduleId && route.meta?.parent) {
			currentModuleId.value = route.meta.moduleId ?? null
			currentItemId.value = route.meta.parent ?? null
		} else {
			currentModuleId.value = null
			currentItemId.value = null
		}
	}

	// Update IDs whenever the route changes
	watch(() => route.path, updateCurrentIds, { immediate: true, deep: true })

	const currentModule = computed(() => {
		return menuItems.value.find(item => item.type !== 'spacer' && item.id === currentModuleId.value) || null
	})

	const currentItem = computed(() => {
		const module = currentModule.value
		if (module?.type === 'module') {
			return (
				module.children.find(item => item?.type !== 'spacer' && item?.id === currentItemId.value) ||
				module.children[0] ||
				null
			)
		}
		return module
	})

	const currentSubMenu = computed(() => (currentModule.value?.type === 'module' && currentModule.value.children) || [])

	const setCurrentModule = (module: MenuItem) => {
		if (module.type === 'spacer') {
			return
		}
		if (module.id === currentModuleId.value) {
			closeSwitchMenu()
		}

		if (module.type === 'module' && module.children[0]?.type === 'page') {
			router.push(`${langPrefix.value}${module.children[0].path}`)
		} else {
			router.push(`${langPrefix.value}${module.path}`)
		}
	}

	const setCurrentItem = (item: MenuItem) => {
		if (item.type === 'spacer') {
			return
		}
		router.push(`${langPrefix.value}${item.path}`)
	}

	const toggleMenuFold = () => {
		isMenuUnfolded.value = !isMenuUnfolded.value
	}

	const toggleSwitchMenu = () => {
		isSwitchMenuOpen.value = !isSwitchMenuOpen.value
	}

	const closeSwitchMenu = () => {
		isSwitchMenuOpen.value = false
	}

	return {
		menuItems,
		currentSubMenu,
		isMenuUnfolded,
		isSwitchMenuOpen,
		toggleMenuFold,
		toggleSwitchMenu,
		currentModule,
		currentItem,
		setCurrentModule,
		setCurrentItem,
	}
}
