<script setup lang="ts">
import { type DocumentsSearchInput, useDocumentSearch } from '@composables/search/use-search'

import { useSettings } from '@composables/settings/use-settings'
import { type Space } from '@services/nolas-api'
import { useURLState } from '@url-states/composables/use-url-state'
import { UrlStateContextKeys } from '@url-states/utils'
import { useDocumentsSelection } from '@workbench/composables/use-documents-selection'
import { buildQuerySortId, draftsContextKey, type SortOrder } from '@workbench/doclist/use-drafts'
import { inboxContextKey } from '@workbench/doclist/use-inbox'
import { sentContextKey } from '@workbench/doclist/use-sent'
import { BaseTableParams, fromHit } from '@workbench/doclist/use-table-state'
import { trashContextKey } from '@workbench/doclist/use-trash'
import {
	DraftsTableProperties,
	InboxTableProperties,
	SentTableProperties,
	TrashTableProperties,
} from '@workbench/utils/tables'
import { computed, provide, watch } from 'vue'

const props = defineProps<{
	space: Exclude<Space, 'FOLDER'>
}>()

let contextKey
let urlStateKey

switch (props.space) {
	case 'DRAFTS': {
		DraftsTableProperties.provideTableProperties()
		contextKey = draftsContextKey
		urlStateKey = UrlStateContextKeys.drafts
		break
	}
	case 'INBOX': {
		InboxTableProperties.provideTableProperties()
		contextKey = inboxContextKey
		urlStateKey = UrlStateContextKeys.inbox
		break
	}
	case 'SENT': {
		SentTableProperties.provideTableProperties()
		contextKey = sentContextKey
		urlStateKey = UrlStateContextKeys.sent
		break
	}
	case 'TRASH': {
		TrashTableProperties.provideTableProperties()
		contextKey = trashContextKey
		urlStateKey = UrlStateContextKeys.trash
		break
	}
}

const { query } = useURLState<typeof BaseTableParams>({ key: urlStateKey })

const searchInput = computed<DocumentsSearchInput>(() => ({
	space: props.space,
	filterBy: {
		classification: query.classification ?? undefined,
		'subclassification.secondaryLabel': query.subclassification ?? undefined,
		mimeType: query.format ?? undefined,
		channel: query.channel ?? undefined,
		'workflow.name': query.workflow?.name ?? undefined,
	},
	...(props.space === 'INBOX' && {
		filterByExclude: {
			status: 'PENDING',
		},
	}),
	limit: query.limit,
	page: query.page,
	q: query.q || undefined,
}))

const { searchResult, ...fetchState } = useDocumentSearch(searchInput)
const { selectedDocuments } = useDocumentsSelection()

const { classes } = useSettings({ search: '' })

const documents = computed(() => searchResult.value?.hits?.map(hit => fromHit(hit.document, classes.value)) || [])
const total = computed(() => searchResult.value?.found)

const selectedDocumentsSize = computed(() => {
	let size = 0
	size = documents.value
		.filter(doc => selectedDocuments.value.includes(doc.id))
		.map(doc => doc.size)
		.reduce((partialSum, a) => partialSum + a, 0)

	return size / 1024 / 1024
})

const facets = computed(() => searchResult.value?.facet_counts || [])

function setSort(id: string, order: SortOrder) {
	query.page = 1
	query.sort = `${buildQuerySortId(id)}:${order}`
}

const state = {
	table: query,
	documents,
	isLoading: fetchState.isLoading,
	total,
	facets,
	setSort,
	selectedDocumentsSize,
}

watch(
	() => state.table.q,
	() => {
		state.table.page = 1
	}
)

provide(contextKey, state)
</script>

<template>
	<slot />
</template>