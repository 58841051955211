import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

type LabelsMap = Record<string, string>

const customBreadcrumbsLabels = ref<LabelsMap>({})

export function useBreadcrumbs() {
	function setBreadcrumbLabel(key: string, value: string) {
		customBreadcrumbsLabels.value[key] = value
	}

	function resetBreadcrumbLabels(key?: string) {
		if (!key) {
			customBreadcrumbsLabels.value = {}
			return
		}

		delete customBreadcrumbsLabels.value[key]
	}

	function getBreadcrumbLabel(pageTitle: string) {
		const { t } = useI18n()

		const customLabel = customBreadcrumbsLabels.value[pageTitle] || undefined

		return customLabel || t(`breadcrumbs.app.${pageTitle}`)
	}

	return {
		customBreadcrumbsLabels,
		setBreadcrumbLabel,
		resetBreadcrumbLabels,
		getBreadcrumbLabel,
	}
}
