import { createQueryKeys } from '@lukemorales/query-key-factory'
import { ListFolders } from '@services/nolas-api'
import { useQuery } from '@tanstack/vue-query'
import type { Ref } from 'vue'

import { ServiceError } from '@nolas/lib/errors'

import { useUser } from '@auth/composables/use-user'

export const foldersQueryKey = createQueryKeys('folders')

async function fetchFolders(workspaceId: string) {
	const { response, data, error } = await ListFolders({
		params: {
			path: {
				workspaceId,
			},
			query: {
				hierarchical: 'true',
			},
		},
	})

	if (!response.ok || !data) {
		throw ServiceError.fromResponse(error)
	}
	return data
}

export function useFolders() {
	const { workspaceId } = useUser()

	const {
		data: folders,
		error,
		isLoading,
	} = useQuery({
		queryKey: foldersQueryKey._def,
		retry: 2,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: 1000,
		queryFn: () => fetchFolders(workspaceId.value),
	})

	return { folders, isLoading, error: error as Ref<ServiceError | null> }
}
