<script setup lang="ts">
import { Button } from '@nolas/design-system/button'
import { Paragraph } from '@nolas/design-system/paragraph'
</script>

<template>
  <div class="w-96 px-3">
    <INolasLogoBig class="mx-auto h-32 w-48" />
    <Paragraph class="mb-8 flex flex-col" align="center">
      <span> {{ $t('auth.flow.reset_password.success.line_1') }} </span>
      <span> {{ $t('auth.flow.reset_password.success.line_2') }} </span>
    </Paragraph>
    <Button as="router-link" to="/auth" variant="secondary" size="lg" class="w-full" data-e2e="login">
      {{ $t('common.continue') }}
    </Button>
  </div>
</template>
