import { cva, type VariantProps } from 'class-variance-authority'

export { default as Heading } from './heading.vue'

export const headingVariants = cva('text-gray-900', {
	variants: {
		size: {
			xxs: 'font-semibold text-xs',
			xs: 'font-semibold text-sm',
			sm: 'font-semibold text-base',
			md: 'font-semibold text-lg',
			lg: 'font-bold text-xl',
			xl: 'font-bold text-2xl',
			'2xl': 'font-semibold text-3xl',
		},
		align: {
			left: 'text-left',
			center: 'text-center',
			right: 'text-right',
		},
	},
	defaultVariants: {
		size: 'md',
		align: 'left',
	},
})

type Variants = VariantProps<typeof headingVariants>
export interface HeadingProps {
	size?: Variants['size']
	align?: Variants['align']
	as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
	bold?: boolean
}
