import { inject } from 'vue'
import { sendingSettingsContextKey } from '@sending-settings/utils'

export function useSendingSettings() {
	const context = inject(sendingSettingsContextKey)
	if (!context) {
		throw new Error('Please use `provideSendingSettings` before calling this function.')
	}

	return context
}
