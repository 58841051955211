<script setup lang="ts">
import { onBeforeUnmount } from 'vue'

import { cn } from '@nolas/design-system/utils'
import { SidebarRoot, SidebarHeader, SidebarContent, SidebarRail } from '.'

import { SIDEBAR_WIDTH_ICON } from './utils'

import { type SidebarProps, useSidebarLeft } from '.'

defineOptions({
	inheritAttrs: false,
})

const { type, width, close, isOpen } = useSidebarLeft()

onBeforeUnmount(async () => {
	if (type.value === 'normal') {
		await close()
	}
})

const props = withDefaults(defineProps<SidebarProps>(), {
	variant: 'sidebar',
})
</script>

<template>
  <div
    :style="{
      '--sidebar-width': isOpen ? width : 0,
      '--sidebar-width-icon': isOpen ? SIDEBAR_WIDTH_ICON : 0,
    }"
    :class="cn('group/sidebar-wrapper has-[[data-variant=inset]]:bg-sidebar flex min-h-svh')"
    v-bind="$attrs"
  >
    <SidebarRoot v-bind="props" :collapsible="type === 'navigation' ? 'icon' : 'offcanvas'">
      <SidebarHeader>
        <slot name="header" />
      </SidebarHeader>
      <SidebarContent>
        <slot />
        <slot name="footer" />
      </SidebarContent>
      <SidebarRail />
    </SidebarRoot>
  </div>
</template>
