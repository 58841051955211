import type { InjectionKey, Ref } from 'vue'

import type { Tables } from '@workbench/docview/utils/util'

export const views = ['default', 'accounting'] as const
export type View = (typeof views)[number]

export const tabs = ['sending_settings', 'preview', 'details', 'workflow', 'history'] as const
export type Tab = (typeof tabs)[number]

export type TabsPreference = {
	[key in Tables]: Tab
}

export interface SideDrawerContext {
	open: Ref<boolean>
	transitionAppear: Ref<boolean>
	transitionType: Ref<'open' | 'close'>
	setOpen(open: boolean): void
	/**
	 * Current view of the side drawer.
	 *
	 * `default` - The tabs are displayed.
	 *
	 * `accounting` - The form for e-invoicing is displayed.
	 */
	view: Ref<View>
	setView(view: View): void
	tab: Ref<TabsPreference>
	setTab(table: Tables, tab: Tab): void
}

export const contextKey = Symbol('docviewsidedrawer') as InjectionKey<SideDrawerContext>
