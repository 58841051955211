<script setup lang="ts">
import { cn } from '../../utils'

import type { CustomSelectItemProps } from '.'

defineProps<CustomSelectItemProps>()
</script>

<template>
  <template v-if="item.icon">
    <component
      :is="item.icon"
      v-if="typeof item.icon !== 'string'"
      :class="cn('h-6 w-6 min-w-6 text-gray-400', iconClasses)"
    />
    <span v-else :class="cn('h-6 w-6 min-w-6 text-gray-400', iconClasses, String(item.icon))" />
  </template>
</template>
