<script setup lang="ts">
import { cn } from '../../utils'

import { headingVariants, type HeadingProps } from '.'

withDefaults(defineProps<HeadingProps>(), {
	as: 'h1',
})
</script>

<template>
  <component :is="as" :class="cn(headingVariants({ size, align }), $attrs.class ?? '')">
    <slot />
  </component>
</template>
