import { inject } from 'vue'
import { contextKey } from '@url-states/utils'

import type { z } from 'zod'
import type { BaseURLState, BaseSchema } from '@url-states/utils'

export function useURLState<S extends z.ZodSchema = typeof BaseSchema>(options?: { key?: symbol }) {
	const _contextKey = options?.key || contextKey
	const state = inject(_contextKey)
	if (!state) {
		throw new Error('Please wrap your component with URLStateProvider before using this.')
	}

	return state as BaseURLState<S>
}
