<script setup lang="ts">
import { cn } from '../../utils'

import { paragraphVariants, type ParagraphProps } from '.'

withDefaults(defineProps<ParagraphProps>(), {
	as: 'p',
	bold: false,
})
</script>

<template>
  <component
    :is="as"
    :class="
      cn(
        '',
        paragraphVariants({ size, align, weight, color }),
        $attrs.class ?? '',
        $attrs.bold ? 'font-bold' : 'font-normal'
      )
    "
    :data-e2e="dataE2e"
  >
    <slot />
  </component>
</template>
