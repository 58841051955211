<script setup lang="ts">
import { Checkbox } from '@nolas/design-system/checkbox'
import { Label } from '@nolas/design-system/label'

const modelValue = defineModel<boolean>()
</script>

<template>
  <div class="mb-6 flex items-center space-x-2">
    <Checkbox id="terms" v-model="modelValue" />
    <Label
      for="terms"
      class="text-xs font-medium leading-none text-gray-600 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
    >
      {{ $t('auth.common.terms.string') }}
      <a href="/auth/terms" target="_blank" class="text-crm-blue-600 underline" data-e2e="terms-link">{{
        $t('auth.common.terms.cta')
      }}</a>
    </Label>
  </div>
</template>
