import { type Ref, computed, ref } from 'vue'

import { ServiceError } from '@nolas/lib/errors'
import { type Document, GetDocument } from '@services/nolas-api'
import type { DocumentAssignmentID } from '@services/nolas-api'
import { useQuery } from '@tanstack/vue-query'
import { useRoute } from 'vue-router'

import { useUser } from '@auth/composables/use-user'

import { QK } from '@queries'

//TODO: this formatter can be removed once the backend data is migrated to the new format
function formatDocumentProperties({ document }: Document): Document['document']['properties'] {
	if (Array.isArray(document.properties)) {
		return {
			pageProperties: document.properties,
		}
	}
	const letterhead =
		typeof document?.properties?.activeLetterhead === 'string'
			? document?.properties?.activeLetterhead
			: document?.properties?.activeLetterhead?.value

	return {
		pageProperties: document?.properties?.pageProperties,
		activeLetterhead: letterhead || undefined,
		sheets: document?.properties?.sheets,
	}
}

export function useDocument() {
	const { workspaceId } = useUser()

	const route = useRoute()
	const id = ref<string>(route.params.document as DocumentAssignmentID)

	const setId = (newId: string) => {
		id.value = newId
	}

	const queryKey = computed(() => QK.documents.document(id.value).queryKey)

	const {
		data: document,
		error,
		...queryRest
	} = useQuery({
		queryKey,
		retry: 2,
		enabled: () => Boolean(id.value),
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		refetchInterval: query => {
			const tasks = Object.values(query.state.data?.document.processingStatus || {})
			const isStatusPending = tasks.some(task => task.status === 'PENDING')

			const refetchInterval = isStatusPending ? 500 : false

			return import.meta.env.DEV ? false : refetchInterval
		},
		gcTime: 10,
		staleTime: 1000,
		queryFn: async ({ queryKey }) => {
			const [, , id] = queryKey

			const { response, data, error } = await GetDocument({
				params: { path: { documentAssignmentId: id ?? '', workspaceId: workspaceId.value } },
			})

			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}
			return data
		},
		select: (data: Document) => {
			// Transform the data here to have activeLetterhead as a string
			return {
				...data.document,
				children: data.children,
				properties: formatDocumentProperties(data),
				activeWorkflow: data.activeWorkflow,
			}
		},
	})

	return { document, error: error as Ref<ServiceError | null>, setId, ...queryRest }
}
