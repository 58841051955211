import parsePhoneNumber from 'libphonenumber-js'
import { z } from 'zod'

import { isValidUrl } from '@nolas/lib/text-utils'

const addressSchema = z
	.object({
		country: z.string().trim().nullish(),
		region: z.string().trim().nullish(),
		street: z.string().trim().nullish(),
		houseNumber: z.string().trim().nullish(),
		zipCode: z.string().trim().nullish(),
		city: z.string().trim().nullish(),
		addressLine: z.string().trim().nullish(),
	})
	.superRefine((data, ctx) => {
		if (data.country) {
			if (data.country === 'DE') {
				// if (!data.region) {
				//   ctx.addIssue({
				//     code: 'custom',
				//     message: 'common.error.required',
				//     path: ['region'],
				//   })
				// }
				if (!data.street) {
					ctx.addIssue({
						code: 'custom',
						message: 'common.error.required',
						path: ['street'],
					})
				}
				if (!data.houseNumber) {
					ctx.addIssue({
						code: 'custom',
						message: 'common.error.required',
						path: ['houseNumber'],
					})
				}
				if (!data.zipCode) {
					ctx.addIssue({
						code: 'custom',
						message: 'common.error.required',
						path: ['zipCode'],
					})
				}
				if (!data.city) {
					ctx.addIssue({
						code: 'custom',
						message: 'common.error.required',
						path: ['city'],
					})
				}
			} else {
				if (!data.addressLine) {
					ctx.addIssue({
						code: 'custom',
						message: 'common.error.required',
						path: ['addressLine'],
					})
				}
			}
		} else {
			ctx.addIssue({
				code: 'custom',
				message: 'common.error.required',
				path: ['country'],
			})
		}
	})

const invalidUrlSchema = z
	.string()
	.trim()
	.min(1, { message: 'common.error.required' })
	.superRefine((value, ctx) => {
		if (!value) return
		if (!isValidUrl(value)) {
			ctx.addIssue({
				code: 'custom',
				message: 'common.error.invalid_url',
			})
		}
	})

export const extraFieldsSchema = z.array(
	z.union([
		z.object({
			name: z.literal('address'),
			value: addressSchema,
		}),
		z.object({
			name: z.literal('firstName'),
			value: z.union([z.string().trim().email().nullish(), z.literal('')]).optional(),
		}),
		z.object({
			name: z.literal('lastName'),
			value: z.union([z.string().trim().email().nullish(), z.literal('')]).optional(),
		}),
		z.object({
			name: z.literal('email'),
			value: z
				.string()
				.trim()
				.email({ message: 'common.error.invalid_string_email' })
				.min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('phone'),
			value: z
				.string()
				.trim()
				.superRefine((value, ctx) => {
					const phoneNumber = parsePhoneNumber(value)
					if (!phoneNumber?.isValid()) {
						ctx.addIssue({
							code: 'custom',
							message: 'common.error.invalid_phone_number',
						})
					}
				}),
		}),
		z.object({
			name: z.literal('website'),
			value: invalidUrlSchema,
		}),
		z.object({
			name: z.literal('invoiceFormat'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('notes'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('function'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('social'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('relatedTo'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('social'),
			value: invalidUrlSchema,
		}),
		z.object({
			name: z.literal('beA'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('peppol'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('KIM'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('eBO'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('vatID'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('CRN'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('creditorID'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
		z.object({
			name: z.literal('leitwegID'),
			value: z.string().trim().min(1, { message: 'common.error.required' }),
		}),
	])
)

export const schema = z
	.object({
		name: z.string().trim().min(1, { message: 'common.error.required' }),
		type: z.enum(['organisation', 'person', 'authority']).optional(),
		isHeadquarter: z.boolean().optional(),
		inviteImmediately: z.boolean().optional(),
		folder: z.string().trim().optional(),
		extra: extraFieldsSchema.optional(),
	})
	.superRefine(({ folder }, ctx) => {
		if (!folder) {
			ctx.addIssue({
				code: 'custom',
				message: 'common.error.required',
				path: ['folder'],
			})
		}
	})

export type AddressBookFieldSchema = z.infer<typeof schema>
