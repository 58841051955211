import type { Component, ComputedRef, Ref, InjectionKey } from 'vue'

export { default as Dialog } from './dialog.vue'
export { default as DialogActions } from './dialog-actions.vue'
export { default as DialogSidePreview } from './templates/dialog-side-preview.vue'
export { default as DialogConfirmation } from './templates/dialog-confirmation.vue'
export { default as DialogCustom } from './templates/dialog-custom.vue'

export { default as DialogProvider } from './dialog-provider.vue'
// TODO: see what we do with the overlay
export { default as DialogOverlay } from './dialog-overlay.vue'
export { default as DialogHeader } from './dialog-header.vue'
export { default as DialogTitle } from './dialog-title.vue'
export { default as DialogDescription } from './dialog-description.vue'
export { default as DialogContent } from './dialog-content.vue'

// LEGACY DIALOG
export { default as DialogLegacy } from './dialog-legacy/dialog-legacy.vue'
export { default as DialogLegacyTrigger } from './dialog-legacy/dialog-legacy-trigger.vue'
export { default as DialogLegacyHeader } from './dialog-legacy/dialog-legacy-header.vue'
export { default as DialogLegacyTitle } from './dialog-legacy/dialog-legacy-title.vue'
export { default as DialogLegacyDescription } from './dialog-legacy/dialog-legacy-description.vue'
export { default as DialogLegacyContent } from './dialog-legacy/dialog-legacy-content.vue'
export { default as DialogLegacyFooter } from './dialog-legacy/dialog-legacy-footer.vue'
export { default as DialogLegacyClose } from './dialog-legacy/dialog-legacy-close.vue'
export { default as DialogLegacyOverlay } from './dialog-legacy/dialog-legacy-overlay.vue'

export type DialogType = 'confirmation' | 'form' | 'preview'
export interface DialogProps {
	id: string
	title: string
	type: DialogType
	description?: string
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'custom'
	headerDivider?: boolean
	templateProps?: Record<string, unknown>
	icon?: Component
	onSuccess?: (data?: unknown) => Promise<void> | void
	onClose?: () => void
}

export type DialogConfirmationVariant = 'success' | 'warning' | 'danger'

export interface DialogComponentWithProps {
	as: Component
	props?: Record<string, unknown>
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	on?: Record<string, (...args: any[]) => void>
}

export type DialogSlotsType = 'header' | 'actions' | 'content' | 'footer'
export type DialogSlots = Record<string, DialogComponentWithProps>

export interface DialogComponent {
	component: DialogComponentWithProps
	wrapper: Component | string
	props: DialogProps
	slots?: DialogSlots
}

export interface DialogContext {
	dialogs: Ref<DialogComponent[]>
	isOpen: ComputedRef<boolean>
	lastDialog: ComputedRef<DialogComponent | undefined>
	slots?: ComputedRef<DialogSlots>
	openDialog: (component: DialogComponentWithProps, props: DialogProps, slots?: DialogSlots) => Promise<void>
	closeDialog: () => Promise<void>
	resetDialog: () => void
	patchModal: (props: DialogProps) => void
	handleClose: () => Promise<void>
	handleSuccess: () => Promise<void>
}

export const contextKey: InjectionKey<DialogContext> = Symbol('nolas-ui:dialog')

export * from './provider'
export * from './use-confirmation-dialog'
