<script setup lang="ts">
import { type Component } from 'vue'

import DialogActions from '../dialog-actions.vue'
import DialogHeader from '../dialog-header.vue'

defineProps<{
  title: string
  description?: string
  icon?: Component
  primaryText?: string
  secondaryText?: string
  primaryType?: 'button' | 'submit'
  primaryLoading?: boolean
  primaryDisabled?: boolean
  closeOnPrimary?: boolean
  dataE2e?: string
  previewSpan?: string
  hideActionButtons?: boolean
}>()

const emit = defineEmits(['submit', 'cancel'])

const handlePrimaryClick = () => {
  emit('submit')
}

const handleSecondaryClick = () => {
  emit('cancel')
}
</script>

<template>
  <div class="flex h-full w-full">
    <div class="h-full" :class="previewSpan || 'w-3/5'">
      <slot name="preview" />
    </div>

    <div
      class="flex h-full flex-col"
      :class="previewSpan ? `w-[calc(100%-${previewSpan})]` : 'w-2/5'"
    >
      <div class="px-10 pt-10">
        <DialogHeader :icon="icon" :title="title" :description="description" />
      </div>

      <div class="flex-1 overflow-auto px-10 py-6">
        <slot />
      </div>

      <div v-if="!hideActionButtons" class="px-10 pb-10">
        <DialogActions
          :primary-text="primaryText || 'Save'"
          :secondary-text="secondaryText || 'Cancel'"
          :primary-type="primaryType || 'button'"
          :primary-loading="primaryLoading"
          :primary-disabled="primaryDisabled"
          :close-on-primary="closeOnPrimary"
          :primary-data-e2e="`${dataE2e}-submit`"
          :secondary-data-e2e="`${dataE2e}-cancel`"
          @primary-click="handlePrimaryClick"
          @secondary-click="handleSecondaryClick"
        >
          <template v-if="$slots.footerStart" #start>
            <slot name="footerStart" />
          </template>
        </DialogActions>
      </div>
    </div>
  </div>
</template>
