<script lang="ts" setup>
import { computed, provide, reactive, ref, toRefs, watch } from 'vue'

import deepEqual from 'deep-eql'
import { uniqBy } from 'lodash'

import { useDocumentsSelection } from '@workbench/composables/use-documents-selection'
import { useExtractRecipients } from '@workbench/doclist/use-extract-recipients'
import { useSendingSettingsPreventSelect } from '@sending-settings/composables/use-sending-settings-prevent-select'

import { sendingSettingsContextKey } from '@sending-settings/utils'
import type { DocumentAssignmentID } from '@services/nolas-api'
import type {
	AddRecipient,
	Channels,
	EmailRecipientType,
	NotSendableContact,
	Recipient,
	RecipientItem,
	SendingSettingsContext,
	AttachmentFile,
} from '@sending-settings/types'

const defaultSendingSettingsConfig = {
	isPending: false,
	workflow: undefined,
	isAutomaticSending: false,
	subject: '',
	message: '',
	recipients: {
		to: [] as Recipient[],
		cc: [] as Recipient[],
		bcc: [] as Recipient[],
	},
	recipientsIds: {
		to: [] as RecipientItem[],
		cc: [] as RecipientItem[],
		bcc: [] as RecipientItem[],
	},
	attachments: [],
}

const workflow = ref<string | undefined>(undefined)
const isPending = ref<boolean>(false)
const selectedType = ref<EmailRecipientType>('to')
const notSendableContacts = reactive({
	nolas: [] as NotSendableContact[],
	email: [] as NotSendableContact[],
	post: [] as NotSendableContact[],
})
const isAutomaticSending = ref<boolean>(false)
const isWarningDialogOpen = ref<boolean>(false)
const documents = ref<DocumentAssignmentID[]>([])
const attachments = ref<AttachmentFile[]>([])
const subject = ref<string>('')
const message = ref<string>('')
const isAddressingModalOpen = ref<boolean>(false)
const selectedRecipient = ref<Recipient>()
const recipientsIds = reactive({
	to: [] as RecipientItem[],
	cc: [] as RecipientItem[],
	bcc: [] as RecipientItem[],
})
const recipients = reactive({
	to: [] as Recipient[],
	cc: [] as Recipient[],
	bcc: [] as Recipient[],
})
const nolasRecipients = ref<Recipient[]>([])
const emailRecipients = ref<Recipient[]>([])
const postRecipients = ref<Recipient[]>([])

const detectChangesInSendingSettingsConfig = () => {
	const config = {
		isPending: isPending.value,
		workflow: workflow.value,
		isAutomaticSending: isAutomaticSending.value,
		subject: subject.value,
		message: message.value,
		recipients: recipients,
		recipientsIds: recipientsIds,
		attachments: attachments.value,
	}
	return !deepEqual(config, defaultSendingSettingsConfig)
}

const changesInConfig = computed(() => detectChangesInSendingSettingsConfig())
const { to, cc, bcc } = toRefs(recipients)
const recipientsMap = computed(() => [...to.value, ...cc.value, ...bcc.value])

const notSendableContactsMap = computed(() => [
	...notSendableContacts.nolas,
	...notSendableContacts.email,
	...notSendableContacts.post,
])

const notSendableContactsIds = computed(() => notSendableContactsMap.value.map(contact => contact.id))

const parseRecipients = (recipients: Recipient[], channel: Channels) => {
	const notSendableContactsArray = []
	const recipientsManual = recipients.filter(recipient => recipient.channel === channel)
	const recipientsExtracted = recipientsManual.filter(
		recipient => recipient.channel === channel && recipient.meta?.extracted
	)
	const recipientsFromFolder = recipientsManual.filter(
		recipient => recipient.channel === channel && recipient.meta?.folder
	)
	const recipientsList = [
		...recipientsFromFolder.flatMap(recipient => recipient.value as Recipient[]),
		...recipientsManual,
	]

	for (const recipient of recipientsList.filter(recipient => typeof recipient.value === 'string')) {
		const condition =
			(channel === 'nolas' && (recipient.userId || recipient.email)) ||
			(channel === 'email' && recipient.email) ||
			(channel === 'post' && recipient.address)
		if (!condition) {
			notSendableContactsArray.push({ id: recipient.id, name: recipient.name, folderId: recipient?.folderId })
		}
	}
	notSendableContacts[channel] = uniqBy(notSendableContactsArray, 'id')

	return recipientsExtracted.length > 0
		? recipientsExtracted.flatMap(recipient => recipient.value as Recipient[])
		: recipientsList.filter(
				recipient =>
					recipient.value !== null &&
					((channel === 'nolas' && (recipient.userId || recipient.email)) ||
						(channel === 'email' && recipient.email) ||
						(channel === 'post' && recipient.address))
			)
}

watch(
	() => recipientsMap,
	newRecipients => {
		emailRecipients.value = parseRecipients(newRecipients.value, 'email')
		postRecipients.value = parseRecipients(newRecipients.value, 'post')
		nolasRecipients.value = parseRecipients(newRecipients.value, 'nolas')
	},
	{ immediate: true, deep: true }
)

const allParsedRecipientsIds = computed(() =>
	[
		...nolasRecipients.value.map(recipient => recipient.id),
		...emailRecipients.value.map(recipient => recipient.id),
		...postRecipients.value.map(recipient => recipient.id),
		...notSendableContactsIds.value,
	].flat()
)

const onAddRecipients = (items: AddRecipient[], type: EmailRecipientType) => {
	const channels = {
		email: 'email',
		normal: 'post',
		'address-book': 'nolas',
	}

	for (const item of items) {
		const newRecipient = {
			id: item.id,
			isSelected: false,
			name: item?.name,
			email: item.type === 'email' && typeof item.value === 'string' ? item.value : item?.email,
			address: item?.address,
			assignmentId: item?.assignmentId,
			userId: item?.userId,
			preferedChannel: item?.preferedChannel,
			availableChannels: item?.availableChannels || [channels[item.type] as Channels],
			meta: item?.meta,
			channel: (item?.channel as Channels) || item?.preferedChannel || (channels[item.type] as Channels),
			value: item.value,
		}

		recipients[type] = uniqBy([...recipients[type], newRecipient], 'id')
		recipientsIds[type] = uniqBy(
			[
				...recipientsIds[type],
				{
					id: item.id,
					value: item.value,
					name: item?.name || item?.email || '',
					channel: newRecipient.channel,
					meta: item?.meta,
				},
			],
			'id'
		)
	}
}

const { selectedDocuments } = useDocumentsSelection()
const { setAreAddressesAlreadyExtracted } = useExtractRecipients()
const { initiatePreventSelect, changePreventSelectState, preventSelectState } = useSendingSettingsPreventSelect()

const reset = () => {
	isPending.value = defaultSendingSettingsConfig.isPending
	selectedType.value = 'to'
	workflow.value = defaultSendingSettingsConfig.workflow
	isAutomaticSending.value = defaultSendingSettingsConfig.isAutomaticSending
	documents.value = []
	isWarningDialogOpen.value = false
	subject.value = defaultSendingSettingsConfig.subject
	message.value = defaultSendingSettingsConfig.message
	isAddressingModalOpen.value = false
	recipients.to = defaultSendingSettingsConfig.recipients.to
	recipients.cc = defaultSendingSettingsConfig.recipients.cc
	recipients.bcc = defaultSendingSettingsConfig.recipients.bcc
	recipientsIds.to = defaultSendingSettingsConfig.recipientsIds.to
	recipientsIds.cc = defaultSendingSettingsConfig.recipientsIds.cc
	recipientsIds.bcc = defaultSendingSettingsConfig.recipientsIds.bcc
	attachments.value = defaultSendingSettingsConfig.attachments
	selectedRecipient.value = undefined
	preventSelectState.value = undefined
	notSendableContacts.nolas = []
	notSendableContacts.email = []
	notSendableContacts.post = []
	setAreAddressesAlreadyExtracted(false)
}

const resetDataAndPromise = (data: boolean) => {
	changePreventSelectState(data)
	reset()
}

watch(
	() => selectedDocuments.value,
	newDocumentsIds => {
		documents.value = newDocumentsIds
	}
)

watch(
	changesInConfig,
	hasChanges => {
		if (hasChanges) {
			initiatePreventSelect()
		} else {
			preventSelectState.value = undefined
		}
	},
	{ deep: true }
)

const context: SendingSettingsContext = {
	isPending,
	workflow,
	selectedType,
	notSendableContacts,
	notSendableContactsIds,
	isAutomaticSending,
	documents,
	subject,
	message,
	recipients,
	recipientsIds,
	attachments,
	isAddressingModalOpen,
	isWarningDialogOpen,
	changesInConfig,
	selectedRecipient,
	preventSelectState,
	recipientsMap,
	emailRecipients,
	postRecipients,
	nolasRecipients,
	allParsedRecipientsIds,
	notSendableContactsMap,
	reset,
	resetDataAndPromise,
	changePreventSelectState,
	onAddRecipients,
}

provide(sendingSettingsContextKey, context)
</script>

<template>
  <slot />
</template>