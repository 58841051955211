<script lang="ts" setup>
import { useFormField } from './use-form-field'

const { formDescriptionId } = useFormField()
</script>

<template>
  <p :id="formDescriptionId" :class="['text-sm text-gray-600 font-normal mt-1.5', $attrs.class]">
    <slot />
  </p>
</template>
