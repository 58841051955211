import { ref } from 'vue'

interface Feedback {
	warning: string
	suggestions: string[]
}

const score = ref(0)
export const usePasswordStrength = () => {
	const setScore = (value: number) => {
		score.value = value
	}
	return { score, setScore }
}

const feedback = ref<Feedback | undefined>()
export const usePasswordFeedback = () => {
	const setFeedback = (value?: Feedback) => {
		feedback.value = value
	}
	return { feedback, setFeedback }
}
