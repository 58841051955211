import { UpdatePreferences } from '@services/nolas-api'
import { QK } from '@queries'
import { useUser } from '@auth/composables/use-user'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { ServiceError } from '@nolas/lib/errors'

import { useUserPreferences } from './use-user-preferences'

import type { Ref } from 'vue'
import type { PreferredColumnProperties } from '../types'

export function useUpdateTablePreferences<T extends string>() {
	const queryClient = useQueryClient()
	const { userId, workspaceId } = useUser()
	const preferences = useUserPreferences()

	const {
		mutateAsync: updateTablePreferences,
		error,
		...mutation
	} = useMutation({
		mutationFn: async (payload: { table: T; columns: PreferredColumnProperties<string>[] }) => {
			const { response, data, error } = await UpdatePreferences({
				body: {
					workspaceId: workspaceId.value,
					userId: userId.value,
					preferences: {
						...preferences.value,
						[payload.table]: payload.columns,
					},
				},
			})

			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}

			return data
		},
		onSuccess() {
			queryClient.invalidateQueries({ queryKey: QK.users.me._def })
		},
	})

	return { updateTablePreferences, error: error as Ref<ServiceError | null>, ...mutation }
}
