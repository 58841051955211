<script setup lang="ts">
import { cn } from '../../utils'
import { Button } from '../button'
import { Paragraph } from '../paragraph'
import DropdownMenuItem from './dropdown-menu-item.vue'

import type { Item } from './utils'

const props = withDefaults(defineProps<Item & { class?: string; iconClass?: string }>(), {
	iconPosition: 'left',
	class: '',
	iconClass: '',
})

const _iconClass = [
	'pointer-events-none h-5 w-5 min-w-5',
	props.iconClass?.includes('text-') ? '' : 'text-gray-900',
	props.description ? 'mt-1' : '',
	props.iconClass,
]
</script>

<template>
  <div v-if="props.id === 'divider'" class="px-4">
    <hr class="my-2 h-px w-full bg-gray-250" />
  </div>
  <DropdownMenuItem  v-else as-child>
    <Button
      v-bind="{
        to: props.url,
        as: props.url ? 'router-link' : 'button',
        class: cn(
          'min-h-10 !h-auto w-full justify-start !gap-2 whitespace-nowrap rounded-none bg-white text-left hover:bg-gray-200',
          props.description ? '!items-start' : '',
          props.class
        ),
        disabled: props.disabled,
        variant: 'transparent',
      }"
      :data-e2e="props.id"
      v-on="{ click: props.callback }"
    >
      <component :is="props.icon" v-if="props.icon" :class="_iconClass" :data-icon-left="true" />

      <div>
        <Paragraph as="p" size="sm" weight="medium" color="gray-900" class="whitespace-pre-line">
          {{ props.label }}
        </Paragraph>
        <Paragraph
          v-if="props.description"
          as="p"
          size="xs"
          weight="medium"
          color="gray-600"
          class="whitespace-pre-line"
        >
          {{ props.description }}
        </Paragraph>
      </div>

      <component
        :is="props.iconRight"
        v-if="props.iconRight"
        :class="[_iconClass, 'ml-auto']"
        :data-icon-right="true"
      />
    </Button>
  </DropdownMenuItem>
</template>
