import { filesize } from 'filesize'

const mimeTypes: Record<string, string> = {
	'application/pdf': 'pdf',
	'application/pdf-zugferd': 'zug',
	'application/postscript': 'ps',
	'image/png': 'png',
	'image/jpeg': 'jpg',
	'text/csv': 'csv',
	'text/xml': 'xml',
	'application/xml': 'xml',
	'application/msword': 'doc',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
	'application/vnd.ms-powerpoint': 'ppt',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
	'application/vnd.ms-excel': 'xls',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
	'application/x-cfb': 'oldMicrosoft',
	'application/rtf': 'rtf',
	'text/rtf': 'rtf',
	'text/plain': 'txt',
}

const documentType = (mimeType?: string, fileName?: string) => {
	let fileType: string | undefined = mimeTypes[mimeType || '']

	if (fileType === 'oldMicrosoft' && fileName) {
		fileType = fileName.split('.').pop()
	}

	if (fileType) {
		fileType = fileType.toUpperCase()
	}

	return fileType || '-'
}

export type ConvertFileSizeArgs = {
	// ensure "locale: useI18n().locale" exists when calling this func
	locale: string
	[key: string]: unknown
}
const convertFileSize = (size: number, options: ConvertFileSizeArgs) => {
	return filesize(size, options)
}

export const useFileFormat = () => {
	return {
		convertFileSize,
		documentType,
	}
}
