import { CompleteOnboarding, type CompleteOnboardingRequest } from '@services/nolas-api'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServiceError } from '@nolas/lib/errors'
import { toast } from '@nolas/design-system/sonner'

import { contactsQueryKey } from '@common/composables/search/use-contact-search'
import { useUser } from '@auth/composables/use-user'
import { QK } from '@queries'

export function useCompleteOnboarding() {
	const { t } = useI18n()
	const { workspaceId } = useUser()
	const queryClient = useQueryClient()

	const {
		mutateAsync: completeOnboarding,
		error,
		...mutation
	} = useMutation({
		mutationFn: async (payload: CompleteOnboardingRequest['body']) => {
			const { response, data, error } = await CompleteOnboarding({
				params: { path: { workspaceId: workspaceId.value } },
				body: payload,
			})

			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}
			return data
		},
		onSuccess: (_, variables) => {
			queryClient.invalidateQueries({ queryKey: ['get-onboarding-data'] })
			queryClient.invalidateQueries({ queryKey: contactsQueryKey._def })
			queryClient.invalidateQueries({ queryKey: [QK.users.me._def] })
			if (variables?.paymentInfo) {
				toast.success(t('app.keyflowz.onboarding.notification.success'))
			} else {
				toast.success(t('app.keyflowz.onboarding.notification.success_without_payment_information'))
			}
		},
		onError: () => {
			toast.error(t('app.keyflowz.onboarding.notification.error'))
		},
	})

	return { completeOnboarding, error: error as Ref<ServiceError | null>, ...mutation }
}
