import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { MaybeRef, Ref } from 'vue'
import type { TypeSenseContact } from '@services/nolas-api'
import type { ContactSearchInput } from '@composables/search/use-contact-search'

export const search = createQueryKeys('search', {
	contacts: () => ['contacts'],
	dynamicFacets: () => ['dynamic-facets'],
	recipients: () => ['recipients'],
	key: () => ['search-key'],
	documents: () => ['documents'],
	counts: () => ['counts'],
	contact: (id: string) => [id],
	contactSearch: (search: MaybeRef<ContactSearchInput>, myContact: Ref<TypeSenseContact>) => [search, myContact],
})
