<script setup lang="ts">
import { type ClassValue } from 'clsx'
import { DialogRoot, type DialogContentProps } from 'reka-ui'
import { useSlots } from 'vue'

import { cn } from '../../../utils'

import {
  DialogLegacyTrigger,
  DialogLegacyHeader,
  DialogLegacyTitle,
  DialogLegacyDescription,
  DialogLegacyContent,
  DialogLegacyFooter,
} from '.'

const slots = useSlots()

const props = withDefaults(
  defineProps<
    DialogContentProps & {
      id: string
      class?: ClassValue
      contentClass?: ClassValue
      showCloseButton?: boolean
      hideOverlay?: boolean
      overlayClasses?: ClassValue
      footerClasses?: ClassValue
      contentSectionClasses?: ClassValue
      titleClasses?: ClassValue
      dataE2e?: string
      description?: string
      title?: string
      titleWrapperClasses?: ClassValue
      noTruncateTitle?: boolean
    }
  >(),
  {
    showCloseButton: false,
    hideOverlay: false,
    overlayClasses: '',
    footerClasses: '',
    contentSectionClasses: '',
    titleClasses: '',
    titleWrapperClasses: '',
    noTruncateTitle: false,
    class: '',
    contentClass: '',
    dataE2e: '',
    description: '',
    title: '',
  }
)
const handleKeyDown = (e: KeyboardEvent) => {
  e.preventDefault()
  e.stopPropagation()
}
</script>

<template>
  <DialogRoot :data-e2e="`${id}-modal`" @keydown="handleKeyDown">
    <DialogLegacyTrigger as-child>
      <slot name="trigger" />
    </DialogLegacyTrigger>
    <DialogLegacyContent
      :class="cn('', props.class)"
      :show-close-button="showCloseButton"
      :content-class="contentClass"
      :data-e2e="`${id}-modal-content`"
      @interact-outside="(e) => e.preventDefault()"
      @escape-key-down="handleKeyDown"
    >
      <template v-if="!slots.preview" #header>
        <div v-if="title" :class="cn('px-10 pt-10', titleWrapperClasses)">
          <DialogLegacyHeader
            class="flex flex-col gap-5"
            :data-e2e="`${id}-modal-header`"
          >
            <DialogLegacyTitle
              :class="
                cn(
                  'flex items-center justify-between gap-3 text-xl font-medium',
                  titleClasses
                )
              "
              :data-e2e="`${id}-modal-title`"
            >
              <div class="flex items-center gap-3 text-xl font-medium">
                <slot name="header-icon" />
                <span :class="[{ truncate: !noTruncateTitle }]">{{
                  title
                }}</span>
              </div>
              <slot name="header-action" />
            </DialogLegacyTitle>
            <DialogLegacyDescription
              v-if="description"
              :data-e2e="`${id}-modal-description`"
            >
              {{ description }}
            </DialogLegacyDescription>
          </DialogLegacyHeader>
        </div>
      </template>

      <template #content>
        <div class="flex h-full w-full items-center">
          <slot name="preview" />
          <div :class="cn('h-full w-full px-2', contentSectionClasses)">
            <slot />
          </div>
        </div>
      </template>

      <template v-if="!slots.preview" #footer>
        <DialogLegacyFooter
          v-if="slots.footer"
          :class="cn('w-full bg-white', footerClasses)"
          :data-e2e="`${id}-modal-footer`"
        >
          <slot name="footer" />
        </DialogLegacyFooter>
      </template>
    </DialogLegacyContent>
  </DialogRoot>
</template>
