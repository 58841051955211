<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

import { cn } from '@nolas/design-system/utils'
import { IconButton } from '@nolas/design-system/icon-button'

import { useSidebar } from './provider'

const props = defineProps<{
	class?: HTMLAttributes['class']
}>()

const { properties, side, isNavigationClosed, close } = useSidebar()

const onCloseSidebar = async () => {
	if (properties.value.onClose) {
		properties.value.onClose()
	} else {
		await close()
	}
}
</script>

<template>
  <IconButton
    data-sidebar="trigger"
    :variant="isNavigationClosed ? 'gray' : 'transparent'"
    :class="cn('h-9 w-9 min-w-9', props.class)"
    @click="onCloseSidebar"
  >
    <INolasChevronLeftDouble :class="['h-6 w-6 min-w-6', { 'rotate-180': side === 'left' && !isNavigationClosed }]" />
    <span class="sr-only">Toggle Sidebar</span>
  </IconButton>
</template>
