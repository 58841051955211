<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'

import { Alert } from '@nolas/design-system/alert'
import { Button } from '@nolas/design-system/button'
import { Input } from '@nolas/design-system/input'
import { CardWrapper } from '@nolas/design-system/organisms/card-wrapper'

import { Login } from '@auth/utils/schemas'
import { useLogin } from '@auth/composables/use-login'
import { useRouter } from 'vue-router'
import { FormField } from '@components/form'

const router = useRouter()

const form = useForm({
	validationSchema: toTypedSchema(Login),
	validateOnMount: false,
})

const { login, error, isPending } = useLogin()

const onSubmit = form.handleSubmit(async values => {
	if (error.value?.name === 'IdleAuthenticationError') {
		router.push('/auth')
		return
	}

	await login({ ...values })
})
</script>

<template>
  <CardWrapper :description="$t('auth.flow.login.description')">
    <template #sso-button>
      <Button as="router-link" to="/auth/sso" variant="social" size="lg" class="mb-8 w-full" data-e2e="login-sso">
        {{ $t('auth.button.sso') }}
      </Button>
    </template>

    <Alert v-if="error" variant="destructive" class="mb-4" :description="$t(`common.error.${error.name}`)" />

    <form autocomplete="off" novalidate @submit.prevent="onSubmit">
      <FormField v-slot="{ field }" :form="form" name="email">
        <Input type="email" :label="$t('auth.field.email.label')" autocomplete="email" v-bind="field" />
      </FormField>

      <FormField v-slot="{ field }" :form="form" name="password">
        <Input type="password" :label="$t('auth.field.password.label')" autocomplete="password" v-bind="field" />
      </FormField>

      <Button
        as="router-link"
        to="/auth/forgot-password"
        variant="link"
        size="sm"
        type="submit"
        class="mb-6 w-max"
        data-e2e="forgot-password"
      >
        {{ $t('auth.button.forgot_password') }}
      </Button>

      <Button variant="primary" type="submit" size="lg" class="w-full" :loading="isPending" data-e2e="login">
        {{ $t(error?.name === 'IdleAuthenticationError' ? 'auth.button.try_again' : 'auth.button.login') }}
      </Button>
    </form>
  </CardWrapper>
</template>
