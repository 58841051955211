<script setup lang="ts">
import { usePasswordFeedback } from '@auth/utils/password-strength'

import FeedbackItem from '../components/feedback-item.vue'

const { feedback } = usePasswordFeedback()
</script>

<template>
  <div
    v-if="feedback && (feedback?.warning || (feedback?.suggestions && feedback.suggestions?.length))"
    class="mt-6 flex flex-col gap-3"
  >
    <FeedbackItem v-if="feedback?.warning" type="warning" :message="feedback.warning" />

    <div v-if="feedback?.suggestions && feedback.suggestions?.length" class="flex flex-col gap-3">
      <FeedbackItem v-for="(item, i) in feedback.suggestions" :key="`${item}-${i}`" type="suggestion" :message="item" />
    </div>
  </div>
</template>
