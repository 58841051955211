import type { MaybeRef } from 'vue'
import { unref } from 'vue'
import { DocumentApprovers } from '@services/nolas-api'
import { ServiceError } from '@nolas/lib/errors'
import { createQueryKeys } from '@lukemorales/query-key-factory'

// export type ActionType = 'approval' | 'rejection' | undefined

// export type DocumentApproversQuery = {
// 	action: ActionType
// }

async function fetchDocumentApprovers(
	workspaceId: MaybeRef<string>,
	docAssnId: MaybeRef<string | undefined>
	// query: DocumentApproversQuery
) {
	const { response, data, error } = await DocumentApprovers({
		params: {
			path: {
				workspaceId: unref(workspaceId),
				docAssnId: unref(docAssnId) ?? '',
			},
		},
	})

	if (!response.ok || !data) {
		throw ServiceError.fromResponse(error)
	}
	return data
}

export const documents = createQueryKeys('documents', {
	approvers: (
		workspaceId: MaybeRef<string>,
		docAssnId: MaybeRef<string | undefined>
		// query: DocumentApproversQuery
	) => ({
		queryKey: [docAssnId],
		queryFn: () => fetchDocumentApprovers(workspaceId, docAssnId),
	}),
	document: (id: string) => [id],
})
