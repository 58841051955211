<script setup lang="ts">
import PasswordChecks from '@auth/components/password-checks.vue'
import TermsCheckbox from '@auth/components/terms-checkbox.vue'
import Strength from '../strength.vue'

import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { ref, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'

import { Alert } from '@nolas/design-system/alert'
import { Button } from '@nolas/design-system/button'
import { Card, CardContent } from '@nolas/design-system/card'
import { FormField } from '@components/form'
import { Input } from '@nolas/design-system/input'
import { Paragraph } from '@nolas/design-system/paragraph'

import { Register, useParseEmail } from '@auth/utils/schemas'
import { usePasswordStrength, usePasswordFeedback } from '@auth/utils/password-strength'
import { useResetPassword } from '@auth/composables/use-reset-password'

const { setScore } = usePasswordStrength()
const { setFeedback } = usePasswordFeedback()

onBeforeMount(() => {
	setScore(0)
	setFeedback()
})

const route = useRoute()
const { parseEmail } = useParseEmail()

const form = useForm({
	validationSchema: toTypedSchema(Register),
	initialValues: {
		email: parseEmail(route.query.email as string),
		password: undefined,
		repeatPassword: '',
	},
})

const emit = defineEmits<{
	success: void[]
}>()

const terms = ref(false)

const { resetPassword, error, isPending } = useResetPassword()

const onSubmit = form.handleSubmit(async ({ password }) => {
	await resetPassword(
		{
			token: String(route.query.token),
			newPassword: password,
		},
		{
			onSuccess() {
				emit('success')
			},
		}
	)
})
</script>
<template>
  <div class="m-auto w-full max-w-sm px-3">
    <INolasLogoBig class="mx-auto h-32 w-48" />
    <Paragraph v-if="route.query.added_by" class="mb-8 flex flex-col px-12" align="center">
      {{ $t('auth.flow.setup_password.description', { name: route.query.added_by }) }}
    </Paragraph>
    <Card>
      <CardContent class="px-5 py-6">
        <Alert v-if="error" variant="destructive" class="mb-4" :description="$t(`common.error.${error.name}`)" />
        <form novalidate class="flex w-full flex-col justify-between" @submit.prevent="onSubmit">
          <div>
            <FormField v-slot="{ componentField }" :form="form" name="email">
              <Input
                type="email"
                :label="$t('auth.field.email.label')"
                autocomplete="email"
                readonly
                v-bind="componentField"
              />
            </FormField>
            <FormField v-slot="{ field }" :form="form" name="password">
              <Input type="password" :label="$t('auth.field.password.label')" autocomplete="password" v-bind="field" />
            </FormField>
            <FormField v-slot="{ field }" :form="form" name="repeatPassword">
              <Input
                type="password"
                :label="$t('auth.field.confirmPassword.label')"
                autocomplete="repeat-password"
                v-bind="field"
              />
            </FormField>
            <Strength />
            <PasswordChecks />
          </div>
          <div>
            <TermsCheckbox v-model="terms" class="mt-6" />
            <Button
              variant="primary"
              type="submit"
              size="lg"
              class="w-full"
              :disabled="isPending || !terms"
              data-e2e="setup-password"
            >
              {{ $t('auth.button.register') }}
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
    <Button as="router-link" to="/auth" variant="link" size="md" class="mx-auto mt-8 w-max" data-e2e="login">
      <INolasArrowLeft class="mr-1.5 h-5 w-5" />
      {{ $t('auth.button.back') }}
    </Button>
  </div>
</template>
