<script setup lang="ts">
import { Label, type LabelProps } from 'reka-ui'
import type { HTMLAttributes } from 'vue'

import { cn } from '../../utils'

import { labelVariants, type LabelFontProps } from '.'

const props = defineProps<LabelProps & LabelFontProps & { class?: HTMLAttributes['class'] }>()
</script>

<template>
  <Label
    v-bind="props"
    :class="
      cn(
        'block text-left text-sm font-medium tracking-tight text-foreground',
        labelVariants({ size, weight }),
        props.class
      )
    "
  >
    <slot />
  </Label>
</template>
