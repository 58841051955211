import { LogoutURL } from '@services/nolas-api'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { Ref } from 'vue'

import type { ServiceError } from '@nolas/lib/errors'

export function useLogout() {
	const queryClient = useQueryClient()

	const {
		mutateAsync: logout,
		error,
		...mutation
	} = useMutation({
		mutationFn: async () => {
			await queryClient.invalidateQueries()
			window.open(LogoutURL, '_self')
		},
	})

	return { logout, error: error as Ref<ServiceError | null>, ...mutation }
}
