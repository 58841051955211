<script setup lang="ts">
import {
	DropdownMenuContent,
	type DropdownMenuContentEmits,
	type DropdownMenuContentProps,
	DropdownMenuPortal,
	useForwardPropsEmits,
} from 'reka-ui'

import { cn } from '../../utils'

const props = withDefaults(defineProps<DropdownMenuContentProps & { class?: string; dataE2e?: string }>(), {
	sideOffset: 4,
	class: undefined,
	dataE2e: undefined,
})
const emits = defineEmits<DropdownMenuContentEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <DropdownMenuPortal>
    <DropdownMenuContent
      :class="[
        cn(
          'z-50 overflow-hidden rounded-lg bg-white py-2 shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
          props.class
        ),
      ]"
      v-bind="forwarded"
      :data-e2e="props.dataE2e"
    >
      <slot />
    </DropdownMenuContent>
  </DropdownMenuPortal>
</template>
