<script setup lang="ts">
import { computed } from 'vue'

import { useNavigation } from '@common/navigation'

import { ExpandableSearchInput } from '@nolas/design-system/input'
import { Heading } from '@nolas/design-system/heading'
import { Paragraph } from '@nolas/design-system/paragraph'
import { useSidebarLeft } from '@components/sidebar'

import Header from './header.vue'
import NavigationItem from './navigation-item.vue'

import type { MenuItem } from '@common/navigation'

const search = defineModel<string | undefined>()
defineEmits(['page-change'])

const { close } = useSidebarLeft()

const {
	menuItems,
	currentItem,
	currentModule,
	currentSubMenu,
	setCurrentModule,
	setCurrentItem,
	toggleSwitchMenu,
	isSwitchMenuOpen,
} = useNavigation()

const groupedMenuItems = computed(() => {
	const result: MenuItem[][] = []
	let currentArray: MenuItem[] = []

	for (const [index, item] of menuItems.value.entries()) {
		if (item.type === 'spacer') {
			if (currentArray.length > 0) {
				result.push(currentArray)
				currentArray = []
			}
		} else {
			currentArray.push(item)
		}

		if (index === menuItems.value.length - 1 && currentArray.length > 0) {
			result.push(currentArray)
		}
	}

	return result
})

async function onCloseMenu() {
	await close()
	if (currentModule.value?.type === 'module') {
		isSwitchMenuOpen.value = false
	}
}
</script>

<template>
  <div>
    <Header
      :is-switch-menu-open="isSwitchMenuOpen"
      :show-switcher="currentModule?.type === 'module'"
      @toggle-switch-menu="toggleSwitchMenu"
      @toggle-menu-fold="onCloseMenu"
    >
      <template v-if="!isSwitchMenuOpen && currentModule?.type === 'module'" #title>
        <Heading as="h3" size="sm" class="ml-2" :data-e2e="`app-bar-navigation-bar-title-${currentModule?.id}`">
          {{ currentModule?.name }}
        </Heading>
      </template>
    </Header>
    <div class="navbar-content flex h-full flex-col justify-between">
      <Transition name="fade" mode="out-in">
        <div
          v-if="isSwitchMenuOpen || currentModule?.type === 'page' || currentModule === null"
          class="mt-8 flex flex-col gap-6 px-4"
        >
          <div v-for="(itemList, i) in groupedMenuItems" :key="itemList[0].id">
            <Paragraph
              v-if="i < groupedMenuItems.length - 1"
              as="p"
              size="xs"
              weight="semibold"
              class="mb-1 text-gray-600"
            >
              {{ $t(`common.navigation_group_type.${itemList[0].type}`) }}
            </Paragraph>
            <div class="rounded-lg bg-white px-1 py-0.5">
              <NavigationItem
                v-for="item in itemList"
                :key="item.id"
                :is-selected="currentModule?.id === item.id"
                :item="item"
                has-right-chevron
                @click="setCurrentModule(item)"
              />
            </div>
          </div>
        </div>

        <div v-else-if="currentModule?.type === 'module'" id="navmenu" class="px-4">
          <ExpandableSearchInput
            v-if="currentModule?.id !== 'administration'"
            v-show="false"
            v-model="search"
            hide-clear-button
            color="gray"
            :debounce-time="400"
            :placeholder="$t('common.search')"
            :show-dot="false"
            :data-e2e="`app-bar-navigation-bar-search-${currentModule?.id}`"
          />
          <div class="mt-6">
            <div v-for="(item, id) in currentSubMenu" :key="id">
              <NavigationItem
                v-if="item && currentItem"
                :is-selected="currentItem.id === item.id"
                :item="item"
                @click.stop="setCurrentItem(item)"
              />
            </div>
            <slot name="groups" />
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<style>
.resizable-component > .resizable-r,
.resizable-component > .resizable-l {
  @apply cursor-col-resize !important;
}
.navbar-content {
  scrollbar-gutter: stable;
}

.no-user-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
</style>
