<script setup lang="ts">
import { cn } from '../../utils'

interface DialogDescriptionProps {
  class?: string
}

const props = defineProps<DialogDescriptionProps>()
</script>

<template>
  <p :class="cn('text-sm text-gray-700', props.class)">
    <slot />
  </p>
</template>
