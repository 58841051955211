import { deburr } from 'lodash'

const countries = [
	{
		icon: 'icon-[circle-flags--af]',
		label: 'common.countries.AF',
		country_name: 'Afghanistan',
		country_name_de: 'Afghanistan',
		dial_code: '+93',
		value: 'AF',
	},
	{
		icon: 'icon-[circle-flags--al]',
		label: 'common.countries.AL',
		country_name: 'Albania',
		country_name_de: 'Albanien',
		dial_code: '+355',
		value: 'AL',
	},
	{
		icon: 'icon-[circle-flags--dz]',
		label: 'common.countries.DZ',
		country_name: 'Algeria',
		country_name_de: 'Algerien',
		dial_code: '+213',
		value: 'DZ',
	},
	{
		icon: 'icon-[circle-flags--as]',
		label: 'common.countries.AS',
		country_name: 'American Samoa',
		country_name_de: 'Amerikanisch-Samoa',
		dial_code: '+1 684',
		value: 'AS',
	},
	{
		icon: 'icon-[circle-flags--ad]',
		label: 'common.countries.AD',
		country_name: 'Andorra',
		country_name_de: 'Andorra',
		dial_code: '+376',
		value: 'AD',
	},
	{
		icon: 'icon-[circle-flags--ao]',
		label: 'common.countries.AO',
		country_name: 'Angola',
		country_name_de: 'Angola',
		dial_code: '+244',
		value: 'AO',
	},
	{
		icon: 'icon-[circle-flags--ai]',
		label: 'common.countries.AI',
		country_name: 'Anguilla',
		country_name_de: 'Anguilla',
		dial_code: '+1 264',
		value: 'AI',
	},
	{
		icon: 'icon-[circle-flags--aq]',
		label: 'common.countries.AQ',
		country_name: 'Antarctica',
		country_name_de: 'Antarktis',
		dial_code: '+672',
		value: 'AQ',
	},
	{
		icon: 'icon-[circle-flags--ag]',
		label: 'common.countries.AG',
		country_name: 'Antigua and Barbuda',
		country_name_de: 'Antigua und Barbuda',
		dial_code: '+1 268',
		value: 'AG',
	},
	{
		icon: 'icon-[circle-flags--ar]',
		label: 'common.countries.AR',
		country_name: 'Argentina',
		country_name_de: 'Argentinien',
		dial_code: '+54',
		value: 'AR',
	},
	{
		icon: 'icon-[circle-flags--am]',
		label: 'common.countries.AM',
		country_name: 'Armenia',
		country_name_de: 'Armenien',
		dial_code: '+374',
		value: 'AM',
	},
	{
		icon: 'icon-[circle-flags--aw]',
		label: 'common.countries.AW',
		country_name: 'Aruba',
		country_name_de: 'Aruba',
		dial_code: '+297',
		value: 'AW',
	},
	{
		icon: 'icon-[circle-flags--ac]',
		label: 'common.countries.AC',
		country_name: 'Ascension Island',
		country_name_de: 'Ascension',
		dial_code: '+247',
		value: 'AC',
	},
	{
		icon: 'icon-[circle-flags--au]',
		label: 'common.countries.AU',
		country_name: 'Australia',
		country_name_de: 'Australien',
		dial_code: '+61',
		value: 'AU',
	},
	{
		icon: 'icon-[circle-flags--at]',
		label: 'common.countries.AT',
		country_name: 'Austria',
		country_name_de: 'Österreich',
		dial_code: '+43',
		value: 'AT',
	},
	{
		icon: 'icon-[circle-flags--az]',
		label: 'common.countries.AZ',
		country_name: 'Azerbaijan',
		country_name_de: 'Aserbaidschan',
		dial_code: '+994',
		value: 'AZ',
	},
	{
		icon: 'icon-[circle-flags--bs]',
		label: 'common.countries.BS',
		country_name: 'Bahamas',
		country_name_de: 'Bahamas',
		dial_code: '+1 242',
		value: 'BS',
	},
	{
		icon: 'icon-[circle-flags--bh]',
		label: 'common.countries.BH',
		country_name: 'Bahrain',
		country_name_de: 'Bahrain',
		dial_code: '+973',
		value: 'BH',
	},
	{
		icon: 'icon-[circle-flags--bd]',
		label: 'common.countries.BD',
		country_name: 'Bangladesh',
		country_name_de: 'Bangladesch',
		dial_code: '+880',
		value: 'BD',
	},
	{
		icon: 'icon-[circle-flags--bb]',
		label: 'common.countries.BB',
		country_name: 'Barbados',
		country_name_de: 'Barbados',
		dial_code: '+1 246',
		value: 'BB',
	},
	{
		icon: 'icon-[circle-flags--by]',
		label: 'common.countries.BY',
		country_name: 'Belarus',
		country_name_de: 'Belarus',
		dial_code: '+375',
		value: 'BY',
	},
	{
		icon: 'icon-[circle-flags--be]',
		label: 'common.countries.BE',
		country_name: 'Belgium',
		country_name_de: 'Belgien',
		dial_code: '+32',
		value: 'BE',
	},
	{
		icon: 'icon-[circle-flags--bz]',
		label: 'common.countries.BZ',
		country_name: 'Belize',
		country_name_de: 'Belize',
		dial_code: '+501',
		value: 'BZ',
	},
	{
		icon: 'icon-[circle-flags--bj]',
		label: 'common.countries.BJ',
		country_name: 'Benin',
		country_name_de: 'Benin',
		dial_code: '+229',
		value: 'BJ',
	},
	{
		icon: 'icon-[circle-flags--bm]',
		label: 'common.countries.BM',
		country_name: 'Bermuda',
		country_name_de: 'Bermuda',
		dial_code: '+1 441',
		value: 'BM',
	},
	{
		icon: 'icon-[circle-flags--bt]',
		label: 'common.countries.BT',
		country_name: 'Bhutan',
		country_name_de: 'Bhutan',
		dial_code: '+975',
		value: 'BT',
	},
	{
		icon: 'icon-[circle-flags--bo]',
		label: 'common.countries.BO',
		country_name: 'Bolivia',
		country_name_de: 'Bolivien',
		dial_code: '+591',
		value: 'BO',
	},
	{
		icon: 'icon-[circle-flags--bq]',
		label: 'common.countries.BQ',
		country_name: 'Bonaire, Sint Eustatius and Saba',
		country_name_de: 'Bonaire, Sint Eustatius und Saba',
		dial_code: '+599',
		value: 'BQ',
	},
	{
		icon: 'icon-[circle-flags--ba]',
		label: 'common.countries.BA',
		country_name: 'Bosnia and Herzegovina',
		country_name_de: 'Bosnien und Herzegovina',
		dial_code: '+387',
		value: 'BA',
	},
	{
		icon: 'icon-[circle-flags--bw]',
		label: 'common.countries.BW',
		country_name: 'Botswana',
		country_name_de: 'Botsuana',
		dial_code: '+267',
		value: 'BW',
	},
	{
		icon: 'icon-[circle-flags--bv]',
		label: 'common.countries.BV',
		country_name: 'Bouvet Island',
		country_name_de: 'Bouvetinsel',
		dial_code: '+47',
		value: 'BV',
	},
	{
		icon: 'icon-[circle-flags--br]',
		label: 'common.countries.BR',
		country_name: 'Brazil',
		country_name_de: 'Brasilien',
		dial_code: '+55',
		value: 'BR',
	},
	{
		icon: 'icon-[circle-flags--io]',
		label: 'common.countries.IO',
		country_name: 'British Indian Ocean Territory',
		country_name_de: 'Britisches Territorium im Indischen Ozean',
		dial_code: '+246',
		value: 'IO',
	},
	{
		icon: 'icon-[circle-flags--bn]',
		label: 'common.countries.BN',
		country_name: 'Brunei Darussalam',
		country_name_de: 'Brunei Darussalam',
		dial_code: '+673',
		value: 'BN',
	},
	{
		icon: 'icon-[circle-flags--bg]',
		label: 'common.countries.BG',
		country_name: 'Bulgaria',
		country_name_de: 'Bulgarien',
		dial_code: '+359',
		value: 'BG',
	},
	{
		icon: 'icon-[circle-flags--bf]',
		label: 'common.countries.BF',
		country_name: 'Burkina Faso',
		country_name_de: 'Burkina Faso',
		dial_code: '+226',
		value: 'BF',
	},
	{
		icon: 'icon-[circle-flags--bi]',
		label: 'common.countries.BI',
		country_name: 'Burundi',
		country_name_de: 'Burundi',
		dial_code: '+257',
		value: 'BI',
	},
	{
		icon: 'icon-[circle-flags--cv]',
		label: 'common.countries.CV',
		country_name: 'Cabo Verde',
		country_name_de: 'Kap Verde',
		dial_code: '+238',
		value: 'CV',
	},
	{
		icon: 'icon-[circle-flags--kh]',
		label: 'common.countries.KH',
		country_name: 'Cambodia',
		country_name_de: 'Kambodscha',
		dial_code: '+855',
		value: 'KH',
	},
	{
		icon: 'icon-[circle-flags--cm]',
		label: 'common.countries.CM',
		country_name: 'Cameroon',
		country_name_de: 'Kamerun',
		dial_code: '+237',
		value: 'CM',
	},
	{
		icon: 'icon-[circle-flags--ca]',
		label: 'common.countries.CA',
		country_name: 'Canada',
		country_name_de: 'Kanada',
		dial_code: '+1',
		value: 'CA',
	},
	{
		icon: 'icon-[circle-flags--ky]',
		label: 'common.countries.KY',
		country_name: 'Cayman Islands',
		country_name_de: 'Kaimaninseln',
		dial_code: '+ 345',
		value: 'KY',
	},
	{
		icon: 'icon-[circle-flags--cf]',
		label: 'common.countries.CF',
		country_name: 'Central African Republic',
		country_name_de: 'Zentralafrikanische Republik',
		dial_code: '+236',
		value: 'CF',
	},
	{
		icon: 'icon-[circle-flags--td]',
		label: 'common.countries.TD',
		country_name: 'Chad',
		country_name_de: 'Tschad',
		dial_code: '+235',
		value: 'TD',
	},
	{
		icon: 'icon-[circle-flags--cl]',
		label: 'common.countries.CL',
		country_name: 'Chile',
		country_name_de: 'Chile',
		dial_code: '+56',
		value: 'CL',
	},
	{
		icon: 'icon-[circle-flags--cn]',
		label: 'common.countries.CN',
		country_name: 'China',
		country_name_de: 'China',
		dial_code: '+86',
		value: 'CN',
	},
	{
		icon: 'icon-[circle-flags--cx]',
		label: 'common.countries.CX',
		country_name: 'Christmas Island',
		country_name_de: 'Weihnachtsinsel',
		dial_code: '+61',
		value: 'CX',
	},
	{
		icon: 'icon-[circle-flags--cc]',
		label: 'common.countries.CC',
		country_name: 'Cocos (Keeling) Islands',
		country_name_de: 'Kokosinseln',
		dial_code: '+61',
		value: 'CC',
	},
	{
		icon: 'icon-[circle-flags--co]',
		label: 'common.countries.CO',
		country_name: 'Colombia',
		country_name_de: 'Kolumbien',
		dial_code: '+57',
		value: 'CO',
	},
	{
		icon: 'icon-[circle-flags--km]',
		label: 'common.countries.KM',
		country_name: 'Comoros',
		country_name_de: 'Komoren',
		dial_code: '+269',
		value: 'KM',
	},
	{
		icon: 'icon-[circle-flags--cg]',
		label: 'common.countries.CG',
		country_name: 'Congo',
		country_name_de: 'Kongo',
		dial_code: '+242',
		value: 'CG',
	},
	{
		icon: 'icon-[circle-flags--cd]',
		label: 'common.countries.CD',
		country_name: 'Congo, The Democratic Republic of the',
		country_name_de: 'Kongo, Demokratische Republik',
		dial_code: '+243',
		value: 'CD',
	},
	{
		icon: 'icon-[circle-flags--ck]',
		label: 'common.countries.CK',
		country_name: 'Cook Islands',
		country_name_de: 'Cookinseln',
		dial_code: '+682',
		value: 'CK',
	},
	{
		icon: 'icon-[circle-flags--cr]',
		label: 'common.countries.CR',
		country_name: 'Costa Rica',
		country_name_de: 'Costa Rica',
		dial_code: '+506',
		value: 'CR',
	},
	{
		icon: 'icon-[circle-flags--hr]',
		label: 'common.countries.HR',
		country_name: 'Croatia',
		country_name_de: 'Kroatien',
		dial_code: '+385',
		value: 'HR',
	},
	{
		icon: 'icon-[circle-flags--cu]',
		label: 'common.countries.CU',
		country_name: 'Cuba',
		country_name_de: 'Kuba',
		dial_code: '+53',
		value: 'CU',
	},
	{
		icon: 'icon-[circle-flags--cw]',
		label: 'common.countries.CW',
		country_name: 'Curaçao',
		country_name_de: 'Curaçao',
		dial_code: '+599',
		value: 'CW',
	},
	{
		icon: 'icon-[circle-flags--cy]',
		label: 'common.countries.CY',
		country_name: 'Cyprus',
		country_name_de: 'Zypern',
		dial_code: '+357',
		value: 'CY',
	},
	{
		icon: 'icon-[circle-flags--cz]',
		label: 'common.countries.CZ',
		country_name: 'Czech Republic',
		country_name_de: 'Tschechische Republik',
		dial_code: '+420',
		value: 'CZ',
	},
	{
		icon: 'icon-[circle-flags--ci]',
		label: 'common.countries.CI',
		country_name: "Côte d'Ivoire",
		country_name_de: 'Elfenbeinküste',
		dial_code: '+225',
		value: 'CI',
	},
	{
		icon: 'icon-[circle-flags--dk]',
		label: 'common.countries.DK',
		country_name: 'Denmark',
		country_name_de: 'Dänemark',
		dial_code: '+45',
		value: 'DK',
	},
	{
		icon: 'icon-[circle-flags--dj]',
		label: 'common.countries.DJ',
		country_name: 'Djibouti',
		country_name_de: 'Dschibuti',
		dial_code: '+253',
		value: 'DJ',
	},
	{
		icon: 'icon-[circle-flags--dm]',
		label: 'common.countries.DM',
		country_name: 'Dominica',
		country_name_de: 'Dominica',
		dial_code: '+1 767',
		value: 'DM',
	},
	{
		icon: 'icon-[circle-flags--do]',
		label: 'common.countries.DO',
		country_name: 'Dominican Republic',
		country_name_de: 'Dominikanische Republik',
		dial_code: '+1 849',
		value: 'DO',
	},
	{
		icon: 'icon-[circle-flags--ec]',
		label: 'common.countries.EC',
		country_name: 'Ecuador',
		country_name_de: 'Ecuador',
		dial_code: '+593',
		value: 'EC',
	},
	{
		icon: 'icon-[circle-flags--eg]',
		label: 'common.countries.EG',
		country_name: 'Egypt',
		country_name_de: 'Ägypten',
		dial_code: '+20',
		value: 'EG',
	},
	{
		icon: 'icon-[circle-flags--sv]',
		label: 'common.countries.SV',
		country_name: 'El Salvador',
		country_name_de: 'El Salvador',
		dial_code: '+503',
		value: 'SV',
	},
	{
		icon: 'icon-[circle-flags--gq]',
		label: 'common.countries.GQ',
		country_name: 'Equatorial Guinea',
		country_name_de: 'Äquatorialguinea',
		dial_code: '+240',
		value: 'GQ',
	},
	{
		icon: 'icon-[circle-flags--er]',
		label: 'common.countries.ER',
		country_name: 'Eritrea',
		country_name_de: 'Eritrea',
		dial_code: '+291',
		value: 'ER',
	},
	{
		icon: 'icon-[circle-flags--ee]',
		label: 'common.countries.EE',
		country_name: 'Estonia',
		country_name_de: 'Estland',
		dial_code: '+372',
		value: 'EE',
	},
	{
		icon: 'icon-[circle-flags--sz]',
		label: 'common.countries.SZ',
		country_name: 'Eswatini',
		country_name_de: 'Eswatini',
		dial_code: '+268',
		value: 'SZ',
	},
	{
		icon: 'icon-[circle-flags--et]',
		label: 'common.countries.ET',
		country_name: 'Ethiopia',
		country_name_de: 'Äthiopien',
		dial_code: '+251',
		value: 'ET',
	},
	{
		icon: 'icon-[circle-flags--fk]',
		label: 'common.countries.FK',
		country_name: 'Falkland Islands (Malvinas)',
		country_name_de: 'Falklandinseln',
		dial_code: '+500',
		value: 'FK',
	},
	{
		icon: 'icon-[circle-flags--fo]',
		label: 'common.countries.FO',
		country_name: 'Faroe Islands',
		country_name_de: 'Färöer-Inseln',
		dial_code: '+298',
		value: 'FO',
	},
	{
		icon: 'icon-[circle-flags--fj]',
		label: 'common.countries.FJ',
		country_name: 'Fiji',
		country_name_de: 'Fidschi',
		dial_code: '+679',
		value: 'FJ',
	},
	{
		icon: 'icon-[circle-flags--fi]',
		label: 'common.countries.FI',
		country_name: 'Finland',
		country_name_de: 'Finnland',
		dial_code: '+358',
		value: 'FI',
	},
	{
		icon: 'icon-[circle-flags--fr]',
		label: 'common.countries.FR',
		country_name: 'France',
		country_name_de: 'Frankreich',
		dial_code: '+33',
		value: 'FR',
	},
	{
		icon: 'icon-[circle-flags--gf]',
		label: 'common.countries.GF',
		country_name: 'French Guiana',
		country_name_de: 'Französisch-Guayana',
		dial_code: '+594',
		value: 'GF',
	},
	{
		icon: 'icon-[circle-flags--pf]',
		label: 'common.countries.PF',
		country_name: 'French Polynesia',
		country_name_de: 'Französisch-Polynesien',
		dial_code: '+689',
		value: 'PF',
	},
	{
		icon: 'icon-[circle-flags--tf]',
		label: 'common.countries.TF',
		country_name: 'French Southern Territories',
		country_name_de: 'Französische Süd- und Antarktisgebiete',
		dial_code: '+262',
		value: 'TF',
	},
	{
		icon: 'icon-[circle-flags--ga]',
		label: 'common.countries.GA',
		country_name: 'Gabon',
		country_name_de: 'Gabun',
		dial_code: '+241',
		value: 'GA',
	},
	{
		icon: 'icon-[circle-flags--gm]',
		label: 'common.countries.GM',
		country_name: 'Gambia',
		country_name_de: 'Gambia',
		dial_code: '+220',
		value: 'GM',
	},
	{
		icon: 'icon-[circle-flags--ge]',
		label: 'common.countries.GE',
		country_name: 'Georgia',
		country_name_de: 'Georgien',
		dial_code: '+995',
		value: 'GE',
	},
	{
		icon: 'icon-[circle-flags--de]',
		label: 'common.countries.DE',
		country_name: 'Germany',
		country_name_de: 'Deutschland',
		dial_code: '+49',
		value: 'DE',
	},
	{
		icon: 'icon-[circle-flags--gh]',
		label: 'common.countries.GH',
		country_name: 'Ghana',
		country_name_de: 'Ghana',
		dial_code: '+233',
		value: 'GH',
	},
	{
		icon: 'icon-[circle-flags--gi]',
		label: 'common.countries.GI',
		country_name: 'Gibraltar',
		country_name_de: 'Gibraltar',
		dial_code: '+350',
		value: 'GI',
	},
	{
		icon: 'icon-[circle-flags--gr]',
		label: 'common.countries.GR',
		country_name: 'Greece',
		country_name_de: 'Griechenland',
		dial_code: '+30',
		value: 'GR',
	},
	{
		icon: 'icon-[circle-flags--gl]',
		label: 'common.countries.GL',
		country_name: 'Greenland',
		country_name_de: 'Grönland',
		dial_code: '+299',
		value: 'GL',
	},
	{
		icon: 'icon-[circle-flags--gd]',
		label: 'common.countries.GD',
		country_name: 'Grenada',
		country_name_de: 'Grenada',
		dial_code: '+1 473',
		value: 'GD',
	},
	{
		icon: 'icon-[circle-flags--gp]',
		label: 'common.countries.GP',
		country_name: 'Guadeloupe',
		country_name_de: 'Guadeloupe',
		dial_code: '+590',
		value: 'GP',
	},
	{
		icon: 'icon-[circle-flags--gu]',
		label: 'common.countries.GU',
		country_name: 'Guam',
		country_name_de: 'Guam',
		dial_code: '+1 671',
		value: 'GU',
	},
	{
		icon: 'icon-[circle-flags--gt]',
		label: 'common.countries.GT',
		country_name: 'Guatemala',
		country_name_de: 'Guatemala',
		dial_code: '+502',
		value: 'GT',
	},
	{
		icon: 'icon-[circle-flags--gg]',
		label: 'common.countries.GG',
		country_name: 'Guernsey',
		country_name_de: 'Guernsey',
		dial_code: '+44',
		value: 'GG',
	},
	{
		icon: 'icon-[circle-flags--gn]',
		label: 'common.countries.GN',
		country_name: 'Guinea',
		country_name_de: 'Guinea',
		dial_code: '+224',
		value: 'GN',
	},
	{
		icon: 'icon-[circle-flags--gw]',
		label: 'common.countries.GW',
		country_name: 'Guinea-Bissau',
		country_name_de: 'Guinea-Bissau',
		dial_code: '+245',
		value: 'GW',
	},
	{
		icon: 'icon-[circle-flags--gy]',
		label: 'common.countries.GY',
		country_name: 'Guyana',
		country_name_de: 'Guyana',
		dial_code: '+595',
		value: 'GY',
	},
	{
		icon: 'icon-[circle-flags--ht]',
		label: 'common.countries.HT',
		country_name: 'Haiti',
		country_name_de: 'Haiti',
		dial_code: '+509',
		value: 'HT',
	},
	{
		icon: 'icon-[circle-flags--hm]',
		label: 'common.countries.HM',
		country_name: 'Heard Island and McDonald Islands',
		country_name_de: 'Heard und McDonaldinseln',
		dial_code: '+672',
		value: 'HM',
	},
	{
		icon: 'icon-[circle-flags--va]',
		label: 'common.countries.VA',
		country_name: 'Holy See',
		country_name_de: 'Heiliger Stuhl',
		dial_code: '+379',
		value: 'VA',
	},
	{
		icon: 'icon-[circle-flags--hn]',
		label: 'common.countries.HN',
		country_name: 'Honduras',
		country_name_de: 'Honduras',
		dial_code: '+504',
		value: 'HN',
	},
	{
		icon: 'icon-[circle-flags--hk]',
		label: 'common.countries.HK',
		country_name: 'Hong Kong',
		country_name_de: 'Hongkong',
		dial_code: '+852',
		value: 'HK',
	},
	{
		icon: 'icon-[circle-flags--hu]',
		label: 'common.countries.HU',
		country_name: 'Hungary',
		country_name_de: 'Ungarn',
		dial_code: '+36',
		value: 'HU',
	},
	{
		icon: 'icon-[circle-flags--is]',
		label: 'common.countries.IS',
		country_name: 'Iceland',
		country_name_de: 'Island',
		dial_code: '+354',
		value: 'IS',
	},
	{
		icon: 'icon-[circle-flags--in]',
		label: 'common.countries.IN',
		country_name: 'India',
		country_name_de: 'Indien',
		dial_code: '+91',
		value: 'IN',
	},
	{
		icon: 'icon-[circle-flags--id]',
		label: 'common.countries.ID',
		country_name: 'Indonesia',
		country_name_de: 'Indonesien',
		dial_code: '+62',
		value: 'ID',
	},
	{
		icon: 'icon-[circle-flags--ir]',
		label: 'common.countries.IR',
		country_name: 'Iran',
		country_name_de: 'Iran',
		dial_code: '+98',
		value: 'IR',
	},
	{
		icon: 'icon-[circle-flags--iq]',
		label: 'common.countries.IQ',
		country_name: 'Iraq',
		country_name_de: 'Irak',
		dial_code: '+964',
		value: 'IQ',
	},
	{
		icon: 'icon-[circle-flags--ie]',
		label: 'common.countries.IE',
		country_name: 'Ireland',
		country_name_de: 'Irland',
		dial_code: '+353',
		value: 'IE',
	},
	{
		icon: 'icon-[circle-flags--im]',
		label: 'common.countries.IM',
		country_name: 'Isle of Man',
		country_name_de: 'Isle of Man',
		dial_code: '+44',
		value: 'IM',
	},
	{
		icon: 'icon-[circle-flags--il]',
		label: 'common.countries.IL',
		country_name: 'Israel',
		country_name_de: 'Israel',
		dial_code: '+972',
		value: 'IL',
	},
	{
		icon: 'icon-[circle-flags--it]',
		label: 'common.countries.IT',
		country_name: 'Italy',
		country_name_de: 'Italien',
		dial_code: '+39',
		value: 'IT',
	},
	{
		icon: 'icon-[circle-flags--jm]',
		label: 'common.countries.JM',
		country_name: 'Jamaica',
		country_name_de: 'Jamaika',
		dial_code: '+1 876',
		value: 'JM',
	},
	{
		icon: 'icon-[circle-flags--jp]',
		label: 'common.countries.JP',
		country_name: 'Japan',
		country_name_de: 'Japan',
		dial_code: '+81',
		value: 'JP',
	},
	{
		icon: 'icon-[circle-flags--je]',
		label: 'common.countries.JE',
		country_name: 'Jersey',
		country_name_de: 'Jersey',
		dial_code: '+44',
		value: 'JE',
	},
	{
		icon: 'icon-[circle-flags--jo]',
		label: 'common.countries.JO',
		country_name: 'Jordan',
		country_name_de: 'Jordanien',
		dial_code: '+962',
		value: 'JO',
	},
	{
		icon: 'icon-[circle-flags--kz]',
		label: 'common.countries.KZ',
		country_name: 'Kazakhstan',
		country_name_de: 'Kasachstan',
		dial_code: '+7 7',
		value: 'KZ',
	},
	{
		icon: 'icon-[circle-flags--ke]',
		label: 'common.countries.KE',
		country_name: 'Kenya',
		country_name_de: 'Kenia',
		dial_code: '+254',
		value: 'KE',
	},
	{
		icon: 'icon-[circle-flags--ki]',
		label: 'common.countries.KI',
		country_name: 'Kiribati',
		country_name_de: 'Kiribati',
		dial_code: '+686',
		value: 'KI',
	},
	{
		icon: 'icon-[circle-flags--kp]',
		label: 'common.countries.KP',
		country_name: 'Korea (North)',
		country_name_de: 'Korea (Nord)',
		dial_code: '+850',
		value: 'KP',
	},
	{
		icon: 'icon-[circle-flags--kr]',
		label: 'common.countries.KR',
		country_name: 'Korea (South)',
		country_name_de: 'Korea (Süd)',
		dial_code: '+82',
		value: 'KR',
	},
	{
		icon: 'icon-[circle-flags--xk]',
		label: 'common.countries.XK',
		country_name: 'Kosovo',
		country_name_de: 'Kosovo',
		dial_code: '+383',
		value: 'XK',
	},
	{
		icon: 'icon-[circle-flags--kw]',
		label: 'common.countries.KW',
		country_name: 'Kuwait',
		country_name_de: 'Kuwait',
		dial_code: '+965',
		value: 'KW',
	},
	{
		icon: 'icon-[circle-flags--kg]',
		label: 'common.countries.KG',
		country_name: 'Kyrgyzstan',
		country_name_de: 'Kirgisistan',
		dial_code: '+996',
		value: 'KG',
	},
	{
		icon: 'icon-[circle-flags--la]',
		label: 'common.countries.LA',
		country_name: 'Laos',
		country_name_de: 'Laos',
		dial_code: '+856',
		value: 'LA',
	},
	{
		icon: 'icon-[circle-flags--lv]',
		label: 'common.countries.LV',
		country_name: 'Latvia',
		country_name_de: 'Lettland',
		dial_code: '+371',
		value: 'LV',
	},
	{
		icon: 'icon-[circle-flags--lb]',
		label: 'common.countries.LB',
		country_name: 'Lebanon',
		country_name_de: 'Libanon',
		dial_code: '+961',
		value: 'LB',
	},
	{
		icon: 'icon-[circle-flags--ls]',
		label: 'common.countries.LS',
		country_name: 'Lesotho',
		country_name_de: 'Lesotho',
		dial_code: '+266',
		value: 'LS',
	},
	{
		icon: 'icon-[circle-flags--lr]',
		label: 'common.countries.LR',
		country_name: 'Liberia',
		country_name_de: 'Liberia',
		dial_code: '+231',
		value: 'LR',
	},
	{
		icon: 'icon-[circle-flags--ly]',
		label: 'common.countries.LY',
		country_name: 'Libya',
		country_name_de: 'Libyen',
		dial_code: '+218',
		value: 'LY',
	},
	{
		icon: 'icon-[circle-flags--li]',
		label: 'common.countries.LI',
		country_name: 'Liechtenstein',
		country_name_de: 'Liechtenstein',
		dial_code: '+423',
		value: 'LI',
	},
	{
		icon: 'icon-[circle-flags--lt]',
		label: 'common.countries.LT',
		country_name: 'Lithuania',
		country_name_de: 'Litauen',
		dial_code: '+370',
		value: 'LT',
	},
	{
		icon: 'icon-[circle-flags--lu]',
		label: 'common.countries.LU',
		country_name: 'Luxembourg',
		country_name_de: 'Luxemburg',
		dial_code: '+352',
		value: 'LU',
	},
	{
		icon: 'icon-[circle-flags--mo]',
		label: 'common.countries.MO',
		country_name: 'Macao',
		country_name_de: 'Macao',
		dial_code: '+853',
		value: 'MO',
	},
	{
		icon: 'icon-[circle-flags--mg]',
		label: 'common.countries.MG',
		country_name: 'Madagascar',
		country_name_de: 'Madagaskar',
		dial_code: '+261',
		value: 'MG',
	},
	{
		icon: 'icon-[circle-flags--mw]',
		label: 'common.countries.MW',
		country_name: 'Malawi',
		country_name_de: 'Malawi',
		dial_code: '+265',
		value: 'MW',
	},
	{
		icon: 'icon-[circle-flags--my]',
		label: 'common.countries.MY',
		country_name: 'Malaysia',
		country_name_de: 'Malaysia',
		dial_code: '+60',
		value: 'MY',
	},
	{
		icon: 'icon-[circle-flags--mv]',
		label: 'common.countries.MV',
		country_name: 'Maldives',
		country_name_de: 'Malediven',
		dial_code: '+960',
		value: 'MV',
	},
	{
		icon: 'icon-[circle-flags--ml]',
		label: 'common.countries.ML',
		country_name: 'Mali',
		country_name_de: 'Mali',
		dial_code: '+223',
		value: 'ML',
	},
	{
		icon: 'icon-[circle-flags--mt]',
		label: 'common.countries.MT',
		country_name: 'Malta',
		country_name_de: 'Malta',
		dial_code: '+356',
		value: 'MT',
	},
	{
		icon: 'icon-[circle-flags--mh]',
		label: 'common.countries.MH',
		country_name: 'Marshall Islands',
		country_name_de: 'Marshallinseln',
		dial_code: '+692',
		value: 'MH',
	},
	{
		icon: 'icon-[circle-flags--mq]',
		label: 'common.countries.MQ',
		country_name: 'Martinique',
		country_name_de: 'Martinique',
		dial_code: '+596',
		value: 'MQ',
	},
	{
		icon: 'icon-[circle-flags--mr]',
		label: 'common.countries.MR',
		country_name: 'Mauritania',
		country_name_de: 'Mauretanien',
		dial_code: '+222',
		value: 'MR',
	},
	{
		icon: 'icon-[circle-flags--mu]',
		label: 'common.countries.MU',
		country_name: 'Mauritius',
		country_name_de: 'Mauritius',
		dial_code: '+230',
		value: 'MU',
	},
	{
		icon: 'icon-[circle-flags--yt]',
		label: 'common.countries.YT',
		country_name: 'Mayotte',
		country_name_de: 'Mayotte',
		dial_code: '+262',
		value: 'YT',
	},
	{
		icon: 'icon-[circle-flags--mx]',
		label: 'common.countries.MX',
		country_name: 'Mexico',
		country_name_de: 'Mexiko',
		dial_code: '+52',
		value: 'MX',
	},
	{
		icon: 'icon-[circle-flags--fm]',
		label: 'common.countries.FM',
		country_name: 'Micronesia',
		country_name_de: 'Mikronesien',
		dial_code: '+691',
		value: 'FM',
	},
	{
		icon: 'icon-[circle-flags--md]',
		label: 'common.countries.MD',
		country_name: 'Moldova',
		country_name_de: 'Moldawien',
		dial_code: '+373',
		value: 'MD',
	},
	{
		icon: 'icon-[circle-flags--mc]',
		label: 'common.countries.MC',
		country_name: 'Monaco',
		country_name_de: 'Monaco',
		dial_code: '+377',
		value: 'MC',
	},
	{
		icon: 'icon-[circle-flags--mn]',
		label: 'common.countries.MN',
		country_name: 'Mongolia',
		country_name_de: 'Mongolei',
		dial_code: '+976',
		value: 'MN',
	},
	{
		icon: 'icon-[circle-flags--me]',
		label: 'common.countries.ME',
		country_name: 'Montenegro',
		country_name_de: 'Montenegro',
		dial_code: '+382',
		value: 'ME',
	},
	{
		icon: 'icon-[circle-flags--ms]',
		label: 'common.countries.MS',
		country_name: 'Montserrat',
		country_name_de: 'Montserrat',
		dial_code: '+1 664',
		value: 'MS',
	},
	{
		icon: 'icon-[circle-flags--ma]',
		label: 'common.countries.MA',
		country_name: 'Morocco',
		country_name_de: 'Marokko',
		dial_code: '+212',
		value: 'MA',
	},
	{
		icon: 'icon-[circle-flags--mz]',
		label: 'common.countries.MZ',
		country_name: 'Mozambique',
		country_name_de: 'Mosambik',
		dial_code: '+258',
		value: 'MZ',
	},
	{
		icon: 'icon-[circle-flags--mm]',
		label: 'common.countries.MM',
		country_name: 'Myanmar',
		country_name_de: 'Myanmar',
		dial_code: '+95',
		value: 'MM',
	},
	{
		icon: 'icon-[circle-flags--na]',
		label: 'common.countries.NA',
		country_name: 'Namibia',
		country_name_de: 'Namibia',
		dial_code: '+264',
		value: 'NA',
	},
	{
		icon: 'icon-[circle-flags--nr]',
		label: 'common.countries.NR',
		country_name: 'Nauru',
		country_name_de: 'Nauru',
		dial_code: '+674',
		value: 'NR',
	},
	{
		icon: 'icon-[circle-flags--np]',
		label: 'common.countries.NP',
		country_name: 'Nepal',
		country_name_de: 'Nepal',
		dial_code: '+977',
		value: 'NP',
	},
	{
		icon: 'icon-[circle-flags--nl]',
		label: 'common.countries.NL',
		country_name: 'Netherlands',
		country_name_de: 'Niederlande',
		dial_code: '+31',
		value: 'NL',
	},
	{
		icon: 'icon-[circle-flags--an]',
		label: 'common.countries.AN',
		country_name: 'Netherlands Antilles',
		country_name_de: 'Niederländische Antillen',
		dial_code: '+599',
		value: 'AN',
	},
	{
		icon: 'icon-[circle-flags--nc]',
		label: 'common.countries.NC',
		country_name: 'New Caledonia',
		country_name_de: 'Neukaledonien',
		dial_code: '+687',
		value: 'NC',
	},
	{
		icon: 'icon-[circle-flags--nz]',
		label: 'common.countries.NZ',
		country_name: 'New Zealand',
		country_name_de: 'Neuseeland',
		dial_code: '+64',
		value: 'NZ',
	},
	{
		icon: 'icon-[circle-flags--ni]',
		label: 'common.countries.NI',
		country_name: 'Nicaragua',
		country_name_de: 'Nicaragua',
		dial_code: '+505',
		value: 'NI',
	},
	{
		icon: 'icon-[circle-flags--ne]',
		label: 'common.countries.NE',
		country_name: 'Niger',
		country_name_de: 'Niger',
		dial_code: '+227',
		value: 'NE',
	},
	{
		icon: 'icon-[circle-flags--ng]',
		label: 'common.countries.NG',
		country_name: 'Nigeria',
		country_name_de: 'Nigeria',
		dial_code: '+234',
		value: 'NG',
	},
	{
		icon: 'icon-[circle-flags--nu]',
		label: 'common.countries.NU',
		country_name: 'Niue',
		country_name_de: 'Niue',
		dial_code: '+683',
		value: 'NU',
	},
	{
		icon: 'icon-[circle-flags--nf]',
		label: 'common.countries.NF',
		country_name: 'Norfolk Island',
		country_name_de: 'Norfolkinsel',
		dial_code: '+672',
		value: 'NF',
	},
	{
		icon: 'icon-[circle-flags--mk]',
		label: 'common.countries.MK',
		country_name: 'North Macedonia',
		country_name_de: 'Nordmazedonien',
		dial_code: '+389',
		value: 'MK',
	},
	{
		icon: 'icon-[circle-flags--mp]',
		label: 'common.countries.MP',
		country_name: 'Northern Mariana Islands',
		country_name_de: 'Nördliche Marianen',
		dial_code: '+1 670',
		value: 'MP',
	},
	{
		icon: 'icon-[circle-flags--no]',
		label: 'common.countries.NO',
		country_name: 'Norway',
		country_name_de: 'Norwegen',
		dial_code: '+47',
		value: 'NO',
	},
	{
		icon: 'icon-[circle-flags--om]',
		label: 'common.countries.OM',
		country_name: 'Oman',
		country_name_de: 'Oman',
		dial_code: '+968',
		value: 'OM',
	},
	{
		icon: 'icon-[circle-flags--pk]',
		label: 'common.countries.PK',
		country_name: 'Pakistan',
		country_name_de: 'Pakistan',
		dial_code: '+92',
		value: 'PK',
	},
	{
		icon: 'icon-[circle-flags--pw]',
		label: 'common.countries.PW',
		country_name: 'Palau',
		country_name_de: 'Palau',
		dial_code: '+680',
		value: 'PW',
	},
	{
		icon: 'icon-[circle-flags--ps]',
		label: 'common.countries.PS',
		country_name: 'Palestine',
		country_name_de: 'Palästina',
		dial_code: '+970',
		value: 'PS',
	},
	{
		icon: 'icon-[circle-flags--pa]',
		label: 'common.countries.PA',
		country_name: 'Panama',
		country_name_de: 'Panama',
		dial_code: '+507',
		value: 'PA',
	},
	{
		icon: 'icon-[circle-flags--pg]',
		label: 'common.countries.PG',
		country_name: 'Papua New Guinea',
		country_name_de: 'Papua-Neuguinea',
		dial_code: '+675',
		value: 'PG',
	},
	{
		icon: 'icon-[circle-flags--py]',
		label: 'common.countries.PY',
		country_name: 'Paraguay',
		country_name_de: 'Paraguay',
		dial_code: '+595',
		value: 'PY',
	},
	{
		icon: 'icon-[circle-flags--pe]',
		label: 'common.countries.PE',
		country_name: 'Peru',
		country_name_de: 'Peru',
		dial_code: '+51',
		value: 'PE',
	},
	{
		icon: 'icon-[circle-flags--ph]',
		label: 'common.countries.PH',
		country_name: 'Philippines',
		country_name_de: 'Philippinen',
		dial_code: '+63',
		value: 'PH',
	},
	{
		icon: 'icon-[circle-flags--pn]',
		label: 'common.countries.PN',
		country_name: 'Pitcairn Islands',
		country_name_de: 'Pitcairninseln',
		dial_code: '+872',
		value: 'PN',
	},
	{
		icon: 'icon-[circle-flags--pl]',
		label: 'common.countries.PL',
		country_name: 'Poland',
		country_name_de: 'Polen',
		dial_code: '+48',
		value: 'PL',
	},
	{
		icon: 'icon-[circle-flags--pt]',
		label: 'common.countries.PT',
		country_name: 'Portugal',
		country_name_de: 'Portugal',
		dial_code: '+351',
		value: 'PT',
	},
	{
		icon: 'icon-[circle-flags--pr]',
		label: 'common.countries.PR',
		country_name: 'Puerto Rico',
		country_name_de: 'Puerto Rico',
		dial_code: '+1 939',
		value: 'PR',
	},
	{
		icon: 'icon-[circle-flags--qa]',
		label: 'common.countries.QA',
		country_name: 'Qatar',
		country_name_de: 'Katar',
		dial_code: '+974',
		value: 'QA',
	},
	{
		icon: 'icon-[circle-flags--ro]',
		label: 'common.countries.RO',
		country_name: 'Romania',
		country_name_de: 'Rumänien',
		dial_code: '+40',
		value: 'RO',
	},
	{
		icon: 'icon-[circle-flags--ru]',
		label: 'common.countries.RU',
		country_name: 'Russian Federation',
		country_name_de: 'Russische Föderation',
		dial_code: '+7',
		value: 'RU',
	},
	{
		icon: 'icon-[circle-flags--rw]',
		label: 'common.countries.RW',
		country_name: 'Rwanda',
		country_name_de: 'Ruanda',
		dial_code: '+250',
		value: 'RW',
	},
	{
		icon: 'icon-[circle-flags--re]',
		label: 'common.countries.RE',
		country_name: 'Réunion',
		country_name_de: 'Réunion',
		dial_code: '+262',
		value: 'RE',
	},
	{
		icon: 'icon-[circle-flags--bl]',
		label: 'common.countries.BL',
		country_name: 'Saint Barthélemy',
		country_name_de: 'Saint-Barthélemy',
		dial_code: '+590',
		value: 'BL',
	},
	{
		icon: 'icon-[circle-flags--sh]',
		label: 'common.countries.SH',
		country_name: 'Saint Helena, Ascension and Tristan da Cunha',
		country_name_de: 'St. Helena, Ascension und Tristan da Cunha',
		dial_code: '+290',
		value: 'SH',
	},
	{
		icon: 'icon-[circle-flags--kn]',
		label: 'common.countries.KN',
		country_name: 'Saint Kitts and Nevis',
		country_name_de: 'St. Kitts und Nevis',
		dial_code: '+1 869',
		value: 'KN',
	},
	{
		icon: 'icon-[circle-flags--lc]',
		label: 'common.countries.LC',
		country_name: 'Saint Lucia',
		country_name_de: 'St. Lucia',
		dial_code: '+1 758',
		value: 'LC',
	},
	{
		icon: 'icon-[circle-flags--mf]',
		label: 'common.countries.MF',
		country_name: 'Saint Martin (French part)',
		country_name_de: 'Saint-Martin (französischer Teil)',
		dial_code: '+590',
		value: 'MF',
	},
	{
		icon: 'icon-[circle-flags--pm]',
		label: 'common.countries.PM',
		country_name: 'Saint Pierre and Miquelon',
		country_name_de: 'Saint-Pierre und Miquelon',
		dial_code: '+508',
		value: 'PM',
	},
	{
		icon: 'icon-[circle-flags--vc]',
		label: 'common.countries.VC',
		country_name: 'Saint Vincent and the Grenadines',
		country_name_de: 'St. Vincent und die Grenadinen',
		dial_code: '+1 784',
		value: 'VC',
	},
	{
		icon: 'icon-[circle-flags--ws]',
		label: 'common.countries.WS',
		country_name: 'Samoa',
		country_name_de: 'Samoa',
		dial_code: '+685',
		value: 'WS',
	},
	{
		icon: 'icon-[circle-flags--sm]',
		label: 'common.countries.SM',
		country_name: 'San Marino',
		country_name_de: 'San Marino',
		dial_code: '+378',
		value: 'SM',
	},
	{
		icon: 'icon-[circle-flags--st]',
		label: 'common.countries.ST',
		country_name: 'Sao Tome and Principe',
		country_name_de: 'São Tomé und Príncipe',
		dial_code: '+239',
		value: 'ST',
	},
	{
		icon: 'icon-[circle-flags--sa]',
		label: 'common.countries.SA',
		country_name: 'Saudi Arabia',
		country_name_de: 'Saudi-Arabien',
		dial_code: '+966',
		value: 'SA',
	},
	{
		icon: 'icon-[circle-flags--sn]',
		label: 'common.countries.SN',
		country_name: 'Senegal',
		country_name_de: 'Senegal',
		dial_code: '+221',
		value: 'SN',
	},
	{
		icon: 'icon-[circle-flags--rs]',
		label: 'common.countries.RS',
		country_name: 'Serbia',
		country_name_de: 'Serbien',
		dial_code: '+381',
		value: 'RS',
	},
	{
		icon: 'icon-[circle-flags--sc]',
		label: 'common.countries.SC',
		country_name: 'Seychelles',
		country_name_de: 'Seychellen',
		dial_code: '+248',
		value: 'SC',
	},
	{
		icon: 'icon-[circle-flags--sl]',
		label: 'common.countries.SL',
		country_name: 'Sierra Leone',
		country_name_de: 'Sierra Leone',
		dial_code: '+232',
		value: 'SL',
	},
	{
		icon: 'icon-[circle-flags--sg]',
		label: 'common.countries.SG',
		country_name: 'Singapore',
		country_name_de: 'Singapur',
		dial_code: '+65',
		value: 'SG',
	},
	{
		icon: 'icon-[circle-flags--sx]',
		label: 'common.countries.SX',
		country_name: 'Sint Maarten (Dutch part)',
		country_name_de: 'Sint Maarten (niederländischer Teil)',
		dial_code: '+1 721',
		value: 'SX',
	},
	{
		icon: 'icon-[circle-flags--sk]',
		label: 'common.countries.SK',
		country_name: 'Slovakia',
		country_name_de: 'Slowakei',
		dial_code: '+421',
		value: 'SK',
	},
	{
		icon: 'icon-[circle-flags--si]',
		label: 'common.countries.SI',
		country_name: 'Slovenia',
		country_name_de: 'Slowenien',
		dial_code: '+386',
		value: 'SI',
	},
	{
		icon: 'icon-[circle-flags--sb]',
		label: 'common.countries.SB',
		country_name: 'Solomon Islands',
		country_name_de: 'Salomonen',
		dial_code: '+677',
		value: 'SB',
	},
	{
		icon: 'icon-[circle-flags--so]',
		label: 'common.countries.SO',
		country_name: 'Somalia',
		country_name_de: 'Somalia',
		dial_code: '+252',
		value: 'SO',
	},
	{
		icon: 'icon-[circle-flags--za]',
		label: 'common.countries.ZA',
		country_name: 'South Africa',
		country_name_de: 'Südafrika',
		dial_code: '+27',
		value: 'ZA',
	},
	{
		icon: 'icon-[circle-flags--gs]',
		label: 'common.countries.GS',
		country_name: 'South Georgia and the South Sandwich Islands',
		country_name_de: 'Südgeorgien und die Südlichen Sandwichinseln',
		dial_code: '+500',
		value: 'GS',
	},
	{
		icon: 'icon-[circle-flags--ss]',
		label: 'common.countries.SS',
		country_name: 'South Sudan',
		country_name_de: 'Südsudan',
		dial_code: '+211',
		value: 'SS',
	},
	{
		icon: 'icon-[circle-flags--es]',
		label: 'common.countries.ES',
		country_name: 'Spain',
		country_name_de: 'Spanien',
		dial_code: '+34',
		value: 'ES',
	},
	{
		icon: 'icon-[circle-flags--lk]',
		label: 'common.countries.LK',
		country_name: 'Sri Lanka',
		country_name_de: 'Sri Lanka',
		dial_code: '+94',
		value: 'LK',
	},
	{
		icon: 'icon-[circle-flags--sd]',
		label: 'common.countries.SD',
		country_name: 'Sudan',
		country_name_de: 'Sudan',
		dial_code: '+249',
		value: 'SD',
	},
	{
		icon: 'icon-[circle-flags--sr]',
		label: 'common.countries.SR',
		country_name: 'Suriname',
		country_name_de: 'Suriname',
		dial_code: '+597',
		value: 'SR',
	},
	{
		icon: 'icon-[circle-flags--sj]',
		label: 'common.countries.SJ',
		country_name: 'Svalbard and Jan Mayen',
		country_name_de: 'Svalbard und Jan Mayen',
		dial_code: '+47',
		value: 'SJ',
	},
	{
		icon: 'icon-[circle-flags--se]',
		label: 'common.countries.SE',
		country_name: 'Sweden',
		country_name_de: 'Schweden',
		dial_code: '+46',
		value: 'SE',
	},
	{
		icon: 'icon-[circle-flags--ch]',
		label: 'common.countries.CH',
		country_name: 'Switzerland',
		country_name_de: 'Schweiz',
		dial_code: '+41',
		value: 'CH',
	},
	{
		icon: 'icon-[circle-flags--sy]',
		label: 'common.countries.SY',
		country_name: 'Syria',
		country_name_de: 'Syrien',
		dial_code: '+963',
		value: 'SY',
	},
	{
		icon: 'icon-[circle-flags--tw]',
		label: 'common.countries.TW',
		country_name: 'Taiwan',
		country_name_de: 'Taiwan',
		dial_code: '+886',
		value: 'TW',
	},
	{
		icon: 'icon-[circle-flags--tj]',
		label: 'common.countries.TJ',
		country_name: 'Tajikistan',
		country_name_de: 'Tadschikistan',
		dial_code: '+992',
		value: 'TJ',
	},
	{
		icon: 'icon-[circle-flags--tz]',
		label: 'common.countries.TZ',
		country_name: 'Tanzania',
		country_name_de: 'Tansania',
		dial_code: '+255',
		value: 'TZ',
	},
	{
		icon: 'icon-[circle-flags--th]',
		label: 'common.countries.TH',
		country_name: 'Thailand',
		country_name_de: 'Thailand',
		dial_code: '+66',
		value: 'TH',
	},
	{
		icon: 'icon-[circle-flags--tl]',
		label: 'common.countries.TL',
		country_name: 'Timor-Leste',
		country_name_de: 'Timor-Leste',
		dial_code: '+670',
		value: 'TL',
	},
	{
		icon: 'icon-[circle-flags--tg]',
		label: 'common.countries.TG',
		country_name: 'Togo',
		country_name_de: 'Togo',
		dial_code: '+228',
		value: 'TG',
	},
	{
		icon: 'icon-[circle-flags--tk]',
		label: 'common.countries.TK',
		country_name: 'Tokelau',
		country_name_de: 'Tokelau',
		dial_code: '+690',
		value: 'TK',
	},
	{
		icon: 'icon-[circle-flags--to]',
		label: 'common.countries.TO',
		country_name: 'Tonga',
		country_name_de: 'Tonga',
		dial_code: '+676',
		value: 'TO',
	},
	{
		icon: 'icon-[circle-flags--tt]',
		label: 'common.countries.TT',
		country_name: 'Trinidad and Tobago',
		country_name_de: 'Trinidad und Tobago',
		dial_code: '+1 868',
		value: 'TT',
	},
	{
		icon: 'icon-[circle-flags--tn]',
		label: 'common.countries.TN',
		country_name: 'Tunisia',
		country_name_de: 'Tunesien',
		dial_code: '+216',
		value: 'TN',
	},
	{
		icon: 'icon-[circle-flags--tr]',
		label: 'common.countries.TR',
		country_name: 'Turkey',
		country_name_de: 'Türkei',
		dial_code: '+90',
		value: 'TR',
	},
	{
		icon: 'icon-[circle-flags--tm]',
		label: 'common.countries.TM',
		country_name: 'Turkmenistan',
		country_name_de: 'Turkmenistan',
		dial_code: '+993',
		value: 'TM',
	},
	{
		icon: 'icon-[circle-flags--tc]',
		label: 'common.countries.TC',
		country_name: 'Turks and Caicos Islands',
		country_name_de: 'Turks und Caicosinseln',
		dial_code: '+1 649',
		value: 'TC',
	},
	{
		icon: 'icon-[circle-flags--tv]',
		label: 'common.countries.TV',
		country_name: 'Tuvalu',
		country_name_de: 'Tuvalu',
		dial_code: '+688',
		value: 'TV',
	},
	{
		icon: 'icon-[circle-flags--ug]',
		label: 'common.countries.UG',
		country_name: 'Uganda',
		country_name_de: 'Uganda',
		dial_code: '+256',
		value: 'UG',
	},
	{
		icon: 'icon-[circle-flags--ua]',
		label: 'common.countries.UA',
		country_name: 'Ukraine',
		country_name_de: 'Ukraine',
		dial_code: '+380',
		value: 'UA',
	},
	{
		icon: 'icon-[circle-flags--ae]',
		label: 'common.countries.AE',
		country_name: 'United Arab Emirates',
		country_name_de: 'Vereinigte Arabische Emirate',
		dial_code: '+971',
		value: 'AE',
	},
	{
		icon: 'icon-[circle-flags--gb]',
		label: 'common.countries.GB',
		country_name: 'United Kingdom',
		country_name_de: 'Vereinigtes Königreich',
		dial_code: '+44',
		value: 'GB',
	},
	{
		icon: 'icon-[circle-flags--um]',
		label: 'common.countries.UM',
		country_name: 'United States Minor Outlying Islands',
		country_name_de: 'Amerikanische Überseeinseln',
		dial_code: '+1',
		value: 'UM',
	},
	{
		icon: 'icon-[circle-flags--us]',
		label: 'common.countries.US',
		country_name: 'United States of America',
		country_name_de: 'Vereinigte Staaten von Amerika',
		dial_code: '+1',
		value: 'US',
	},
	{
		icon: 'icon-[circle-flags--uy]',
		label: 'common.countries.UY',
		country_name: 'Uruguay',
		country_name_de: 'Uruguay',
		dial_code: '+598',
		value: 'UY',
	},
	{
		icon: 'icon-[circle-flags--uz]',
		label: 'common.countries.UZ',
		country_name: 'Uzbekistan',
		country_name_de: 'Usbekistan',
		dial_code: '+998',
		value: 'UZ',
	},
	{
		icon: 'icon-[circle-flags--vu]',
		label: 'common.countries.VU',
		country_name: 'Vanuatu',
		country_name_de: 'Vanuatu',
		dial_code: '+678',
		value: 'VU',
	},
	{
		icon: 'icon-[circle-flags--ve]',
		label: 'common.countries.VE',
		country_name: 'Venezuela',
		country_name_de: 'Venezuela',
		dial_code: '+58',
		value: 'VE',
	},
	{
		icon: 'icon-[circle-flags--vn]',
		label: 'common.countries.VN',
		country_name: 'Vietnam',
		country_name_de: 'Vietnam',
		dial_code: '+84',
		value: 'VN',
	},
	{
		icon: 'icon-[circle-flags--vg]',
		label: 'common.countries.VG',
		country_name: 'Virgin Islands, British',
		country_name_de: 'Britische Jungferninseln',
		dial_code: '+1 284',
		value: 'VG',
	},
	{
		icon: 'icon-[circle-flags--vi]',
		label: 'common.countries.VI',
		country_name: 'Virgin Islands, U.S.',
		country_name_de: 'Amerikanische Jungferninseln',
		dial_code: '+1 340',
		value: 'VI',
	},
	{
		icon: 'icon-[circle-flags--wf]',
		label: 'common.countries.WF',
		country_name: 'Wallis and Futuna',
		country_name_de: 'Wallis und Futuna',
		dial_code: '+681',
		value: 'WF',
	},
	{
		icon: 'icon-[circle-flags--eh]',
		label: 'common.countries.EH',
		country_name: 'Western Sahara',
		country_name_de: 'Westsahara',
		dial_code: '+212',
		value: 'EH',
	},
	{
		icon: 'icon-[circle-flags--ye]',
		label: 'common.countries.YE',
		country_name: 'Yemen',
		country_name_de: 'Jemen',
		dial_code: '+967',
		value: 'YE',
	},
	{
		icon: 'icon-[circle-flags--zm]',
		label: 'common.countries.ZM',
		country_name: 'Zambia',
		country_name_de: 'Sambia',
		dial_code: '+260',
		value: 'ZM',
	},
	{
		icon: 'icon-[circle-flags--zw]',
		label: 'common.countries.ZW',
		country_name: 'Zimbabwe',
		country_name_de: 'Simbabwe',
		dial_code: '+263',
		value: 'ZW',
	},
	{
		icon: 'icon-[circle-flags--ax]',
		label: 'common.countries.AX',
		country_name: 'Åland Islands',
		country_name_de: 'Ålandinseln',
		dial_code: '+358',
		value: 'AX',
	},
]

const countriesMap = new Map<string, (typeof countries)[number]>()
const countriesMapLabel = new Map<string, (typeof countries)[number]>()
const counriesMapLabelDe = new Map<string, (typeof countries)[number]>()

for (const country of countries) {
	countriesMap.set(country.value, country)
	countriesMapLabel.set(deburr(country.country_name).toLowerCase(), country)
	counriesMapLabelDe.set(deburr(country.country_name_de).toLowerCase(), country)
}

const dialCodes = [
	{
		label: '+93',
		value: '+93',
		code: 'AF',
	},
	{
		label: '+355',
		value: '+355',
		code: 'AL',
	},
	{
		label: '+213',
		value: '+213',
		code: 'DZ',
	},
	{
		label: '+1 684',
		value: '+1 684',
		code: 'AS',
	},
	{
		label: '+376',
		value: '+376',
		code: 'AD',
	},
	{
		label: '+244',
		value: '+244',
		code: 'AO',
	},
	{
		label: '+1 264',
		value: '+1 264',
		code: 'AI',
	},
	{
		label: '+672',
		value: '+672',
		code: 'AQ',
	},
	{
		label: '+1 268',
		value: '+1 268',
		code: 'AG',
	},
	{
		label: '+54',
		value: '+54',
		code: 'AR',
	},
	{
		label: '+374',
		value: '+374',
		code: 'AM',
	},
	{
		label: '+297',
		value: '+297',
		code: 'AW',
	},
	{
		label: '+247',
		value: '+247',
		code: 'AC',
	},
	{
		label: '+61',
		value: '+61',
		code: 'AU',
	},
	{
		label: '+43',
		value: '+43',
		code: 'AT',
	},
	{
		label: '+994',
		value: '+994',
		code: 'AZ',
	},
	{
		label: '+1 242',
		value: '+1 242',
		code: 'BS',
	},
	{
		label: '+973',
		value: '+973',
		code: 'BH',
	},
	{
		label: '+880',
		value: '+880',
		code: 'BD',
	},
	{
		label: '+1 246',
		value: '+1 246',
		code: 'BB',
	},
	{
		label: '+375',
		value: '+375',
		code: 'BY',
	},
	{
		label: '+32',
		value: '+32',
		code: 'BE',
	},
	{
		label: '+501',
		value: '+501',
		code: 'BZ',
	},
	{
		label: '+229',
		value: '+229',
		code: 'BJ',
	},
	{
		label: '+1 441',
		value: '+1 441',
		code: 'BM',
	},
	{
		label: '+975',
		value: '+975',
		code: 'BT',
	},
	{
		label: '+591',
		value: '+591',
		code: 'BO',
	},
	{
		label: '+599',
		value: '+599',
		code: 'BQ',
	},
	{
		label: '+387',
		value: '+387',
		code: 'BA',
	},
	{
		label: '+267',
		value: '+267',
		code: 'BW',
	},
	{
		label: '+47',
		value: '+47',
		code: 'BV',
	},
	{
		label: '+55',
		value: '+55',
		code: 'BR',
	},
	{
		label: '+246',
		value: '+246',
		code: 'IO',
	},
	{
		label: '+673',
		value: '+673',
		code: 'BN',
	},
	{
		label: '+359',
		value: '+359',
		code: 'BG',
	},
	{
		label: '+226',
		value: '+226',
		code: 'BF',
	},
	{
		label: '+257',
		value: '+257',
		code: 'BI',
	},
	{
		label: '+238',
		value: '+238',
		code: 'CV',
	},
	{
		label: '+855',
		value: '+855',
		code: 'KH',
	},
	{
		label: '+237',
		value: '+237',
		code: 'CM',
	},
	{
		label: '+1',
		value: '+1',
		code: 'CA',
	},
	{
		label: '+ 345',
		value: '+ 345',
		code: 'KY',
	},
	{
		label: '+236',
		value: '+236',
		code: 'CF',
	},
	{
		label: '+235',
		value: '+235',
		code: 'TD',
	},
	{
		label: '+56',
		value: '+56',
		code: 'CL',
	},
	{
		label: '+86',
		value: '+86',
		code: 'CN',
	},
	{
		label: '+61',
		value: '+61',
		code: 'CX',
	},
	{
		label: '+61',
		value: '+61',
		code: 'CC',
	},
	{
		label: '+57',
		value: '+57',
		code: 'CO',
	},
	{
		label: '+269',
		value: '+269',
		code: 'KM',
	},
	{
		label: '+242',
		value: '+242',
		code: 'CG',
	},
	{
		label: '+243',
		value: '+243',
		code: 'CD',
	},
	{
		label: '+682',
		value: '+682',
		code: 'CK',
	},
	{
		label: '+506',
		value: '+506',
		code: 'CR',
	},
	{
		label: '+385',
		value: '+385',
		code: 'HR',
	},
	{
		label: '+53',
		value: '+53',
		code: 'CU',
	},
	{
		label: '+599',
		value: '+599',
		code: 'CW',
	},
	{
		label: '+357',
		value: '+357',
		code: 'CY',
	},
	{
		label: '+420',
		value: '+420',
		code: 'CZ',
	},
	{
		label: '+225',
		value: '+225',
		code: 'CI',
	},
	{
		label: '+45',
		value: '+45',
		code: 'DK',
	},
	{
		label: '+253',
		value: '+253',
		code: 'DJ',
	},
	{
		label: '+1 767',
		value: '+1 767',
		code: 'DM',
	},
	{
		label: '+1 849',
		value: '+1 849',
		code: 'DO',
	},
	{
		label: '+593',
		value: '+593',
		code: 'EC',
	},
	{
		label: '+20',
		value: '+20',
		code: 'EG',
	},
	{
		label: '+503',
		value: '+503',
		code: 'SV',
	},
	{
		label: '+240',
		value: '+240',
		code: 'GQ',
	},
	{
		label: '+291',
		value: '+291',
		code: 'ER',
	},
	{
		label: '+372',
		value: '+372',
		code: 'EE',
	},
	{
		label: '+268',
		value: '+268',
		code: 'SZ',
	},
	{
		label: '+251',
		value: '+251',
		code: 'ET',
	},
	{
		label: '+500',
		value: '+500',
		code: 'FK',
	},
	{
		label: '+298',
		value: '+298',
		code: 'FO',
	},
	{
		label: '+679',
		value: '+679',
		code: 'FJ',
	},
	{
		label: '+358',
		value: '+358',
		code: 'FI',
	},
	{
		label: '+33',
		value: '+33',
		code: 'FR',
	},
	{
		label: '+594',
		value: '+594',
		code: 'GF',
	},
	{
		label: '+689',
		value: '+689',
		code: 'PF',
	},
	{
		label: '+262',
		value: '+262',
		code: 'TF',
	},
	{
		label: '+241',
		value: '+241',
		code: 'GA',
	},
	{
		label: '+220',
		value: '+220',
		code: 'GM',
	},
	{
		label: '+995',
		value: '+995',
		code: 'GE',
	},
	{
		label: '+49',
		value: '+49',
		code: 'DE',
	},
	{
		label: '+233',
		value: '+233',
		code: 'GH',
	},
	{
		label: '+350',
		value: '+350',
		code: 'GI',
	},
	{
		label: '+30',
		value: '+30',
		code: 'GR',
	},
	{
		label: '+299',
		value: '+299',
		code: 'GL',
	},
	{
		label: '+1 473',
		value: '+1 473',
		code: 'GD',
	},
	{
		label: '+590',
		value: '+590',
		code: 'GP',
	},
	{
		label: '+1 671',
		value: '+1 671',
		code: 'GU',
	},
	{
		label: '+502',
		value: '+502',
		code: 'GT',
	},
	{
		label: '+44',
		value: '+44',
		code: 'GG',
	},
	{
		label: '+224',
		value: '+224',
		code: 'GN',
	},
	{
		label: '+245',
		value: '+245',
		code: 'GW',
	},
	{
		label: '+595',
		value: '+595',
		code: 'GY',
	},
	{
		label: '+509',
		value: '+509',
		code: 'HT',
	},
	{
		label: '+672',
		value: '+672',
		code: 'HM',
	},
	{
		label: '+379',
		value: '+379',
		code: 'VA',
	},
	{
		label: '+504',
		value: '+504',
		code: 'HN',
	},
	{
		label: '+852',
		value: '+852',
		code: 'HK',
	},
	{
		label: '+36',
		value: '+36',
		code: 'HU',
	},
	{
		label: '+354',
		value: '+354',
		code: 'IS',
	},
	{
		label: '+91',
		value: '+91',
		code: 'IN',
	},
	{
		label: '+62',
		value: '+62',
		code: 'ID',
	},
	{
		label: '+98',
		value: '+98',
		code: 'IR',
	},
	{
		label: '+964',
		value: '+964',
		code: 'IQ',
	},
	{
		label: '+353',
		value: '+353',
		code: 'IE',
	},
	{
		label: '+44',
		value: '+44',
		code: 'IM',
	},
	{
		label: '+972',
		value: '+972',
		code: 'IL',
	},
	{
		label: '+39',
		value: '+39',
		code: 'IT',
	},
	{
		label: '+1 876',
		value: '+1 876',
		code: 'JM',
	},
	{
		label: '+81',
		value: '+81',
		code: 'JP',
	},
	{
		label: '+44',
		value: '+44',
		code: 'JE',
	},
	{
		label: '+962',
		value: '+962',
		code: 'JO',
	},
	{
		label: '+7 7',
		value: '+7 7',
		code: 'KZ',
	},
	{
		label: '+254',
		value: '+254',
		code: 'KE',
	},
	{
		label: '+686',
		value: '+686',
		code: 'KI',
	},
	{
		label: '+850',
		value: '+850',
		code: 'KP',
	},
	{
		label: '+82',
		value: '+82',
		code: 'KR',
	},
	{
		label: '+383',
		value: '+383',
		code: 'XK',
	},
	{
		label: '+965',
		value: '+965',
		code: 'KW',
	},
	{
		label: '+996',
		value: '+996',
		code: 'KG',
	},
	{
		label: '+856',
		value: '+856',
		code: 'LA',
	},
	{
		label: '+371',
		value: '+371',
		code: 'LV',
	},
	{
		label: '+961',
		value: '+961',
		code: 'LB',
	},
	{
		label: '+266',
		value: '+266',
		code: 'LS',
	},
	{
		label: '+231',
		value: '+231',
		code: 'LR',
	},
	{
		label: '+218',
		value: '+218',
		code: 'LY',
	},
	{
		label: '+423',
		value: '+423',
		code: 'LI',
	},
	{
		label: '+370',
		value: '+370',
		code: 'LT',
	},
	{
		label: '+352',
		value: '+352',
		code: 'LU',
	},
	{
		label: '+853',
		value: '+853',
		code: 'MO',
	},
	{
		label: '+261',
		value: '+261',
		code: 'MG',
	},
	{
		label: '+265',
		value: '+265',
		code: 'MW',
	},
	{
		label: '+60',
		value: '+60',
		code: 'MY',
	},
	{
		label: '+960',
		value: '+960',
		code: 'MV',
	},
	{
		label: '+223',
		value: '+223',
		code: 'ML',
	},
	{
		label: '+356',
		value: '+356',
		code: 'MT',
	},
	{
		label: '+692',
		value: '+692',
		code: 'MH',
	},
	{
		label: '+596',
		value: '+596',
		code: 'MQ',
	},
	{
		label: '+222',
		value: '+222',
		code: 'MR',
	},
	{
		label: '+230',
		value: '+230',
		code: 'MU',
	},
	{
		label: '+262',
		value: '+262',
		code: 'YT',
	},
	{
		label: '+52',
		value: '+52',
		code: 'MX',
	},
	{
		label: '+691',
		value: '+691',
		code: 'FM',
	},
	{
		label: '+373',
		value: '+373',
		code: 'MD',
	},
	{
		label: '+377',
		value: '+377',
		code: 'MC',
	},
	{
		label: '+976',
		value: '+976',
		code: 'MN',
	},
	{
		label: '+382',
		value: '+382',
		code: 'ME',
	},
	{
		label: '+1 664',
		value: '+1 664',
		code: 'MS',
	},
	{
		label: '+212',
		value: '+212',
		code: 'MA',
	},
	{
		label: '+258',
		value: '+258',
		code: 'MZ',
	},
	{
		label: '+95',
		value: '+95',
		code: 'MM',
	},
	{
		label: '+264',
		value: '+264',
		code: 'NA',
	},
	{
		label: '+674',
		value: '+674',
		code: 'NR',
	},
	{
		label: '+977',
		value: '+977',
		code: 'NP',
	},
	{
		label: '+31',
		value: '+31',
		code: 'NL',
	},
	{
		label: '+599',
		value: '+599',
		code: 'AN',
	},
	{
		label: '+687',
		value: '+687',
		code: 'NC',
	},
	{
		label: '+64',
		value: '+64',
		code: 'NZ',
	},
	{
		label: '+505',
		value: '+505',
		code: 'NI',
	},
	{
		label: '+227',
		value: '+227',
		code: 'NE',
	},
	{
		label: '+234',
		value: '+234',
		code: 'NG',
	},
	{
		label: '+683',
		value: '+683',
		code: 'NU',
	},
	{
		label: '+672',
		value: '+672',
		code: 'NF',
	},
	{
		label: '+389',
		value: '+389',
		code: 'MK',
	},
	{
		label: '+1 670',
		value: '+1 670',
		code: 'MP',
	},
	{
		label: '+47',
		value: '+47',
		code: 'NO',
	},
	{
		label: '+968',
		value: '+968',
		code: 'OM',
	},
	{
		label: '+92',
		value: '+92',
		code: 'PK',
	},
	{
		label: '+680',
		value: '+680',
		code: 'PW',
	},
	{
		label: '+970',
		value: '+970',
		code: 'PS',
	},
	{
		label: '+507',
		value: '+507',
		code: 'PA',
	},
	{
		label: '+675',
		value: '+675',
		code: 'PG',
	},
	{
		label: '+595',
		value: '+595',
		code: 'PY',
	},
	{
		label: '+51',
		value: '+51',
		code: 'PE',
	},
	{
		label: '+63',
		value: '+63',
		code: 'PH',
	},
	{
		label: '+872',
		value: '+872',
		code: 'PN',
	},
	{
		label: '+48',
		value: '+48',
		code: 'PL',
	},
	{
		label: '+351',
		value: '+351',
		code: 'PT',
	},
	{
		label: '+1 939',
		value: '+1 939',
		code: 'PR',
	},
	{
		label: '+974',
		value: '+974',
		code: 'QA',
	},
	{
		label: '+40',
		value: '+40',
		code: 'RO',
	},
	{
		label: '+7',
		value: '+7',
		code: 'RU',
	},
	{
		label: '+250',
		value: '+250',
		code: 'RW',
	},
	{
		label: '+262',
		value: '+262',
		code: 'RE',
	},
	{
		label: '+590',
		value: '+590',
		code: 'BL',
	},
	{
		label: '+290',
		value: '+290',
		code: 'SH',
	},
	{
		label: '+1 869',
		value: '+1 869',
		code: 'KN',
	},
	{
		label: '+1 758',
		value: '+1 758',
		code: 'LC',
	},
	{
		label: '+590',
		value: '+590',
		code: 'MF',
	},
	{
		label: '+508',
		value: '+508',
		code: 'PM',
	},
	{
		label: '+1 784',
		value: '+1 784',
		code: 'VC',
	},
	{
		label: '+685',
		value: '+685',
		code: 'WS',
	},
	{
		label: '+378',
		value: '+378',
		code: 'SM',
	},
	{
		label: '+239',
		value: '+239',
		code: 'ST',
	},
	{
		label: '+966',
		value: '+966',
		code: 'SA',
	},
	{
		label: '+221',
		value: '+221',
		code: 'SN',
	},
	{
		label: '+381',
		value: '+381',
		code: 'RS',
	},
	{
		label: '+248',
		value: '+248',
		code: 'SC',
	},
	{
		label: '+232',
		value: '+232',
		code: 'SL',
	},
	{
		label: '+65',
		value: '+65',
		code: 'SG',
	},
	{
		label: '+1 721',
		value: '+1 721',
		code: 'SX',
	},
	{
		label: '+421',
		value: '+421',
		code: 'SK',
	},
	{
		label: '+386',
		value: '+386',
		code: 'SI',
	},
	{
		label: '+677',
		value: '+677',
		code: 'SB',
	},
	{
		label: '+252',
		value: '+252',
		code: 'SO',
	},
	{
		label: '+27',
		value: '+27',
		code: 'ZA',
	},
	{
		label: '+500',
		value: '+500',
		code: 'GS',
	},
	{
		label: '+211',
		value: '+211',
		code: 'SS',
	},
	{
		label: '+34',
		value: '+34',
		code: 'ES',
	},
	{
		label: '+94',
		value: '+94',
		code: 'LK',
	},
	{
		label: '+249',
		value: '+249',
		code: 'SD',
	},
	{
		label: '+597',
		value: '+597',
		code: 'SR',
	},
	{
		label: '+47',
		value: '+47',
		code: 'SJ',
	},
	{
		label: '+46',
		value: '+46',
		code: 'SE',
	},
	{
		label: '+41',
		value: '+41',
		code: 'CH',
	},
	{
		label: '+963',
		value: '+963',
		code: 'SY',
	},
	{
		label: '+886',
		value: '+886',
		code: 'TW',
	},
	{
		label: '+992',
		value: '+992',
		code: 'TJ',
	},
	{
		label: '+255',
		value: '+255',
		code: 'TZ',
	},
	{
		label: '+66',
		value: '+66',
		code: 'TH',
	},
	{
		label: '+670',
		value: '+670',
		code: 'TL',
	},
	{
		label: '+228',
		value: '+228',
		code: 'TG',
	},
	{
		label: '+690',
		value: '+690',
		code: 'TK',
	},
	{
		label: '+676',
		value: '+676',
		code: 'TO',
	},
	{
		label: '+1 868',
		value: '+1 868',
		code: 'TT',
	},
	{
		label: '+216',
		value: '+216',
		code: 'TN',
	},
	{
		label: '+90',
		value: '+90',
		code: 'TR',
	},
	{
		label: '+993',
		value: '+993',
		code: 'TM',
	},
	{
		label: '+1 649',
		value: '+1 649',
		code: 'TC',
	},
	{
		label: '+688',
		value: '+688',
		code: 'TV',
	},
	{
		label: '+256',
		value: '+256',
		code: 'UG',
	},
	{
		label: '+380',
		value: '+380',
		code: 'UA',
	},
	{
		label: '+971',
		value: '+971',
		code: 'AE',
	},
	{
		label: '+44',
		value: '+44',
		code: 'GB',
	},
	{
		label: '+1',
		value: '+1',
		code: 'UM',
	},
	{
		label: '+1',
		value: '+1',
		code: 'US',
	},
	{
		label: '+598',
		value: '+598',
		code: 'UY',
	},
	{
		label: '+998',
		value: '+998',
		code: 'UZ',
	},
	{
		label: '+678',
		value: '+678',
		code: 'VU',
	},
	{
		label: '+58',
		value: '+58',
		code: 'VE',
	},
	{
		label: '+84',
		value: '+84',
		code: 'VN',
	},
	{
		label: '+1 284',
		value: '+1 284',
		code: 'VG',
	},
	{
		label: '+1 340',
		value: '+1 340',
		code: 'VI',
	},
	{
		label: '+681',
		value: '+681',
		code: 'WF',
	},
	{
		label: '+212',
		value: '+212',
		code: 'EH',
	},
	{
		label: '+967',
		value: '+967',
		code: 'YE',
	},
	{
		label: '+260',
		value: '+260',
		code: 'ZM',
	},
	{
		label: '+263',
		value: '+263',
		code: 'ZW',
	},
	{
		label: '+358',
		value: '+358',
		code: 'AX',
	},
]

const dialCodeMap = new Map<string, (typeof dialCodes)[number]>()
for (const code of dialCodes) dialCodeMap.set(code.code, code)
const dialCodesValues = dialCodes.map(code => code.value)
export const useCountries = () => {
	return {
		countries,
		dialCodesValues,
		get: (val: string) => countriesMap.get(val),
		getByLabel: (val: string) => countriesMapLabel.get(val),
		getByLabelDe: (val: string) => counriesMapLabelDe.get(val),
	}
}

export const germanyRegions = [
	{
		label: 'Baden-Württemberg',
		value: 'baden-württemberg',
	},
	{
		label: 'Bayern',
		value: 'bayern',
	},
	{
		label: 'Berlin',
		value: 'berlin',
	},
	{
		label: 'Brandenburg',
		value: 'brandenburg',
	},
	{
		label: 'Bremen',
		value: 'bremen',
	},
	{
		label: 'Hamburg',
		value: 'hamburg',
	},
	{
		label: 'Hessen',
		value: 'hessen',
	},
	{
		label: 'Mecklenburg-Vorpommern',
		value: 'mecklenburg-vorpommern',
	},
	{
		label: 'Niedersachsen',
		value: 'niedersachsen',
	},
	{
		label: 'Nordrhein-Westfalen',
		value: 'nordrhein-westfalen',
	},
	{
		label: 'Rheinland-Pfalz',
		value: 'rheinland-pfalz',
	},
	{
		label: 'Saarland',
		value: 'saarland',
	},
	{
		label: 'Sachsen',
		value: 'sachsen',
	},
	{
		label: 'Sachsen-Anhalt',
		value: 'sachsen-anhalt',
	},
	{
		label: 'Schleswig-Holstein',
		value: 'schleswig-holstein',
	},
	{
		label: 'Thüringen',
		value: 'thüringen',
	},
]

export const germanyRegionsMap = new Map<string, (typeof germanyRegions)[number]>()
for (const region of germanyRegions) {
	germanyRegionsMap.set(region.value, region)
}
