import '@nolas/design-system/css'

import { VueQueryPlugin } from '@tanstack/vue-query'
import { createHead } from '@unhead/vue/client'
import * as veevalidate from 'vee-validate'
import { createApp } from 'vue'
import VueLazyLoad from 'vue3-lazyload'

import { createI18n } from 'vue-i18n'
import { createRouter } from 'vue-i18n-routing'
import { createWebHistory } from 'vue-router'

import de from './locales/de.json'
import en from './locales/en.json'

import { routes } from './routes'

import App from './app.vue'
import ibanFormat from './directives/iban-format.ts'
import numericInputIntegerNegatives from './directives/numeric-input-integer-negatives.ts'
import numericInputInteger from './directives/numeric-input-integer.ts'
import numericInput from './directives/numeric-input.ts'

veevalidate.configure({
	validateOnInput: false,
	validateOnModelUpdate: false,
	validateOnChange: false,
	validateOnBlur: false,
})

const i18n = createI18n({
	legacy: false,
	locale: navigator.language.split('-')[0],
	fallbackLocale: 'en',
	globalInjection: true,
	warnHtmlInMessage: false,
	warnHtmlMessage: false,
	messages: {
		en,
		de,
	},
})

const router = createRouter(i18n, {
	version: 4,
	locales: ['en', 'de'],
	defaultLocale: localStorage.getItem('nolas_lang') || 'de',
	history: createWebHistory(),
	routes: routes,
})

router.onError((error: unknown) => {
	if (error instanceof Error) {
		const msg = error.message.toLowerCase()
		if (msg.includes('failed to fetch dynamically')) {
			window.location.reload()
		}
	}
})

createApp(App)
	.use(router)
	.use(i18n)
	.use(createHead())
	.use(VueQueryPlugin)
	.use(VueLazyLoad, {})
	.directive('numeric-input', numericInput)
	.directive('numeric-input-integer', numericInputInteger)
	.directive('iban-format', ibanFormat)
	.directive('numeric-input-integer-negatives', numericInputIntegerNegatives)
	.mount('#app')
