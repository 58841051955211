<script setup lang="ts">
import { computed } from 'vue'
import { provideWorkflowState } from '@workflows/states/provider'
import { VueFlowProviderWrapper, type VFlowInitialData } from '@webamboos/vue-flow-wrapper'

const { workflow } = provideWorkflowState()

const initialData = computed(
	() =>
		(workflow?.value?.builderConfig || {
			nodes: {},
			edges: {},
		}) as VFlowInitialData
)
</script>
<template>
	<VueFlowProviderWrapper :initial-data="initialData">
		<slot />
	</VueFlowProviderWrapper>
</template>
