import { createQueryKeys } from '@lukemorales/query-key-factory'
import { ListWorkflows } from '@services/nolas-api'
import { GetWorkflow } from '@services/nolas-api'
import { ServiceError } from '@nolas/lib/errors'
import type { MaybeRef } from 'vue'
import { unref } from 'vue'

export type WorkflowsQuery = {
	page?: number
	limit?: number
	search?: string
}

const fetchWorkflows = async (workspaceId: MaybeRef<string>, query: WorkflowsQuery) => {
	const { response, data, error } = await ListWorkflows({
		params: {
			path: { workspaceId: unref(workspaceId) },
			...(!!query.search?.length && { query: { search: query.search } }),
		},
	})

	if (!response.ok || !data) {
		throw ServiceError.fromResponse(error)
	}
	return data.workflows
}

const fetchWorkflow = async (workspaceId: MaybeRef<string>, workflowId: MaybeRef<string | undefined>) => {
	const { response, data, error } = await GetWorkflow({
		params: { path: { workflowId: unref(workflowId) ?? '', workspaceId: unref(workspaceId) } },
	})

	if (!response.ok || !data) {
		throw ServiceError.fromResponse(error)
	}
	return data
}

export const workflows = createQueryKeys('workflows', {
	details: (workspaceId: MaybeRef<string>, workflowId: MaybeRef<string | undefined>) => ({
		queryKey: [workflowId],
		queryFn: () => fetchWorkflow(workspaceId, workflowId),
	}),
	list: (workspaceId: MaybeRef<string>, query: WorkflowsQuery) => ({
		queryKey: [{ query }],
		queryFn: () => fetchWorkflows(workspaceId, query),
	}),
})