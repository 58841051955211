<script lang="ts" setup>
import { provide, reactive, watch } from 'vue'
import { useRoute, useRouter, type LocationQueryRaw } from 'vue-router'

import { flattenObject } from '@nolas/lib/object-utils'
import { contextKey, AddressBookSchema, BaseSchema, UrlStateContextKeys } from '@url-states/utils'

import type { BaseURLState } from '@url-states/utils'

// Setup URL state
const route = useRoute()
const router = useRouter()

const _contextKey = route.meta.type
	? UrlStateContextKeys[route.meta.type as keyof typeof UrlStateContextKeys]
	: contextKey

const schema = route.meta.type === 'addressBook' ? AddressBookSchema : BaseSchema
const queryState = reactive(schema.parse(route.query))

const context: BaseURLState<typeof schema> = {
	query: queryState,
}

watch(
	queryState,
	state => {
		const query = {
			...state,
			page: String(state.page),
			limit: String(state.limit),
			q: state.q || undefined,
		}
		const flattenedQuery = flattenObject(query)

		// if (props.config?.useURLReplace !== false) {
		router.replace({ query: flattenedQuery as LocationQueryRaw })
		// }
	},
	{
		immediate: true,
	}
)

provide(_contextKey, context)
</script>

<template>
  <slot />
</template>