import { createQueryKeys } from '@lukemorales/query-key-factory'
import { ListFolders } from '@services/nolas-api'
import { useQuery } from '@tanstack/vue-query'
import type { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServiceError } from '@nolas/lib/errors'

import { useUser } from '@auth/composables/use-user'

export const foldersFlatQueryKey = createQueryKeys('folders-flat')

async function fetchFoldersFlat(workspaceId: string) {
	const { response, data, error } = await ListFolders({
		params: {
			path: {
				workspaceId,
			},
		},
	})

	if (!response.ok || !data) {
		throw ServiceError.fromResponse(error)
	}
	return data
}

export function useFoldersFlat() {
	const { workspaceId } = useUser()
	const { t } = useI18n()
	const {
		data: foldersFlat,
		error,
		isLoading,
	} = useQuery({
		queryKey: foldersFlatQueryKey._def,
		retry: 2,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: 1000,
		queryFn: () => fetchFoldersFlat(workspaceId.value),
		select: (data: ListFolders) => {
			const parentFolders = data.filter(folder => folder.parentId === null)
			return data.map(folder => {
				let name: string
				if (folder.parentId === null) {
					name = t(`app.keyflowz.address_book.folders.name.${folder.type}`)
				} else if (folder.type === 'admin_folder') {
					name = `${t(`app.keyflowz.address_book.folders.name.${folder.type}`)}`
				} else if (parentFolders.some(parent => parent.id === folder.parentId)) {
					name = `${t(`app.keyflowz.address_book.folders.name.${folder.type}`)} > ${folder.name}`
				} else {
					name = `${t(`app.keyflowz.address_book.folders.name.${folder.type}`)} > ... > ${folder.name}`
				}
				return {
					...folder,
					name,
				}
			})
		},
	})

	return { foldersFlat, isLoading, error: error as Ref<ServiceError | null> }
}
