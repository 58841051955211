import type { InjectionKey, Reactive, Ref } from 'vue'

import type { useDocument } from 'src/common/composables/documents/use-document'

import type { TimelineStep } from './timeline-utils'
import type { Color, PageBounds, PageSignatures, Printing, Variant } from './util'

export interface Sheet {
	front: number
	back?: number
}

export interface PageProperties {
	color: Color
	thumbnail: string
	letterhead: boolean
	signatures: PageSignatures
	hideToolbox?: boolean
}

export interface PreviewState {
	viewElement: Ref<HTMLElement | undefined>
	inViewIndex: Ref<number>
	sheets: Reactive<Sheet[]>
	selection: Ref<number[]>
	zoom: Ref<number>
	letterhead: Ref<boolean>
	color: Ref<Color | 'mixed'>
	printing: Ref<Printing | 'mixed'>
	activeLetterhead: Ref<string | undefined>
	variant: Ref<Variant | undefined>
}

export interface SignatureState {
	activeSignatureId: Ref<string | undefined>
	pageBounds: Ref<PageBounds>
}

export interface Timeline {
		showRequestApprovalDialog: Ref<boolean>
		showApprovalReasonDialog: Ref<'reject' | 'approve' | undefined>
		stepMap: Reactive<Record<string, boolean>>
		stepsToFollow: Reactive<TimelineStep[]>
	}

export interface DocViewContext {
	document: Exclude<ReturnType<typeof useDocument>['document'], Ref<undefined>>
	queryState: Omit<ReturnType<typeof useDocument>, 'document'>
	pages: Ref<string[]>
	pageProperties: Reactive<PageProperties[]>
	preview: PreviewState
	signatures: SignatureState
	timeline: Timeline
}

export const contextKey = Symbol('docview') as InjectionKey<DocViewContext>
