<script setup lang="ts">
import { Card, CardContent } from '../../components/card'
import { Heading } from '../../components/heading'
import { Paragraph } from '../../components/paragraph'

defineProps<{ title?: string; description?: string }>()
</script>

<template>
  <div
    class="flex h-screen min-h-screen w-full flex-col items-center justify-center overflow-y-auto bg-auth-gradient md-height:justify-center md-height:py-16"
  >
    <div class="w-full max-w-sm px-3">
      <INolasLogoBig class="mx-auto h-32 w-48" />
      <Heading v-if="title" size="2xl" class="mb-3" align="center">
        {{ title }}
      </Heading>
      <Paragraph class="mb-8" align="center">
        {{ description }}
      </Paragraph>
      <slot name="sso-button" />
      <Card>
        <CardContent class="px-5 py-6">
          <slot />
        </CardContent>
      </Card>
      <slot name="footer" />
    </div>
  </div>
</template>
