import { cva, type VariantProps } from 'class-variance-authority'

export { default as IconButton } from './icon-button.vue'

export const iconButtonVariants = cva(
	'inline-flex items-center justify-center font-semibold transition-all cursor-pointer',
	{
		variants: {
			variant: {
				primary: 'text-white bg-brand-550 hover:bg-brand-650 disabled:bg-gray-200 disabled:text-gray-450',
				secondary: 'text-gray-900 bg-secondary hover:bg-secondary-25 disabled:bg-gray-100 disabled:text-gray-450',
				outline:
					'text-brand-550 border border-gray-450 bg-transparent hover:bg-gray-50 hover:text-brand-650 disabled:border-gray-200 disabled:text-gray-450',
				beige:
					'text-gray-900 bg-gray-250 hover:bg-gray-350 active:text-white active:bg-brand-550 data-[state=open]:bg-brand-550  [&[data-state=open]>svg]:text-white disabled:bg-gray-200 disabled:text-gray-450',
				sand: 'text-gray-900 bg-neutal-150 hover:bg-gray-150 active:text-white active:bg-brand-550 disabled:bg-gray-100 disabled:text-gray-450',
				gray: 'text-gray-900 bg-gray-50 hover:bg-gray-150 active:bg-gray-250 disabled:bg-gray-100 disabled:text-gray-450',
				social:
					'text-gray-450 border border-gray-450 bg-transparent hover:bg-gray-50 hover:text-gray-450 disabled:border-gray-200 disabled:text-gray-450',
				tertiary:
					'bg-gray-350 hover:bg-gray-400 active:bg-brand-550 data-[state=open]:bg-brand-550 [&[data-state=open]>svg]:text-white disabled:bg-gray-200 disabled:text-gray-450',
				transparent: 'bg-transparent hover:bg-gray-150',
			},
			size: {
				xs: 'min-w-6 w-6 h-6 p-0',
				sm: 'min-w-7 w-7 h-7 p-1',
				md: 'min-w-9 w-9 h-9 p-1.5',
				lg: 'min-w-10 w-10 h-10 p-2',
			},
		},
		defaultVariants: {
			variant: 'primary',
			size: 'md',
		},
	}
)

type Variants = VariantProps<typeof iconButtonVariants>
export interface IconButtonProps {
	variant?: Variants['variant']
	size?: Variants['size']
	as?: 'button' | 'a' | 'router-link'
	rounded?: boolean
	loading?: boolean
	disabled?: boolean
}
