<template>
  <Button
    variant="transparent"
    size="xs"
    :class="
      cn(
        alwaysVisible
          ? 'visible text-gray-600'
          : 'invisible text-gray-600 group-hover:visible data-[state=open]:visible',
        visibilityClass,
        $attrs.class ?? ''
      )
    "
    :data-e2e="dataE2e"
  >
    <INolasDotsHorizontal class="h-6 w-6" />
    <slot />
  </Button>
</template>

<script setup lang="ts">
import { Button } from '../button'

import { cn } from '../../utils'

withDefaults(
	defineProps<{
		dataE2e?: string
		alwaysVisible?: boolean
		visibilityClass?: string
	}>(),
	{
		dataE2e: undefined,
		alwaysVisible: false,
		visibilityClass: 'pr-2',
	}
)
</script>
