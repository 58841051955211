import { getFeatureFlag } from '@services/feature-flags'
import isEqual from 'lodash/isEqual'
import { provide, ref, reactive, watch, nextTick, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { FileMimeType } from '@nolas/lib/constants'
import { useSidebarRight } from '@components/sidebar'

import { useCreateCostCenter } from '@administration/cost-centers/composables/use-create-cost-center'
import { useWorkflow } from '@workflows/composables/use-workflow'

import { contextKey, type AllowedConversion } from '../utils/context'
import type { RecipientFilter, SenderFilter, DocContentFilter, FileFormat } from '../utils/triggers'

export function provideWorkflowState() {
	const { isOpen, patch } = useSidebarRight()
	const { isCreatingNewCostCenter } = useCreateCostCenter()

	const route = useRoute()
	const { t } = useI18n()

	const activeWorkflow = ref<string | undefined>(route.params.id as string | undefined)
	const { workflow, ...queryState } = useWorkflow(activeWorkflow)

	const inputChannels = reactive<string[]>([])
	const fileFormats = reactive<FileFormat[]>([])
	const recipientsFilters = reactive<RecipientFilter[]>([])
	const senderFilters = reactive<SenderFilter[]>([])
	const docContentFilters = reactive<DocContentFilter[]>([])
	const conversionDialogOpen = ref(false)
	const allowedConversions = reactive<AllowedConversion[]>([])
	const emptyRecipientsExists = ref(false)
	const emptySenderExists = ref(false)
	const workflowName = ref('')
	const workflowDescription = ref('')
	const authorizedUsers = ref<string[]>([])
	const workflowCostCenter = ref('')
	const initialDataSet = ref(false)
	const tableSelectedWorkflows = ref([])

	const omitBuilder = computed(() => route.meta.omitBuilder || false)

	function setInitialInputChannels() {
		inputChannels.splice(0, inputChannels.length)
		const active = workflow.value?.triggers?.channel || []
		inputChannels.push(...active)
	}

	function setInitialFileFormats() {
		if (!getFeatureFlag('fileFormatsTrigger')) {
			return
		}

		fileFormats.splice(0, fileFormats.length)
		const active = workflow.value?.triggers?.fileFormat || []

		fileFormats.push(...active)
	}

	function setInitialDocContent() {
		docContentFilters.splice(0, docContentFilters.length)
		const active = workflow.value?.triggers?.content || []
		docContentFilters.push(...active)
	}

	function setInitialRecipients() {
		recipientsFilters.splice(0, recipientsFilters.length)
		const active = workflow.value?.triggers?.recipient || []
		recipientsFilters.push(...active)
	}

	function setInitialSender() {
		senderFilters.splice(0, senderFilters.length)
		const active = workflow.value?.triggers?.sender || []
		senderFilters.push(...active)
	}

	function setWorkflowAllowedConversions() {
		allowedConversions.splice(0, allowedConversions.length)
		const settings = workflow.value?.settings?.allowedConversions || []
		for (const setting of settings) {
			allowedConversions.push({
				from: setting.from as FileMimeType,
				to: setting.to as FileMimeType,
				effect: setting.effect,
			})
		}
	}

	function setInitialWorkflowData() {
		if (workflow.value) {
			workflowName.value = workflow.value.name
			workflowDescription.value = workflow.value.description || ''
			authorizedUsers.value = workflow.value.authorizedUsers || []
		}
	}

	function setWorkflowCostCenter(costCenter?: string | null) {
		if (costCenter) {
			workflowCostCenter.value = costCenter
		}
	}

	watch(
		() => isCreatingNewCostCenter.value,
		value => {
			patch({
				title: value ? t('app.workflows.workflow_page.cost_center.add_new') : (workflow.value?.name ?? ''),
			})
		},
		{ immediate: true, deep: true }
	)

	watch(
		workflow,
		async (newVal, oldVal) => {
			if (omitBuilder.value) {
				// setting data for the workflows table sidedrawer
				setWorkflowCostCenter(newVal?.costCenter)
				setInitialWorkflowData()
			}

			if (isEqual(newVal, oldVal) || omitBuilder.value) {
				return
			}

			setWorkflowCostCenter(workflow.value?.costCenter)
			setInitialWorkflowData()
			setWorkflowAllowedConversions()
			setInitialInputChannels()
			setInitialDocContent()
			setInitialFileFormats()
			setInitialRecipients()
			setInitialSender()

			await nextTick()
			initialDataSet.value = true
			// second watcher should only be triggered after the completion of this one
		},
		{ immediate: true, deep: true }
	)

	watch(
		() => [tableSelectedWorkflows.value, route.params.id, isOpen.value],
		([workflows, workflowId, open]) => {
			const workflowsIds = workflowId ? [workflowId] : workflows
			if (open && Array.isArray(workflowsIds) && workflowsIds.length === 1 && typeof workflowsIds[0] === 'string') {
				activeWorkflow.value = workflowsIds[0]
			}
		},
		{ deep: true, immediate: true }
	)

	provide(contextKey, {
		workflow,
		queryState,
		triggersState: {
			selectedTrigger: ref(),
			inputChannels,
			fileFormats,
			docContentFilters,
			recipientsFilters,
			senderFilters,
			emptyRecipientsExists,
			emptySenderExists,
		},
		general: {
			initialDataSet,
			stepMenuActive: ref(false),
			builderHasChanges: ref(false),
			isSidedrawerCostCenterActive: ref(false),
		},
		selectionState: {
			activeWorkflow,
			tableSelectedWorkflows,
		},
		conversionState: {
			conversionDialogOpen,
			allowedConversions,
		},
		workflowDetails: {
			name: workflowName,
			description: workflowDescription,
			costCenter: workflowCostCenter,
			authorizedUsers,
		},
	})

	return {
		workflow,
	}
}
