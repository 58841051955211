import type { Updater } from '@tanstack/vue-table'
import { cva } from 'class-variance-authority'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import type { Ref } from 'vue'

import type { ErrorMessage } from './components/form'

export const IS_ENV = {
	DEVELOPMENT: import.meta.env.VITE_APP_STAGE === 'development',
	TEST: import.meta.env.VITE_APP_STAGE === 'test',
	PRODUCTION: import.meta.env.VITE_APP_STAGE === 'production',
	TEST_OR_PROD: import.meta.env.VITE_APP_STAGE === 'test' || import.meta.env.VITE_APP_STAGE === 'production',
}

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export function valueUpdater<T extends Updater<unknown>>(updaterOrValue: T, ref: Ref) {
	ref.value = typeof updaterOrValue === 'function' ? updaterOrValue(ref.value) : updaterOrValue
}

export const groupStyleClasses = (isInputGroup: boolean, error?: ErrorMessage) => {
	return isInputGroup
		? cn(
				'rounded-lg first:rounded-r-none middle:rounded-l-none   middle:rounded-r-none last:rounded-l-none',
				'middle:border-l-0',
				error ? 'first:border-error-650 middle:border-error-650 last:border-error-650' : ''
			)
		: ''
}

export const groupWrapperClasses = (isInputGroup: boolean) => {
	return cn('flex items-center', isInputGroup ? 'rounded-lg [&>*]:!rounded-l-lg' : '')
}

export const groupVariants = cva('hover:bg-gray-200 disabled:text-gray-550', {
	variants: {
		color: {
			beige: 'bg-gray-100',
			white: 'bg-white',
		},
	},
	defaultVariants: {
		color: 'beige',
	},
})

export function addExtensionIfMissing(file: File): string {
	const fileName = file.name
	const fileType = file.type

	if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
		return fileName.toLowerCase().endsWith('.docx') ? fileName : `${fileName}.docx`
	}

	const extension = fileType.split('/').pop()

	if (!extension) {
		return fileName
	}

	const regex = new RegExp(`\\.${extension}$`, 'i')
	if (regex.test(fileName)) {
		return fileName
	}

	return `${fileName}.${extension}`
}

export async function fileToString(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()

		reader.addEventListener('load', () => {
			if (typeof reader.result === 'string') {
				const base64String = reader.result
				resolve(base64String)
			} else {
				reject(new Error('Failed to read file as a string.'))
			}
		})

		reader.addEventListener('error', error => {
			reject(error instanceof Error ? error : new Error('File read error.'))
		})
		reader.readAsText(file, 'utf8')
	})
}

export function fileToImageUrl(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()

		reader.addEventListener('load', () => {
			if (typeof reader.result === 'string') {
				const imageUrl = reader.result
				const blob = dataURItoBlob(imageUrl)
				resolve(blob)
			} else {
				reject(new Error('Failed to read file as an image.'))
			}
		})

		reader.addEventListener('error', error => {
			reject(error instanceof Error ? error : new Error('File read error.'))
		})
		reader.readAsDataURL(file)
	})
}

export function dataURItoBlob(dataURI: string) {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	const byteString = atob(dataURI.split(',')[1])

	// separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

	// write the bytes of the string to an ArrayBuffer
	const ab = new ArrayBuffer(byteString.length)

	// create a view into the buffer
	const ia = new Uint8Array(ab)

	// set the bytes of the buffer to the correct values
	for (let i = 0; i < byteString.length; i++) {
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		ia[i] = byteString.codePointAt(i)!
	}

	// write the ArrayBuffer to a blob, and you're done
	const blob = new Blob([ab], { type: mimeString })
	return URL.createObjectURL(blob)
}

export function truncateText(text: string, maxLength = 50): string {
	return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text
}

export interface BaseItem {
	label: string
}
export interface BaseGroup {
	title: string
}
export function isListItems<Item extends BaseItem, Group extends BaseGroup>(items: Item[] | Group[]): items is Item[] {
	if (!items || !items[0]) {
		return false
	}
	const [item] = items
	return !('title' in item)
}
export function isListGroups<Item extends BaseItem, Group extends BaseGroup>(
	items: Item[] | Group[]
): items is Group[] {
	if (!items || !items[0]) {
		return false
	}
	const [item] = items
	return 'title' in item
}
