<script setup lang="ts">
import { cn } from '../../utils'

import type { CustomSelectTriggerProps } from '.'

defineProps<Pick<CustomSelectTriggerProps, 'error' | 'hideErrorIcon' | 'disabled'>>()
</script>

<template>
  <INolasAlertCircle v-if="error && !hideErrorIcon" class="h-6 w-6 min-w-6 text-error-650" />
  <INolasSlashCircle v-if="disabled" :class="cn('h-6 w-6 min-w-6 text-gray-400')" />
  <INolasChevronDown
    v-if="!disabled"
    class="h-5 w-5 shrink-0 transform text-gray-900 transition-all duration-200 group-data-[state='open']:rotate-180"
  />
</template>
