<script setup lang="ts">
import { SelectContent, type SelectContentEmits, SelectPortal, SelectViewport, useForwardPropsEmits } from 'reka-ui'

import SelectGroup from './_select-group.vue'
import SelectItem from './_select-item.vue'
import Group from './select-group.vue'
import { bindCommonProps } from './utils'

import { type CustomSelectContentProps, selectContentVariants, selectViewportVariants } from '.'

const props = withDefaults(defineProps<CustomSelectContentProps>(), {
	position: 'popper',
	sideOffset: 4,
	class: undefined,
})
const emits = defineEmits<SelectContentEmits>()
const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <SelectPortal>
    <SelectContent
      v-bind="{
        ...forwarded,
        ...$attrs,
        items: undefined,
        class: selectContentVariants({ class: props.class, position: props.position }),
      }"
    >
      <SelectViewport :class="selectViewportVariants({ position: props.position })">
        <Group>
          <template v-for="item in items" :key="item">
            <SelectGroup v-if="'title' in item" v-bind="{ group: item, ...bindCommonProps(props) }" />
            <SelectItem v-else v-bind="{ item, ...bindCommonProps(props) }" />
          </template>
        </Group>
      </SelectViewport>
    </SelectContent>
  </SelectPortal>
</template>
