<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

import { cn } from '@nolas/design-system/utils'

import { sidebarHeaderVariants, useSidebar } from '.'

const { type } = useSidebar()

const props = defineProps<{
	class?: HTMLAttributes['class']
}>()
</script>

<template>
  <div data-sidebar="header" :class="cn(sidebarHeaderVariants({ type }), props.class)">
    <slot />
  </div>
</template>
