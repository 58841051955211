import type { SearchResponseFacetCountSchema } from 'typesense/lib/Typesense/Documents'

import type { TypeSenseDocument } from '@services/nolas-api'

export const facetsQuery = [
	'subclassification.secondaryLabel',
	'workflow.name',
	'channel',
	'classification',
	'mimeType',
]

type FacetKey = 'subclassification.secondaryLabel' | 'workflow.name' | 'channel' | 'classification' | 'mimeType'

export type Facets = {
	[key in FacetKey]: string[]
}

export function mapFacetsToObject(facets: SearchResponseFacetCountSchema<TypeSenseDocument>[]): Facets {
	const _facets = facets || []

	return _facets
		.map(entry => {
			const key = entry.field_name as FacetKey
			return {
				[key]: entry.counts.map(count => count.value),
			}
		})
		.reduce(
			(final, current) => ({
				// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
				...final,
				...current,
			}),
			{}
		) as Facets
}
