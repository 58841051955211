<script setup lang="ts">
import { onMounted, provide, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { useDocumentsSelection } from '@workbench/composables/use-documents-selection'

import { type Tab, type View, contextKey, views } from '@workbench/docview/side-drawer/context'
import type { Tables } from '@workbench/docview/utils/util'
import type { TabsPreference } from './context'

const tab = ref<TabsPreference>({
	drafts: 'sending_settings',
	inbox: 'sending_settings',
	sent: 'sending_settings',
	trash: 'preview',
})

function setTab(table: Tables, tabValue: Tab) {
	tab.value[table] = tabValue
}

const view = ref<View>('default')

function setView(input: View) {
	if (!views.includes(input)) {
		return
	}
	view.value = input
}

const open = ref<boolean>(true)
const route = useRoute()
const transitionAppear = ref<boolean>(false)
const transitionType = ref<'open' | 'close'>('open')

function setOpen(value: boolean) {
	open.value = value
}

const { selectedDocuments } = useDocumentsSelection()

watch(selectedDocuments, newVal => {
	setOpen(newVal.length > 0)

	if (newVal?.length > 1) {
		setView('default')
		setTab(route.meta?.type as Tables, 'sending_settings')
	}
})

onMounted(() => {
	// for docview page
	if (route.params.document) {
		setOpen(true)
	} else {
		setOpen(selectedDocuments.value.length > 0)
	}
})

provide(contextKey, {
	view,
	setView,
	tab,
	setTab,
	open,
	setOpen,
	transitionAppear,
	transitionType,
})
</script>

<template>
    <slot />
</template>