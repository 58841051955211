import { type ComputedRef, type InjectionKey, type Reactive, type Ref, inject } from 'vue'

import type { TypeSenseDocument } from '@services/nolas-api'
import type { SearchResponseFacetCountSchema } from 'typesense/lib/Typesense/Documents'
import type { z } from 'zod'

import type { SelectableDocument } from '../composables/use-documents-selection'

import { type BaseTableParams, type BaseTableState, contextKey } from './use-table-state'

export interface SentTableState extends BaseTableState {
	table: Reactive<z.infer<typeof BaseTableParams>>
	documents: ComputedRef<SelectableDocument[]>
	total: ComputedRef<number | undefined>
	isLoading: Ref<boolean>
	selectedDocumentsSize: ComputedRef<number>
	facets: ComputedRef<SearchResponseFacetCountSchema<TypeSenseDocument>[]>
}

export const sentContextKey = contextKey as InjectionKey<SentTableState>

export function useSent() {
	const context = inject(sentContextKey)
	if (!context) {
		throw new Error('Please use `provideSentState` before calling this function.')
	}

	return { ...context, selectedDocumentsSize: context.selectedDocumentsSize }
}
