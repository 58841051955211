import { createQueryKeys } from '@lukemorales/query-key-factory'
import { GetAvailableChannels, type GetAvailableChannelsRequest } from '@services/nolas-api'
import { useMutation } from '@tanstack/vue-query'
import { uniqBy } from 'lodash'
import type { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServiceError } from '@nolas/lib/errors'
import { toast } from '@nolas/design-system/sonner'

import { useUser } from '@auth/composables/use-user'
import { useSendingSettingsRecipients, useSendingSettings } from '@sending-settings'

import type { RecipientAddedType, Recipient, Channels } from '@sending-settings'

export const AvailableChannels = createQueryKeys('available-channels')

type Payload = {
	ids?: string[]
	userIds?: string[]
	emails?: string[]
	contacts?: GetAvailableChannelsRequest['contacts']
	isExtracted?: boolean
	folderIds?: string[]
	blockAddToRecipient?: boolean
}

export const fetchAvailableChannels = async (workspaceId: string, payload?: Payload) => {
	const { response, data, error } = await GetAvailableChannels({
		params: {
			path: { workspaceId },
		},
		body: {
			...(payload?.ids && { assignmentIds: payload?.ids }),
			...(payload?.userIds && { userIds: payload?.userIds }),
			...(payload?.emails && { emails: payload?.emails }),
			...(payload?.contacts && {
				contacts: payload?.contacts.map(contact => ({
					name: contact.name,
					type: contact.type,
					properties: contact.properties,
				})),
			}),
			...(payload?.folderIds && { folderIds: payload?.folderIds }),
		},
	})

	if (!response.ok || !data) {
		throw ServiceError.fromResponse(error)
	}
	return data
}

const getPriority = (channel: Channels) => {
	switch (channel) {
		case 'nolas': {
			return 1
		}
		case 'email': {
			return 2
		}
		default: {
			return 3
		}
	}
}

export function useGetAvailableChannels() {
	const { t } = useI18n()
	const { workspaceId } = useUser()
	const { recipientsIds, selectedRecipient, removeMultipleRecipients } = useSendingSettingsRecipients()
	const { onAddRecipients, isPending, selectedType, allParsedRecipientsIds } = useSendingSettings()
	const {
		mutateAsync: getAvailableChannels,
		error,
		...mutation
	} = useMutation({
		mutationFn: async (payload: Payload) => {
			isPending.value = true
			return await fetchAvailableChannels(workspaceId.value, payload)
		},
		onError: () => {
			isPending.value = false
			toast.error(t('common.error.get_available_channels'))
		},
		onSuccess: (data, variables) => {
			if (variables.blockAddToRecipient) return
			const ids = new Set(recipientsIds[selectedType.value].map(item => item.id))
			const emails = new Set(recipientsIds[selectedType.value].map(item => item.value))
			if ((data.folders?.length ?? 0) > 0) {
				const foldersToBeAdded = data.folders?.map(folder => {
					if (Array.isArray(folder?.folderParentIds) && folder.folderParentIds.some(parentId => ids.has(parentId))) {
						toast.error(t('common.error.group_already_exists in_another_group'))
						return
					}

					if (ids.has(folder?.folderId)) {
						toast.error(t('common.error.group_already_added'))
						return
					}

					const availableChannels = [
						...new Set(
							folder.entries
								.flatMap(item => item.availableChannels)
								.filter(
									channel => !(folder.folderType === 'workspace_folder' && channel.toLocaleLowerCase() === 'post')
								)
						),
					]
					const availableChannelsWithPriority = availableChannels.map(channel => {
						return {
							channel: channel.toLocaleLowerCase() as Channels,
							priority: getPriority(channel.toLocaleLowerCase() as Channels),
						}
					})

					const entriesToBeRemoved: string[] = []

					const orderedChannels = availableChannelsWithPriority
						.sort((a, b) => a.priority - b.priority)
						.map(o => o.channel)

					const formattedFolder = {
						id: folder.folderId,
						name: folder.folderName || t(`app.keyflowz.address_book.folders.name.${folder?.folderType}`) || '',
						preferedChannel: orderedChannels?.[0] as Channels,
						type: 'normal' as RecipientAddedType,
						isSelected: false,
						meta: {
							folder: true,
							...(variables.isExtracted ? { extracted: true } : {}),
						},
						isFolder: true,
						channel: orderedChannels?.[0] as Channels,
						availableChannels: orderedChannels as Channels[],
						key: JSON.stringify([folder.folderName || '', folder.folderId].join('-')),
						value: folder.entries
							.map(item => {
								if (item.assignmentId && allParsedRecipientsIds.value.includes(item.assignmentId)) {
									entriesToBeRemoved.push(item.assignmentId)
									toast.info(
										t('common.error.contact_removed_from_recipients_group', {
											name: item.name || item.properties?.name || '',
										})
									)
								}

								return {
									id: item.assignmentId || window.crypto.randomUUID(),
									isSelected: false,
									assignmentId: item.assignmentId,
									value: item.name || item.email || item.properties?.name || '',
									name: item.name || item.properties?.name || '',
									email: item.email,
									userId: item.userId,
									meta: {
										folder: true,
									},
									folderId: folder.folderId,
									address:
										item.address || item.properties?.properties?.find(property => 'address' in property)?.address,
									preferedChannel: item.preferedChannel?.toLocaleLowerCase() as Channels,
									availableChannels: (item.availableChannels?.map(o => o.toLocaleLowerCase()) as Channels[]) || [],
									key: JSON.stringify(
										[
											item.name || item.email || item.properties?.name || '',
											item.email || '',
											item.address ||
												item.properties?.properties?.find(property => 'address' in property)?.address ||
												'',
										]
											.map(value => (typeof value === 'object' ? JSON.stringify(value) : value))
											.join('-')
									),
									channel:
										(item.preferedChannel?.toLocaleLowerCase() as Channels) ||
										(item.availableChannels?.[0]?.toLocaleLowerCase() as Channels),
								}
							})
							.filter(item => item !== undefined) as unknown as string[],
					}
					removeMultipleRecipients(entriesToBeRemoved, selectedType.value)
					return formattedFolder
				})

				if (!foldersToBeAdded?.length) return

				for (const folder of foldersToBeAdded) {
					if (!folder) return
					if (folder?.value.length === 0) return
					onAddRecipients([folder], selectedType.value)
					selectedRecipient.value = foldersToBeAdded.at(-1)
				}
			}
			if ((data.entries?.length ?? 0) > 0) {
				const recipientsToBeAdded = data.entries.map(item => ({
					id: item.assignmentId || window.crypto.randomUUID(),
					isSelected: false,
					assignmentId: item.assignmentId,
					value: item.name || item.email || item.properties?.name || '',
					name: item.name || item.properties?.name || '',
					email: item.email,
					userId: item.userId,
					meta: {
						extracted: Boolean(variables.isExtracted),
					},
					address: item.address || item.properties?.properties?.find(property => 'address' in property)?.address,
					preferedChannel: item.preferedChannel?.toLocaleLowerCase() as Channels,
					availableChannels: item.availableChannels?.map(o => o.toLocaleLowerCase()) as Channels[],
					channel:
						(item.preferedChannel?.toLocaleLowerCase() as Channels) ||
						(item.availableChannels?.[0]?.toLocaleLowerCase() as Channels),
					key: JSON.stringify(
						[
							item.name || item.email || item.properties?.name || '',
							item.email || '',
							item.address || item.properties?.properties?.find(property => 'address' in property)?.address || '',
						]
							.map(value => (typeof value === 'object' ? JSON.stringify(value) : value))
							.join('-')
					),
				}))
				const uniQueRecipients = uniqBy(recipientsToBeAdded, 'key')

				if (variables.isExtracted) {
					const emailRecipientsToBeAdded = {
						recipients: uniQueRecipients.filter(contact => contact.preferedChannel === 'email'),
						channel: 'email',
					}
					const postRecipientsToBeAdded = {
						recipients: uniQueRecipients.filter(contact => contact.preferedChannel === 'post'),
						channel: 'post',
					}
					const nolasRecipientsToBeAdded = {
						recipients: uniQueRecipients.filter(contact => contact.preferedChannel === 'nolas'),
						channel: 'nolas',
					}
					const allExtractedRecipients = [
						emailRecipientsToBeAdded,
						postRecipientsToBeAdded,
						nolasRecipientsToBeAdded,
					].filter(({ recipients }) => recipients.length > 0)

					for (const extractedRecipients of allExtractedRecipients) {
						let recipients: string[] | Recipient[] = []

						recipients = extractedRecipients.recipients.map(contact => {
							return {
								...contact,
								id: contact.assignmentId || window.crypto.randomUUID(),
								type: 'normal' as RecipientAddedType,
								name: contact?.name || '',
								email: contact?.email || '',
								value: contact?.value || '',
								isSelected: false,
								meta: {
									...(variables.isExtracted ? { extracted: true } : {}),
								},
							}
						})

						const formattedRecipients = [
							{
								id: window.crypto.randomUUID(),
								type: 'normal' as RecipientAddedType,
								name:
									extractedRecipients.recipients.length > 1
										? t('app.keyflowz.documents.sending_settings.pill.recipients_post', {
												count: extractedRecipients.recipients.length,
											})
										: extractedRecipients.recipients[0]?.name || '',
								value: recipients,
								meta: {
									...(variables.isExtracted ? { extracted: true } : {}),
								},
								channel: extractedRecipients.channel as Channels,
								preferedChannel: extractedRecipients.channel as Channels,
								availableChannels: extractedRecipients.recipients[0]?.availableChannels,
								isSelected: false,
							},
						]
						onAddRecipients(formattedRecipients, selectedType.value)
						selectedRecipient.value = formattedRecipients.at(-1)
					}
				} else if (recipientsToBeAdded?.[0]?.assignmentId) {
					const formattedRecipients = recipientsToBeAdded
						.map(contact => {
							if (
								allParsedRecipientsIds.value.includes(contact.id) ||
								ids.has(contact?.id) ||
								(contact?.email && emails.has(contact?.email))
							) {
								toast.error(t('common.error.email_already_added'))
								return
							}

							const contactToBeAdded = [
								{
									...contact,
									type: 'normal' as RecipientAddedType,
									name: contact?.name || '',
									meta: {
										...(variables.isExtracted ? { extracted: true } : {}),
									},
								},
							]
							onAddRecipients(contactToBeAdded, selectedType.value)
							return contactToBeAdded[0]
						})
						.filter(contact => contact !== null) as unknown as RecipientAddedType[]
					selectedRecipient.value = formattedRecipients.at(-1) as unknown as Recipient
				} else {
					const contact = recipientsToBeAdded[0]
					const id = window.crypto.randomUUID()
					const values = recipientsIds[selectedType.value].map(item => item?.value)
					if (!values?.includes(contact?.value)) {
						const formattedRecipients = [
							{
								...contact,
								id,
								type: 'normal' as RecipientAddedType,
								name: contact?.name || '',
								email: contact?.email || '',
								isSelected: false,
								meta: {
									...(variables.isExtracted ? { extracted: true } : {}),
								},
							},
						]
						onAddRecipients(formattedRecipients, selectedType.value)
						selectedRecipient.value = formattedRecipients.at(-1)
					}
				}
			}
			isPending.value = false
		},
	})

	return { getAvailableChannels, error: error as Ref<ServiceError | null>, ...mutation }
}
