import { GetEmailAccounts } from '@services/nolas-api'
import { useQuery } from '@tanstack/vue-query'

import { useUser } from '@auth/composables/use-user'
import { ServiceError } from '@nolas/lib/errors'
import { QK } from '@queries'

export const emailAccountsQuery = QK.emailIntegration.accounts

type EmailAccount = {
	id: string
	email: string
	protocol: string
	status: number
	displayName?: string
}

export function useEmailAccounts() {
	const { workspaceId } = useUser()

	const { data: emailAccounts, error } = useQuery({
		queryKey: emailAccountsQuery._def,
		queryFn: async () => {
			const { response, data, error } = await GetEmailAccounts({
				params: {
					path: {
						workspaceId: workspaceId.value,
					},
				},
			})

			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}

			return data as EmailAccount[]
		},
	})

	if (!emailAccounts) {
		throw ServiceError.fromResponse(error)
	}
	return { emailAccounts }
}
