<script setup lang="ts">
import type { Component } from 'vue'

import { cn } from '../../utils'

import DialogDescription from './dialog-description.vue'
import DialogTitle from './dialog-title.vue'

interface DialogHeaderProps {
  class?: string
  icon?: Component
  title?: string
  description?: string
}

const props = defineProps<DialogHeaderProps>()
</script>

<template>
  <div :class="cn('flex flex-col gap-5', props.class)">
    <DialogTitle
      v-if="title || icon"
      class="flex items-center gap-3 text-xl font-medium"
    >
      <component :is="icon" v-if="icon" class="h-10 w-10 min-w-10" />
      <span class="truncate">{{ title }}</span>
    </DialogTitle>
    <DialogDescription v-if="description">
      {{ description }}
    </DialogDescription>
    <slot />
  </div>
</template>
