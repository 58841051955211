import { createQueryKeys } from '@lukemorales/query-key-factory'

type BasicQuery = {
	search: string
	filterDeleted?: 'true' | 'false'
}

export const administration = createQueryKeys('administration', {
	signatures: (query?: BasicQuery) => ['signatures', query] as const,
	letterheads: (query?: BasicQuery) => ['letterheads', query] as const,
	attachments: (query?: BasicQuery) => ['attachments', query] as const,
	textTemplates: (query?: BasicQuery) => ['textTemplates', query] as const,
	interfaces: (query?: BasicQuery) => ['interfaces', query] as const,
	costCenters: (query?: BasicQuery) => ['cost-centers', query] as const,
	saml: (userId: string) => ['saml-config', userId] as const,
	scim: user => ['scim-config', user] as const,
	glAccounts: () => ['gl-accounts'],
	identifiers: () => ['identifiers'],
	references: () => ['references'],
	creditors: () => ['creditors'],
})
