<script setup lang="ts">
import { cn } from '../../utils'

import { AlertTitle, AlertDescription } from '.'
import { alertVariants, icon } from '.'

interface Props {
	variant?: NonNullable<Parameters<typeof alertVariants>[0]>['variant']
	class?: string
	title?: string
	description?: unknown
}

const props = defineProps<Props>()

const isErrorVariant = props.variant === 'error'
</script>

<template>
  <div
    :class="cn(alertVariants({ variant }), props.class ?? '', { '!items-center': !props.description })"
    data-e2e="alert-component"
  >
    <component :is="icon[variant!]" v-if="icon[variant!]" :class="!isErrorVariant && 'h-8 w-8 min-w-8 p-1.5'" />
    <div class="flex w-full items-center justify-between gap-x-4">
      <div>
        <AlertTitle v-if="title" :class="cn('text-sm', isErrorVariant ? 'text-white' : 'font-semibold text-gray-900')">
          {{ title }}
        </AlertTitle>
        <AlertDescription
          v-if="description"
          :class="cn('font-normal', isErrorVariant ? 'text-white' : 'text-gray-600')"
        >
          {{ description }}
        </AlertDescription>
      </div>
      <slot name="actions" />
    </div>
  </div>
</template>
