import type { RouteRecordRaw } from 'vue-router'

import WorkflowsProvider from '@workflows/states/workflows-wrapper.vue'
import WorkflowProvider from '@workflows/states/workflow-wrapper.vue'
import DocumentsProvider from '@workbench/components/documents.vue'
import AddressBookProvider from '@address-book/states/address-book-provider.vue'
import UrlStateProvider from '@url-states/states/url-state-provider.vue'

import AdministrationIndex from '@views/administration/index.vue'
import WorkbenchIndex from '@views/workbench/index.vue'
import EmailVerified from '@views/auth/email-verified.vue'
import ForgotPassword from '@views/auth/forgot-password.vue'
import Register from '@views/auth/register.vue'
import ResetPassword from '@views/auth/reset-password.vue'
import SetupPassword from '@views/auth/setup-password.vue'
import Sso from '@views/auth/sso.vue'
import Terms from '@views/auth/terms.vue'
import AuthIndex from '@views/auth/index.vue'
import Auth from '@layouts/auth.vue'

const workbench: RouteRecordRaw = {
	path: '/workbench',
	component: WorkbenchIndex,
	name: 'workbench',
	meta: {
		title: 'workbench',
		module: 'workbench',
	},
	children: [
		{
			path: '',
			component: () => import('@views/workbench/inbox.vue'),
			name: 'workbench-home',
			meta: {
				title: 'workbench',
				module: 'workbench',
			},
			beforeEnter() {
				return { path: '/workbench/inbox' }
			},
			children: [],
		},
		{
			path: 'document-editor',
			component: () => import('@views/workbench/editor.vue'),
			name: 'workbench-document-editor',
			meta: {
				title: 'workbench-document-editor',
				module: 'workbench',
				fullpage: true,
			},
		},
		{
			path: 'inbox',
			name: 'workbench-inbox-url-state-provider',
			meta: {
				title: 'workbench-inbox',
				module: 'workbench',
				type: 'inbox',
				urlState: UrlStateProvider,
			},
			children: [
				{
					path: '',
					component: () => import('@views/workbench/inbox.vue'),
					name: 'workbench-inbox',
					meta: {
						title: 'workbench-inbox',
						module: 'workbench',
						type: 'inbox',
						provider: DocumentsProvider,
					},
				},
				{
					path: 'd/:document',
					component: () => import('@views/workbench/document.vue'),
					name: 'workbench-inbox-document',
					meta: {
						title: 'workbench-inbox-document',
						module: 'workbench',
						type: 'inbox',
						provider: DocumentsProvider,
					},
				},
			],
		},
		{
			path: 'drafts',
			name: 'workbench-drafts-url-state-provider',
			meta: {
				title: 'workbench-drafts',
				module: 'workbench',
				urlState: UrlStateProvider,
				type: 'drafts',
			},
			props: {
				options: { key: 'drafts' },
			},
			children: [
				{
					path: '',
					component: () => import('@views/workbench/drafts.vue'),
					name: 'workbench-drafts',
					meta: {
						title: 'workbench-drafts',
						module: 'workbench',
						type: 'drafts',
						provider: DocumentsProvider,
					},
				},
				{
					path: 'd/:document',
					component: () => import('@views/workbench/document.vue'),
					name: 'workbench-drafts-document',
					meta: {
						title: 'workbench-drafts-document',
						module: 'workbench',
						type: 'drafts',
						provider: DocumentsProvider,
					},
				},
			],
		},
		{
			path: 'sent',
			name: 'workbench-sent-url-state-provider',
			meta: {
				title: 'workbench-sent',
				module: 'workbench',
				urlState: UrlStateProvider,
				type: 'sent',
			},
			children: [
				{
					path: '',
					component: () => import('@views/workbench/sent.vue'),
					name: 'workbench-sent',
					meta: {
						title: 'workbench-sent',
						module: 'workbench',
						type: 'sent',
						provider: DocumentsProvider,
					},
				},
				{
					path: 'd/:document',
					component: () => import('@views/workbench/document.vue'),
					name: 'workbench-sent-document',
					meta: {
						title: 'workbench-sent-document',
						module: 'workbench',
						type: 'sent',
						provider: DocumentsProvider,
					},
				},
			],
		},
		{
			path: 'trash',
			name: 'workbench-trash-url-state-provider',
			meta: {
				title: 'workbench-trash',
				module: 'workbench',
				type: 'trash',
				urlState: UrlStateProvider,
			},
			children: [
				{
					path: '',
					component: () => import('@views/workbench/trash.vue'),
					name: 'workbench-trash',
					meta: {
						title: 'workbench-trash',
						module: 'workbench',
						type: 'trash',
						provider: DocumentsProvider,
					},
				},
				{
					path: 'd/:document',
					component: () => import('@views/workbench/document.vue'),
					name: 'workbench-trash-document',
					meta: {
						title: 'workbench-trash-document',
						module: 'workbench',
						type: 'trash',
						provider: DocumentsProvider,
					},
				},
			],
		},
		{
			path: 'd/:document',
			component: () => import('@views/workbench/document.vue'),
			name: 'workbench-document',
			meta: {
				title: 'workbench-document',
				module: 'workbench',
				provider: DocumentsProvider,
			},
		},
		{
			path: 'address-book',
			name: 'workbench-address-book-url-state-provider',
			meta: {
				title: 'workbench-address-book',
				module: 'workbench',
				type: 'addressBook',
				urlState: UrlStateProvider,
			},
			children: [
				{
					path: '',
					component: () => import('@views/address-book/address-book.vue'),
					name: 'workbench-address-book',
					meta: {
						title: 'workbench-address-book',
						module: 'workbench',
						type: 'addressBook',
						provider: AddressBookProvider,
					},
				},
			],
		},
		{
			path: 'calendar',
			component: () => import('@views/404.vue'),
			name: 'workbench-calendar',
			meta: {
				title: 'workbench-calendar',
				module: 'workbench',
			},
		},
		{
			path: 'my-office',
			component: () => import('@views/404.vue'),
			name: 'workbench-office',
			meta: {
				title: 'workbench-office',
				module: 'workbench',
			},
		},
		// {
		//   path: 'split',
		//   name: 'workbench-split',
		//   component: () => import('./views/documents-split.vue'),
		//   meta: {
		//     title: 'keyflowz-split',
		//     module: 'workbench',
		//   },
		// },
	],
}

const administration: RouteRecordRaw = {
	name: 'administration',
	path: '/administration',
	component: AdministrationIndex,
	meta: {
		title: 'administration',
		module: 'administration',
		type: 'addressBook',
		urlState: UrlStateProvider,
	},
	children: [
		{
			name: 'administration-index',
			path: '',
			redirect() {
				return 'administration/general'
			},
			children: [],
		},
		{
			path: 'general',
			name: 'administration-general',
			component: () => import('@views/404.vue'),
			meta: {
				title: 'administration-general',
				module: 'administration',
			},
		},
		{
			path: 'interfaces',
			name: 'administration-interfaces',
			component: () => import('@views/administration/interfaces.vue'),
			meta: {
				title: 'administration-interfaces',
				module: 'administration',
			},
		},
		{
			path: 'workflows',
			name: 'administration-workflows',
			component: () => import('@views/workflows/workflows.vue'),
			meta: {
				title: 'administration-workflows',
				module: 'administration',
				provider: WorkflowsProvider,
				omitBuilder: true,
			},
		},
		{
			path: 'workflow/:id',
			name: 'administration-workflow',
			component: () => import('@views/workflows/workflow.vue'),
			meta: {
				title: 'administration-workflow',
				module: 'administration',
				moduleId: 'workspace-administration',
				parent: 'administration-workflows',
				provider: WorkflowProvider,
			},
		},
		{
			path: 'data-exchange',
			name: 'administration-data-exchange',
			component: () => import('@views/administration/data-exchange.vue'),
			meta: {
				title: 'administration-data-exchange',
				module: 'administration',
			},
		},
		{
			path: 'cost-centers',
			name: 'administration-cost-centers',
			component: () => import('@views/administration/cost-centers.vue'),
			meta: {
				title: 'administration-cost-centers',
				module: 'administration',
				provider: WorkflowsProvider,
			},
		},
		{
			path: 'sso-settings',
			name: 'administration-sso',
			component: () => import('@views/administration/sso.vue'),
			meta: {
				title: 'administration-sso',
				module: 'administration',
			},
		},
		{
			path: 'signatures',
			name: 'administration-signatures',
			component: () => import('@views/administration/signatures.vue'),
			meta: {
				title: 'administration-signatures',
				module: 'administration',
			},
		},
		{
			path: 'letterheads',
			name: 'administration-letterheads',
			component: () => import('@views/administration/letterheads.vue'),
			meta: {
				title: 'administration-letterheads',
				module: 'administration',
			},
		},
		{
			path: 'attachments',
			name: 'administration-attachments',
			component: () => import('@views/administration/attachments.vue'),
			meta: {
				title: 'administration-attachments',
				module: 'administration',
			},
		},
		{
			path: 'text-templates',
			name: 'administration-text-templates',
			component: () => import('@views/administration/text-templates.vue'),
			meta: {
				title: 'administration-text-templates',
				module: 'administration',
			},
		},
	],
}

const workspace: RouteRecordRaw[] = [
	{
		name: 'virtual-office',
		path: '/virtual-office',
		component: () => import('@views/404.vue'),
		meta: {
			title: 'virtual-office',
			module: 'virtual-office',
		},
	},
	{
		name: 'settings',
		path: '/settings',
		component: () => import('@views/404.vue'),
		meta: {
			title: 'workspace-settings',
			module: 'workspace',
		},
	},
	{
		name: 'download',
		path: '/download',
		component: () => import('@views/404.vue'),
		meta: {
			title: 'workspace-download',
			module: 'workspace',
		},
	},
	{
		name: 'tutorials',
		path: '/tutorials',
		component: () => import('@views/404.vue'),
		meta: {
			title: 'workspace-tutorials',
			module: 'workspace',
		},
	},
	{
		name: 'support',
		path: '/support',
		component: () => import('@views/404.vue'),
		meta: {
			title: 'workspace-support',
			module: 'workspace',
		},
	},
]

const auth: RouteRecordRaw = {
	name: 'auth-portal',
	path: '/auth',
	component: Auth,
	children: [
		{
			path: '',
			name: 'auth_index',
			component: AuthIndex,
			children: [],
			meta: {
				title: 'auth_index',
			},
		},
		{
			path: 'sso',
			name: 'auth_sso',
			component: Sso,
			meta: {
				title: 'auth_sso',
			},
		},
		{
			path: 'register',
			name: 'auth_register',
			component: Register,
			meta: {
				title: 'auth_register',
			},
		},
		{
			path: 'email-verified',
			name: 'auth_email_verified',
			component: EmailVerified,
			meta: {
				title: 'auth_email_verified',
			},
		},
		{
			path: 'forgot-password',
			name: 'auth_forgot_password',
			component: ForgotPassword,
			meta: {
				title: 'auth_forgot_password',
			},
		},
		{
			path: 'reset-password',
			name: 'auth_reset_password',
			component: ResetPassword,
			meta: {
				title: 'auth_reset_password',
			},
		},
		{
			path: 'setup-password',
			name: 'auth_setup_password',
			component: SetupPassword,
			meta: {
				title: 'auth_setup_password',
			},
		},
		{
			path: 'terms',
			name: 'auth_terms',
			component: Terms,
			meta: {
				title: 'auth_terms',
			},
		},
	],
}

export const routes = [
	workbench,
	auth,
	{
		name: 'entrypoint',
		path: '/',
		component: Auth,
		beforeEnter() {
			return { path: '/workbench/inbox' }
		},
	},
	administration,
	...workspace,
	{
		name: '404',
		path: '/:pathMatch(.*)*',
		component: () => import('@views/404.vue'),
		meta: {
			title: '404',
		},
	},
]
