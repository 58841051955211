import { useVirtualList } from '@vueuse/core'
import { computed } from 'vue'

import { isListGroups, isListItems } from '../../utils'

import type { CustomSelectContentProps, CustomSelectGroupProps, CustomSelectItemProps, SelectProps } from '.'

export function bindCommonProps(
	props: SelectProps | CustomSelectContentProps | CustomSelectGroupProps
): Partial<CustomSelectContentProps | CustomSelectGroupProps | CustomSelectItemProps> {
	return {
		iconClasses: props.iconClasses,
		name: props.name,
		translatable: props.translatable,
	}
}

export function useVirtualItems(items: SelectProps['items']) {
	const virtualListConfig = { itemHeight: 48 } as const
	const virtualItems = useVirtualList(isListItems(items) ? items : [], virtualListConfig)
	const virtualGroups = useVirtualList(isListGroups(items) ? items : [], virtualListConfig)

	return computed(() => {
		if (isListGroups(items)) {
			return virtualGroups
		}
		return virtualItems
	})
}
