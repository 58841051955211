<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import AuthWrapper from '@auth/components/auth-wrapper.vue'
import ResetPasswordSuccess from '@auth/components/reset-password/reset-password-success.vue'
import ResetPassword from '@auth/components/reset-password/reset-password.vue'

const route = useRoute()
const router = useRouter()

onMounted(() => {
	if (!route.query.token) {
		router.push('/auth/login')
	}
})

const step = ref(1)
const onSuccess = () => {
	step.value = 2
}
</script>

<template>
  <AuthWrapper>
    <ResetPassword v-if="step == 1" @success="onSuccess" />
    <ResetPasswordSuccess v-else />
  </AuthWrapper>
</template>
