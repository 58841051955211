import { type Ref, ref } from 'vue'

import { ServiceError } from '@nolas/lib/errors'
import { UpdateFolder, type UpdateFolderRequest } from '@services/nolas-api'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { useI18n } from 'vue-i18n'

import { useUser } from '@auth/composables/use-user'
import { useAddressBookState } from '../../states/provider'

import { getFolderLabel } from '@address-book/utils'
import { toast } from '@nolas/design-system/sonner'
import { foldersQueryKey } from './use-folders'
import { foldersFlatQueryKey } from './use-folders-flat'

const selectedFolderIdToBeEdited = ref<string | undefined>()
const selectFolderIdToBeEdited = (folderId?: string) => {
	selectedFolderIdToBeEdited.value = folderId || undefined
}

async function update(payload: UpdateFolderRequest, workspaceId: string, folderId: string) {
	const { response, data, error } = await UpdateFolder({
		params: {
			path: {
				workspaceId,
				folderId,
			},
		},
		body: payload,
	})

	if (!response.ok || !data) {
		throw ServiceError.fromResponse(error)
	}
	return data
}

export function useUpdateFolder() {
	const { currentFolder, table } = useAddressBookState()
	const folderId = selectedFolderIdToBeEdited.value ?? currentFolder.value?.id ?? ''
	const { workspaceId } = useUser()
	const queryClient = useQueryClient()
	const { t } = useI18n()

	const {
		mutateAsync: updateFolder,
		error,
		isPending,
	} = useMutation({
		mutationFn: (payload: UpdateFolderRequest) => update(payload, workspaceId.value, folderId),
		onSuccess: response => {
			toast.success(t('app.keyflowz.address_book.dialog.folder.edit.notification.success', { name: response.name }))
			table.pageName = getFolderLabel(response)
			queryClient.invalidateQueries({ queryKey: foldersQueryKey._def })
			queryClient.invalidateQueries({ queryKey: foldersFlatQueryKey._def })
		},
		onError: () => {
			toast.error(t('app.keyflowz.address_book.dialog.folder.edit.notification.error'))
		},
	})

	return {
		updateFolder,
		isPending,
		error: error as Ref<ServiceError | null>,
		selectedFolderIdToBeEdited,
		selectFolderIdToBeEdited,
	}
}
