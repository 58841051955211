<script setup lang="ts">
import { Button } from '@nolas/design-system/button'
import { Paragraph } from '@nolas/design-system/paragraph'

defineProps<{ email?: string }>()
</script>

<template>
  <div data-e2e="check-email-div">
    <INolasLogoBig class="mx-auto h-32 w-48" />
    <Paragraph class="mb-8 flex flex-col" align="center">
      {{ $t('auth.flow.check_email.description') }}
      <span class="font-medium"> {{ email }} </span>
    </Paragraph>

    <Button as="router-link" to="/auth" variant="link" size="sm" class="mx-auto w-max" data-e2e="login">
      <INolasArrowLeft class="mr-1.5 h-5 w-5" />
      {{ $t('auth.button.back') }}
    </Button>
  </div>
</template>
