import {
	nolasAPIClient,
	type InferResponse,
	type InferBody,
	type InferRequest,
	type Components,
} from '@nolas/lib/api-client'

import { accessToken } from './access-token'

const nolasAPI = nolasAPIClient({
	baseUrl: location.origin,
	credentials: 'include',
})
nolasAPI.use({
	onRequest({ request }) {
		if (accessToken) {
			request.headers.set('authorization', `Bearer ${accessToken}`)
		}
		return request
	},
})

export const Register = nolasAPI.post('/api/auth/flow/register')
export type Register = InferResponse<typeof Register>
export type RegisterRequest = InferBody<typeof Register>

export const Login = nolasAPI.post('/api/auth/flow/login')
export type Login = InferResponse<typeof Login>
export type LoginRequest = InferBody<typeof Login>

export const LoginSAML = nolasAPI.post('/api/auth/flow/saml')
export type LoginSAML = InferResponse<typeof LoginSAML>
export type LoginSAMLRequest = InferBody<typeof LoginSAML>

export const ForgotPassword = nolasAPI.get('/api/auth/flow/password')
export type ForgotPassword = InferResponse<typeof ForgotPassword>
export type ForgotPasswordRequest = InferRequest<typeof ForgotPassword>['params']['query']

export const ResetPassword = nolasAPI.put('/api/auth/flow/password')
export type ResetPassword = InferResponse<typeof ResetPassword>
export type ResetPasswordRequest = InferBody<typeof ResetPassword>

export const AuthorizeURL = nolasAPI.getUrl('/api/authorize')
export const LogoutURL = nolasAPI.getUrl('/api/auth/flow/logout')

export const TokenURL = nolasAPI.getUrl('/api/token')
export const GetToken = nolasAPI.post('/api/token')
// @ts-expect-error weird
export type GetToken = InferResponse<typeof GetToken>
// @ts-expect-error weird
export type GetTokenRequest = InferBody<typeof GetToken>

export const GetCurrentUser = nolasAPI.get('/api/session/me')
export type CurrentUser = InferResponse<typeof GetCurrentUser>

export const AcceptInvitation = nolasAPI.put('/api/w/{workspaceId}/members/{userId}')
export type AcceptInvitation = InferResponse<typeof AcceptInvitation>
export type AcceptInvitationRequest = InferRequest<typeof AcceptInvitation>['params']['path']

export const SetupAccountEmail = nolasAPI.patch('/api/user/{workspaceId}/setup-account-email')
export type SetupAccountEmail = InferResponse<typeof SetupAccountEmail>
export type SetupAccountEmailRequest = Exclude<InferRequest<typeof SetupAccountEmail>['body'], undefined>
export type SetupAccountEmailRequestParam = InferRequest<typeof SetupAccountEmail>['params']['path']

export const UpdatePreferences = nolasAPI.put('/api/me/preferences')
export type UpdatePreferencesRequest = Exclude<InferRequest<typeof UpdatePreferences>['body'], undefined>

export const CreateDomain = nolasAPI.post('/api/w/{workspaceId}/domains')
export type CreateDomainRequest = Exclude<InferRequest<typeof CreateDomain>['body'], undefined>

export const ValidateDomain = nolasAPI.put('/api/w/{workspaceId}/domains')
export type ValidateDomainRequest = Exclude<InferRequest<typeof ValidateDomain>['body'], undefined>

export const DeleteDomain = nolasAPI.delete('/api/w/{workspaceId}/domains')
export type DeleteDomainRequest = InferRequest<typeof DeleteDomain>['params']['query']

export const GetSAMLConfig = nolasAPI.get('/api/sp/{workspaceId}')
export type GetSAMLConfig = InferResponse<typeof GetSAMLConfig>

export const SetSAMLConfig = nolasAPI.post('/api/sp/{workspaceId}')
export type SetSAMLConfig = InferResponse<typeof SetSAMLConfig>
export type SetSAMLConfigRequest = Exclude<InferRequest<typeof SetSAMLConfig>['body'], undefined>

export const ToggleSAML = nolasAPI.put('/api/sp/{workspaceId}/status')
export type ToggleSAML = InferResponse<typeof ToggleSAML>
export type ToggleSAMLRequest = Exclude<InferRequest<typeof ToggleSAML>['body'], undefined>

export const GetSCIMConfig = nolasAPI.get('/api/scim/{workspaceId}')
export type GetSCIMConfig = InferResponse<typeof GetSCIMConfig>

export const GenerateSCIMToken = nolasAPI.put('/api/scim/{workspaceId}')

export const GetWorkspace = nolasAPI.get('/api/w/{workspaceId}')
export type Workspace = InferResponse<typeof GetWorkspace>

export const UpdateWorkspaceSettings = nolasAPI.put('/api/w/{workspaceId}/settings')
export type UpdateWorkspaceSettingsRequest = InferBody<typeof UpdateWorkspaceSettings>
export type UpdateWorkspaceSettingsResponse = InferResponse<typeof UpdateWorkspaceSettings>

export const ChangePassword = nolasAPI.put('/api/auth/flow/change/password')
export type ChangePassword = InferResponse<typeof ChangePassword>
export type ChangePasswordRequest = Exclude<InferRequest<typeof ChangePassword>['body'], undefined>

export const GetEmailAccounts = nolasAPI.get('/api/w/{workspaceId}/email-accounts')
export type GetEmailAccounts = InferResponse<typeof GetEmailAccounts>['accounts']

export const GetImapHosts = nolasAPI.get('/api/w/{workspaceId}/email-accounts/imap-host')
// @ts-expect-error weird
export type GetImapHosts = InferResponse<typeof GetImapHosts>

export const AddImapAccount = nolasAPI.post('/api/w/{workspaceId}/email-accounts/imap')
export type AddImapAccount = InferResponse<typeof AddImapAccount>
export type AddImapAccountRequest = Exclude<InferRequest<typeof AddImapAccount>['body'], undefined>

export const ExchangeLogin = nolasAPI.post('/api/w/{workspaceId}/email-accounts/exchange/login')
export type ExchangeLogin = InferResponse<typeof ExchangeLogin>

export const UpdateEmailAccount = nolasAPI.patch('/api/w/{workspaceId}/email-accounts/{emailAccountId}')
export type UpdateEmailAccount = InferResponse<typeof ExchangeLogin>

export const FetchEmailInboxes = nolasAPI.get('/api/w/{workspaceId}/email-accounts/fetch-initiate')
export type FetchEmailInboxes = InferResponse<typeof FetchEmailInboxes>

export const DeleteEmailAccount = nolasAPI.delete('/api/w/{workspaceId}/email-accounts/{emailAccountId}')
export type DeleteEmailAccount = InferResponse<typeof DeleteEmailAccount>

export const ChangeUserRole = nolasAPI.put('/api/w/{workspaceId}/members/{userId}/role')
export type ChangeUserRole = InferResponse<typeof ChangeUserRole>

export type TypeSenseDocument = Components['schemas']['TypeSenseDocument']
export type TypeSenseContact = Components['schemas']['TypeSenseContact']
export type TypeSenseDocumentWorkflow = Components['schemas']['TypeSenseDocumentWorkflow']

export type DocumentRecord = Components['schemas']['DocumentRecord']
export type MailboxPreference = Components['schemas']['MailboxPreference']
export type ContactProperties = Components['schemas']['ContactProperties']
export type Workflow = Components['schemas']['Workflow']
export type WorkflowStepNode = Components['schemas']['WorkflowStepNode']
export type WorkflowBuilderConfig = Components['schemas']['WorkflowBuilderConfig']
export type WorkflowStateMachineStep = Components['schemas']['WorkflowStateMachineStep']
export type DocumentID = Components['schemas']['DocumentData']['doc_id']
export type DocumentAssignmentID = Components['schemas']['DocumentData']['id']
export type Preferences = Components['schemas']['Preferences']
export type HandshakeID = string
export type WorkspaceID = string
export type WorkflowID = string
export type UserID = string

export type Space = Components['schemas']['Space']

export const CreateDocument = nolasAPI.post('/api/w/{workspaceId}/documents')
export type CreateDocument = InferResponse<typeof CreateDocument>

export const GetDocument = nolasAPI.get('/api/w/{workspaceId}/documents/{documentAssignmentId}/v2')
export type Document = InferResponse<typeof GetDocument>

export const DownloadDocuments = nolasAPI.post('/api/w/{workspaceId}/documents/download')
export type DocumentDownloadResponse = InferResponse<typeof DownloadDocuments>
export type DocumentDownloadRequest = InferRequest<typeof DownloadDocuments>

export const DocumentsExtractData = nolasAPI.post('/api/w/{workspaceId}/accounting/export')
export type DocumentsExtractDataResponse = InferResponse<typeof DocumentsExtractData>
export type DocumentsExtractDataBody = InferBody<typeof DocumentsExtractData>

export const MarkReadDocuments = nolasAPI.patch('/api/w/{workspaceId}/documents/read')
export type MarkReadDocumentsRequest = InferBody<typeof MarkReadDocuments>

export const MarkReadSingleDocument = nolasAPI.patch('/api/w/{workspaceId}/documents/read/{documentAssignmentId}')
export type MarkReadSingleDocumentRequest = InferBody<typeof MarkReadSingleDocument>

export const UpdateDocument = nolasAPI.put('/api/w/{workspaceId}/documents/{documentAssignmentId}/properties')
export type UpdateDocumentRequest = InferBody<typeof UpdateDocument>

export const DeleteDocument = nolasAPI.delete('/api/w/{workspaceId}/documents/bulk')
export type DeleteDocumentRequest = InferBody<typeof DeleteDocument>

export const RestoreDocuments = nolasAPI.patch('/api/w/{workspaceId}/documents/restore')
export type RestoreDocumentsRequest = InferBody<typeof RestoreDocuments>

export const ApproveDocument = nolasAPI.put('/api/w/{workspaceId}/documents/{documentAssignmentId}/approve')
export type ApproveDocumentRequest = InferBody<typeof ApproveDocument>
export type ApproveDocumentResponse = InferResponse<typeof ApproveDocument>

export const DocumentApprovers = nolasAPI.get('/api/w/{workspaceId}/addresses/documents/{docAssnId}/approvers')
export type DocumentApproversResponse = InferResponse<typeof DocumentApprovers>

export const CreateSignature = nolasAPI.post('/api/w/{workspaceId}/signatures')
export type CreateSignature = InferResponse<typeof CreateSignature>
export type CreateSignatureBody = InferBody<typeof CreateSignature>

export const ListSignatures = nolasAPI.get('/api/w/{workspaceId}/signatures')
export type ListSignatures = InferResponse<typeof ListSignatures>

export const GetSignature = nolasAPI.get('/api/w/{workspaceId}/signatures/{signatureId}')
export type Signature = InferResponse<typeof GetSignature>

export const UpdateSignature = nolasAPI.put('/api/w/{workspaceId}/signatures/{signatureId}')
export type UpdateSignature = InferResponse<typeof UpdateSignature>

export const DeleteSignature = nolasAPI.delete('/api/w/{workspaceId}/signatures')
export type DeleteSignatureRequest = InferBody<typeof DeleteSignature>

export const CreateLetterhead = nolasAPI.post('/api/w/{workspaceId}/letterheads')
export type CreateLetterhead = InferResponse<typeof CreateLetterhead>
export type CreateLetterheadBody = InferBody<typeof CreateLetterhead>

export const ListLetterheads = nolasAPI.get('/api/w/{workspaceId}/letterheads')
export type ListLetterheads = InferResponse<typeof ListLetterheads>

export const GetLetterhead = nolasAPI.get('/api/w/{workspaceId}/letterheads/{letterheadId}')
export type Letterhead = InferResponse<typeof GetLetterhead>

export const UpdateLetterhead = nolasAPI.patch('/api/w/{workspaceId}/letterheads/{letterheadId}')
export type UpdateLetterhead = InferResponse<typeof UpdateLetterhead>

export const DeleteLetterhead = nolasAPI.delete('/api/w/{workspaceId}/letterheads')
export type DeleteLetterheadRequest = InferBody<typeof DeleteLetterhead>

export const CreateAttachment = nolasAPI.post('/api/w/{workspaceId}/attachments')
export type CreateAttachment = InferResponse<typeof CreateAttachment>
export type CreateAttachmentBody = InferBody<typeof CreateAttachment>

export const ListAttachments = nolasAPI.get('/api/w/{workspaceId}/attachments')
export type ListAttachments = InferResponse<typeof ListAttachments>

export const GetAttachment = nolasAPI.get('/api/w/{workspaceId}/attachments/{attachmentId}')
export type Attachment = InferResponse<typeof GetAttachment>

export const UpdateAttachment = nolasAPI.patch('/api/w/{workspaceId}/attachments/{attachmentId}')
export type UpdateAttachment = InferResponse<typeof UpdateAttachment>

export const DeleteAttachment = nolasAPI.delete('/api/w/{workspaceId}/attachments')
export type DeleteAttachmentRequest = InferBody<typeof DeleteAttachment>

export const GetSearchKey = nolasAPI.get('/api/w/{workspaceId}/search/key')
export type GetSearchKeyResponse = InferResponse<typeof GetSearchKey>

export const ListTextTemplates = nolasAPI.get('/api/w/{workspaceId}/text-templates')
export type ListTextTemplatesResponse = InferResponse<typeof ListTextTemplates>
export type ListTextTemplatesRequest = InferRequest<typeof ListTextTemplates>

export const CreateTextTemplate = nolasAPI.post('/api/w/{workspaceId}/text-templates')
export type CreateTextTemplatesResponse = InferResponse<typeof CreateTextTemplate>
export type CreateTextTemplateRequest = InferBody<typeof CreateTextTemplate>

export const GetTextTemplate = nolasAPI.get('/api/w/{workspaceId}/text-templates/{textTemplateId}')
export type GetTextTemplateResponse = InferResponse<typeof GetTextTemplate>

export const UpdateTextTemplate = nolasAPI.patch('/api/w/{workspaceId}/text-templates/{textTemplateId}')
export type UpdateTextTemplateResponse = InferResponse<typeof UpdateTextTemplate>
export type UpdateTextTemplateRequest = InferBody<typeof UpdateTextTemplate>

export const DeleteTextTemplate = nolasAPI.delete('/api/w/{workspaceId}/text-templates/bulk')
export type DeleteTextTemplateResponse = InferResponse<typeof DeleteTextTemplate>
export type DeleteTextTemplateRequest = InferBody<typeof DeleteTextTemplate>

export const GetContact = nolasAPI.get('/api/w/{workspaceId}/addresses/{addressId}')
export type GetContact = InferResponse<typeof GetContact>

export const GetMyContact = nolasAPI.get('/api/w/{workspaceId}/addresses/assignment/me')
export type GetMyContact = InferResponse<typeof GetMyContact>

export const CreateContact = nolasAPI.post('/api/w/{workspaceId}/addresses')
export type CreateContact = InferResponse<typeof CreateContact>
export type CreateContactRequest = InferBody<typeof CreateContact>

export const UpdateContact = nolasAPI.patch('/api/w/{workspaceId}/addresses/{addressId}')
export type UpdateContact = InferResponse<typeof UpdateContact>
export type UpdateContactRequest = InferBody<typeof UpdateContact>

// TODO: is this one named correctly?
export const DeleteContact = nolasAPI.delete('/api/w/{workspaceId}/addresses/folders/{folderId}')
export type DeleteContact = InferResponse<typeof DeleteContact>
export type DeleteContactRequest = InferBody<typeof DeleteContact>

export const DeleteContactsBulk = nolasAPI.delete('/api/w/{workspaceId}/addresses')
export type DeleteContactsBulk = InferResponse<typeof DeleteContactsBulk>
export type DeleteContactsBulkRequest = InferBody<typeof DeleteContactsBulk>

export const InviteContact = nolasAPI.post('/api/w/{workspaceId}/addresses/handshakes/invite')
export type InviteContact = InferResponse<typeof InviteContact>
export type InviteContactRequest = InferBody<typeof InviteContact>

export const BlockContact = nolasAPI.put('/api/w/{workspaceId}/addresses/block')
export type BlockContact = InferResponse<typeof BlockContact>
export type BlockContactRequest = InferBody<typeof BlockContact>

export const MoveContacts = nolasAPI.patch('/api/w/{workspaceId}/addresses/move/bulk')
export type MoveContacts = InferResponse<typeof MoveContacts>
export type MoveContactsRequest = InferBody<typeof MoveContacts>

export const UpdatePreferredChannel = nolasAPI.put('/api/w/{workspaceId}/addresses/default/preference')
export type UpdatePreferredChannel = InferResponse<typeof UpdatePreferredChannel>
export type UpdatePreferredChannelRequest = InferBody<typeof UpdatePreferredChannel>

export const UpdateHandshakePreferredChannel = nolasAPI.put('/api/w/{workspaceId}/addresses/{addressId}/preference')
export type UpdateHandshakePreferredChannel = InferResponse<typeof UpdateHandshakePreferredChannel>
export type UpdateHandshakePreferredChannelRequest = InferBody<typeof UpdateHandshakePreferredChannel>

export const GetPendingHandshakes = nolasAPI.get('/api/w/{workspaceId}/addresses/handshakes/pending/all')
export type GetPendingHandshakesResponse = InferResponse<typeof GetPendingHandshakes>
export type GetPendingHandshakesRequest = InferBody<typeof GetPendingHandshakes>

export const GetPendingHandshakesByAssignment = nolasAPI.get(
	'/api/w/{workspaceId}/addresses/handshakes/pending/{documentAssignmentId}'
)
export type GetPendingHandshakesByAssignmentResponse = InferResponse<typeof GetPendingHandshakesByAssignment>
export type GetPendingHandshakesByAssignmentRequest = InferBody<typeof GetPendingHandshakesByAssignment>

export const ListFolders = nolasAPI.get('/api/w/{workspaceId}/addresses/folders')
export type ListFolders = InferResponse<typeof ListFolders>

export const CreateFolder = nolasAPI.post('/api/w/{workspaceId}/addresses/folders')
export type CreateFolder = InferResponse<typeof CreateFolder>
export type CreateFolderRequest = InferBody<typeof CreateFolder>

export const ShareFolder = nolasAPI.post('/api/w/{workspaceId}/addresses/folders/{assignmentId}/share')
export type ShareFolder = InferResponse<typeof ShareFolder>
export type ShareFolderRequest = InferBody<typeof ShareFolder>

export const SharedFolderAssignments = nolasAPI.get(
	'/api/w/{workspaceId}/addresses/folders/{assignmentId}/shared/assignments'
)
export type SharedFolderAssignments = InferResponse<typeof SharedFolderAssignments>
export type SharedFolderAssignmentsRequest = InferBody<typeof SharedFolderAssignments>

export const DeleteFolder = nolasAPI.delete('/api/w/{workspaceId}/addresses/folders/{folderId}')
export type DeleteFolder = InferResponse<typeof DeleteFolder>

export const MoveFolder = nolasAPI.patch('/api/w/{workspaceId}/addresses/folders/{folderId}')
export type MoveFolder = InferResponse<typeof MoveFolder>
export type MoveFolderRequest = InferBody<typeof MoveFolder>

export const UpdateFolder = nolasAPI.patch('/api/w/{workspaceId}/addresses/folders/{folderId}')
export type UpdateFolder = InferResponse<typeof UpdateFolder>
export type UpdateFolderRequest = InferBody<typeof UpdateFolder>

export const GetAvailableChannels = nolasAPI.post('/api/w/{workspaceId}/addresses/channels')
export type GetAvailableChannels = InferResponse<typeof GetAvailableChannels>
export type GetAvailableChannelsRequest = InferBody<typeof GetAvailableChannels>

export const ListSettings = nolasAPI.get('/api/w/{workspaceId}/settings')
export type ListSettingsResponse = InferResponse<typeof ListSettings>
export type ListSettingsRequest = InferRequest<typeof ListSettings>

export const ListWorkflows = nolasAPI.get('/api/w/{workspaceId}/workflows')
export type ListWorkflowsResponse = InferResponse<typeof ListWorkflows>
export type ListWorkflowsRequest = InferRequest<typeof ListWorkflows>

export const GetWorkflow = nolasAPI.get('/api/w/{workspaceId}/workflows/{workflowId}')
export type GetWorkflowResponse = InferResponse<typeof GetWorkflow>
export type GetWorkflowRequest = InferRequest<typeof GetWorkflow>

export const CreateWorkflow = nolasAPI.post('/api/w/{workspaceId}/workflows')
export type CreateWorkflowsResponse = InferResponse<typeof CreateWorkflow>
export type CreateWorkflowRequest = InferBody<typeof CreateWorkflow>

export const UpdateWorkflow = nolasAPI.patch('/api/w/{workspaceId}/workflows/{workflowId}')
export type UpdateWorkflowResponse = InferResponse<typeof UpdateWorkflow>
export type UpdateWorkflowRequest = InferBody<typeof UpdateWorkflow>

// export const DeleteWorkflow = Workbench.delete('/api/w/{workspaceId}/workflows/{workflowId}')
// export type DeleteWorkflowResponse = InferResponse<typeof DeleteWorkflow>
// export type DeleteWorkflowRequest = InferBody<typeof DeleteWorkflow>

export const GetGlAccounts = nolasAPI.get('/api/w/{workspaceId}/accounting/gl-accounts')
export type GetGlAccountsResponse = InferResponse<typeof GetGlAccounts>
export type GetGlAccountsRequest = InferBody<typeof GetGlAccounts>

export const ImportGlAccounts = nolasAPI.post('/api/w/{workspaceId}/accounting/gl-accounts')
export type ImportGlAccountsBody = InferBody<typeof ImportGlAccounts>

export const DownloadGlAccounts = nolasAPI.get('/api/w/{workspaceId}/accounting/gl-accounts/download')
export type DownloadGlAccountsResponse = InferResponse<typeof DownloadGlAccounts>
export type DownloadGlAccountsRequest = InferBody<typeof DownloadGlAccounts>

export const GetCreditors = nolasAPI.get('/api/w/{workspaceId}/accounting/creditors')
export type GetCreditorsResponse = InferResponse<typeof GetCreditors>
export type GetCreditorsRequest = InferBody<typeof GetCreditors>

export const ImportCreditors = nolasAPI.post('/api/w/{workspaceId}/accounting/creditors')
export type ImportCreditorsBody = InferBody<typeof ImportCreditors>

export const DownloadCreditors = nolasAPI.get('/api/w/{workspaceId}/accounting/creditors/download')
export type DownloadCreditorsResponse = InferResponse<typeof DownloadCreditors>
export type DownloadCreditorsRequest = InferBody<typeof DownloadCreditors>

export const GetIdentifiers = nolasAPI.get('/api/w/{workspaceId}/accounting/identifiers')
export type GetIdentifiersResponse = InferResponse<typeof GetIdentifiers>
export type GetIdentifiersRequest = InferBody<typeof GetIdentifiers>

export const ImportIdentifiers = nolasAPI.post('/api/w/{workspaceId}/accounting/identifiers')
export type ImportIdentifiersBody = InferBody<typeof ImportIdentifiers>

export const DownloadIdentifiers = nolasAPI.get('/api/w/{workspaceId}/accounting/identifiers/download')
export type DownloadIdentifiersResponse = InferResponse<typeof DownloadIdentifiers>
export type DownloadIdentifiersRequest = InferBody<typeof DownloadIdentifiers>

export const GetReferences = nolasAPI.get('/api/w/{workspaceId}/accounting/references')
export type GetReferencesResponse = InferResponse<typeof GetReferences>
export type GetReferencesRequest = InferBody<typeof GetReferences>

export const ImportReferences = nolasAPI.post('/api/w/{workspaceId}/accounting/references')
export type ImportReferencesBody = InferBody<typeof ImportReferences>

export const DownloadReferences = nolasAPI.get('/api/w/{workspaceId}/accounting/references/download')
export type DownloadReferencesResponse = InferResponse<typeof DownloadReferences>
export type DownloadReferencesRequest = InferBody<typeof DownloadReferences>

export const ListInterfaces = nolasAPI.get('/api/w/{workspaceId}/workflow-intefaces')
export type ListInterfacesResponse = InferResponse<typeof ListInterfaces>
export type ListInterfacesRequest = InferRequest<typeof ListInterfaces>

export const CreateInterface = nolasAPI.post('/api/w/{workspaceId}/workflow-intefaces')
export type CreateInterfaceResponse = InferResponse<typeof CreateInterface>
export type CreateInterfaceRequest = InferRequest<typeof CreateInterface>

export const DeleteInterface = nolasAPI.delete('/api/w/{workspaceId}/workflow-intefaces/{interfaceId}')
export type DeleteInterfaceResponse = InferResponse<typeof DeleteInterface>
export type DeleteInterfaceRequest = InferRequest<typeof DeleteInterface>

export const AcceptHandshake = nolasAPI.post('/api/w/{workspaceId}/addresses/handshakes/{handshakeId}/accept')
export type AcceptHandshakeResponse = InferResponse<typeof AcceptHandshake>
export type AcceptHandshakeRequest = InferRequest<typeof AcceptHandshake>

export const RejectHandshake = nolasAPI.post('/api/w/{workspaceId}/addresses/handshakes/{handshakeId}/reject')
export type RejectHandshakeResponse = InferResponse<typeof RejectHandshake>
export type RejectHandshakeRequest = InferRequest<typeof RejectHandshake>

export const CompleteOnboarding = nolasAPI.post('/api/w/{workspaceId}/onboarding')
export type CompleteOnboardingRequest = InferRequest<typeof CompleteOnboarding>

export const GetOnboarding = nolasAPI.get('/api/w/{workspaceId}/onboarding')
export type GetOnboardingResponse = InferResponse<typeof GetOnboarding>

export const GetCostCenters = nolasAPI.get('/api/w/{workspaceId}/cost-center')
export type GetCostCentersResponse = InferResponse<typeof GetCostCenters>

export const GetCostCenter = nolasAPI.get('/api/w/{workspaceId}/cost-center/{costCenterId}')
export type GetCostCenterResponse = InferResponse<typeof GetCostCenter>

export const CreateCostCenter = nolasAPI.post('/api/w/{workspaceId}/cost-center')
export type CreateCostCenterResponse = InferResponse<typeof CreateCostCenter>
export type CreateCostCenterBody = InferBody<typeof CreateCostCenter>

export const UpdateCostCenter = nolasAPI.patch('/api/w/{workspaceId}/cost-center/{costCenterId}')
export type UpdateCostCenterResponse = InferResponse<typeof UpdateCostCenter>
export type UpdateCostCenterBody = InferBody<typeof UpdateCostCenter>

export const DeleteCostCenters = nolasAPI.delete('/api/w/{workspaceId}/cost-center')
export type DeleteCostCentersResponse = InferResponse<typeof DeleteCostCenters>
export type DeleteCostCentersBody = InferBody<typeof DeleteCostCenters>

export const ContactsBulkImportCheck = nolasAPI.post('/api/w/{workspaceId}/addresses/{folderId}/import/check')
export type ContactsBulkImportCheckResponse = InferResponse<typeof ContactsBulkImportCheck>
export type ContactsBulkImportCheckBody = InferBody<typeof ContactsBulkImportCheck>

export const ContactsBulkImport = nolasAPI.post('/api/w/{workspaceId}/addresses/{folderId}/import')
export type ContactsBulkImportResponse = InferResponse<typeof ContactsBulkImport>
export type ContactsBulkImportBody = InferBody<typeof ContactsBulkImport>

export const CreateContactsBulk = nolasAPI.post('/api/w/{workspaceId}/addresses/bulk')
export type CreateContactsBulkResponse = InferResponse<typeof CreateContactsBulk>
export type CreateContactsBulkBody = InferBody<typeof CreateContactsBulk>

export const SendDocumentViaNolas = nolasAPI.post('/api/delivery/nolas')
export type SendDocumentViaNolas = InferResponse<typeof SendDocumentViaNolas>
export type SendDocumentViaNolasRequest = InferBody<typeof SendDocumentViaNolas>

export const SendDocumentViaEmail = nolasAPI.post('/api/delivery/email')
export type SendDocumentViaEmail = InferResponse<typeof SendDocumentViaEmail>
export type SendDocumentViaEmailRequest = InferBody<typeof SendDocumentViaEmail>

export const SendDocumentViaPost = nolasAPI.post('/api/delivery/post')
export type SendDocumentViaPost = InferResponse<typeof SendDocumentViaPost>
export type SendDocumentViaPostRequest = InferBody<typeof SendDocumentViaPost>

export const TransformDocument = nolasAPI.post('/api/transform')
export type TransformDocument = InferResponse<typeof TransformDocument>
export type TransformDocumentRequest = InferBody<typeof TransformDocument>
