<script setup lang="ts">
import { type Component } from 'vue'

import { Dialog } from '../'
import DialogHeader from '../dialog-header.vue'

defineProps<{
  id: string
  title: string
  description?: string
  icon?: Component
  headerDivider?: boolean
  primaryText?: string
  secondaryText?: string
  primaryLoading?: boolean
  primaryDisabled?: boolean
  hideActionButtons?: string
}>()
</script>

<template>
  <Dialog :id="id" :data-e2e="`${id}-modal`" size="lg">
    <div class="flex w-full flex-col">
      <DialogHeader
        :icon="icon"
        :title="title"
        class="px-10 py-8"
        :description="description"
      />

      <hr v-if="headerDivider" class="py-2" />
      <slot />
    </div>
  </Dialog>
</template>
