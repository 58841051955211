import { ListSettings } from '@services/nolas-api'
import { useQuery } from '@tanstack/vue-query'
import type { Ref } from 'vue'

import { ServiceError } from '@nolas/lib/errors'

import { useUser } from '@auth/composables/use-user'

export type SettingsQuery = {
	search: string
}
const queryKey = {
	search: (search: SettingsQuery) => ['settings', search] as const,
}

export function useSettings(query: SettingsQuery) {
	const { workspaceId } = useUser()

	const {
		data: classes,
		error,
		...queryRest
	} = useQuery({
		queryKey: queryKey.search(query),
		retry: false,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		// eslint-disable-next-line unicorn/no-unreadable-array-destructuring
		queryFn: async () => {
			const { response, data, error } = await ListSettings({
				params: {
					path: { workspaceId: workspaceId.value },
				},
			})

			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}
			return data.classes
		},
	})

	return { classes, error: error as Ref<ServiceError | null>, ...queryRest }
}
