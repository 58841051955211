<script lang="ts" setup>
import type { ClassValue } from 'clsx'
import { Primitive, type PrimitiveProps } from 'reka-ui'

import { cn } from '../../utils'

const props = withDefaults(defineProps<PrimitiveProps & { class?: ClassValue }>(), {
	as: 'a',
	class: undefined,
})
</script>

<template>
  <Primitive :as="as" :as-child="asChild" :class="cn('transition-colors hover:text-gray-900', props.class)">
    <slot />
  </Primitive>
</template>
