<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'

import type { CheckboxRootEmits, CheckboxRootProps } from 'reka-ui'
import { CheckboxIndicator, CheckboxRoot, useForwardPropsEmits } from 'reka-ui'

import INolasCheck from '~icons/nol-icons/check'
import INolasIndeterminate from '~icons/nol-icons/indeterminate'

import { cn } from '../../utils'

const props = defineProps<CheckboxRootProps & { class?: HTMLAttributes['class'] }>()
const emits = defineEmits<CheckboxRootEmits>()

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props

	return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <CheckboxRoot
    v-bind="forwarded"
    :class="
      cn(
        'peer h-5 w-5 flex-shrink-0 appearance-none rounded border border-gray-450 bg-white !ring-brand-550 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-brand-550 data-[state=indeterminate]:border-gray-450 data-[state=checked]:bg-brand-550 data-[state=indeterminate]:bg-gray-450 data-[state=checked]:text-white data-[state=indeterminate]:text-white',
        props.class ?? ''
      )
    "
  >
    <CheckboxIndicator class="flex items-center justify-center text-current">
      <INolasCheck v-if="modelValue === true" :font-size="14" />
      <INolasIndeterminate
        v-else-if="modelValue === 'indeterminate'"
        :font-size="14"
      />
    </CheckboxIndicator>
  </CheckboxRoot>
</template>
