import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export const sidebarVariants = cva('', {
	variants: {
		type: {
			normal: 'px-8',
			navigation: 'px-0',
		},
	},
	defaultVariants: {
		type: 'normal',
	},
})

export const sidebarHeaderVariants = cva('flex items-center font-semibold truncate', {
	variants: {
		type: {
			normal: 'gap-x-4 py-4 text-sm font-semibold',
			navigation: 'relative  gap-x-2  pt-3 text-base',
		},
	},
	defaultVariants: {
		type: 'normal',
	},
})

export const sidebarMenuButtonVariants = cva(
	'peer/menu-button flex w-full items-center gap-2 overflow-hidden rounded-md p-2 text-left text-sm outline-none ring-sidebar-ring transition-[width,height,padding] hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 active:bg-sidebar-accent active:text-sidebar-accent-foreground disabled:pointer-events-none disabled:opacity-50 group-has-[[data-sidebar=menu-action]]/menu-item:pr-8 aria-disabled:pointer-events-none aria-disabled:opacity-50 data-[active=true]:bg-sidebar-accent data-[active=true]:font-medium data-[active=true]:text-sidebar-accent-foreground data-[state=open]:hover:bg-sidebar-accent data-[state=open]:hover:text-sidebar-accent-foreground group-data-[collapsible=icon]:!size-8 group-data-[collapsible=icon]:!p-2 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0',
	{
		variants: {
			variant: {
				default: 'hover:bg-sidebar-accent hover:text-sidebar-accent-foreground',
				outline:
					'bg-background shadow-[0_0_0_1px_hsl(var(--sidebar-border))] hover:bg-sidebar-accent hover:text-sidebar-accent-foreground hover:shadow-[0_0_0_1px_hsl(var(--sidebar-accent))]',
			},
			size: {
				default: 'h-8 text-sm',
				sm: 'h-7 text-xs',
				lg: 'h-12 text-sm group-data-[collapsible=icon]:!p-0',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
)

export type SidebarVariants = VariantProps<typeof sidebarVariants>

export type SidebarMenuButtonVariants = VariantProps<typeof sidebarMenuButtonVariants>
