<script setup lang="ts">
import { uniqBy } from 'lodash'
import { computed } from 'vue'
import { type LocationQuery, type RouteLocationMatched } from 'vue-router'

import { useBreadcrumbs } from './use-breadcrumbs'
import Breadcrumb from './breadcrumb.vue'
import BreadcrumbList from './breadcrumb-list.vue'
import BreadcrumbSeparator from './breadcrumb-separator.vue'
import BreadcrumbItem from './breadcrumb-item.vue'
import BreadcrumbPage from './breadcrumb-page.vue'
import BreadcrumbLink from './breadcrumb-link.vue'

const { getBreadcrumbLabel } = useBreadcrumbs()

const props = defineProps<{
	location: RouteLocationMatched[]
	query: LocationQuery
}>()

const matches = computed(() => uniqBy(props.location, 'path'))
</script>

<template>
  <Breadcrumb class="flex items-center" data-e2e="breadcrumbs">
    <BreadcrumbList data-e2e="breadcrumbs-list">
      <template v-for="(link, index) in matches" :key="link.meta.title">
        <BreadcrumbSeparator v-if="index > 0" />
        <BreadcrumbItem data-e2e="breadcrumbs-list-item">
          <BreadcrumbPage
            v-if="index === matches?.length - 1"
            :data-e2e="`breadcrumbs-list-item-${link.meta.title}`"
          >
            {{ 'title' in link.meta ? getBreadcrumbLabel(link.meta.title as string) : '' }}
          </BreadcrumbPage>

          <BreadcrumbLink v-else as-child data-e2e="breadcrumbs-list-item">
            <RouterLink
              :to="link.path"
              class="text-xs font-medium"
              :data-e2e="`breadcrumbs-list-item-${link.meta.title}`"
            >
              {{ 'title' in link.meta ? getBreadcrumbLabel(link.meta.title as string) : '' }}
            </RouterLink>
          </BreadcrumbLink>
        </BreadcrumbItem>
      </template>

      <template v-if="query?.folderName">
        <BreadcrumbSeparator />
        <BreadcrumbItem data-e2e="breadcrumbs-list-item">
          <BreadcrumbPage :data-e2e="`breadcrumbs-list-item-${query?.folderName}`">
            {{ query?.folderName }}
          </BreadcrumbPage>
        </BreadcrumbItem>
      </template>

      <template v-if="query?.pageName">
        <BreadcrumbSeparator />
        <BreadcrumbItem data-e2e="breadcrumbs-list-item">
          <BreadcrumbPage :data-e2e="`breadcrumbs-list-item-${query?.pageName}`">
            {{ query?.pageName }}
          </BreadcrumbPage>
        </BreadcrumbItem>
      </template>
    </BreadcrumbList>
  </Breadcrumb>
</template>
