import { type Ref, ref } from 'vue'

import { ServiceError } from '@nolas/lib/errors'
import { GetTextTemplate } from '@services/nolas-api'
import { useQuery } from '@tanstack/vue-query'

import { QK } from '@queries'
import { useUser } from '@auth/composables/use-user'

import { useSidebarRight } from '@components/sidebar'

export const selectedTextTemplate = ref<string>()
export const selectedTextTemplates = ref<string[]>([])

export const setSelectedTextTemplateId = (id?: string) => {
	selectedTextTemplate.value = id
}

export function useTextTemplate() {
	const { workspaceId } = useUser()
	const { patch } = useSidebarRight()

	const {
		data: textTemplate,
		error,
		...queryRest
	} = useQuery({
		enabled: () => !!selectedTextTemplate.value,
		queryKey: [QK.administration.textTemplates._def, selectedTextTemplate] as const,
		retry: false,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: 1000,
		queryFn: async ({ queryKey: [, id] }) => {
			const { response, data, error } = await GetTextTemplate({
				params: { path: { textTemplateId: id ?? '', workspaceId: workspaceId.value } },
			})

			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}
			patch({ title: data.name })
			return data
		},
	})

	return { textTemplate, error: error as Ref<ServiceError | null>, ...queryRest }
}
