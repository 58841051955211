import { cva, type VariantProps } from 'class-variance-authority'

interface ErrorMessageWithVariables {
	message: string
	variables: Record<string, string>
}

export type ErrorMessage = ErrorMessageWithVariables | string

export const formItemVariants = cva('', {
	variants: {
		orientation: {
			vertical: 'flex flex-col',
			horizontal: 'flex gap-x-4 justify-between',
		},
	},
	defaultVariants: {
		orientation: 'vertical',
	},
})

type FormItemVariants = VariantProps<typeof formItemVariants>
export interface FormItemProps {
	required?: boolean
	orientation?: FormItemVariants['orientation']
}
