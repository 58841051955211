import type { ListSettingsResponse } from '@services/nolas-api'
import type { TypeSenseDocument } from '@services/nolas-api'
import { uniqBy } from 'lodash'

import { useCountries } from '@nolas/design-system/composables'

type AdditionalInformationData = {
	type: string
	information: string
}
const { get } = useCountries()

const formatAddress = (
	entity:
		| { contactPersonFullName?: string; companyName?: string; zipCode?: string; city?: string; country?: string }
		| undefined
) => {
	if (!entity) {
		return ''
	}

	const countryCode = entity.country?.replaceAll('_', '') || ''
	const countryName =
		countryCode?.toLowerCase() === 'de' ? '' : get(countryCode)?.country_name || entity.country?.replaceAll('_', '')

	return [entity.contactPersonFullName || entity.companyName, entity.zipCode, entity.city, countryName]
		.filter(part => part?.trim())
		.join(', ')
}

const getDraftsAdditionalInformation = (document: TypeSenseDocument): AdditionalInformationData => {
	let additionalInformation = {
		type: '',
		information: '',
	}

	if (document.metadata?.recipient) {
		const recipient = document.metadata.recipient

		const information = formatAddress(recipient)

		// additionalInformation = { type: 'Recipient', information }
		additionalInformation = { type: 'Empfänger', information }
	} else if (typeof document.subclassification?.entities?.['Empfänger-Adresse'] === 'string') {
		const information = document.subclassification?.entities['Empfänger-Adresse']
		// additionalInformation = { type: 'Recipient', information }
		additionalInformation = { type: 'Empfänger', information }
	}

	return additionalInformation
}

const getInboxOrSentAdditionalInformation = (document: TypeSenseDocument): AdditionalInformationData => {
	let additionalInformation = {
		type: '',
		information: '',
	}

	if (document.metadata?.sender) {
		const sender = document.metadata.sender

		const information = formatAddress(sender)

		// additionalInformation = { type: 'Sender', information }
		additionalInformation = { type: 'Absender', information }
	} else if (typeof document.subclassification?.entities?.['Absender-Adresse'] === 'string') {
		const information = document.subclassification?.entities['Absender-Adresse']
		// additionalInformation = { type: 'Sender', information }
		additionalInformation = { type: 'Absender', information }
	}

	return additionalInformation
}

const getDocumentEntity = (
	document: TypeSenseDocument,
	classes: ListSettingsResponse['classes']
): { key: string; value: string } => {
	if (!classes || !document?.classification || !document?.subclassification) {
		return {
			key: '',
			value: '',
		}
	}

	const classification = classes.find(c => c.name === document.classification)
	const subclassification = classification?.subclasses.find(c => c.name === document.subclassification?.secondaryLabel)
	const importantEntityName = subclassification?.entities.find(e => e.important)?.name

	const highlightedEntity = importantEntityName
		? document?.subclassification?.entities?.[importantEntityName]
		: undefined

	if (highlightedEntity) {
		return {
			// biome-ignore lint/style/noNonNullAssertion: <explanation>
			key: importantEntityName!,
			value: highlightedEntity,
		}
	}

	const firstEntity = subclassification?.entities[0]

	return {
		key: firstEntity?.name || '',
		value: (firstEntity?.name && document.subclassification.entities?.[firstEntity?.name]) || '',
	}
}

export function useTableFormatting() {
	const getAdditionalInformation = (document: TypeSenseDocument, classes: ListSettingsResponse['classes']) => {
		let additionalInformation: AdditionalInformationData = {
			type: '',
			information: '',
		}

		if (document.space === 'DRAFTS' || document.originalSpace === 'DRAFTS') {
			additionalInformation = getDraftsAdditionalInformation(document)
		} else if (['SENT', 'INBOX'].includes(document.space) || ['SENT', 'INBOX'].includes(document.originalSpace)) {
			additionalInformation = getInboxOrSentAdditionalInformation(document)
		}

		if (additionalInformation.information.length === 0 && document.subclassification?.entities) {
			const entity = getDocumentEntity(document, classes)

			additionalInformation = {
				type: entity.key,
				information: entity.value,
			}
		}

		return additionalInformation
	}

	const getRecipients = (document: TypeSenseDocument) => {
		let recipients = ''

		if (
			document.channel === 'EMAIL' &&
			(document.space === 'INBOX' || document.originalSpace === 'INBOX') &&
			document.recipients?.length === 1
		) {
			const recipient = document.recipients[0]
			const email = 'email' in recipient ? (recipient.email ?? '') : ''
			const name = 'name' in recipient ? (recipient.name ?? '') : ''

			if (name && email) {
				return {
					name,
					email,
				}
			}

			if (email) {
				return email
			}
		}

		if (document.metadata?.mergeInfo === 'mail_merge') {
			const totalUniqueRecipients = uniqBy(document.metadata?.decomposed, 'address.recipient')
			recipients = `${totalUniqueRecipients.length ?? 'Pending'} recipients`
		} else if ((document.recipients?.length || 0) > 1) {
			recipients = `${document.recipients?.length} recipients`
		} else if (document.recipients?.length === 1) {
			const recipient = document.recipients[0]
			recipients = 'email' in recipient ? (recipient.email ?? '') : ''
			recipients = ('name' in recipient ? recipient.name : recipients) || ''
			recipients =
				'city' in recipient ? `${recipient.city} ${recipient.zip ?? ''} ${recipient.city}`.trim() : recipients
		}

		if (document.metadata?.mergeInfo === 'mail_merge') {
			recipients = `${recipients} (mail-merge)`
		}
		if (document.metadata?.mergeInfo === 'merge') {
			recipients = `${recipients} (merged)`
		}
		if (document.metadata?.mergeInfo === 'split') {
			recipients = `${recipients} (split)`
		}

		return recipients
	}

	return {
		getRecipients,
		getAdditionalInformation,
	}
}
