<script setup lang="ts">
import Page from '@layouts/page.vue'

import { Breadcrumbs } from '@nolas/design-system/breadcrumb'
import { useSidebarLeft } from '@components/sidebar'

const { isOpen } = useSidebarLeft()
</script>

<template>
  <Page>
    <div :class="['flex h-16 min-h-16 items-center justify-between pr-8', { 'pl-8': isOpen || !!$route.meta.fullpage }]">
      <Breadcrumbs :location="$route.matched" :query="$route.query" />

      <div class="flex">
        <slot name="nav-actions" />
      </div>
    </div>

    <div class="flex h-full min-h-[100px] flex-1 flex-col">
      <slot />
    </div>
  </Page>
</template>
