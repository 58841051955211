<script setup lang="ts">
import { provideDialog } from '.'

const { dialogs, isOpen } = provideDialog()
</script>

<template>
  <slot />
  <template v-for="dialog in dialogs" :key="dialog.props.id">
    <template v-if="dialog.props.type === 'confirmation'">
      <component
        :is="dialog.component.as"
        v-model:open="isOpen"
        v-bind="{ ...dialog.props, ...(dialog.component.props || {}) }"
        v-on="dialog.component.on || {}"
      />
    </template>

    <template v-else>
      <component
        :is="dialog.wrapper"
        v-model:open="isOpen"
        v-bind="dialog.props"
      >
        <component
          :is="dialog.component.as"
          v-bind="dialog.component.props || {}"
          v-on="dialog.component.on || {}"
        />
      </component>
    </template>
  </template>
</template>
