import { GetToken } from '@services/nolas-api'
import { setAccessToken } from '@services/access-token'
import { useQuery } from '@tanstack/vue-query'
import { type Ref, computed } from 'vue'

import { ServiceError } from '@nolas/lib/errors'

export function useSession() {
	const {
		data: token,
		error,
		...query
	} = useQuery({
		queryKey: ['session'],
		refetchInterval: 5 * 60 * 1000,
		retry: false,
		staleTime: 1000 * 60 * 5,
		queryFn: async () => {
			const { response, data, error } = await GetToken({
				body: {
					client_id: 'web',
					grant_type: 'refresh_token',
				},
				bodySerializer(body) {
					return new URLSearchParams(body)
				},
				headers: {
					'content-type': 'application/x-www-form-urlencoded',
				},
			})

			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}

			const result = data

			setAccessToken(result.access_token)
			return result.access_token
		},
	})

	const isLoggedIn = computed(() => !!token.value)

	return { isLoggedIn, error: error as Ref<ServiceError | null>, ...query }
}
