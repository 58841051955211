<script setup lang="ts">
import SelectItem from './_select-item.vue'
import SelectLabel from './select-label.vue'

import { type CustomSelectGroupProps } from '.'

defineProps<CustomSelectGroupProps>()
</script>

<template>
  <SelectLabel v-if="group.title" class="mt-2.5 truncate">
    <p class="truncate">{{ group.title }}</p>
  </SelectLabel>

  <SelectItem
    v-for="item in group.items"
    :key="`${item.label}-${item.value}`"
    v-bind="{ item, iconClasses, name, translatable }"
  />
</template>
