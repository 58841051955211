import { computed, ref, reactive } from 'vue'
import { useRoute } from 'vue-router'

import { useFolders } from '@address-book/composables/folders/use-folders'
import { useFoldersFlat } from '@address-book/composables/folders/use-folders-flat'
import { useFoldersHasAccess } from '@address-book/composables/folders/use-folders-has-access'

import { findFolder, getFolderIdsForTheSelectedFolders, findFolderIds, getFolderLabel } from '@address-book/utils'
import type { TableContact } from './contacts/use-table-state'

const selectedFolderOverlay = ref<string | undefined>()
const searchQuery = ref<string | undefined>()
const selectedContacts = ref<string[]>([])
const _selectedFolders = ref<Set<string>>(new Set())
const allContacts = ref<TableContact[]>([])

export interface Filters {
	q: string | undefined
	folder: string | undefined
	page: number
	authenticatedBy: string | undefined
	nolas: string | undefined
	limit: number
	isAdminFolder: boolean
}

const filters = reactive<Filters>({
	q: undefined,
	folder: undefined,
	page: 1,
	authenticatedBy: undefined,
	limit: 25,
	nolas: undefined,
	isAdminFolder: false,
})

const resetSelections = () => {
	_selectedFolders.value = new Set()
	selectedContacts.value = []
	selectedFolderOverlay.value = undefined
	searchQuery.value = undefined
	filters.q = undefined
	filters.folder = undefined
	filters.page = 1
	filters.authenticatedBy = undefined
	filters.nolas = undefined
	filters.limit = 25
	filters.isAdminFolder = false
}
export function useAddressBookOverlay() {
	const { folders } = useFolders()
	const { foldersFlat } = useFoldersFlat()
	const { foldersHasAccess } = useFoldersHasAccess()

	const route = useRoute()
	const selectedFolders = computed({
		get: () => [..._selectedFolders.value],
		set: value => {
			_selectedFolders.value = new Set(value)
		},
	})

	const removeFolder = (folderId: string) => {
		_selectedFolders.value = new Set([..._selectedFolders.value].filter(id => id !== folderId))
		const folderIds = findFolderIds(folders.value || [], folderId)
		const contactsFromFolder = new Set(
			allContacts.value
				?.filter((contact: TableContact) => folderIds.includes(contact.folder.id))
				.map((contact: TableContact) => contact.id)
		)
		selectedContacts.value = selectedContacts.value.filter(selectedContact => !contactsFromFolder.has(selectedContact))
	}

	const getAncestorIds = (folderId: string): string[] => {
		const ancestors: string[] = []
		let currentFolder = findFolder(folders.value || [], folderId)
		while (currentFolder?.parentId) {
			currentFolder = findFolder(folders.value || [], currentFolder.parentId)
			if (currentFolder) {
				ancestors.push(currentFolder.id)
			}
		}
		return ancestors
	}

	const currentFolder = computed(() => {
		if (!foldersFlat.value) {
			return
		}
		return foldersFlat.value.find(f => f.id === filters.folder)
	})

	const getDescendantIds = (folderId: string): string[] => {
		return getFolderIdsForTheSelectedFolders(folders.value || [], [folderId])
	}

	const addFolder = (folderId: string) => {
		const ancestors = getAncestorIds(folderId)
		if (ancestors.some(id => _selectedFolders.value.has(id))) {
			return
		}

		const descendants = getDescendantIds(folderId)
		for (const id of descendants) {
			_selectedFolders.value.delete(id)
		}

		_selectedFolders.value.add(folderId)
	}

	const myOrganizationFolder = computed(() =>
		folders.value?.find(f => f.type === 'workspace_folder' && f.parentId === null)
	)
	const activeFolderItem = computed<string>(
		() => (route.query.folder as string) || selectedFolderOverlay.value || myOrganizationFolder.value?.id || ''
	)

	const selectedFolderChildren = computed(() => {
		const selectedFolder = findFolder(folders.value || [], activeFolderItem.value)
		if (!selectedFolder) {
			return []
		}
		if (selectedFolder.parentId) {
			const parentFolder = findFolder(folders.value || [], selectedFolder.parentId)
			return [
				...(parentFolder ? [parentFolder] : []),
				selectedFolder,
				...(selectedFolder.children?.filter(o => o.type !== 'admin_folder') || []),
			].filter(Boolean)
		}
		return [selectedFolder, ...(selectedFolder.children?.filter(o => o.type !== 'admin_folder') || [])].filter(Boolean)
	})

	const getFolderIds = (folderId: string) => {
		return getFolderIdsForTheSelectedFolders(folders.value || [], [folderId])
	}

	const selectFolderOverlay = (folderId?: string) => {
		const id = folderId || undefined
		selectedFolderOverlay.value = id
		filters.folder = id
		filters.isAdminFolder = isAdminFolder.value
	}

	const isMyOrganizationFolder = computed(() => {
		if (!selectedFolderOverlay.value) {
			return false
		}
		return foldersFlat.value?.find(folder => folder.id === selectedFolderOverlay.value)?.type === 'workspace_folder'
	})

	const isBlockedFolder = computed(() => {
		if (!selectedFolderOverlay.value) {
			return false
		}
		return foldersFlat.value?.find(folder => folder.id === selectedFolderOverlay.value)?.type === 'blocked_folder'
	})

	const isWorkspaceFolder = computed(() => {
		if (!selectedFolderOverlay.value) {
			return false
		}
		return foldersFlat.value?.find(folder => folder.id === selectedFolderOverlay.value)?.type === 'workspace_folder'
	})

	const isAdminFolder = computed(() => {
		if (!selectedFolderOverlay.value) {
			return false
		}
		return foldersFlat.value?.find(folder => folder.id === selectedFolderOverlay.value)?.type === 'admin_folder'
	})
	return {
		folders,
		foldersFlat,
		currentFolder,
		foldersHasAccess,
		getFolderLabel,
		findFolderIds,
		getFolderIds,
		selectFolderOverlay,
		searchQuery,
		selectedFolderChildren,
		selectedFolderOverlay,
		activeFolderItem,
		myOrganizationFolder,
		filters,
		selectedContacts,
		selectedFolders,
		addFolder,
		removeFolder,
		resetSelections,
		allContacts,
		isMyOrganizationFolder,
		isBlockedFolder,
		isWorkspaceFolder,
		isAdminFolder,
	}
}
