import { defineTableProperties, type ColumnProperties } from '@common/columns-preferences'

//#region column definitions
const icon: ColumnProperties<DocumentColumns> = {
	name: 'icon',
	static: true,
	defaultVisible: true,
	defaultSize: 8,
	minSize: 8,
	maxSize: 9,
	align: 'left',
}

const sender: ColumnProperties<DocumentColumns> = {
	name: 'sender',
	defaultVisible: true,
	alwaysVisible: true,
	defaultSize: 30,
	minSize: 24,
	maxSize: 64,
	align: 'left',
}

const recipients: ColumnProperties<DocumentColumns> = {
	name: 'recipients',
	defaultVisible: true,
	alwaysVisible: true,
	defaultSize: 30,
	minSize: 24,
	maxSize: 36,
	align: 'left',
}

const mimeType: ColumnProperties<DocumentColumns> = {
	name: 'mimeType',
	defaultVisible: true,
	defaultSize: 11,
	minSize: 11,
	maxSize: 12,
	align: 'left',
}

const title: ColumnProperties<DocumentColumns> = {
	name: 'title',
	defaultVisible: true,
	alwaysVisible: true,
	defaultSize: 24,
	minSize: 20,
	maxSize: 64,
	align: 'left',
}

const additionalInfo: ColumnProperties<DocumentColumns> = {
	name: 'additionalInformation',
	defaultVisible: true,
	defaultSize: 24,
	minSize: 18,
	maxSize: 64,
	align: 'left',
}

const workflow: ColumnProperties<DocumentColumns> = {
	name: 'workflow',
	defaultVisible: true,
	defaultSize: 24,
	minSize: 18,
	maxSize: 64,
	align: 'left',
}

const workflows: ColumnProperties<DocumentColumns> = {
	name: 'workflows',
	defaultVisible: true,
	defaultSize: 24,
	minSize: 18,
	maxSize: 64,
	align: 'left',
}

const classification: ColumnProperties<DocumentColumns> = {
	name: 'classification',
	defaultVisible: false,
	defaultSize: 24,
	minSize: 16,
	maxSize: 64,
	align: 'left',
}

const subclassification: ColumnProperties<DocumentColumns> = {
	name: 'subclassification',
	defaultVisible: true,
	defaultSize: 24,
	minSize: 16,
	maxSize: 64,
	align: 'left',
}

const channel: ColumnProperties<DocumentColumns> = {
	name: 'channel',
	defaultVisible: true,
	defaultSize: 11,
	minSize: 11,
	maxSize: 12,
	align: 'left',
}

const pages: ColumnProperties<DocumentColumns> = {
	name: 'pagesCount',
	defaultVisible: true,
	defaultSize: 8,
	minSize: 7,
	maxSize: 9,
	align: 'left',
}

const owner: ColumnProperties<DocumentColumns> = {
	name: 'owner',
	defaultVisible: true,
	defaultSize: 18,
	minSize: 14,
	maxSize: 18,
	align: 'left',
}

const updatedAt: ColumnProperties<DocumentColumns> = {
	name: 'uploadedAt',
	defaultVisible: true,
	defaultSize: 18,
	minSize: 14,
	maxSize: 18,
	align: 'left',
}

const name: ColumnProperties<DocumentColumns> = {
	name: 'name',
	defaultVisible: true,
	defaultSize: 24,
	sortable: true,
	minSize: 10,
	maxSize: 64,
	align: 'left',
}

const email: ColumnProperties<DocumentColumns> = {
	name: 'email',
	defaultVisible: true,
	defaultSize: 24,
	minSize: 10,
	maxSize: 64,
	align: 'left',
}

const authenticatedBy: ColumnProperties<DocumentColumns> = {
	name: 'authenticatedBy',
	defaultVisible: true,
	defaultSize: 17,
	minSize: 17,
	maxSize: 18,
	align: 'left',
}

const nolas: ColumnProperties<DocumentColumns> = {
	name: 'nolas',
	defaultVisible: true,
	defaultSize: 9,
	minSize: 9,
	maxSize: 10,
	align: 'left',
}

const isHandshakeInvite: ColumnProperties<DocumentColumns> = {
	name: 'isHandshakeInvite',
	defaultVisible: true,
	defaultSize: 14,
	minSize: 10,
	maxSize: 14,
	align: 'left',
}

const phoneNumber: ColumnProperties<DocumentColumns> = {
	name: 'phoneNumber',
	defaultVisible: true,
	defaultSize: 18,
	minSize: 16,
	maxSize: 24,
	align: 'left',
}

const street: ColumnProperties<DocumentColumns> = {
	name: 'street',
	defaultVisible: true,
	defaultSize: 24,
	minSize: 10,
	maxSize: 64,
	align: 'left',
}

const zipCode: ColumnProperties<DocumentColumns> = {
	name: 'zipCode',
	defaultVisible: true,
	defaultSize: 12,
	minSize: 10,
	maxSize: 14,
	align: 'left',
}

const city: ColumnProperties<DocumentColumns> = {
	name: 'city',
	defaultVisible: true,
	defaultSize: 24,
	minSize: 10,
	maxSize: 64,
	align: 'left',
}

const receivedAt: ColumnProperties<DocumentColumns> = {
	name: 'receivedAt',
	defaultVisible: true,
	defaultSize: 24,
	minSize: 16,
	maxSize: 24,
	align: 'left',
}

const action: ColumnProperties<DocumentColumns> = {
	name: 'action',
	defaultVisible: true,
	static: false,
	defaultSize: 8,
	minSize: 8,
	maxSize: 8,
	align: 'center',
}
//#endregion

export const DraftsTableProperties = defineTableProperties<'drafts', DocumentColumns>({
	name: 'drafts',
	columns: [
		icon,
		recipients,
		mimeType,
		title,
		additionalInfo,
		workflow,
		classification,
		subclassification,
		channel,
		pages,
		owner,
		{ ...updatedAt, sortable: true },
	],
})

export const InboxTableProperties = defineTableProperties<'inbox', DocumentColumns>({
	name: 'inbox',
	columns: [
		icon,
		sender,
		recipients,
		mimeType,
		title,
		additionalInfo,
		workflow,
		classification,
		subclassification,
		channel,
		pages,
		owner,
		receivedAt,
		isHandshakeInvite,
	],
})

export const SentTableProperties = defineTableProperties<'sent', DocumentColumns>({
	name: 'sent',
	columns: [
		icon,
		sender,
		recipients,
		mimeType,
		title,
		additionalInfo,
		workflow,
		classification,
		subclassification,
		channel,
		pages,
		owner,
		updatedAt,
	],
})
export const TrashTableProperties = defineTableProperties<'trash', DocumentColumns>({
	name: 'trash',
	columns: [
		icon,
		recipients,
		mimeType,
		title,
		additionalInfo,
		workflow,
		classification,
		subclassification,
		channel,
		pages,
		owner,
		updatedAt,
	],
})

export const ContactTableProperties = defineTableProperties<'contact', DocumentColumns>({
	name: 'contact',
	columns: [name, email, authenticatedBy, nolas, phoneNumber, street, zipCode, city, workflows],
})

export const BulkImportTableProperties = defineTableProperties<'bulkImport', DocumentColumns>({
	name: 'bulkImport',
	columns: [action, name, email],
})

type DocumentColumns =
	| 'icon'
	| 'sender'
	| 'recipients'
	| 'mimeType'
	| 'title'
	| 'additionalInformation'
	| 'workflow'
	| 'workflows'
	| 'classification'
	| 'subclassification'
	| 'channel'
	| 'pagesCount'
	| 'owner'
	| 'uploadedAt'
	| 'assignmentStatus'
	| 'name'
	| 'email'
	| 'authenticatedBy'
	| 'phoneNumber'
	| 'street'
	| 'zipCode'
	| 'city'
	| 'nolas'
	| 'isHandshakeInvite'
	| 'receivedAt'
	| 'action'
