import { LoginSAML, type LoginSAMLRequest } from '@services/nolas-api'
import { useMutation } from '@tanstack/vue-query'

import { ServiceError } from '@nolas/lib/errors'

export function useSaml() {
	const { data, mutateAsync, error, ...mutation } = useMutation<LoginSAML, ServiceError, LoginSAMLRequest>({
		mutationFn: async variables => {
			const { response, data, error } = await LoginSAML({
				body: variables,
			})
			if (!response.ok) {
				throw ServiceError.fromResponse(error)
			}
			// Ensure data is defined
			if (!data) {
				throw new ServiceError({ name: 'ValidationError', status: 400, details: 'No data returned from SAML login' })
			}
			return data
		},
		onSuccess: result => {
			window.open(result.redirectUrl, '_self')
		},
	})

	return { login: mutateAsync, result: data, error, ...mutation }
}
