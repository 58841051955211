import { type ComputedRef, type InjectionKey, type Reactive, type Ref, inject } from 'vue'

import type { TypeSenseDocument } from '@services/nolas-api'
import type { SearchResponseFacetCountSchema } from 'typesense/lib/Typesense/Documents'
import type { z } from 'zod'

import type { SelectableDocument } from '../composables/use-documents-selection'

import { type BaseTableParams, type BaseTableState, contextKey } from './use-table-state'

export interface TrashTableState extends BaseTableState {
	table: Reactive<z.infer<typeof BaseTableParams>>
	documents: ComputedRef<SelectableDocument[]>
	total: ComputedRef<number | undefined>
	isLoading: Ref<boolean>
	facets: ComputedRef<SearchResponseFacetCountSchema<TypeSenseDocument>[]>
}

export const trashContextKey = contextKey as InjectionKey<TrashTableState>

export function useTrash() {
	const context = inject(trashContextKey)
	if (!context) {
		throw new Error('Please use `provideTrashState` before calling this function.')
	}
	return context
}
