import { ref } from 'vue'

export const useSendingSettingsPreventSelect = () => {
	let resolvePromise: (value: boolean | PromiseLike<boolean>) => void
	const preventSelectState = ref<Promise<boolean>>()

	const initiatePreventSelect = () => {
		preventSelectState.value = new Promise(resolve => {
			resolvePromise = resolve
		})
	}

	const changePreventSelectState = (data: boolean) => {
		if (resolvePromise) {
			resolvePromise(data)
		}
	}

	return {
		preventSelectState,
		initiatePreventSelect,
		changePreventSelectState,
	}
}
