<script setup lang="ts">
import { computed, ref } from 'vue'

import { MoreOptionsButton } from '@nolas/design-system/more-options-button'
import { Button } from '@nolas/design-system/button'
import { DropdownMenu } from '@nolas/design-system/dropdown-menu'

import type { MenuItem, MenuItemAction } from '@common/navigation'

const props = defineProps<{
	item: MenuItem
	isSelected?: boolean
	hasRightChevron?: boolean
}>()

const isHovered = ref(false)
const isDropdownOpen = ref(false)

const showDotsOrDropdown = computed(() => {
	return props.item.id === 'workbench-inbox' && (isHovered.value || isDropdownOpen.value)
})
</script>

<template>
  <Button
    v-if="item.type !== 'spacer'"
    size="md"
    variant="menu"
    :class="['my-1 w-full justify-start gap-3 text-left', isSelected ? 'bg-gray-250' : '']"
    :data-e2e="`app-bar-navigation-bar-item-${item.id}`"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <component
      :is="item.icon"
      v-if="item.icon"
      :class="['!h-6 min-h-6 !w-6 min-w-6', isSelected ? 'text-gray-1000' : 'text-gray-475']"
      :data-e2e="`app-bar-navigation-bar-item-${item.id}-icon${isSelected ? '-active' : ''}`"
    />
    <span class="truncate">{{ $t(item.name) }}</span>
    <span
      v-if="item.badge && !showDotsOrDropdown"
      class="ml-auto text-sm proportional-nums text-gray-675"
      :data-e2e="`app-bar-navigation-bar-item-${item.id}-${item.badge}`"
    >
      {{ item.badge }}
    </span>
    <DropdownMenu
      v-if="showDotsOrDropdown"
      :id="`${props.item.id}-dropdown`"
      v-model:open="isDropdownOpen"
      :items="item.itemActions as MenuItemAction[]"
    >
      <template #trigger>
        <MoreOptionsButton
          :always-visible="true"
          visibility-class="ml-auto"
          :data-e2e="`app-bar-navigation-bar-item-${item.id}-menu`"
        />
      </template>
    </DropdownMenu>
    <INolasChevronRight v-if="hasRightChevron" class="ml-auto !h-6 !w-6" />
  </Button>
  <div v-else class="h-4 bg-gray-100" />
</template>
