<script setup lang="ts">
import { provideSidebar } from '.'

provideSidebar()
</script>

<template>
  <Teleport to="#app">
    <slot />
  </Teleport>
</template>
