<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

import { cn } from '@nolas/design-system/utils'

import { useSidebar } from './provider'

const props = defineProps<{
	class?: HTMLAttributes['class']
}>()

const { side, state, resizable, onResize } = useSidebar()
</script>

<template>
  <div
    v-if="resizable"
    data-sidebar="rail"
    aria-label="Toggle Sidebar"
    :tabindex="-1"
    title="Toggle Sidebar"
    :class="
      cn(
        'hover:after:bg-sidebar-border absolute inset-y-0 z-50 hidden w-2 -translate-x-1/2 transition-all ease-in-out after:absolute after:inset-y-0 after:left-1/2 after:w-[2px] group-data-[side=left]:-right-4 group-data-[side=right]:left-0 sm:flex',
        '[[data-side=left]_&]:cursor-w-resize [[data-side=right]_&]:cursor-e-resize',
        '[[data-side=left][data-state=collapsed]_&]:cursor-e-resize [[data-side=right][data-state=collapsed]_&]:cursor-w-resize',
        'group-data-[collapsible=offcanvas]:hover:bg-sidebar group-data-[collapsible=offcanvas]:translate-x-0 group-data-[collapsible=offcanvas]:after:left-full',
        '[[data-side=left][data-collapsible=offcanvas]_&]:-right-2',
        '[[data-side=right][data-collapsible=offcanvas]_&]:-left-2',
        side === 'left' ? '-right-1' : '-left-1',
        { hidden: state === 'collapsed' },
        props.class
      )
    "
    @mousedown="onResize"
    @touchstart="onResize"
  >
    <slot />
  </div>
</template>
