import { inject, nextTick, provide, reactive } from 'vue'
import { pausableWatch, whenever } from '@vueuse/core'
import { debounce } from 'lodash'
import { useI18n } from 'vue-i18n'
import { useUserPreferences } from '../composables/use-user-preferences'
import { useUpdateTablePreferences } from '../composables/use-update-table-preferences'

import { contextKey, mergeColumns } from '../utils'
import type { Options, TablePropertiesContext } from '../types'

export function defineTableProperties<N extends string, T extends string>(options: Options<N, T>) {
	/** Utility to get columns in the correct format needed for the DataTable. */
	function useTableColumns() {
		const context = inject<TablePropertiesContext<T>>(contextKey)
		if (!context) {
			throw new Error('Context not provided for this table.')
		}

		return context.columns
	}

	function provideTableProperties() {
		const { t } = useI18n()
		const preferences = useUserPreferences<T>()
		const { updateTablePreferences } = useUpdateTablePreferences()

		const columns = reactive(
			mergeColumns<T>(
				options.columns,
				Array.isArray(preferences.value?.[options.name]) ? preferences.value[options.name] : [],
				name => t(`columns.${name}`)
			)
		)

		const runUpdate = debounce(updateTablePreferences, 500)
		const updater = pausableWatch(columns, columns => {
			runUpdate({
				columns,
				table: options.name,
			})
		})
		updater.pause()

		whenever(
			() => preferences.value,
			(tablePreferences, old) => {
				if (!!old && !!tablePreferences) {
					return // we only want to execute this after the user's preferences were loaded from the backend.
				}
				if (!tablePreferences) {
					return
				}

				columns.splice(
					0,
					options.columns.length,
					...mergeColumns<T>(
						options.columns,
						Array.isArray(preferences.value[options.name]) ? preferences.value[options.name] : [],
						name => t(`columns.${name}`)
					)
				)

				nextTick(() => {
					updater.resume()
				})
			},
			{ immediate: true }
		)

		const context: TablePropertiesContext<T> = {
			defaultColumns: options.columns,
			columns: columns as TablePropertiesContext<T>['columns'],
		}

		provide(contextKey, context)
		return context
	}

	return {
		provideTableProperties,
		useTableColumns,
	}
}
