<script setup lang="ts">
import { provide, type Component } from 'vue'

import { IconButton } from '@nolas/design-system/icon-button'

import { type SidebarProps, useSidebarLeft, SidebarWrapperNavigation } from '.'

defineOptions({
	inheritAttrs: false,
})

const props = withDefaults(defineProps<SidebarProps & { component: Component }>(), {
	variant: 'sidebar',
})

const { sidebar, open } = useSidebarLeft()

const openSidebar = () => {
	open(props.component)
}

provide('nolas-ui:sidebarInstance', sidebar)
</script>

<template>
  <div>
    <div class="flex h-screen w-20 justify-center bg-transparent px-4 pt-3">
      <IconButton size="md" variant="gray" class="open-left-drawer" data-e2e="open-left-drawer" @click="openSidebar">
        <INolasChevronLeftDouble class="pointer-events-none h-6 w-6" />
      </IconButton>
    </div>
    <SidebarWrapperNavigation>
      <component :is="component" v-if="component" />
      <slot name="footer" />
    </SidebarWrapperNavigation>
  </div>
</template>
