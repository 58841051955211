<script setup lang="ts">
import { cn } from '../../../utils'

interface DialogFooterProps {
  class?: string
  dataE2e?: string
}

const props = defineProps<DialogFooterProps>()
</script>

<template>
  <div
    :class="
      cn(
        'flex flex-col justify-between space-y-2 sm:flex-row sm:gap-3 sm:space-y-0',
        props.class
      )
    "
    :data-e2e="dataE2e"
  >
    <slot />
  </div>
</template>
