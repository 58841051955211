import type { InjectionKey } from 'vue'
import type { ColumnProperties, PreferredColumnProperties, TablePropertiesContext } from '../types'

export const contextKey = Symbol('table-properties') as InjectionKey<TablePropertiesContext<string>>

/**
 * This utility allows consumers of the DataTable to specify the min/max size
 * of columns in `ch` units instead of pixels. It's much easier to think
 * in character numbers than pixels.
 */
const singleCharacterWidth = (parent = document.body) => {
	const span = document.createElement('span')
	span.style.width = '1ch'
	span.style.position = 'absolute'

	parent.append(span)
	const width = span.getBoundingClientRect().width
	span.remove()

	return width
}
const chWidth = singleCharacterWidth()

export function mergeColumns<T extends string>(
	defaultColumns: ColumnProperties<T>[],
	preferredColumns: PreferredColumnProperties<T>[],
	t?: (string: string) => string
) {
	const merged: PreferredColumnProperties<string>[] = Array.from({ length: defaultColumns.length })

	// We need to append columns that are new to the end of the array.
	// This might happen when we add new columns and when users return
	// they need to be able to see those columns in the options.
	const otherColumns: PreferredColumnProperties<string>[] = []
	let total = 0

	for (const defaultProp of defaultColumns) {
		const preferredPosition = preferredColumns.findIndex(prop => prop?.name === defaultProp?.name)
		if (preferredPosition === -1) {
			// if the column did not exist before in the user's preferences we save it for later (see |*|)
			otherColumns.push({
				...defaultProp,
				defaultSize: defaultProp.defaultSize * chWidth,
				minSize: defaultProp.minSize ? defaultProp.minSize * chWidth : undefined,
				maxSize: defaultProp.maxSize ? defaultProp.maxSize * chWidth : undefined,
				label: t?.(defaultProp.label || defaultProp.name) || defaultProp.name,
				size: defaultProp.defaultSize * chWidth,
				visible: defaultProp.defaultVisible ?? false,
			})
		} else {
			// if the user has a preference for the column we push it in the user's preferred order
			total += 1
			merged[preferredPosition] = {
				...defaultProp,
				defaultSize: defaultProp.defaultSize * chWidth,
				minSize: defaultProp.minSize ? defaultProp.minSize * chWidth : undefined,
				maxSize: defaultProp.maxSize ? defaultProp.maxSize * chWidth : undefined,
				label: t?.(defaultProp.label || defaultProp.name) || defaultProp.name,
				size: preferredColumns[preferredPosition].size,
				visible: preferredColumns[preferredPosition].visible,
			}
		}
	}

	// |*| we fill in the last positions in the array with the columns that didn't exist in the user's preferences.
	merged.splice(total, otherColumns.length, ...otherColumns)
	return merged
}
