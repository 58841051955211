import type { Ref } from 'vue'

import { FetchEmailInboxes } from '@services/nolas-api'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { useI18n } from 'vue-i18n'

import { emailAccountsQuery } from 'src/common/composables/email-integration/use-email-accounts'
import { useUser } from '@auth/composables/use-user'

import { ServiceError } from '@nolas/lib/errors'
import { toast } from '@nolas/design-system/sonner'

export function useEmailFetchInboxes() {
	const { t } = useI18n()
	const { workspaceId } = useUser()

	const queryClient = useQueryClient()

	const {
		mutateAsync: fetchEmailInboxes,
		isPending,
		error,
		...mutation
	} = useMutation({
		mutationFn: async () => {
			const { response, data, error } = await FetchEmailInboxes({
				params: {
					path: {
						workspaceId: workspaceId.value,
					},
				},
			})

			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}
			return data
		},
		onSuccess: () => {
			toast.success(t('auth.common.email_fetch_inboxes'))
			queryClient.invalidateQueries({ queryKey: emailAccountsQuery._def })
		},
		onError: () => {
			toast.error(t('auth.common.email_fetch_inboxes_failed'))
		},
	})

	return { fetchEmailInboxes, isPending, error: error as Ref<ServiceError | null>, ...mutation }
}
