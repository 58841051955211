import { type Ref, ref } from 'vue'

import { ServiceError } from '@nolas/lib/errors'
import { CreateCostCenter, type CreateCostCenterBody } from '@services/nolas-api'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { useI18n } from 'vue-i18n'

import { QK } from '@queries'
import { useUser } from '@auth/composables/use-user'

import { toast } from '@nolas/design-system/sonner'

const isCreatingNewCostCenter = ref(false)
const setIsCreatingNewCostCenter = (value: boolean) => {
	isCreatingNewCostCenter.value = value
}

async function create(payload: Omit<CreateCostCenterBody, 'workspace'>, workspaceId: string) {
	const { response, data, error } = await CreateCostCenter({
		params: {
			path: {
				workspaceId,
			},
		},
		body: {
			...payload,
			workspace: workspaceId,
		},
	})

	if (!response.ok || !data) {
		throw ServiceError.fromResponse(error)
	}
	return data
}

export function useCreateCostCenter() {
	const { workspaceId } = useUser()
	const queryClient = useQueryClient()
	const { t } = useI18n()
	const {
		mutateAsync: createCostCenter,
		error,
		isPending,
	} = useMutation({
		mutationFn: (payload: Omit<CreateCostCenterBody, 'workspace'>) => create(payload, workspaceId.value),
		onSuccess: response => {
			toast.success(t('app.cost_centers.add.notification.success', { name: response.name }))
			queryClient.invalidateQueries({ queryKey: QK.administration.costCenters._def })
		},
		onError: () => {
			toast.error(t('app.cost_centers.add.notification.error'))
		},
	})

	return {
		createCostCenter,
		isPending,
		isCreatingNewCostCenter,
		setIsCreatingNewCostCenter,
		error: error as Ref<ServiceError | null>,
	}
}
