import { GetSearchKey } from '@services/nolas-api'
import { useQuery } from '@tanstack/vue-query'
import type { Ref } from 'vue'

import { ServiceError } from '@nolas/lib/errors'

import { useUser } from '@auth/composables/use-user'
import { QK } from '@queries'

export function useSearchKey() {
	const { user, workspaceId } = useUser()

	const {
		data: searchCreds,
		error,
		...queryRest
	} = useQuery({
		enabled: () => !!user.value,
		queryKey: QK.search.key._def,
		retry: 2,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: 60_000,
		queryFn: async () => {
			const { response, data, error } = await GetSearchKey({
				params: { path: { workspaceId: workspaceId.value } },
			})

			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}

			return data
		},
	})

	return { searchCreds, error: error as Ref<ServiceError | null>, ...queryRest }
}
