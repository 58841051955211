import { ForgotPassword, type ForgotPasswordRequest } from '@services/nolas-api'
import { useMutation } from '@tanstack/vue-query'

import { ServiceError } from '@nolas/lib/errors'

export function useForgotPassword() {
	const {
		data,
		mutateAsync: forgotPassword,
		error,
		...mutation
	} = useMutation<ForgotPassword, ServiceError, ForgotPasswordRequest>({
		mutationFn: async variables => {
			const { response, data, error } = await ForgotPassword({
				params: {
					query: variables,
				},
			})
			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}
			return data
		},
	})

	return { forgotPassword, result: data, error, ...mutation }
}
