import type { ListFolders } from '@services/nolas-api'
import { snakeCase } from 'lodash'
import {
	computed,
	type ComputedRef,
	inject,
	type InjectionKey,
	onMounted,
	provide,
	type Reactive,
	reactive,
	ref,
	type Ref,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { UrlStateContextKeys, type AddressBookSchema } from '@url-states/utils'
import { useURLState } from '@url-states/composables/use-url-state'

import { useFolders } from '@address-book/composables/folders/use-folders'
import { useFoldersFlat } from '@address-book/composables/folders/use-folders-flat'
import { useFoldersHasAccess } from '@address-book/composables/folders/use-folders-has-access'
import { findFolder, getFolderIdsForTheSelectedFolders, findFolderIds, getFolderLabel } from '@address-book/utils'
import type { z } from 'zod'

export type Query = z.infer<typeof AddressBookSchema>

export type SortableColumns =
	| 'name'
	| 'email'
	| 'authenticatedBy'
	| 'nolas'
	| 'phoneNumber'
	| 'street'
	| 'zipCode'
	| 'city'
	| 'workflows'

export interface Context {
	table: Reactive<z.infer<typeof AddressBookSchema>>
	sort: { key: string; order: 'asc' | 'desc' }
	setSort(key: string, order: 'asc' | 'desc'): void
	folders: Ref<ListFolders | undefined>
	foldersFlat: Ref<ListFolders | undefined>
	foldersHasAccess: Ref<ListFolders | undefined>
	currentFolder: ComputedRef<ListFolders[0] | undefined>
	isMyOrganizationFolder: ComputedRef<boolean>
	isWorkspaceFolder: ComputedRef<boolean>
	isPrivateFolder: ComputedRef<boolean>
	isBlockedFolder: ComputedRef<boolean>
	isAdminFolder: ComputedRef<boolean>
	setFolderInUrl(folderId: string): void
	findFolder: (tree: ListFolders, targetId: string) => ListFolders[0] | undefined
	getFolderIdsForTheSelectedFolders: (folders: ListFolders, selectedFolders: string[]) => string[]
	setFolderInUrlDefault(): void
	isAddresBookOverlayOpen: Ref<boolean>
	isLoadingFolders: Ref<boolean>
	getFolderLabel: (folder: ListFolders[number] | undefined, pathForm: boolean) => string
	findFolderIds(folders: ListFolders, targetId: string): string[]
}

// this works with foldersFlat
export const getRootFolder = (folderId: string, folders?: ListFolders): ListFolders[number] | undefined => {
	if (!folders) {
		return undefined
	}

	const currentFolder = folders.find((f: { id: string }) => f.id === folderId)
	if (!currentFolder) {
		return undefined
	}
	if (currentFolder.parentId === null) {
		return currentFolder
	}

	return getRootFolder(currentFolder.parentId, folders)
}

export const contextKey = Symbol('address-book') as InjectionKey<Context>

function findParent(tree: ListFolders, targetId: string, parents: string[] = []): ListFolders[0]['id'][] {
	for (const node of tree) {
		if (node.id === targetId) {
			return parents // Base case: return if we find the target
		}
		if (node.children && node.children.length > 0) {
			const result = findParent(node.children, targetId, [...parents, node.id]) // Recursively search children
			if (result) {
				return result // If target is found in children, return result
			}
		}
	}
	return []
}

const isAddresBookOverlayOpen = ref(false)

/**
 * You should wrap the components in `<AddressBookProvider />`, don't use this function directly.
 *
 * If you want to access the data provided, use `useAddressBookState`.
 */
export function provideAddressBook() {
	const { t } = useI18n()
	const route = useRoute()
	const { query } = useURLState<typeof AddressBookSchema>({
		key: UrlStateContextKeys.addressBook,
	})

	const { folders, isLoading: isLoadingFolders } = useFolders()
	const { foldersFlat } = useFoldersFlat()
	const { foldersHasAccess } = useFoldersHasAccess()

	const sort = reactive<{ key: string; order: 'asc' | 'desc' }>({
		key: '',
		order: 'asc',
	})

	function setSort(key: string, order: 'asc' | 'desc') {
		sort.key = key
		sort.order = order
		query.page = 1
		query.sort = `${snakeCase(key)}:${order}`
	}

	onMounted(() => {
		if (route.params.document) {
			query.page = 1
			query.limit = 25
		}
	})

	function setFolderInUrlDefault() {
		query.page = 1
		if (!folders.value) {
			query.folder = undefined
		}
		const myOrgFolder = folders.value?.find(f => f.type === 'workspace_folder' && f.parentId === null)
		if (!myOrgFolder) {
			// biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
			return (query.folder = undefined)
		}
		query.folder = myOrgFolder?.id
		query.pageName = t(`app.keyflowz.address_book.folders.name.${myOrgFolder?.type}`)
		query.folderName = undefined
	}

	function setFolderInUrl(folderId: string) {
		if (!folders.value) {
			query.folder = undefined
		}
		query.folder = folderId
		const path = folders.value ? findParent(folders.value, folderId) : null
		query.folderPath = path && path?.length > 0 ? path.join('.') : undefined
		const folder = folders.value ? findFolder(folders.value, folderId) : null

		if (folder?.name) {
			query.folderName = t(`app.keyflowz.address_book.folders.name.${folder?.type}`)
			query.pageName = folder?.contactData?.initial?.name || folder.name
		} else {
			query.folderName = undefined
			query.pageName = t(`app.keyflowz.address_book.folders.name.${folder?.type}`)
		}
	}

	const currentFolder = computed(() => {
		if (!foldersFlat.value) {
			return
		}
		return foldersFlat.value.find(f => f.id === query.folder)
	})

	const isMyOrganizationFolder = computed(() => {
		if (!currentFolder.value) {
			return false
		}
		return currentFolder.value.type === 'workspace_folder' && currentFolder.value.parentId === null
	})

	const isWorkspaceFolder = computed(() => {
		if (!currentFolder.value) {
			return false
		}
		return currentFolder.value.type === 'workspace_folder'
	})

	const isBlockedFolder = computed(() => {
		if (!currentFolder.value) {
			return false
		}
		return currentFolder.value.type === 'blocked_folder' && currentFolder.value.parentId === null
	})

	const isAdminFolder = computed(() => {
		if (!currentFolder.value) {
			return false
		}
		return currentFolder.value.type === 'admin_folder'
	})

	const isPrivateFolder = computed(() => {
		if (!currentFolder.value) {
			return false
		}
		return currentFolder.value.type === 'private_folder' && currentFolder.value.parentId === null
	})

	const context: Context = {
		table: query,
		sort,
		setSort,
		currentFolder,
		isMyOrganizationFolder,
		isWorkspaceFolder,
		isBlockedFolder,
		isAdminFolder,
		isPrivateFolder,
		folders,
		isLoadingFolders,
		foldersFlat,
		foldersHasAccess,
		setFolderInUrl,
		findFolder,
		setFolderInUrlDefault,
		isAddresBookOverlayOpen,
		getFolderIdsForTheSelectedFolders,
		getFolderLabel,
		findFolderIds,
	}
	provide(contextKey, context)
	return context
}

export function useAddressBookState() {
	const context = inject(contextKey)
	if (!context) {
		throw new Error('Address book context is unavailable. Did you forget to provide it?')
	}

	return context
}
