import { AcceptInvitation, type AcceptInvitationRequest, GetCurrentUser } from '@services/nolas-api'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import { computed, type Ref } from 'vue'
import { QK } from '@queries'

import { ServiceError } from '@nolas/lib/errors'

import { useSession } from './use-session'

export function useUser() {
	const { isLoggedIn } = useSession()
	const { acceptUserInvitation } = useAcceptUserInvitation()

	const {
		data: user,
		error,
		...query
	} = useQuery({
		enabled: () => isLoggedIn.value,
		queryKey: QK.users.me._def,
		staleTime: 1000 * 60 * 5,
		queryFn: async () => {
			const { response, data, error } = await GetCurrentUser({})

			if (response.status !== 200 || !data) {
				throw ServiceError.fromResponse(error)
			}

			if (data.workspaces.length === 1 && data.status !== 'joined') {
				await acceptUserInvitation({ workspaceId: data.workspaces[0].id, userId: data.id })
			}
			return data
		},
	})

	// biome-ignore lint/style/noNonNullAssertion: <explanation>
	const workspaceId = computed(() => user.value!.workspaceId)
	// biome-ignore lint/style/noNonNullAssertion: <explanation>
	const userId = computed(() => user.value!.id)

	return { user, workspaceId, userId, error: error as Ref<ServiceError | null>, ...query }
}

export function useAcceptUserInvitation() {
	const queryClient = useQueryClient()

	const {
		mutateAsync: acceptUserInvitation,
		error,
		...mutation
	} = useMutation({
		mutationFn: async (variables: AcceptInvitationRequest) => {
			const { response, data, error } = await AcceptInvitation({
				params: {
					path: { workspaceId: variables.workspaceId, userId: variables.userId },
				},
			})

			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}

			return data
		},
		onMutate: async () => {
			await queryClient.cancelQueries({ queryKey: QK.users.me._def })
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: QK.users.me._def })
		},
	})

	return { acceptUserInvitation, error: error as Ref<ServiceError | null>, ...mutation }
}
