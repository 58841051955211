<script setup lang="ts">
import { DropdownMenuItem } from 'reka-ui'

import { isListGroups, isListItems } from '../../utils'
import { Paragraph } from '../paragraph'

import MenuItem from './_dropdown-menu-item.vue'

import { type DropdownMenuProps, type Item } from './utils'
import DropdownMenuContent from './dropdown-menu-content.vue'
import DropdownMenuGroup from './dropdown-menu-group.vue'
import DropdownMenuLabel from './dropdown-menu-label.vue'
import DropdownMenuRoot from './dropdown-menu-root.vue'
import DropdownMenuTrigger from './dropdown-menu-trigger.vue'

const props = withDefaults(defineProps<DropdownMenuProps>(), {
	align: 'end',
	side: 'bottom',
	avoidCollisions: true,
	class: '',
})
const open = defineModel<boolean>('open')
const slots = defineSlots<{ trigger: () => void; content: () => void; item: (item: Item) => void }>()

open.value = props.open || props.defaultOpen // set the default value, we cannot use it when initializing the model because `defineModel` is hoisted and we cannot access reference `props` directly
</script>

<template>
  <DropdownMenuRoot v-model:open="open" v-bind="{ modal: props.modal, defaultOpen: props.defaultOpen, dir: props.dir }">
    <DropdownMenuTrigger as-child :data-e2e="`${props.id}-trigger`" @click.stop>
      <slot name="trigger" />
    </DropdownMenuTrigger>

    <DropdownMenuContent v-bind="{ ...props }" :data-e2e="`${props.id}-content`">
      <template v-if="isListGroups(props.items)">
        <DropdownMenuGroup
          v-for="(group, i) in props.items"
          :key="i"
          :class="{ 'mb-1 pb-2': i < props.items.length - 1 }"
        >
          <DropdownMenuLabel class="px-4 py-[2px]">
            <Paragraph as="span" size="xs" weight="semibold" color="gray-900">
              {{ group.title }}
            </Paragraph>
          </DropdownMenuLabel>
          <template v-for="(item, j) in group.items" :key="j">
            <DropdownMenuItem v-if="typeof slots.item !== 'undefined'" as-child>
              <slot v-bind="item" name="item" />
            </DropdownMenuItem>
            <MenuItem v-else v-bind="item" :class="props.itemClass" :icon-class="props.iconClass" />
          </template>
        </DropdownMenuGroup>
      </template>

      <template v-if="isListItems(props.items)">
        <template v-for="(item, i) in props.items" :key="i">
          <DropdownMenuItem v-if="typeof slots.item !== 'undefined'" as-child>
            <slot v-bind="item" name="item" />
          </DropdownMenuItem>
          <MenuItem v-else v-bind="item" :class="props.itemClass" :icon-class="props.iconClass" />
        </template>
      </template>
    </DropdownMenuContent>

    <slot name="content" />
  </DropdownMenuRoot>
</template>
