import { inject, computed } from 'vue'

import { useExtractRecipients } from '@workbench/doclist/use-extract-recipients'
import { usePagePreview } from '@workbench/docview/composables/use-page-preview'

import { sendingSettingsContextKey } from '@sending-settings/utils'
import type { EmailRecipientType, Channels } from '@sending-settings/types'

export function useSendingSettingsRecipients() {
	const context = inject(sendingSettingsContextKey)
	if (!context) {
		throw new Error('Please use `provideSendingSettings` before calling this function.')
	}
	const { removeInvitationCover } = usePagePreview()
	const { setAreAddressesAlreadyExtracted } = useExtractRecipients()
	const mergedRecipients = computed(() => [
		...context.recipients.to,
		...context.recipients.cc,
		...context.recipients.bcc,
	])
	const toggleSelectedRecipient = (recipientId: string, type: EmailRecipientType) => {
		const recipient = context.recipients[type].find(recipient => recipient.id === recipientId)
		if (!recipient) {
			return
		}
		if (context.selectedRecipient.value?.id === recipientId) {
			context.selectedRecipient.value = undefined
			if (recipient) {
				recipient.isSelected = false
			}
		} else {
			context.selectedRecipient.value = recipient
			recipient.isSelected = true
		}
	}
	const toggleChannel = (channel: Channels) => {
		if (!context.selectedRecipient.value) {
			return
		}
		const recipient = mergedRecipients.value.find(recipient => recipient.id === context.selectedRecipient.value?.id)
		if (!recipient) {
			return
		}
		if (context.selectedRecipient.value.channel === channel) {
			context.selectedRecipient.value.channel = undefined
			if (recipient) {
				recipient.channel = undefined
			}
			for (const key of Object.keys(context.recipientsIds)) {
				const recipient = context.recipientsIds[key as keyof typeof context.recipientsIds].find(
					recipient => recipient.id === context.selectedRecipient.value?.id
				)
				if (recipient) {
					recipient.channel = undefined
				}
			}
		} else {
			context.selectedRecipient.value.channel = channel
			if (recipient) {
				recipient.channel = channel
			}
			for (const key of Object.keys(context.recipientsIds)) {
				const recipient = context.recipientsIds[key as keyof typeof context.recipientsIds].find(
					recipient => recipient.id === context.selectedRecipient.value?.id
				)
				if (recipient) {
					recipient.channel = channel
				}
			}
		}
	}

	const removeRecipient = (recipientId: string, type: EmailRecipientType) => {
		context.recipients[type] = context.recipients[type].filter(
			recipient => recipient?.folderId !== recipientId && recipient.id !== recipientId
		)
		context.recipientsIds[type] = context.recipientsIds[type].filter(
			recipient => recipient?.folderId !== recipientId && recipient.id !== recipientId
		)
		context.notSendableContacts.nolas = context.notSendableContacts.nolas.filter(
			contact => contact?.folderId !== recipientId && contact.id !== recipientId
		)
		context.notSendableContacts.email = context.notSendableContacts.email.filter(
			contact => contact?.folderId !== recipientId && contact.id !== recipientId
		)
		context.notSendableContacts.post = context.notSendableContacts.post.filter(
			contact => contact?.folderId !== recipientId && contact.id !== recipientId
		)
		if (context.selectedRecipient.value?.id === recipientId) {
			context.selectedRecipient.value = undefined
		}
		if (context.recipientsIds[type].filter(recipient => recipient.channel === 'post')?.length <= 0) {
			removeInvitationCover()
		}

		const extractedRecipients = context.recipients[type].filter(
			recipient => recipient.meta?.extracted && recipient.id !== recipientId
		)
		if (extractedRecipients.length === 0) {
			setAreAddressesAlreadyExtracted(false)
		}
	}

	const removeMultipleRecipients = (recipientIds: string[], type: EmailRecipientType) => {
		for (const recipientId of recipientIds) {
			removeRecipient(recipientId, type)
		}
	}

	return {
		recipients: context?.recipients,
		recipientsIds: context?.recipientsIds,
		isAddressingModalOpen: context?.isAddressingModalOpen,
		mergedRecipients,
		isAutomaticSending: context?.isAutomaticSending,
		selectedRecipient: context?.selectedRecipient,
		toggleSelectedRecipient,
		toggleChannel,
		removeRecipient,
		removeMultipleRecipients,
	}
}
