import type { MaybeRef, Ref } from 'vue'

import { useQuery } from '@tanstack/vue-query'

import type { ServiceError } from '@nolas/lib/errors'
import type { TypeSenseContact } from '@services/nolas-api'

import { useSearchClient } from '@composables/search/use-search-client'
import { QK } from '@queries'
import type { ContactSearchInput } from './use-contact-search'

const addressbookCollection = 'addressbook'

export const recipientsQueryKey = QK.search.recipients

export function useRecipientSearch(search: MaybeRef<ContactSearchInput>) {
	const { searchClient, searchCreds } = useSearchClient({ useApiKey: 'addressRead' })

	const {
		data: searchResult,
		error,
		...queryRest
	} = useQuery({
		queryKey: [...recipientsQueryKey._def, search] as const,
		enabled: () => !!searchCreds.value,
		retry: 2,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: 1000,
		queryFn: async ({ queryKey: [, , search] }) => {
			if (!searchClient.value) {
				throw new Error('Search client is not available')
			}

			const searchResults = await searchClient.value
				.collections<TypeSenseContact>(addressbookCollection)
				.documents()
				.search(
					{
						q: search.q ?? '*',
						per_page: search.limit,
						page: search.page,
						filter_curated_hits: true,
						query_by: 'entry.name',
						num_typos: '1',
					},
					{}
				)

			return searchResults
		},
	})

	return { searchResult, error: error as Ref<ServiceError | null>, ...queryRest }
}
