<script setup lang="ts">
import PasswordChecks from '@auth/components/password-checks.vue'
import TermsCheckbox from '@auth/components/terms-checkbox.vue'
import Strength from '../strength.vue'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { ref, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'

import { Alert } from '@nolas/design-system/alert'
import { Button } from '@nolas/design-system/button'
import { Card, CardContent } from '@nolas/design-system/card'
import { FormField } from '@components/form'
import { Input } from '@nolas/design-system/input'
import { Paragraph } from '@nolas/design-system/paragraph'

import { RegisterWithName, EmitSuccessEmail, useParseEmail } from '@auth/utils/schemas'
import { usePasswordStrength, usePasswordFeedback } from '@auth/utils/password-strength'
import { useRegister } from '@auth/composables/use-register'

const { setScore } = usePasswordStrength()
const { setFeedback } = usePasswordFeedback()

onBeforeMount(() => {
	setScore(0)
	setFeedback()
})

const route = useRoute()
const { parseEmail } = useParseEmail()

const form = useForm({
	validationSchema: toTypedSchema(RegisterWithName),
	initialValues: {
		name: '',
		email: route.query.email ? parseEmail(route.query.email as string) : '',
		password: undefined,
		repeatPassword: '',
	},
})

const terms = ref(false)

const emit = defineEmits<EmitSuccessEmail>()

const { register, error, isPending } = useRegister()

const onSubmit = form.handleSubmit(async ({ email, password, name }) => {
	await register({
		email,
		password,
		name,
		token: new URL(location.href).searchParams.get('token') || undefined,
	})
	if (!error.value) {
		emit('success', { email })
	}
})
</script>

<template>
  <div class="m-auto w-full max-w-sm px-3">
    <INolasLogoBig class="mx-auto h-32 w-48" />
    <Paragraph class="mb-8 flex flex-col" align="center">
      {{ $t('auth.flow.register.description') }}
    </Paragraph>
    <Button as="router-link" to="/auth/sso" variant="social" size="lg" class="mb-8 w-full" data-e2e="sso">
      {{ $t('auth.button.sso') }}
    </Button>
    <Card>
      <CardContent class="px-5 py-6">
        <Alert v-if="error" variant="destructive" class="mb-4" :description="$t(`common.error.${error.name}`)" />
        <form novalidate @submit.prevent="onSubmit">
          <FormField v-slot="{ componentField }" :form="form" name="name" data-e2e="name-validation">
            <Input type="text" :label="$t('common.fields.name.label')" autocomplete="name" v-bind="componentField" />
          </FormField>
          <FormField v-slot="{ componentField }" :form="form" name="email" data-e2e="email-validation">
            <Input type="email" :label="$t('auth.field.email.label')" autocomplete="email" v-bind="componentField" />
          </FormField>
          <FormField v-slot="{ field }" :form="form" name="password" data-e2e="field-required">
            <Input type="password" :label="$t('auth.field.password.label')" autocomplete="password" v-bind="field" />
          </FormField>
          <FormField v-slot="{ field }" :form="form" name="repeatPassword" data-e2e="password-match-validation">
            <Input
              type="password"
              :label="$t('auth.field.repeatPassword.label')"
              autocomplete="repeat-password"
              v-bind="field"
            />
          </FormField>
          <Strength />
          <PasswordChecks />
          <TermsCheckbox v-model="terms" class="mt-6" />

          <Button
            variant="primary"
            type="submit"
            size="lg"
            class="w-full"
            :disabled="isPending || !terms"
            data-e2e="register"
          >
            {{ $t('auth.button.register') }}
          </Button>
          <Button
            as="router-link"
            to="/auth/forgot-password"
            variant="link"
            size="md"
            type="submit"
            class="mx-auto mt-4 flex w-max items-center justify-center"
            data-e2e="forgot-password"
          >
            {{ $t('auth.button.forgot_password') }}
          </Button>
        </form>
      </CardContent>
    </Card>
    <Button as="router-link" to="/auth" variant="link" size="md" class="mx-auto mt-8 w-max" data-e2e="login">
      <INolasArrowLeft class="mr-1.5 h-5 w-5" />
      {{ $t('auth.button.back') }}
    </Button>
  </div>
</template>
