<script setup lang="ts">
import { type LoaderProps, loaderVariants, strokeOpacity, strokeColor } from '.'

withDefaults(defineProps<LoaderProps>(), {
	variant: 'primary',
	size: 'md',
})
</script>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none" :class="loaderVariants({ variant, size })">
    <circle
      cx="11"
      cy="11"
      r="9"
      :stroke="strokeColor({ variant })"
      :stroke-opacity="strokeOpacity({ variant })"
      stroke-width="3"
    />
    <path
      d="M20 11.0001C20 15.9706 15.9706 20.0001 11 20.0001C6.02944 20.0001 2 15.9706 2 11.0001C2 8.33457 3.15875 5.93971 5 4.29175"
      stroke="currentColor"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>
