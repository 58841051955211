import { mergeQueryKeys } from '@lukemorales/query-key-factory'

import { administration } from './administration'
import { documents } from './documents'
import { emailIntegration } from './email-integration'
import { handshakes } from './handshakes'
import { users } from './users'
import { search } from './search'
import { workflows } from './workflows'

export const QK = mergeQueryKeys(users, documents, emailIntegration, handshakes, administration, search, workflows)
