<script setup lang="ts">
import type { ClassValue } from 'clsx'
import { DialogClose } from 'reka-ui'

import { Button } from '../button'

withDefaults(
  defineProps<{
    primaryText?: string
    secondaryText?: string

    primaryDisabled?: boolean
    secondaryDisabled?: boolean
    primaryLoading?: boolean

    class?: ClassValue
    align?: 'between' | 'end'

    primaryType?: 'button' | 'submit'
    secondaryType?: 'button' | 'submit'

    primaryDataE2e?: string
    secondaryDataE2e?: string

    closeOnPrimary?: boolean
    closeOnSecondary?: boolean
    hasNoSecondaryButton?: boolean
    hideSeparator?: boolean
  }>(),
  {
    primaryText: 'Save',
    secondaryText: 'Cancel',
    primaryDisabled: false,
    secondaryDisabled: false,
    primaryLoading: false,
    class: '',
    align: 'end',
    primaryType: 'button',
    secondaryType: 'button',
    primaryDataE2e: '',
    secondaryDataE2e: '',
    closeOnPrimary: true,
    closeOnSecondary: true,
  }
)

const emit = defineEmits<{
  (e: 'primaryClick'): void
  (e: 'secondaryClick'): void
}>()

const handlePrimaryClick = () => {
  emit('primaryClick')
}

const handleSecondaryClick = () => {
  emit('secondaryClick')
}
</script>

<template>
  <div
    :class="[
      'flex w-full gap-3 px-10 py-8',
      !hideSeparator && 'border-t border-border-quarternary',
      align === 'between' && 'justify-between',
      align === 'end' && 'justify-end',
    ]"
  >
    <div v-if="align === 'between'" class="w-1/4 flex-shrink-0">
      <slot name="start" />
    </div>

    <div class="flex w-full items-center gap-5">
      <slot name="secondary">
        <component
          v-if="!hasNoSecondaryButton"
          :is="closeOnSecondary ? DialogClose : 'div'"
          class="w-full"
        >
          <Button
            size="lg"
            :type="secondaryType"
            class="w-full"
            variant="outline"
            :disabled="secondaryDisabled"
            :data-e2e="secondaryDataE2e || 'dialog-secondary'"
            @click="handleSecondaryClick"
          >
            {{ secondaryText }}
          </Button>
        </component>
      </slot>

      <slot name="primary">
        <component
          :is="closeOnPrimary ? DialogClose : 'div'"
          :class="['w-full']"
        >
          <Button
            size="lg"
            :type="primaryType"
            class="w-full"
            variant="primary"
            :disabled="primaryDisabled"
            :loading="primaryLoading"
            :data-e2e="primaryDataE2e || 'dialog-primary'"
            @click="handlePrimaryClick"
          >
            {{ primaryText }}
          </Button>
        </component>
      </slot>
    </div>
  </div>
</template>
