import { ref, watch } from 'vue'

import { useSession } from './use-session'
import { useRoute, useRouter } from 'vue-router'

type AuthStates = 'pending' | 'unauthenticated' | 'authenticated' | 'redirected'

export function useAuthFlow() {
  const flowState = ref<AuthStates>('pending')
  const redirectedUrl = ref<string>()
  const router = useRouter()
  const route = useRoute()
  const { status: sessionStatus } = useSession()

  watch(
    sessionStatus,
    (sessionStatus) => {
      if (sessionStatus === 'error') {
        flowState.value = 'redirected'
        route.path.startsWith('/auth') === false ? router.push('/auth') : {}
        return
      }
      if (sessionStatus === 'success') {
        flowState.value = 'authenticated'
        return
      }
    },
    {
      immediate: true,
      deep: true,
    }
  )

  return {
    state: flowState,
    redirectedUrl,
  }
}
