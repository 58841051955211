import { z } from 'zod'

import type { Reactive } from 'vue'

export const contextKey = Symbol('url-state')

export const BaseSchema = z.object({
	page: z.coerce.number().default(1),
	limit: z.coerce.number().default(25),
	q: z.string().optional(),
})

export interface BaseURLState<S extends z.ZodSchema> {
	query: Reactive<z.infer<S>>
}

export const ContactSearchParams = BaseSchema.extend({
	folder: z.string().optional(),
	authenticatedBy: z.string().optional(),
	nolas: z.string().optional(),
})

export const AddressBookSchema = ContactSearchParams.extend({
	sort: z.string().optional(),
	pageName: z.string().optional(),
	folderName: z.string().optional(),
	folderPath: z.string().optional(),
})

export type UrlStateSchema<T extends string> = T extends 'addressBook'
	? z.infer<typeof AddressBookSchema>
	: z.infer<typeof BaseSchema>

export const UrlStateContextKeys = {
	inbox: Symbol('inbox-url-state'),
	drafts: Symbol('drafts-url-state'),
	sent: Symbol('sent-url-state'),
	trash: Symbol('trash-url-state'),
	addressBook: Symbol('address-book-url-state'),
} as const
export type UrlStateContextKey = (typeof UrlStateContextKeys)[keyof typeof UrlStateContextKeys]

export const RouteTypeToSpaceMap = {
	inbox: 'INBOX',
	drafts: 'DRAFTS',
	sent: 'SENT',
	trash: 'TRASH',
} as const

export interface Config {
	/**
	 * Determines whether the URL should be replaced when the filters/pagination change.
	 */
	useURLReplace?: boolean
}
