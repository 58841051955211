<script setup lang="ts">
import { IconButton } from '@nolas/design-system/icon-button'
import { useSidebarLeft } from '@components/sidebar'

defineEmits(['toggleMenuFold', 'toggleSwitchMenu'])

interface Props {
	isSwitchMenuOpen: boolean
	showSwitcher: boolean
}

defineProps<Props>()

interface Slots {
	title: () => unknown
}

const { isNavigationClosed } = useSidebarLeft()

defineSlots<Slots>()
</script>

<template>
  <div :class="['w-full pb-3', { 'border-b border-gray-250': !isNavigationClosed }]">
    <div class="flex w-full items-center justify-between px-4">
      <IconButton
        v-if="showSwitcher && !isNavigationClosed"
        size="md"
        variant="transparent"
        data-e2e="open-module-select-drawer"
        @click="$emit('toggleSwitchMenu')"
      >
        <INolasXCircle v-if="isSwitchMenuOpen" class="pointer-events-none h-6 w-6 min-w-6" />
        <INolasDotsGrid v-else class="pointer-events-none h-6 w-6 min-w-6" />
      </IconButton>
      <slot v-if="!isNavigationClosed" name="title" />
      <IconButton
        size="md"
        :variant="isNavigationClosed ? 'gray' : 'transparent'"
        :class="[isNavigationClosed ? 'mx-auto' : 'ml-auto']"
        data-e2e="close-left-drawer"
        @click="$emit('toggleMenuFold')"
      >
        <INolasChevronLeftDouble
          :class="[
            'pointer-events-none h-6 w-6',
            isNavigationClosed ? 'rotate-0 text-gray-900' : '-rotate-180 text-gray-650',
          ]"
        />
      </IconButton>
    </div>
  </div>
</template>
