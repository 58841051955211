<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import SetupPasswordSuccess from '@auth/components/setup-password/setup-password-success.vue'
import SetupPassword from '@auth/components/setup-password/setup-password.vue'
import AuthWrapper from '@auth/components/auth-wrapper.vue'

const route = useRoute()

onMounted(() => {
	if (!route.query.token) {
		location.replace('/')
	}
})

const step = ref(1)
const onSuccess = () => {
	step.value = 2
}
</script>

<template>
  <AuthWrapper>
    <SetupPassword v-if="step == 1" @success="onSuccess" />
    <SetupPasswordSuccess v-else />
  </AuthWrapper>
</template>
