import { type InjectionKey, type Reactive, inject } from 'vue'

import type { ListSettingsResponse, UserID } from '@services/nolas-api'
import type { TypeSenseDocument } from '@services/nolas-api'
import { z } from 'zod'

import { useFileFormat } from '@nolas/design-system/composables'
import type { SelectableDocument } from '../composables/use-documents-selection'
import { useTableFormatting } from '../composables/use-table-formatting'

const { documentType } = useFileFormat()

const { getAdditionalInformation, getRecipients } = useTableFormatting()

export const BaseTableParams = z.object({
	page: z.coerce.number().default(1),
	limit: z.coerce.number().default(25),
	q: z.string().default(''),
	sort: z.string().optional(),
	classification: z.string().nullish(),
	subclassification: z.string().nullish(),
	format: z.string().nullish(),
	channel: z.string().nullish(),
	workflow: z
		.object({
			name: z.string(),
			id: z.string().optional(),
			hasInvoiceTrigger: z.boolean().optional(),
			isLastStep: z.boolean().optional(),
			authorizedUsers: z.array(z.string()).nullable().optional(),
		})
		.nullish(),
})

export interface BaseTableState {
	table: Reactive<z.infer<typeof BaseTableParams>>
}
export const contextKey = Symbol('document-table-state') as InjectionKey<BaseTableState>

export function useTableState() {
	const state = inject(contextKey)
	if (!state) {
		throw new Error('Please provide table state before using this.')
	}

	return state.table
}

type channelKeys = 'POST' | 'EMAIL' | 'NOLAS' | 'SFTP' | 'PRINTER'

export const DocumentChannels: { [key in channelKeys]: { name: string; color: string } } = {
	POST: {
		name: 'Post',
		color: '#C9E5E5',
	},
	EMAIL: {
		name: 'Email',
		color: '#DAF4CB',
	},
	NOLAS: {
		name: 'nolas',
		color: '#DFE2FF',
	},
	SFTP: {
		name: 'SFTP',
		color: '#DFE2FF',
	},
	PRINTER: {
		name: 'Printer',
		color: '#DFE2FF',
	},
}

function checkAiProcessingError(document: TypeSenseDocument) {
	//checks if zugferd or XML invoices have ai processing errors
	const readInvoiceTask = document?.processingStatus?.ai?.executedSteps.find(e => e.name === 'READ_INVOICE')
	if (readInvoiceTask?.status === 'ERROR') {
		return true
	}

	const visualizeTask = document?.processingStatus?.ai?.executedSteps.find(e => e.name === 'VISUALIZE')
	if (visualizeTask?.status === 'ERROR') {
		return true
	}

	return false
}

export const fromHit = (
	document: TypeSenseDocument,
	classes?: ListSettingsResponse['classes'],
	pendingHandshakesIds?: Array<UserID | undefined>
): SelectableDocument => {
	const additionalInformation = getAdditionalInformation(document, classes || [])

	const recipients = getRecipients(document)

	return {
		id: document.id,
		title: document.name.replace(/\.[^./]+$/, '').replace(/^assn_[\dA-Za-z]*./, '[Attachment] '), // Remove file extension from name
		pagesCount: document.pagesCount,
		uploadedAt: document.receivedAt ? new Date(document.receivedAt) : new Date(),
		receivedAt: document.receivedAt ? new Date(document.receivedAt) : new Date(),
		mimeType: documentType(document.mimeType, document.name),
		status: document.status,
		metadata: document.metadata,
		size: document.size,
		workflow: document.workflow ?? {
			id: '',
			name: '',
		},
		channel: DocumentChannels[document.channel as channelKeys],
		owner: document.owner.name || 'You',
		recipients,
		isSplit: false,
		isRead: !!document.isRead,
		exportedAt: document.exportedAt,
		isApproved: document.isApproved,
		parentId: document.parentId,
		additionalInformation,
		classification: document.classification === 'Unclassified' ? '' : (document.classification ?? ''),
		subclassification: document.subclassification?.secondaryLabel || '',
		summary: document.summary ?? '',
		assignmentStatus: document.assignmentStatus,
		sender:
			document.channel.toLowerCase() === 'email'
				? document.sender?.email
				: document.sender?.name || document.sender?.email || '',
		isHandshakeInvite:
			document.sender?.userId && pendingHandshakesIds && pendingHandshakesIds?.length > 0
				? pendingHandshakesIds?.includes(document.sender?.userId)
				: false,
		icon: 'split',
		info: 'help',
		aiProcessingError: checkAiProcessingError(document),
		ownerId: document.createdBy,
		children:
			'children' in document && Array.isArray(document.children)
				? document.children.map(child => fromHit(child, classes))
				: undefined,
	}
}
