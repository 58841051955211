import { Register, type RegisterRequest } from '@services/nolas-api'
import { useMutation } from '@tanstack/vue-query'

import { ServiceError } from '@nolas/lib/errors'

export function useRegister() {
	const { mutateAsync, data, error, ...mutation } = useMutation<Register, ServiceError, RegisterRequest>({
		mutationFn: async variables => {
			const { response, data, error } = await Register({
				body: variables,
			})
			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}
			return data
		},
	})

	return { register: mutateAsync, result: data, error, ...mutation }
}
