import { type ComputedRef, type InjectionKey, type Reactive, type Ref, computed, inject, ref } from 'vue'

import { useRoute } from 'vue-router'
import type { z } from 'zod'

import { useDocument } from 'src/common/composables/documents/use-document'
import { type SelectableDocument, useDocumentsSelection } from '../composables/use-documents-selection'

import { type BaseTableParams, type BaseTableState, contextKey } from './use-table-state'

interface TableState extends BaseTableState {
	table: Reactive<z.infer<typeof BaseTableParams>>
	documents: ComputedRef<SelectableDocument[]>
	total: ComputedRef<number | undefined>
	isLoading: Ref<boolean>
	selectedDocumentsSize: ComputedRef<number>
}

type Context = TableState

const tablesContextKey = contextKey as InjectionKey<Context>
const areAddressesAlreadyExtracted = ref(false)
const setAreAddressesAlreadyExtracted = (value: boolean) => {
	areAddressesAlreadyExtracted.value = value
}

export function useExtractRecipients() {
	const context = inject(tablesContextKey)
	if (context) {
		const route = useRoute()
		const { selectedDocuments } = useDocumentsSelection()

		const documents = computed(() => (route.params.document ? [route.params.document] : selectedDocuments.value))
		const extractedRecipients = computed(() => {
			const recipients = context.documents.value
				.filter(doc => documents.value.includes(doc.id))
				.flatMap((doc: SelectableDocument) => {
					return doc?.metadata?.mergeInfo === 'mail_merge'
						? doc?.metadata?.decomposed
						: [{ page: 1, address: doc?.metadata?.recipient }]
				})
				.flatMap(recipient => recipient?.address)
				.filter(Boolean)

			return recipients
		})
		const extractedRecipientsLength = computed(() => extractedRecipients.value?.filter(o => o?.country)?.length ?? 0)
		return {
			...context,
			extractedRecipients,
			extractedRecipientsLength,
			areAddressesAlreadyExtracted,
			setAreAddressesAlreadyExtracted,
		}
	}

	const { document } = useDocument()
	const extractedRecipients = computed(() => {
		const recipients =
			document.value?.metadata?.mergeInfo === 'mail_merge'
				? document.value?.metadata?.decomposed?.flatMap(recipient => recipient?.address)
				: [document.value?.metadata?.recipient]

		return recipients
	})
	const extractedRecipientsLength = computed(() => extractedRecipients.value?.length ?? 0)
	return {
		extractedRecipients,
		extractedRecipientsLength,
		areAddressesAlreadyExtracted,
		setAreAddressesAlreadyExtracted,
	}
}
