<script setup lang="ts">
import { type Component } from 'vue'

import { useDialog, Dialog, type DialogConfirmationVariant } from '../'
import DialogActions from '../dialog-actions.vue'
import DialogHeader from '../dialog-header.vue'

withDefaults(
  defineProps<{
    id: string
    title: string
    variant: DialogConfirmationVariant
    description?: string
    icon?: Component
    primaryText?: string
    secondaryText?: string
    primaryLoading?: boolean
    primaryDisabled?: boolean
    hasNoSecondaryButton?: boolean
  }>(),
  {
    description: '',
    icon: undefined,
    primaryText: '',
    secondaryText: '',
    primaryLoading: false,
    primaryDisabled: false,
    variant: 'warning',
  }
)

const { handleClose, handleSuccess } = useDialog()

const iconBgColor = {
  success: 'bg-success-100',
  danger: 'bg-error-100',
  warning: 'bg-warning-100',
}
</script>

<template>
  <Dialog :id="id" :data-e2e="`${id}-modal`" size="xs">
    <div class="flex w-full flex-col items-center px-8 pt-8 text-center">
      <div
        :class="[
          'mb-4 flex h-12 w-12 min-w-12 items-center justify-center rounded-full bg-error-100',
          iconBgColor[variant],
        ]"
      >
        <INolasTrashOutline
          v-if="variant === 'danger'"
          class="h-6 w-6 min-w-6 text-error-650"
        />
        <INolasAlertTriangle
          v-else-if="variant === 'warning'"
          class="h-6 w-6 min-w-6 text-warning-500"
        />
        <INolasCheckCircle v-else class="h-6 w-6 min-w-6 text-success-400" />
      </div>
      <DialogHeader
        :icon="icon"
        :title="title"
        :description="description"
        class="items-center"
      />
      <div class="pb-6">
        <slot />
      </div>
    </div>
    <DialogActions
      :primary-text="primaryText || 'Confirm'"
      :secondary-text="secondaryText || 'Cancel'"
      :primary-loading="primaryLoading"
      :primary-disabled="primaryDisabled"
      :primary-data-e2e="`${id}-confirm`"
      :secondary-data-e2e="`${id}-cancel`"
      :has-no-secondary-button="hasNoSecondaryButton"
      @primary-click="handleSuccess"
      @secondary-click="handleClose"
    />
  </Dialog>
</template>
