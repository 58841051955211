import { cva, type VariantProps } from 'class-variance-authority'

export { default as Paragraph } from './paragraph.vue'

export const paragraphVariants = cva('text-gray-600', {
	variants: {
		size: {
			xs: 'text-xs',
			sm: 'text-sm',
			md: 'text-sm',
			base: 'text-base',
			lg: 'text-lg',
			xl: 'text-xl',
			'2xl': 'text-2xl',
		},
		align: {
			left: 'text-left',
			center: 'text-center',
			right: 'text-right',
		},
		weight: {
			normal: '!font-normal',
			medium: '!font-medium',
			semibold: '!font-semibold',
			bold: '!font-bold',
		},
		color: {
			default: 'text-gray-700',
			'gray-600': 'text-gray-600',
			'gray-700': 'text-gray-700',
			'gray-900': 'text-gray-900',
			'gray-1000': 'text-gray-1000',
		},
	},
	defaultVariants: {
		size: 'md',
		align: 'left',
		weight: 'normal',
		color: 'default',
	},
})

type Variants = VariantProps<typeof paragraphVariants>
export interface ParagraphProps {
	size?: Variants['size']
	align?: Variants['align']
	weight?: Variants['weight']
	color?: Variants['color']
	as?: 'p' | 'span'
	dataE2e?: string
}
