<script setup lang="ts">
import { type ClassValue } from 'clsx'
import { AvatarRoot } from 'reka-ui'

import { cn } from '../../utils'

import { avatarVariant } from '.'

interface Props {
	size?: NonNullable<Parameters<typeof avatarVariant>[0]>['size']
	shape?: NonNullable<Parameters<typeof avatarVariant>[0]>['shape']
}

withDefaults(defineProps<Props>(), {
	size: 'sm',
	shape: 'circle',
})
</script>

<template>
  <AvatarRoot :class="cn(avatarVariant({ size, shape }), $attrs.class as ClassValue)">
    <slot />
  </AvatarRoot>
</template>
