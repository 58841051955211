<script setup lang="ts">
import { provide } from 'vue'

import { type SidebarProps, useSidebarRight, SidebarWrapper } from '.'

defineOptions({
	inheritAttrs: false,
})

withDefaults(defineProps<SidebarProps>(), {
	variant: 'sidebar',
})

const { sidebar, component, isOpen } = useSidebarRight()
provide('nolas-ui:sidebarInstance', sidebar)
</script>

<template>
  <Teleport to="#app">
    <SidebarWrapper>
      <component :is="component" v-if="component && isOpen" />
    </SidebarWrapper>
  </Teleport>
</template>
