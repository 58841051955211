import { QK } from '@queries'
import { GetContact } from '@services/nolas-api'
import { useQuery } from '@tanstack/vue-query'
import { type Ref, computed, ref } from 'vue'

import { ServiceError } from '@nolas/lib/errors'

import { useUser } from '@auth/composables/use-user'

export const addressId = ref<string | null>()
export const selectedContacts = ref<string[]>([])
export const setContactId = (id?: string | null) => {
	addressId.value = id
}

const fetchContact = async (id: string | null, workspaceId: string) => {
	if (!id) {
		return null
	}

	const { response, data, error } = await GetContact({
		params: { path: { workspaceId, addressId: id || '' } },
	})

	if (!response.ok || !data) {
		throw ServiceError.fromResponse(error)
	}
	return data
}

export function useContact() {
	const { user, workspaceId } = useUser()

	const queryKey = computed(() => QK.search.contact(addressId.value || '').queryKey)

	const {
		data: contact,
		error,
		isPending,
		...queryRest
	} = useQuery({
		queryKey,
		retry: 2,
		enabled: () => !!user.value && !!addressId.value,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: 1000,
		queryFn: async ({ queryKey: [, , id] }) => fetchContact(id ?? null, workspaceId.value),
	})

	return { contact, isPending, error: error as Ref<ServiceError | null>, ...queryRest }
}
