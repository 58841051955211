import { UpdateDocument, type UpdateDocumentRequest } from '@services/nolas-api'
import { useMutation } from '@tanstack/vue-query'
import type { Ref } from 'vue'

import { ServiceError } from '@nolas/lib/errors'

import { useUser } from '@auth/composables/use-user'

export function useDocumentsUpdate() {
	const { workspaceId } = useUser()

	const {
		data: document,
		mutateAsync: updateDocument,
		error,
		...mutation
	} = useMutation({
		mutationFn: async (request: { body: UpdateDocumentRequest; id: string }) => {
			const { response, data, error } = await UpdateDocument({
				body: request.body,
				params: { path: { workspaceId: workspaceId.value, documentAssignmentId: request.id } },
			})

			if (!response.ok || !data) {
				throw ServiceError.fromResponse(error)
			}

			return data
		},
	})

	return { document, updateDocument, error: error as Ref<ServiceError | null>, ...mutation }
}
