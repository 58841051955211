<script setup lang="ts">
import { computed } from 'vue'

import { useLogout } from '@auth/composables/use-logout'
import { useUser } from '@auth/composables/use-user'

import { Avatar, AvatarFallback } from '@nolas/design-system/avatar'
import { Heading } from '@nolas/design-system/heading'
import { IconButton } from '@nolas/design-system/icon-button'
import { Paragraph } from '@nolas/design-system/paragraph'
import { Tooltip } from '@nolas/design-system/tooltip'

import { cn } from '@nolas/design-system/utils'

defineProps<{
	version?: {
		build: string
		date: string
	}
}>()

defineEmits<{
	'avatar-click': []
}>()

const { user } = useUser()
const { logout } = useLogout()

const fallback = computed(() => {
	return user.value?.email.slice(0, 2).toUpperCase()
})
const name = computed(() => {
	return user.value?.email.split('@')[0]
})
</script>

<template>
  <div class="mt-auto flex flex-col">
    <div class="relative flex w-full items-center justify-between border-t border-gray-250 p-4 pr-16">
      <div class="flex items-center gap-x-3 overflow-hidden">
        <div class="relative h-10 w-10">
          <Avatar
            shape="circle"
            size="xs"
            :class="
              cn('my-auto h-10 w-10 border border-[#9296E5] bg-[#A3A6FF]', {
                'cursor-pointer': user?.onboardingStatus === 'optional',
              })
            "
            @click="$emit('avatar-click')"
          >
            <AvatarFallback :class="cn('text-sm font-medium uppercase leading-4.5 text-white')">
              {{ fallback }}
            </AvatarFallback>
          </Avatar>
          <Tooltip v-if="user?.onboardingStatus === 'optional'" trigger-as-child content-side="right">
            <template #trigger>
              <div
                v-if="user?.onboardingStatus === 'optional'"
                class="absolute bottom-0 right-0 h-2.5 w-2.5 cursor-pointer rounded-full bg-warning-500"
              />
            </template>
            <template #content>
              {{ $t(`common.onboarding_incomplete`) }}
            </template>
          </Tooltip>
        </div>
        <div class="w-full overflow-hidden">
          <Heading as="h3" class="truncate text-sm font-medium text-gray-900">
            {{ name }}
          </Heading>
          <Paragraph v-if="user?.email" size="sm" class="truncate text-gray-600">
            {{ user?.email }}
          </Paragraph>
        </div>
      </div>
      <IconButton
        size="md"
        variant="transparent"
        data-e2e="logout-button"
        class="absolute right-4 top-1/2 -translate-y-1/2"
        @click="logout"
      >
        <INolasLogOut class="h-6 w-6 text-gray-1000" />
      </IconButton>
    </div>

    <div v-if="version" class="px-4 pb-2">
      <Paragraph size="xs" class="line-clamp-1 text-gray-600 opacity-60">
        Build version {{ version.build }} created on {{ version.date }}.
      </Paragraph>
    </div>
  </div>
</template>
