import { inject } from 'vue'

import type { TypeSenseContact } from '@services/nolas-api'

import { contextKey } from '@address-book/states/provider'

export function useTableState() {
	const state = inject(contextKey)
	if (!state) {
		throw new Error('Please provide table state before using this.')
	}

	return state.table
}

export type TableContact = {
	id: string
	owner: {
		id: string
	}
	folder: {
		id: string
		type: string
	}
	isMain: boolean
	isAdmin?: boolean
	type: string
	userId: string
	name: string
	email?: string
	phoneNumber?: string
	country?: string
	city?: string
	zipCode?: string
	region?: string | undefined
	street?: string | undefined
	houseNumber?: string | undefined
	nolas: boolean
	workflows: string[]
	handshakeStatus: string
	authenticatedBy: string
	workspaceJoined?: boolean
	isHeadquarter?: boolean
}

export const fromHit = (hit: TypeSenseContact): TableContact => {
	const address = hit.entry.properties?.find(p => 'address' in p)?.address
	return {
		id: hit.id,
		owner: {
			id: hit.owner.id,
		},
		folder: {
			id: hit.folder.id || '',
			type: hit.folder.type,
		},
		isMain: hit.entry.isMain,
		isAdmin: hit.entry.isAdmin,
		type: hit.entry.type,
		userId: hit.entry.userId || '',
		name: hit.entry.name || '',
		email: hit.entry.properties?.find(p => 'email' in p)?.email,
		phoneNumber: hit.entry.properties?.find(p => 'phone' in p)?.phone,
		country: address?.country || '',
		city: address && 'city' in address ? address.city : '',
		zipCode: address && 'zipCode' in address ? address.zipCode : '',
		region: address && 'region' in address ? address.region : '',
		street: address && 'street' in address ? address.street : '',
		houseNumber: address && 'houseNumber' in address ? address.houseNumber : '',
		nolas: hit.entry.userId !== 'missing',
		// TODO complete with workflows after implementation
		workflows: [],
		workspaceJoined: hit.entry.workspaceJoined,
		isHeadquarter: hit.entry.isHeadquarter,
		handshakeStatus: hit.handshakeStatus as string,
		authenticatedBy: hit.identityType as string,
	}
}
