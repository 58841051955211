import { useLocalStorage } from '@vueuse/core'
import { computed, ref, onUnmounted, onMounted } from 'vue'

import { SIDEBAR_WIDTH, NAVIGATION_SIDEBAR_WIDTH, SIDEBAR_WIDTH_MIN } from './utils'
import type { SidebarVariants } from './variants'

const getWidth = (resizable: boolean, defaultWidth: string, storageWidth: string): string => {
	return resizable ? `${storageWidth}px` : `${defaultWidth}px`
}

const getBoundaries = (side: 'left' | 'right') => {
	const upperLimit = side === 'left' ? SIDEBAR_WIDTH : SIDEBAR_WIDTH + 50
	const minWidth = side === 'left' ? SIDEBAR_WIDTH_MIN : SIDEBAR_WIDTH_MIN + 220
	const gap = 32
	const windowLimit = window.innerWidth - gap

	let max = side === 'left' ? window.innerWidth / 4 : window.innerWidth / 3 // by default, limit the sidebar to 1/4 of the screen
	// if 1/4 of the screen is too small, let the user resize the sidebar past that size
	if (max < upperLimit) {
		max = upperLimit <= windowLimit && upperLimit ? upperLimit : windowLimit
	}

	// Double-check that max is always greater than min
	if (max < minWidth) {
		max = minWidth
	}

	return {
		min: minWidth,
		max,
	}
}

const isResizing = ref(false)
export const useResizeSidebar = (
	key: string,
	type: SidebarVariants['type'],
	side: 'left' | 'right',
	resizable: boolean
) => {
	const defaultWidth = type === 'navigation' ? NAVIGATION_SIDEBAR_WIDTH : SIDEBAR_WIDTH

	const sidebarWidth = useLocalStorage(`${key}:width`, String(defaultWidth))

	const width = computed(() => getWidth(resizable, String(defaultWidth), sidebarWidth.value))
	const preferredWidth = ref(Number(sidebarWidth.value))

	const setWidth = (value: number) => {
		const { min, max } = getBoundaries(side)
		sidebarWidth.value = String(Math.max(min, Math.min(value, max)))
	}

	const onResize = (event: MouseEvent | TouchEvent) => {
		isResizing.value = true
		const startX = event instanceof TouchEvent ? event.touches[0].clientX : event.clientX
		const startWidth = Number.parseInt(sidebarWidth.value)

		const handleResize = (e: MouseEvent | TouchEvent) => {
			const clientX = e instanceof TouchEvent ? e.touches[0].clientX : e.clientX
			let newWidth = startWidth

			if (side === 'left') {
				newWidth += clientX - startX // Expands when dragging right, shrinks left
			} else {
				newWidth -= clientX - startX // Expands when dragging left, shrinks right
			}

			setWidth(newWidth)
		}

		function onMove(e: MouseEvent | TouchEvent) {
			if (isResizing.value) {
				handleResize(e)
			}
		}

		function onEnd() {
			isResizing.value = false
			preferredWidth.value = Number(sidebarWidth.value)
			document.removeEventListener('mousemove', onMove)
			document.removeEventListener('mouseup', onEnd)
			document.removeEventListener('touchmove', onMove)
			document.removeEventListener('touchend', onEnd)
		}

		document.addEventListener('mousemove', onMove)
		document.addEventListener('mouseup', onEnd)
		document.addEventListener('touchmove', onMove)
		document.addEventListener('touchend', onEnd)
	}

	const adjustWidthOnResize = () => {
		const { min, max } = getBoundaries(side)
		const currentWidth = Number(sidebarWidth.value)

		const gap = 32
		const windowLimit = window.innerWidth - gap

		if (currentWidth > max) {
			sidebarWidth.value = String(max)
		} else if (currentWidth < min) {
			sidebarWidth.value = String(min)
		} else if (preferredWidth.value >= currentWidth) {
			// Smoothly transition to preferred width when possible
			const targetWidth = Math.max(min, Math.min(preferredWidth.value, windowLimit))
			sidebarWidth.value = String(targetWidth)
		}
		// If width is already within bounds, we keep the current width
	}
	onMounted(() => {
		window.addEventListener('resize', adjustWidthOnResize)
	})

	onUnmounted(() => {
		window.removeEventListener('mousemove', onResize)
		window.removeEventListener('mouseup', onResize)
		window.removeEventListener('touchstart', onResize)
		window.removeEventListener('resize', adjustWidthOnResize)
	})

	return {
		width,
		isResizing,
		onResize,
	}
}
