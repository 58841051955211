import { computed } from 'vue'

import { useUser } from '@auth/composables/use-user'

import type { TablePreferences } from '../types'

export function useUserPreferences<T extends string>() {
	const { user } = useUser()

	// TODO: fix backend types and schemas
	// the backend shouldn't really care about how the preferences looks
	// it's more of a frontend concern that is just stored on the backend
	// so we should keep minimal validations on the backend and zod schemas.

	const preferences = computed(() => (user.value?.preferences as unknown as TablePreferences<T>) || {})

	return preferences
}
