import type { GetWorkflowResponse } from '@services/nolas-api'
import type { InjectionKey } from 'vue'
import type { Reactive, Ref } from 'vue'

import type { FileMimeType } from '@nolas/lib/constants'

import type { useWorkflow } from '@workflows/composables/use-workflow'

import type { TriggerId, RecipientFilter, SenderFilter, DocContentFilter, FileFormat } from './triggers'

export type TriggersState = {
	selectedTrigger: Ref<TriggerId | undefined>
	inputChannels: Reactive<string[]>
	fileFormats: Reactive<FileFormat[]>
	recipientsFilters: Reactive<RecipientFilter[]>
	senderFilters: SenderFilter[]
	docContentFilters: Reactive<DocContentFilter[]>
	emptyRecipientsExists: Ref<boolean>
	emptySenderExists: Ref<boolean>
}

// stuff for the ui that is needed across multiple components (active menus, tabs, etc.)
export type General = {
	initialDataSet: Ref<boolean>
	stepMenuActive: Ref<boolean>
	builderHasChanges: Ref<boolean>
	isSidedrawerCostCenterActive: Ref<boolean>
}


// activeWorkflow -> used for the 'provideWorkflowState'
// tableSelectedWorkflows -> used for the table selection
export type SelectionState = {
	activeWorkflow: Ref<string | undefined>
	tableSelectedWorkflows: Ref<string[]>
}

export type ConversionEffect = 'allow' | 'deny'

export type AllowedConversion = {
	from: FileMimeType | 'any'
	to: FileMimeType
	effect: ConversionEffect
}

export type ConversionState = {
	conversionDialogOpen: Ref<boolean>
	allowedConversions: Reactive<AllowedConversion[]>
}

export type Context = {
		triggersState: TriggersState
		general: General
		selectionState: SelectionState
		conversionState: ConversionState
		workflow: Ref<GetWorkflowResponse | undefined>
		workflowDetails: {
			name: Ref<string>
			description: Ref<string>
			costCenter: Ref<string>
			authorizedUsers: Ref<string[] | undefined>
		}
		queryState: Omit<ReturnType<typeof useWorkflow>, 'workflow'>
	}

export const contextKey = Symbol('workflow') as InjectionKey<Context>