import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { z } from 'zod'
import zxcvbn from 'zxcvbn'

import { toast } from '@nolas/design-system/sonner'

import { usePasswordStrength, usePasswordFeedback } from './password-strength'

const { setScore } = usePasswordStrength()
const { setFeedback } = usePasswordFeedback()

export const Email = z.object({
	email: z.string().trim().min(1, 'common.error.required').email(),
})

export const Login = Email.extend({
	password: z.string().trim().min(1, 'common.error.required'),
})

export const Register = Login.extend({ repeatPassword: z.string().trim().min(1, 'common.error.required') }).superRefine(
	({ repeatPassword, password }, ctx) => {
		const zxcvbnResult = zxcvbn(password)
		const passwordScore = zxcvbnResult.score
		const feedback = zxcvbnResult.feedback
		setScore(passwordScore)
		setFeedback(feedback)

		if (passwordScore < 3) {
			ctx.addIssue({
				code: 'custom',
				message: 'common.error.password_too_weak',
				path: ['password'],
			})
		}
		if (repeatPassword !== password) {
			ctx.addIssue({
				code: 'custom',
				message: 'common.error.password_mismatch',
				path: ['repeatPassword'],
			})
		}
	}
)

export const RegisterWithName = Login.extend({
	name: z.string().trim().min(1, 'common.error.required'),
	repeatPassword: z.string().trim().min(1, 'common.error.required'),
}).superRefine(({ repeatPassword, password }, ctx) => {
	const zxcvbnResult = zxcvbn(password)
	const passwordScore = zxcvbnResult.score
	const feedback = zxcvbnResult.feedback
	setScore(passwordScore)
	setFeedback(feedback)

	if (passwordScore < 3) {
		ctx.addIssue({
			code: 'custom',
			message: 'common.error.password_too_weak',
			path: ['password'],
		})
	}
	if (repeatPassword !== password) {
		ctx.addIssue({
			code: 'custom',
			message: 'common.error.password_mismatch',
			path: ['repeatPassword'],
		})
	}
})

export const QueryParamEmail = z.string().trim().min(1, 'common.error.required').email()

export const useParseEmail = () => {
	const router = useRouter()
	const { t } = useI18n()
	const parseEmail = (email: string) => {
		try {
			const parse = QueryParamEmail.parse(email)
			return parse
		} catch {
			router.push('/auth')
			toast.error(t('common.error.page_not_found', { email }))
		}
	}
	return {
		parseEmail,
	}
}

export interface EmitSuccessEmail {
	// biome-ignore lint/style/useShorthandFunctionType: <explanation>
	(e: 'success', payload: { email: string }): void
}
