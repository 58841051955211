<script setup lang="ts">
import { provide, reactive, ref, watch } from 'vue'

import cloneDeep from 'lodash/cloneDeep'
import { useRoute } from 'vue-router'

import { useSidebarRight } from '@components/sidebar'
import { useDocumentsSelection } from '@workbench/composables/use-documents-selection'
import { useDocument } from 'src/common/composables/documents/use-document'

import { type DocViewContext, type PageProperties, type PreviewState, type Sheet, contextKey } from '../utils/context'
import type { TimelineStep } from '../utils/timeline-utils'
import type { PageBounds, Variant } from '../utils/util'

type DocumentSettings = {
	activeLetterhead?: string
	pageProperties?: PageProperties[]
	sheets?: Sheet[]
}

const { selectedDocuments } = useDocumentsSelection()
const { isOpen } = useSidebarRight()
const route = useRoute()

const { document, ...queryState } = useDocument()

const inViewIndex = ref(0)
const variant = ref<Variant | undefined>('pdf')

const pages = ref(document.value?.links?.thumbnails ?? [])

// timeline
const stepsToFollow = reactive<TimelineStep[]>([])
const stepMap = reactive<Record<string, boolean>>({})

const documentSettings = ref<DocumentSettings>({})
const activeLetterhead = ref<string>()
const pageProperties = reactive<PageProperties[]>([])
const sheets = reactive<Sheet[]>([])

function setSheets() {
	if (documentSettings.value.sheets?.length) {
		const newSheets = documentSettings.value.sheets.map(sheet => ({ ...sheet }))
		sheets.push(...newSheets)
	} else {
		const newSheets = pages.value.slice(sheets.length).map((_, i) => ({ front: sheets.length + i }))
		if (newSheets?.length) {
			sheets.push(...newSheets)
		}
	}
}

function setDocumentSettings() {
	documentSettings.value = Array.isArray(document.value?.properties)
		? { pageProperties: document.value?.properties as PageProperties[] }
		: {
				pageProperties: document.value?.properties?.pageProperties as PageProperties[],
				sheets: document.value?.properties?.sheets as Sheet[],
				activeLetterhead: document.value?.properties?.activeLetterhead as string,
			}
}

function setPageProperties() {
	activeLetterhead.value = documentSettings.value?.activeLetterhead || undefined

	const newPageProperties = []

	for (let i = pageProperties.length; i < pages.value.length; i++) {
		const pageSignatureCopy = cloneDeep(documentSettings.value.pageProperties?.[i]?.signatures || {})

		newPageProperties.push({
			color: documentSettings.value.pageProperties?.[i]?.color || 'color',
			thumbnail: pages.value[i],
			signatures: pageSignatureCopy,
			letterhead: documentSettings.value.pageProperties?.[i]?.letterhead || false,
		})
	}

	pageProperties.push(...newPageProperties)
}

function updateDocument() {
	setDocumentSettings()
	setSheets()
	setPageProperties()
}

const context: DocViewContext = {
	// @ts-expect-error document should exist always and we should check for isLoading, isError, etc.
	document,
	queryState,
	pages,
	pageProperties,
	preview: {
		sheets,
		inViewIndex,
		color: ref('color') as PreviewState['color'],
		letterhead: ref(false),
		printing: ref('simplex') as PreviewState['printing'],
		selection: ref([]),
		viewElement: ref(undefined),
		activeLetterhead,
		zoom: ref(1),
		variant,
	},
	signatures: {
		activeSignatureId: ref<string | undefined>(),
		pageBounds: ref<PageBounds>({
			left: 0,
			top: 0,
			right: 0,
			bottom: 0,
		}),
	},
	timeline: {
		showRequestApprovalDialog: ref(false),
		showApprovalReasonDialog: ref(),
		stepMap,
		stepsToFollow,
	},
}

provide(contextKey, context)

function resetContext() {
	inViewIndex.value = 0
	variant.value = 'pdf'

	pages.value = []
	stepsToFollow.splice(0, stepsToFollow.length)
	for (const key of Object.keys(stepMap)) {
		delete stepMap[key]
	}

	documentSettings.value = {}
	activeLetterhead.value = undefined
	pageProperties.splice(0, pageProperties.length)
	sheets.splice(0, sheets.length)

	context.preview.sheets.splice(0, context.preview.sheets.length)
	context.preview.inViewIndex.value = 0
	context.preview.color.value = 'color'
	context.preview.letterhead.value = false
	context.preview.printing.value = 'simplex'
	context.preview.selection.value = []
	context.preview.viewElement.value = undefined
	context.preview.activeLetterhead.value = undefined
	context.preview.zoom.value = 1
	context.preview.variant.value = 'pdf'

	context.signatures.activeSignatureId.value = undefined
	context.signatures.pageBounds.value = { left: 0, top: 0, right: 0, bottom: 0 }

	context.timeline.showRequestApprovalDialog.value = false
	context.timeline.showApprovalReasonDialog.value = undefined
	for (const key of Object.keys(context.timeline.stepMap)) {
		delete context.timeline.stepMap[key]
	}
	context.timeline.stepsToFollow.splice(0, context.timeline.stepsToFollow.length)
}

watch(
	document,
	(docData, prevDocData) => {
		resetContext()
		if (pages.value.length === 0) {
			pages.value = docData?.links?.thumbnails ?? []
		}

		if (!docData || docData?.id !== prevDocData?.id) {
			sheets.splice(0, sheets.length)
			pageProperties.splice(0, pageProperties.length)
		}

		if (!docData || (pageProperties.length > 0 && prevDocData?.processingStatus?.preprocessing?.status !== 'PENDING')) {
			return
		}

		updateDocument()
	},
	{ deep: true, immediate: true }
)

// Watch for first entry into the sidebar

watch(
	() => [selectedDocuments.value, route.params.document, isOpen.value],
	([selectedDocs, docId, open]) => {
		if (!docId) {
			resetContext()
		}
		const documents = docId ? [docId] : selectedDocs
		if (open && Array.isArray(documents) && documents.length === 1) {
			if (typeof documents[0] === 'string') {
				queryState.setId(documents[0])
			}
			updateDocument()
		}
	},
	{ deep: true, immediate: true }
)
</script>

<template>
	<slot />
</template>