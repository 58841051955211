const ibanRegex = new RegExp(
	'^([A-Z]{2}[ ' +
		'\\\\' +
		'-]?[0-9]{2})(?=(?:[ ' +
		'\\\\' +
		'-]?[A-Z0-9]){9,30}$)((?:[ ' +
		'\\\\' +
		'-]?[A-Z0-9]{3,5}){2,7})([ ' +
		'\\\\' +
		'-]?[A-Z0-9]{1,3})?$'
)

const length: Record<string, number> = {
	DE: 22,
	AD: 24,
	AL: 28,
	AT: 20,
	AZ: 28,
	BH: 22,
	BY: 28,
	BE: 16,
	AE: 23,
	GB: 22,
	BA: 20,
	BR: 29,
	BG: 22,
	GI: 23,
	DK: 18,
	DO: 28,
	TL: 23,
	SV: 28,
	EE: 20,
	FO: 18,
	PS: 29,
	FI: 18,
	FR: 27,
	GL: 18,
	GT: 28,
	GE: 22,
	NL: 18,
	HR: 21,
	IQ: 23,
	IE: 22,
	ES: 24,
	IL: 23,
	SE: 24,
	CH: 21,
	IT: 27,
	IS: 26,
	ME: 22,
	QA: 29,
	KZ: 20,
	XK: 20,
	CR: 22,
	KW: 30,
	CY: 28,
	LV: 21,
	LI: 21,
	LT: 20,
	LB: 28,
	LU: 20,
	HU: 28,
	MK: 19,
	MT: 31,
	MU: 30,
	MD: 24,
	MC: 27,
	MR: 27,
	PK: 24,
	PL: 28,
	PT: 25,
	RO: 24,
	LC: 32,
	SM: 27,
	ST: 25,
	SC: 31,
	SK: 24,
	SI: 19,
	SA: 24,
	RS: 22,
	TN: 24,
	TR: 26,
	UA: 29,
	VG: 24,
	GR: 27,
	CZ: 24,
	JO: 30,
}

export default {
	beforeMount(el: HTMLInputElement) {
		el.addEventListener('input', event => {
			const target = event.target as HTMLInputElement
			if (!target) return
			const value = target?.value

			let cleanedValue = value.replaceAll(/[^\da-z]/gi, '')
			const countryCode = cleanedValue.slice(0, 2).toUpperCase()

			const maxLength = length[countryCode] || 32
			if (cleanedValue.length >= maxLength) {
				cleanedValue = cleanedValue.trim().slice(0, maxLength)
			}

			target.value = ibanRegex.test(cleanedValue) ? cleanedValue.replaceAll(/(.{4})/g, '$1 ').trim() : value

			// Trigger an input event to notify Vue of the change
			target.dispatchEvent(new Event('input'))
		})
	},
}
