import { camelCase, uniqBy } from 'lodash'
import type { SearchResponse } from 'typesense/lib/Typesense/Documents'
import { computed, watch, ref } from 'vue'

import type { TypeSenseContact } from '@services/nolas-api'

import { type ContactSearchInput, useContactSearch } from '@common/composables/search/use-contact-search'

import type { ContactsPayload } from '@address-book/types'

import { fromHit, type TableContact } from './use-table-state'
import { useFolders } from '../folders/use-folders'
import { findFolderIds } from '@address-book/utils'

const allContacts = ref<TableContact[]>([])

const isFetching = ref<boolean>(false)
export function useContacts(payload?: ContactsPayload) {
	const { folders } = useFolders()

	const folderId = computed(() =>
		payload?.isInOverlay
			? payload?.folders || findFolderIds(folders.value ?? [], payload?.query?.folder || '')
			: payload?.query?.folder || ''
	)

	const authenticatedBy = computed(() => payload?.query?.authenticatedBy)
	const nolas = computed(() => payload?.query?.nolas)
	const query = computed<string>(() => payload?.query?.q || '')
	const page = computed<number>(() => payload?.query?.page || 1)
	const limit = computed<number>(() => payload?.query?.limit || 25)
	const sort = computed<{ key: string; order: 'asc' | 'desc' }>(() => {
		if (payload?.sort?.key) {
			return { key: payload.sort.key, order: payload.sort.order }
		}

		if (payload?.query?.sort) {
			const [key, order] = payload.query.sort.split(':')
			return { key: camelCase(key), order: order as 'asc' | 'desc' }
		}

		return { key: '', order: 'asc' }
	})

	const searchInput = computed<ContactSearchInput>(() => ({
		filterBy: {
			authenticatedBy: authenticatedBy.value,
			...(payload?.isInWorkflow ? {} : { nolas: nolas.value }),
		},
		sort: sort.value,
		folderId: payload?.getAllContacts ? undefined : folderId.value,
		limit: payload?.includeAll ? 250 : limit.value,
		page: payload?.getAllContacts ? 1 : page.value,
		q: query.value,
		isMyOrganizationFolder: payload?.isMyOrganizationFolder,
		isBlockedFolder: payload?.isBlockedFolder,
		isAdminFolder: payload?.isAdminFolder,
	}))

	const { searchResult, ...fetchState } = useContactSearch(searchInput, {
		refetch: true,
	})

	watch(
		() => query.value,
		() => {
			if (!payload?.isInOverlay && payload?.query?.page) {
				payload.query.page = 1
			}
		}
	)

	watch(
		searchResult,
		result => {
			if (!result) {
				return
			}
			const totalResults = (result?.out_of ?? 0) + 250
			const newHits = (result as SearchResponse<TypeSenseContact>).hits?.map(hit => fromHit(hit.document)) || []
			if (totalResults <= 250 && !payload?.getAllContacts) {
				isFetching.value = true
				allContacts.value = newHits
				return
			}
			isFetching.value = false

			// Append new results
			allContacts.value = uniqBy([...allContacts.value, ...newHits], 'id')

			// Check if more results are needed and trigger a fetch instead of looping
			if (totalResults > allContacts.value.length && isFetching.value && payload?.query?.page) {
				payload.query.page++
			}
		},
		{ immediate: true, deep: true }
	)

	const contacts = computed<TableContact[]>(() => {
		return searchResult.value?.hits?.map(hit => fromHit(hit.document)) || []
	})
	const total = computed(() => {
		return searchResult.value?.found
	})

	return {
		contacts,
		total,
		allContacts,
		refetch: fetchState.refetch,
		isLoading: fetchState.isLoading,
	}
}
