<script setup lang="ts">
import { cn } from '@nolas/design-system/utils'

import { useSidebar } from './provider'

import { type SidebarProps, sidebarVariants } from '.'

defineOptions({
	inheritAttrs: false,
})
withDefaults(defineProps<SidebarProps>(), {
	variant: 'sidebar',
	collapsible: 'offcanvas',
})

const { state, isOpen, type, side, isNavigationClosed, isResizing } = useSidebar()
</script>

<template>
  <div
    :class="['block', { 'group peer': isNavigationClosed || (collapsible === 'offcanvas' && !isOpen) }]"
    :data-state="state"
    :data-collapsible="state === 'collapsed' ? collapsible : ''"
    :data-variant="variant"
    :data-side="side"
  >
    <!-- This is what handles the sidebar gap on desktop  -->

    <div
      :class="
        cn(
          'relative h-svh duration-300 ease-in-out',
          'group-data-[collapsible=offcanvas]:w-0',
          'group-data-[side=right]:rotate-180',
          variant === 'floating' || variant === 'inset'
            ? 'group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4))]'
            : 'group-data-[collapsible=icon]:w-[--sidebar-width-icon]',
          isOpen ? 'w-[--sidebar-width]' : 'w-0',
          isNavigationClosed ? 'bg-transparent' : 'bg-gray-100',
          isResizing ? 'transition-none' : 'transition-[left,right,width]'
        )
      "
    />

    <div
      :class="
        cn(
          'fixed inset-y-0 z-50 flex h-svh duration-300 ease-in-out',
          side === 'left'
            ? 'left-0 border-r group-data-[collapsible=offcanvas]:left-[calc(var(--sidebar-width)*-1)]'
            : 'right-0 border-l group-data-[collapsible=offcanvas]:right-[calc(var(--sidebar-width)*-1)]',
          variant === 'floating' || variant === 'inset'
            ? 'group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4)_+2px)]'
            : 'group-data-[collapsible=icon]:w-[--sidebar-width-icon]',
          isOpen ? 'w-[--sidebar-width]' : 'w-0',
          sidebarVariants({ type }),
          isNavigationClosed ? 'border-transparent bg-transparent' : 'border-r border-gray-250 bg-gray-100',
          isResizing ? 'transition-none' : 'transition-[left,right,width]'
        )
      "
      v-bind="$attrs"
    >
      <div
        data-sidebar="sidebar"
        :class="
          cn(
            'text-sidebar-foreground flex h-full w-full flex-col group-data-[variant=floating]:rounded-lg group-data-[variant=floating]:border group-data-[variant=floating]:border-gray-1000 group-data-[variant=floating]:shadow',
            isNavigationClosed ? 'bg-transparent' : 'bg-gray-100'
          )
        "
      >
        <slot />
      </div>
    </div>
  </div>
</template>
