<script setup lang="ts">
import { Paragraph } from '@nolas/design-system/paragraph'

withDefaults(
	defineProps<{
		type: 'suggestion' | 'warning'
		message: string
	}>(),
	{
		type: 'suggestion',
		message: '',
	}
)
</script>

<template>
  <div v-if="message" class="flex gap-x-2" :data-e2e="`password-${type}`">
    <INolasAlertCircle
      v-if="type === 'warning'"
      class="h-5 w-5 min-w-5 text-warning-500"
      :data-e2e="`password-${type}-icon`"
    />
    <INolasCheckIconCircle v-else class="h-5 w-5 min-w-5 text-primary-100" />
    <Paragraph size="sm" :data-e2e="`password-${type}-message-${message.toLowerCase().replace(/\W+/g, '-')}`">{{
      $t(`password_suggestions.${message.toLowerCase().replace(/\W+/g, '_')}`)
    }}</Paragraph>
  </div>
</template>
