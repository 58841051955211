<script setup lang="ts">
import { computed, onMounted, watchEffect } from 'vue'

import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useGetOnboardingData } from '@onboarding/composables/use-get-onboarding'
import { useUser } from '@auth/composables/use-user'

import NavigationFooter from '@common/navigation/components/navigation-footer.vue'
import SidebarNavigation from '@common/navigation/components/sidebar-navigation.vue'
import OnboardingModal from '@onboarding/components/onboarding-modal.vue'
import { SidebarProviderLeft, SidebarProviderRight } from '@components/sidebar'

const route = useRoute()
const { locale } = useI18n()
const { user } = useUser()
const { isOnboardingModalOpen } = useGetOnboardingData()

const version = {
	build: import.meta.env.VITE_APP_BUILD_VERSION,
	date: import.meta.env.VITE_APP_BUILD_DATE,
}
const isFullpage = computed(() => {
	return route?.meta.fullpage || false
})

watchEffect(() => {
	isOnboardingModalOpen.value = user.value?.onboardingStatus === 'required' ? true : false
})

const onClickAvatar = () => {
	if (user.value?.onboardingStatus === 'optional') {
		isOnboardingModalOpen.value = true
	}
}

onMounted(() => {
	if (!localStorage.getItem('nolas_lang')) {
		localStorage.setItem('nolas_lang', 'de')
	}
})
</script>

<template>
  <div :key="locale" class="w-min-0 w-full grow overflow-hidden">
    <div
      id="app-view"
      class="flex h-screen flex-row bg-white font-bold"
    >
      <OnboardingModal
        v-model:open="isOnboardingModalOpen"
        @close="() => (isOnboardingModalOpen = false)"
      />
      <SidebarProviderLeft
        :component="SidebarNavigation"
        v-if="!isFullpage"
        :key="route.path"
      >
        <template #footer>
          <div class="mt-auto">
            <NavigationFooter
              :version="version"
              @avatar-click="onClickAvatar"
            />
          </div>
        </template>
      </SidebarProviderLeft>
      <div
        class="flex h-screen w-full flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white transition-all duration-300 ease-in-out"

      >
        <slot />
        <SidebarProviderRight  />
      </div>
    </div>
  </div>
</template>

<style>
.loader {
  fill: conic-gradient(
    from 180deg at 50% 50%,
    #ffffff 0deg,
    #2d2d2d 360deg
  ) !important;
}
</style>
