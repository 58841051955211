<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

import { cn } from '@nolas/design-system/utils'

import { useSidebar } from '.'

const props = defineProps<{
	class?: HTMLAttributes['class']
}>()
const { type, isOpen } = useSidebar()
</script>

<template>
  <Transition name="fade" mode="out-in">
    <div
      v-show="isOpen"
      data-sidebar="content"
      :class="
        cn(
          'flex min-h-0 flex-1 flex-col gap-2 overflow-auto group-data-[collapsible=icon]:hidden group-data-[collapsible=icon]:overflow-hidden',
          type === 'navigation' ? 'overflow-auto' : 'overflow-hidden',
          props.class
        )
      "
    >
      <slot />
    </div>
  </Transition>
</template>
<style scoped>
.fade-leave-active {
  transition: opacity 2s ease-in-out;
}

.fade-leave-to {
  opacity: 0;
}
</style>
