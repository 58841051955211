<script setup lang="ts">
import type { PropType } from 'vue'

import { cn } from '../../utils'

type Orientation = 'vertical' | 'horizontal'

const props = defineProps({
	class: {
		type: String,
		default: '',
	},
	orientation: {
		type: String as PropType<Orientation>,
		default: 'vertical',
	},
})
</script>

<template>
  <div
    :class="
      cn(
        'rounded-xl border border-gray-100 bg-white text-card-foreground',
        props.orientation === 'vertical' ? 'space-x-2' : '',
        props.class
      )
    "
  >
    <slot />
  </div>
</template>
