import { createQueryKeys } from '@lukemorales/query-key-factory'
import { SharedFolderAssignments } from '@services/nolas-api'
import { useQuery } from '@tanstack/vue-query'
import type { Ref } from 'vue'

import { ServiceError } from '@nolas/lib/errors'

import { useUser } from '@auth/composables/use-user'

interface GetSharedRequest {
	folderId: string
}

export const foldersShareQueryKey = createQueryKeys('folders-share')

async function fetchFoldersFlat(payload: GetSharedRequest, workspaceId: string) {
	const { response, data, error } = await SharedFolderAssignments({
		params: {
			path: {
				workspaceId,
				assignmentId: payload.folderId,
			},
		},
	})

	if (!response.ok || !data) {
		throw ServiceError.fromResponse(error)
	}
	return data
}

export function useFolderSharedAssignments(payload: GetSharedRequest) {
	const { workspaceId } = useUser()

	const {
		data: sharedAssignments,
		error,
		isLoading,
	} = useQuery({
		queryKey: foldersShareQueryKey._def,
		retry: 2,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: 1000,
		queryFn: () => fetchFoldersFlat(payload, workspaceId.value),
	})

	return { sharedAssignments, isLoading, error: error as Ref<ServiceError | null> }
}
