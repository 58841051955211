import { Client } from 'typesense'
import { computed } from 'vue'

import { useSearchKey } from 'src/common/composables/search/use-search-key'

interface Config {
	/**
	 * Determines which API key to use.
	 * @default documents
	 */
	useApiKey?: 'documents' | 'addressRead'
}

export function useSearchClient(config: Config = {}) {
	const { searchCreds } = useSearchKey()

	function pickApiKey() {
		if (!searchCreds.value) {
			return null
		}

		if (config.useApiKey === 'addressRead') {
			return searchCreds.value.keys.addressReadKey
		}

		return searchCreds.value.keys.documentKey
	}

	const searchClient = computed(() => {
		const apiKey = pickApiKey()

		if (!searchCreds.value || !apiKey) {
			return
		}

		const url = new URL(searchCreds.value.url)

		return new Client({
			nodes: [
				{
					host: url.hostname,
					port: url.protocol === 'https:' ? 443 : Number(url.port || 80),
					protocol: url.protocol.replace(':', ''),
					path: url.pathname,
				},
			],
			apiKey,
			connectionTimeoutSeconds: 10,
		})
	})

	return { searchCreds, searchClient }
}
