import { type ComputedRef, type InjectionKey, type Reactive, type Ref, inject } from 'vue'

import type { TypeSenseDocument } from '@services/nolas-api'
import type { SearchResponseFacetCountSchema } from 'typesense/lib/Typesense/Documents'
import type { z } from 'zod'

import type { SelectableDocument } from '@workbench/composables/use-documents-selection'
import { type BaseTableParams, type BaseTableState, contextKey } from './use-table-state'

export type SortOrder = 'asc' | 'desc'

export interface DraftsTableState extends BaseTableState {
	table: Reactive<z.infer<typeof BaseTableParams>>
	documents: ComputedRef<SelectableDocument[]>
	total: ComputedRef<number | undefined>
	isLoading: Ref<boolean>
	setSort(id: string, order: SortOrder): void
	facets: ComputedRef<SearchResponseFacetCountSchema<TypeSenseDocument>[]>
}

export const draftsContextKey = contextKey as InjectionKey<DraftsTableState>

export function useDrafts() {
	const context = inject(draftsContextKey)
	if (!context) {
		throw new Error('Please use `provideDraftsState` before calling this function.')
	}

	return context
}

/**
 * Builds the sort id which should be embedded into the query. Some columns have a different id than the expected one, so they need to be converted first, otherwise the sorting will not work.
 */
export function buildQuerySortId(sortId: string) {
	if (sortId === 'uploadedAt') {
		return 'receivedAt'
	}

	return sortId
}

/**
 * Builds the sort id which should be passed as state to the table. Some columns have a different id than the expected one, so they need to be converted first, otherwise the table sorting won't be displayed porperly.
 */
export function buildTableSortId(sortId: string) {
	if (sortId === 'receivedAt') {
		return 'uploadedAt'
	}

	return sortId
}
