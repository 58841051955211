<script setup lang="ts">
import type { ClassValue } from 'clsx'
import { RadioGroupRoot, type RadioGroupRootEmits, type RadioGroupRootProps, useEmitAsProps } from 'reka-ui'

import { cn } from '../../utils'

const props = defineProps<RadioGroupRootProps & { class?: ClassValue }>()
const emits = defineEmits<RadioGroupRootEmits>()

const emitsAsProps = useEmitAsProps(emits)
</script>

<template>
  <RadioGroupRoot :class="cn('grid gap-2', props.class)" v-bind="{ ...props, ...emitsAsProps }">
    <slot />
  </RadioGroupRoot>
</template>
