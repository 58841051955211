<script lang="ts" setup>
import { Label, type LabelProps } from 'reka-ui'

import { type FormItemProps } from './utils'
import { useFormField } from './use-form-field'

defineProps<LabelProps & FormItemProps>()

const { formItemId } = useFormField()
</script>

<template>
  <Label
    :class="['block text-left text-sm font-medium leading-5 text-gray-600', { 'flex-1': orientation === 'horizontal' }]"
    :for="formItemId"
  >
    <slot />
  </Label>
</template>
