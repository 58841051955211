<script lang="ts" setup>
import { Slot } from 'reka-ui'

import { type FormItemProps } from './utils'
import { useFormField } from './use-form-field'

const { error, formItemId, formDescriptionId, formMessageId } = useFormField()

defineProps<FormItemProps>()
</script>

<template>
  <Slot
    :id="formItemId"
    :aria-describedby="!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`"
    :aria-invalid="!!error"
    :class="[{ '!flex-2 flex-col': orientation === 'horizontal' }]"
  >
    <slot />
  </Slot>
</template>
