<script lang="ts">
import { type InjectionKey } from 'vue'

import { formItemVariants, type FormItemProps } from './utils'

export const FORM_ITEM_INJECTION_KEY = Symbol() as InjectionKey<string>
</script>

<script lang="ts" setup>
import { provide } from 'vue'
import { useId } from 'reka-ui'

const id = useId()
defineProps<FormItemProps>()
provide(FORM_ITEM_INJECTION_KEY, id)
</script>

<template>
  <div :class="formItemVariants({ orientation, class: $attrs.class as string })">
    <slot />
  </div>
</template>
