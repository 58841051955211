<script setup lang="ts">
import { cn } from '../../utils'

interface DialogTitleProps {
  class?: string
}

const props = defineProps<DialogTitleProps>()
</script>

<template>
  <h2
    :class="
      cn(
        'text-lg font-semibold leading-none tracking-tight text-gray-900',
        props.class
      )
    "
  >
    <slot />
  </h2>
</template>
