import { computed, ref } from 'vue'

import { useI18n } from 'vue-i18n'
import { z } from 'zod'

import { useSendingSettings } from '@sending-settings/composables/use-sending-settings'
import { useAddressBookOverlay } from '@address-book/composables/use-addres-book-overlay'

import INolasFolder from '~icons/nol-icons/folder'

// DON'T REMOVE THE COMMENTS BELOW, WE WILL NEED THEM LATER

// const digitalShippingType = ['insert_qr_code_in_document', 'send_qr_code_as_cover_sheet']
// const envelope = ['small', 'large']
export const addressWindowPosition = z.enum(['A', 'B'])
export const additionalServices = z.enum(['0', '1', '2', '3', '19', '35', '51'])
// const handover = ['by_hand', 'return_receipt']
export const printing = z.enum(['one_sided', 'double_sided'])

export const color = z.enum(['black_and_white', 'color'])
// const paperQuality = ['standard', 'recycling']

const showSaveTo = ref(false)

export const useSendingSettingsPrintSpecifications = () => {
	const { t } = useI18n()

	const { foldersHasAccess, getFolderLabel } = useAddressBookOverlay()
	const { selectedRecipient, postRecipients } = useSendingSettings()

	const postRecipientsFiltered = computed(() => {
		return postRecipients.value.filter(recipient => !recipient.assignmentId)
	})

	const specifications = computed(() => [
		{
			id: 'saveTo',
			show: selectedRecipient.value?.channel === 'post' && postRecipientsFiltered.value.length > 0 && showSaveTo.value,
			type: 'select',
			label: t('app.keyflowz.documents.sending_settings.sections.print_specifications.fields.save_to.label'),
			items: foldersHasAccess.value
				?.filter(o => o.type === 'private_folder')
				.map(folder => ({
					label: getFolderLabel(folder, true),
					value: folder.id,
					icon: INolasFolder,
					type: folder.type,
				})),
		},
		// {
		//   id: 'digitalShippingType',
		//   type: 'select',
		//   label: t(
		//     'app.keyflowz.documents.sending_settings.sections.print_specifications.fields.digital_shipping_type.label'
		//   ),
		//   items: digitalShippingType.map(type => {
		//     return {
		//       label: t(
		//         `app.keyflowz.documents.sending_settings.sections.print_specifications.fields.digital_shipping_type.options.${type}`
		//       ),
		//       value: type,
		//     }
		//   }),
		// },
		// {
		//   id: 'envelope',
		//   type: 'select',
		//   label: t('app.keyflowz.documents.sending_settings.sections.print_specifications.fields.envelope.label'),
		//   items: envelope.map(type => {
		//     return {
		//       label: t(
		//         `app.keyflowz.documents.sending_settings.sections.print_specifications.fields.envelope.options.${type}`
		//       ),
		//       value: type,
		//     }
		//   }),
		// },
		{
			id: 'addressWindowPosition',
			show: true,
			type: 'select',
			label: t(
				'app.keyflowz.documents.sending_settings.sections.print_specifications.fields.address_window_position.label'
			),
			items: addressWindowPosition.options.map(type => {
				return {
					label: t(
						`app.keyflowz.documents.sending_settings.sections.print_specifications.fields.address_window_position.options.type_${type}`
					),
					value: type,
				}
			}),
		},
		{
			id: 'additionalServices',
			show: true,
			type: 'select',
			label: t(
				'app.keyflowz.documents.sending_settings.sections.print_specifications.fields.additional_services.label'
			),
			items: additionalServices.options.map(type => {
				return {
					label: t(
						`app.keyflowz.documents.sending_settings.sections.print_specifications.fields.additional_services.options.${type}`
					),
					value: type,
				}
			}),
		},
		// {
		//   id: 'handover',
		//   type: 'select-multiple',
		//   label: t('app.keyflowz.documents.sending_settings.sections.print_specifications.fields.handover.label'),
		//   items: handover.map(type => {
		//     return {
		//       name: t(
		//         `app.keyflowz.documents.sending_settings.sections.print_specifications.fields.handover.options.${type}`
		//       ),
		//       id: type,
		//     }
		//   }),
		//   length: 2,
		//   joinedBy: ' + ',
		// },
		{
			id: 'printing',
			show: true,
			type: 'select',
			label: t('app.keyflowz.documents.sending_settings.sections.print_specifications.fields.printing.label'),
			items: printing.options.map(type => {
				return {
					label: t(
						`app.keyflowz.documents.sending_settings.sections.print_specifications.fields.printing.options.${type}`
					),
					value: type,
				}
			}),
		},
		{
			id: 'color',
			show: true,
			type: 'select',
			label: t('app.keyflowz.documents.sending_settings.sections.print_specifications.fields.color.label'),
			items: color.options.map(type => {
				return {
					label: t(
						`app.keyflowz.documents.sending_settings.sections.print_specifications.fields.color.options.${type}`
					),
					value: type,
				}
			}),
		},
		// {
		//   id: 'paperQuality',
		//   type: 'select',
		//   label: t('app.keyflowz.documents.sending_settings.sections.print_specifications.fields.paper_quality.label'),
		//   items: paperQuality.map(type => {
		//     return {
		//       label: t(
		//         `app.keyflowz.documents.sending_settings.sections.print_specifications.fields.paper_quality.options.${type}`
		//       ),
		//       value: type,
		//     }
		//   }),
		// },
	])

	return {
		specifications,
		showSaveTo,
	}
}
