import { computed, onUnmounted, ref } from 'vue'

import type { DocumentAssignmentID, DocumentID, HandshakeID, ListSettingsResponse, UserID } from '@services/nolas-api'
import type { TypeSenseDocument, TypeSenseDocumentWorkflow } from '@services/nolas-api'

import { useFileFormat } from '@nolas/design-system/composables'

import { DocumentChannels } from '../doclist/use-table-state'
import { useTableFormatting } from './use-table-formatting'

const { documentType } = useFileFormat()

const { getAdditionalInformation, getRecipients } = useTableFormatting()

export type SelectableDocument = {
	id: DocumentAssignmentID
	title: string
	pagesCount: number | null
	uploadedAt: Date | null
	receivedAt: Date | null
	sender?: string
	mimeType: string
	size: number
	metadata?: TypeSenseDocument['metadata']
	additionalInformation: {
		type: string | null
		information: string | null
	}
	workflow: TypeSenseDocumentWorkflow
	classification: string | null
	subclassification: string | null
	channel: { name: string; color: string } | null
	owner: string
	recipients: string | { email: string; name: string } | null
	isSplit: boolean | null
	isRead: boolean
	exportedAt?: string
	icon: string | null
	info: string | null
	status: 'SUCCESS' | 'PENDING' | 'ERROR'
	summary?: string
	parentId?: DocumentID | null
	assignmentStatus?: string
	isHandshakeInvite?: boolean
	aiProcessingError?: boolean
	ownerId?: UserID
	isApproved?: boolean | null
	children?: SelectableDocument[]
}

const selectedDocuments = ref<DocumentAssignmentID[]>([])
const documentPreviewId = ref<DocumentAssignmentID>()
const selectedDocumentsLength = computed(() => selectedDocuments.value?.length || 0)

const setDocumentPreviewId = (id: DocumentAssignmentID | undefined) => {
	documentPreviewId.value = id
}

const selectedHandShakeId = ref<HandshakeID>()
const setSelectedDocumentHandshake = (handshake: string | undefined) => {
	selectedHandShakeId.value = handshake ? (handshake as HandshakeID) : undefined
}

const isIdSelected = (id: string) => selectedDocuments.value.includes(id)

export const useDocumentsSelection = () => {
	onUnmounted(() => {
		setSelectedDocumentHandshake(undefined)
	})

	const fromHit = (document: TypeSenseDocument, classes?: ListSettingsResponse['classes']): SelectableDocument => {
		const additionalInformation = getAdditionalInformation(document, classes || [])
		const recipients = getRecipients(document)

		return {
			id: document.id,
			title: document.name,
			pagesCount: document.pagesCount,
			uploadedAt: new Date(document.receivedAt),
			receivedAt: new Date(document.receivedAt),
			mimeType: documentType(document.mimeType, document.name),
			status: document.status,
			size: document.size,
			// TODO: maybe the type is wrong, please fix this, signed: @arpad
			// biome-ignore lint/style/noNonNullAssertion: <explanation>
			workflow: document.workflow!,
			channel: DocumentChannels[document.channel as keyof typeof DocumentChannels],
			owner: 'You',
			recipients,
			isSplit: true,
			isRead: !!document.isRead,
			parentId: document.parentId,
			additionalInformation,
			classification: document.classification === 'Unclassified' ? '' : (document.classification ?? ''),
			subclassification: document.subclassification?.secondaryLabel || '',
			icon: 'split',
			info: 'help',
			assignmentStatus: document.assignmentStatus,
			sender:
				document.channel.toLowerCase() === DocumentChannels.EMAIL.name.toLowerCase()
					? document.sender?.email
					: document.sender?.name || document.sender?.email || '',
			ownerId: document.sender?.userId ?? undefined,
		}
	}

	const resetSelection = () => {
		if (documentPreviewId.value && selectedDocuments.value.includes(documentPreviewId.value)) {
			setDocumentPreviewId(undefined)
		}

		selectedDocuments.value = []
	}

	return {
		fromHit,
		selectedDocuments,
		documentPreviewId,
		setDocumentPreviewId,
		selectedDocumentsLength,
		resetSelection,
		selectedHandShakeId,
		setSelectedDocumentHandshake,
		isIdSelected,
	}
}
