import { useQuery } from '@tanstack/vue-query'
import type { MaybeRef } from 'vue'

import { useUser } from '@auth/composables/use-user'
import { QK } from '@queries'

export function useWorkflow(workflowId: MaybeRef<string | undefined>) {
	const { workspaceId } = useUser()

	const {
		data: workflow,
		error,
		...queryRest
	} = useQuery({
		...QK.workflows.details(workspaceId, workflowId),
		retry: 2,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: false,
		enabled: () => !!workflowId,
		gcTime: 10,
		staleTime: 1000,
	})

	return { workflow, error, ...queryRest }
}
