<script setup lang="ts">
import { ref } from 'vue'

import AuthWrapper from '@auth/components/auth-wrapper.vue'
import RegisterSuccess from '@auth/components/register/register-success.vue'
import Register from '@auth/components/register/register.vue'

interface RegisterSuccessEmit {
	email?: string
}

const step = ref(1)
const email = ref<string>()
const onSuccess = (value: RegisterSuccessEmit) => {
	email.value = value.email
	step.value = 2
}
</script>

<template>
  <AuthWrapper>
    <Register v-if="step == 1" @success="onSuccess" />
    <RegisterSuccess v-else :email="email" />
  </AuthWrapper>
</template>
