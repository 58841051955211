import { type MaybeRef, type Ref, computed } from 'vue'

import { useQuery } from '@tanstack/vue-query'

import type { ServiceError } from '@nolas/lib/errors'
import type { TypeSenseContact } from '@services/nolas-api'

import { useMyContact } from '@address-book/composables/contacts/use-my-entry'
import { useSearchClient } from '@composables/search/use-search-client'

import { QK } from '@queries'

const addressbookCollection = 'addressbook'

export const contactsQueryKey = QK.search.contacts

export interface ContactSearchInput {
	q?: string
	filterBy?: {
		authenticatedBy?: string
		nolas?: string
		isGroupOrOrg?: boolean
	}
	sort: {
		key: string
		order: 'asc' | 'desc'
	}
	folderId?: string | string[] | (() => string[])
	isMyOrganizationFolder?: boolean
	isBlockedFolder?: boolean
	isAdminFolder?: boolean
	limit: number
	page: number
}

export function useContactSearch(
	search: MaybeRef<ContactSearchInput>,
	options?: { refetch?: boolean; isMyOrganization?: boolean; nameOnlySearch?: boolean }
) {
	const { myContact } = useMyContact()
	const { searchCreds, searchClient } = useSearchClient({ useApiKey: 'addressRead' })

	const buildFilterString = (
		folderId: string | string[],
		filterBy?: ContactSearchInput['filterBy'],
		isMyOrganizationFolder?: ContactSearchInput['isMyOrganizationFolder'],
		showBlocked = false,
		showAdmin = false
	): string => {
		// If isGroupOrOrg is true, only return the type filter
		if (filterBy?.isGroupOrOrg) {
			return 'type:in:[GROUP,ORGANIZATION]'
		}

		const filters: string[] = []

		if (!showBlocked) {
			filters.push('handshakeStatus:!="blocked"')
		}

		if (showAdmin) {
			filters.push('entry.isAdmin:=true')
		}

		if (folderId && !showAdmin) {
			if (Array.isArray(folderId)) {
				filters.push(`folder.id:=[${folderId}]`)
			} else {
				filters.push(`folder.id:=${folderId}`)
			}
		}

		if (!filterBy) {
			return filters.join(' && ')
		}

		if (filterBy.authenticatedBy) {
			filters.push(`identityType:=${filterBy.authenticatedBy}`)
		}

		if (filterBy.nolas === 'true') {
			isMyOrganizationFolder
				? filters.push('entry.userId:!=missing && entry.workspaceJoined:=true')
				: filters.push('entry.userId:!=missing')
		}

		if (filterBy.nolas === 'false') {
			filters.push('entry.userId:=missing')
		}

		return filters.join(' && ')
	}

	const buildSortString = (sort: ContactSearchInput['sort']): string => {
		return sort.key && `entry.${sort.key}:${sort.order}`
	}

	const pinnedHits = (folderId: string) => {
		if (myContact.value?.folder.id !== folderId) {
			return []
		}
		return [`${myContact.value?.id}:1`]
	}
	const refetchInterval = options?.refetch ? 2000 : undefined

	const queryKey = computed(() => QK.search.contactSearch(search, myContact as Ref<TypeSenseContact>).queryKey)

	const {
		data: searchResult,
		error,
		...queryRest
	} = useQuery({
		queryKey,
		enabled: () => !!searchCreds.value,
		retry: 2,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: 1000,
		refetchInterval: import.meta.env.DEV ? undefined : refetchInterval,
		queryFn: async ({ queryKey: [, , search] }) => {
			// biome-ignore lint/style/noNonNullAssertion: <explanation>
			const searchResults = await searchClient
				.value!.collections<TypeSenseContact>(addressbookCollection)
				.documents()
				.search(
					{
						q: search.q ?? '*',
						per_page: search.limit,
						page: search.page,
						pinned_hits: pinnedHits(search.folderId as string),
						filter_curated_hits: true,
						query_by: `entry.name ${options?.nameOnlySearch ? '' : ', entry.properties'}`,
						filter_by: buildFilterString(
							// Handle the case when folderId is a function
							typeof search.folderId === 'function' ? search.folderId() : (search.folderId ?? ''),
							search.filterBy,
							search.isMyOrganizationFolder,
							search.isBlockedFolder,
							search.isAdminFolder
						),
						sort_by: buildSortString(search.sort),
						num_typos: '1',
					},
					{}
				)

			return searchResults
		},
	})

	return { searchResult, error: error as Ref<ServiceError | null>, ...queryRest }
}
