<script setup lang="ts">
import { SelectLabel, type SelectLabelProps } from 'reka-ui'

import { cn } from '../../utils'

const props = defineProps<SelectLabelProps & { class?: string }>()
</script>

<template>
  <SelectLabel :class="cn('px-4 py-0.5 text-xs font-semibold text-gray-700', props.class)">
    <slot />
  </SelectLabel>
</template>
