import { GetMyContact } from '@services/nolas-api'
import { useQuery } from '@tanstack/vue-query'
import { useQueryClient } from '@tanstack/vue-query'
import type { Ref } from 'vue'

import { ServiceError } from '@nolas/lib/errors'

import { contactsQueryKey } from '@common/composables/search/use-contact-search'
import { useUser } from '@auth/composables/use-user'

const fetchMyContact = async (workspaceId: string) => {
	const { response, data, error } = await GetMyContact({
		params: { path: { workspaceId } },
	})

	if (!response.ok || !data) {
		throw ServiceError.fromResponse(error)
	}
	return data
}

export function useMyContact() {
	const { user, workspaceId } = useUser()
	const queryClient = useQueryClient()

	const {
		data: myContact,
		error,
		isPending,
		...queryRest
	} = useQuery({
		queryKey: contactsQueryKey._def,
		retry: 2,
		enabled: () => !!user.value,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: 1000,
		queryFn: async () => fetchMyContact(workspaceId.value),
	})
	queryClient.invalidateQueries({ queryKey: contactsQueryKey._def })

	return { myContact, isPending, error: error as Ref<ServiceError | null>, ...queryRest }
}
