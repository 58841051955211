<script setup lang="ts" generic="T extends FormContext">
import { Field, type FormContext, type Path } from 'vee-validate'
import { FormItemProps } from './utils'
import FormItem from './form-item.vue'
import FormLabel from './form-label.vue'
import FormControl from './form-control.vue'
import FormDescription from './form-description.vue'
import FormMessage from './form-message.vue'

withDefaults(
	defineProps<
		{
			form?: T
			name: Path<T['values']> | string
			label?: string
			description?: string
			hideErrorMessage?: boolean
			dataE2e?: string
		} & FormItemProps
	>(),
	{
		form: undefined,
		label: '',
		description: '',
		required: false,
		hideErrorMessage: false,
		dataE2e: '',
		orientation: 'vertical',
	}
)
</script>

<template>
  <Field v-slot="field" :name="name as string" :form="form">
    <FormItem
      :class="[
        $attrs.class ?? '',
        orientation === 'vertical' ? 'mb-4' : 'border-b border-gray-200 py-5 last:border-0',
      ]"
      :orientation="orientation"
    >
      <FormLabel v-if="label" :orientation="orientation" :required="required">{{ label }}</FormLabel>
      <FormControl :orientation="orientation">
        <slot
          v-bind="{
            ...field,
            componentField: { ...field.componentField, error: field.errors[0] },
            field: { ...field.field, error: field.errors[0] },
          }"
        />
        <FormDescription v-if="description">
          {{ description }}
        </FormDescription>
        <FormMessage v-if="!hideErrorMessage" class="mt-2" v-bind="dataE2e ? { 'data-e2e': dataE2e + '-error' } : {}" />
      </FormControl>
    </FormItem>
  </Field>
</template>
