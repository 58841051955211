<script lang="ts">
export const DIALOG_SIZE = {
  XS: 'xs', // Extra small (400px × 480px)
  SM: 'sm', // Small (480px × 520px)
  MD: 'md', // Medium (600px × 650px)
  LG: 'lg', // Large (800px × 700px)
  XL: 'xl', // XLarge (1124px × 800px)
  CUSTOM: 'custom', // Custom size
}
</script>

<script setup lang="ts">
import type { ClassValue } from 'clsx'
import { DialogRoot, type DialogContentProps } from 'reka-ui'
import { computed } from 'vue'

import DialogContent from './dialog-content.vue'

const props = withDefaults(
  defineProps<
    DialogContentProps & {
      id: string
      modelValue?: boolean
      size?: string
      class?: ClassValue
      contentClass?: ClassValue
      showCloseButton?: boolean
      hideOverlay?: boolean
      overlayClasses?: ClassValue
      dataE2e?: string
    }
  >(),
  {
    modelValue: false,
    size: DIALOG_SIZE.MD,
    class: '',
    contentClass: '',
    showCloseButton: false,
    hideOverlay: false,
    overlayClasses: '',
    dataE2e: '',
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'close'): void
}>()

const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const sizeClass = computed(() => {
  switch (props.size) {
    case DIALOG_SIZE.XS: {
      return '!max-w-[400px] !max-h-[480px]'
    }
    case DIALOG_SIZE.SM: {
      return '!max-w-[480px] !max-h-[520px]'
    }
    case DIALOG_SIZE.MD: {
      return '!max-w-[600px] !max-h-[650px]'
    }
    case DIALOG_SIZE.LG: {
      return '!max-w-[800px] !max-h-[700px]'
    }
    case DIALOG_SIZE.XL: {
      return '!max-w-[1124px] !max-h-[800px]'
    }
    default: {
      return ''
    }
  }
})

const handleClose = () => {
  emit('close')
  isOpen.value = false
}

const handleKeyDown = (e: KeyboardEvent) => {
  e.preventDefault()
  e.stopPropagation()
}
</script>

<template>
  <DialogRoot
    v-model:open="isOpen"
    :data-e2e="`${id}-modal`"
    @keydown="handleKeyDown"
  >
    <DialogContent
      :class="['h-max w-max', sizeClass]"
      :content-class="contentClass"
      :show-close-button="showCloseButton"
      :hide-overlay="hideOverlay"
      :overlay-classes="overlayClasses"
      :data-e2e="`${id}-modal-content`"
      @interact-outside="(e) => e.preventDefault()"
      @escape-key-down="handleKeyDown"
      @close="handleClose"
    >
      <slot />
    </DialogContent>
  </DialogRoot>
</template>
