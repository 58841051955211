import type { ListFolders } from '@services/nolas-api'

export function findFolder(tree: ListFolders, targetId: string): ListFolders[0] | undefined {
	if (!tree) {
		return undefined
	}
	for (const node of tree) {
		if (node.id === targetId) {
			return node // Base case: return if we find the target
		}
		if (node.children && node.children.length > 0) {
			const result = findFolder(node.children, targetId) // Recursively search children
			if (result) {
				return result // If target is found in children, return result
			}
		}
	}
	return undefined
}

export function collectIds(node?: ListFolders[0]): string[] {
	if (!node) {
		return []
	}
	return [node.id, ...(node.children?.flatMap(collectIds) || [])]
}

export function findFolderIds(folders: ListFolders, targetId: string): string[] {
	const startNode = folders
		? findFolder(
				folders.filter(o => o.type !== 'admin_folder'),
				targetId
			)
		: undefined
	if (!startNode) {
		return []
	}
	return collectIds(startNode)
}

export function collectFolderAndChildrenIds(folder: ListFolders[0], ids: string[]) {
	ids.push(folder.id)
	if (folder.children !== undefined && folder.children.length > 0) {
		for (const child of folder.children) {
			collectFolderAndChildrenIds(child, ids)
		}
	}
}

export function getFolderIdsForTheSelectedFolders(folders: ListFolders, selectedFolders: string[]): string[] {
	const ids: string[] = []

	for (const folder of folders) {
		if (selectedFolders.includes(folder.id)) {
			collectFolderAndChildrenIds(folder, ids)
		} else if (folder.children !== undefined && folder.children.length > 0) {
			for (const id of getFolderIdsForTheSelectedFolders(folder.children, selectedFolders)) {
				ids.push(id)
			}
		}
	}

	return ids
}

export const getFolderLabel = (folder: ListFolders[number] | undefined, pathForm = false): string => {
	if (folder?.name) {
		if (pathForm) {
			return folder?.name
		}
		return (folder?.contactData?.latest?.name || folder?.name) as string
	}

	switch (folder?.type) {
		case 'workspace_folder': {
			return 'My Organization'
		}
		case 'external_folder': {
			return 'Extern'
		}
		case 'private_folder': {
			return 'Private'
		}
		default: {
			return folder?.type || ''
		}
	}
}
