import { ref, computed, provide, inject } from 'vue'

import DialogConfirmation from './templates/dialog-confirmation.vue'
import DialogForm from './templates/dialog-form.vue'
import DialogSidePreview from './templates/dialog-side-preview.vue'

import type { DialogComponent, DialogProps, DialogContext, DialogType, DialogComponentWithProps, DialogSlots } from '.'
import { contextKey } from '.'

const dialogs = ref<DialogComponent[]>([])

const getWrapper = (type: DialogType) => {
	switch (type) {
		case 'form': {
			return DialogForm
		}
		case 'preview': {
			return DialogSidePreview
		}
		default: {
			return DialogConfirmation
		}
	}
}

export function provideDialog() {
	const isOpen = computed(() => dialogs.value.length > 0)
	const lastDialog = computed(() => dialogs.value.at(-1))
	const slots = computed(() => lastDialog.value?.slots ?? {})

	async function openDialog(component: DialogComponentWithProps, props: DialogProps, slots?: DialogSlots) {
		if (!component.as) {
			throw new Error('A valid Vue component must be provided when opening a dialog.')
		}
		if (!props.id || !props.title || !props.type) {
			throw new Error('DialogProps must include "id", "type" and "title".')
		}

		const { on, ...componentProps } = component

		await dialogs.value.push({
			component: {
				as: component.as,
				props: componentProps.props || {},
				on: on || {},
			},
			wrapper: getWrapper(props.type),
			props: props ?? {},
			slots: slots ?? {},
		})
	}

	async function closeDialog() {
		if (dialogs.value.length === 0) {
			return
		}
		await dialogs.value.pop()
	}

	function resetDialog() {
		if (dialogs.value.length === 0) return
		dialogs.value = []
	}

	function patchModal(props: DialogProps) {
		if (dialogs.value.length === 0) return

		if (lastDialog.value) {
			dialogs.value[dialogs.value.length - 1] = {
				component: lastDialog.value.component,
				wrapper: lastDialog.value.wrapper,
				props: {
					...lastDialog.value.props,
					...props,
				},
			}
		}
	}

	async function handleClose() {
		if (lastDialog.value?.props.onClose) {
			lastDialog.value.props.onClose()
		}
		await closeDialog()
	}

	async function handleSuccess() {
		if (lastDialog.value?.props.onSuccess) {
			try {
				await lastDialog.value.props.onSuccess()
			} catch (error) {
				console.error('onSuccess failed:', error)
				return
			}
		}
		await closeDialog()
	}

	const context: DialogContext = {
		dialogs,
		isOpen,
		lastDialog,
		slots,
		openDialog,
		closeDialog,
		resetDialog,
		patchModal,
		handleClose,
		handleSuccess,
	}

	provide(contextKey, context)

	return context
}

export function useDialog() {
	const context = inject<DialogContext>(contextKey)

	if (!context) {
		throw new Error('DialogContext not found. Ensure provideDialog() is called first.')
	}

	return context
}
