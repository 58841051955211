import { useLocalStorage } from '@vueuse/core'
import { computed, provide, inject, reactive, toRefs, type ComputedRef, type Ref, type Component } from 'vue'
import { useRoute } from 'vue-router'

import { useResizeSidebar } from './resize'
import { SIDEBAR_COOKIE_NAME } from './utils'

import type { SidebarVariants } from '.'

export type State = 'expanded' | 'collapsed'

type SidebarInstance = {
	state: ComputedRef<State>
	storageState: Ref<boolean>
	side: 'left' | 'right'
	type: SidebarVariants['type']
	defaultOpen: boolean
	component: Component | string
	properties: ComputedRef<SidebarProps>
	isNavigationClosed: ComputedRef<boolean>
	resizable: boolean
	isOpen: ComputedRef<boolean>
	isResizing: Ref<boolean>
	width: ComputedRef<string>
	open: (component: SidebarInstance['component'], props?: SidebarProps) => void
	patch: (props: SidebarProps) => void
	close: () => Promise<void>
	toggleSidebar: () => void
	onResize: (e: MouseEvent | TouchEvent) => void
}

type SidebarProps = {
	title?: string
	onClose?: () => void
}

type Options = {
	defaultOpen?: boolean
	type?: SidebarVariants['type']
	resizable?: boolean
}

type SidebarContext = Record<string, SidebarInstance>

const openMap = reactive(new Map<string, boolean>())
const componentMap = reactive(new Map<string, Component | string>())
const stateMap = reactive(new Map<string, State>())
const storageStateMap = reactive(new Map<string, boolean>())
const sidebarInstances = reactive<SidebarContext>({})
const propsMap = reactive(new Map<string, SidebarProps>())

const getState = (open = false): State => {
	const state = open ? 'expanded' : 'collapsed'
	return state
}

const getSidebarProperties = (sidebar: SidebarInstance) => {
	return {
		...toRefs(sidebar),
		open: sidebar.open,
		close: sidebar.close,
		patch: sidebar.patch,
		toggleSidebar: sidebar.toggleSidebar,
		onResize: sidebar.onResize,
	}
}

function createSidebar(id: string, side: 'left' | 'right' = 'left', options?: Options) {
	const key = `${SIDEBAR_COOKIE_NAME}:${id}`
	const isSidebarOpen = useLocalStorage(key, options?.defaultOpen ?? side === 'left')

	const defaultOpen = options?.defaultOpen ?? false
	const maintainState = options?.type === 'navigation'
	const type = options?.type ?? 'normal'
	const resizable = options?.resizable ?? false
	const { width, isResizing, onResize } = useResizeSidebar(key, type, side, resizable)

	if (!openMap.has(id)) {
		openMap.set(id, defaultOpen || isSidebarOpen.value || false)
		stateMap.set(id, getState(defaultOpen || isSidebarOpen.value || false))
	}

	const state = computed<State>(() => stateMap.get(id) ?? 'collapsed')

	const isOpen = computed({
		get: () => openMap.get(id) ?? false,
		set: value => {
			openMap.set(id, value)
			stateMap.set(id, getState(value))
			if (maintainState) {
				isSidebarOpen.value = value
			}
		},
	})

	const component = computed({
		get: () => componentMap.get(id) ?? '',
		set: value => {
			componentMap.set(id, value)
		},
	})

	const properties = computed<SidebarProps>({
		get: () => propsMap.get(id) ?? {},
		set: value => {
			propsMap.set(id, value)
		},
	})

	const storageState = computed({
		get: () => isSidebarOpen.value,
		set: value => {
			storageStateMap.set(id, value)
		},
	})

	function open(component: SidebarInstance['component'], props?: SidebarProps) {
		componentMap.set(id, component)
		if (props) {
			propsMap.set(id, props)
		}
		isOpen.value = true
	}

	function patch(props: SidebarProps) {
		propsMap.set(id, {
			...propsMap.get(id),
			...props,
		})
	}
	function toggleSidebar() {
		isOpen.value = !isOpen.value
	}

	// biome-ignore lint/suspicious/useAwait: <explanation>
	async function close() {
		isOpen.value = false
	}

	const isNavigationClosed = computed(() => type === 'navigation' && state.value === 'collapsed')

	const instance: SidebarInstance = {
		state,
		component,
		defaultOpen,
		properties,
		storageState,
		isOpen,
		isNavigationClosed,
		side,
		type,
		resizable,
		width,
		isResizing,
		open,
		close,
		patch,
		toggleSidebar,
		onResize,
	}
	sidebarInstances[id] = instance

	return instance
}

export function provideSidebar() {
	provide('nolas-ui:sidebar', sidebarInstances)
	const route = useRoute()
	if (!sidebarInstances['navigation-sidebar']) {
		createSidebar('navigation-sidebar', 'left', { type: 'navigation', resizable: true })
	}
	if (!sidebarInstances.sidedrawer) {
		createSidebar('sidedrawer', 'right', { resizable: false, defaultOpen: !!route?.params?.document })
	}
}

export function useSidebarLeft() {
	const sidebarContext = inject<SidebarContext>('nolas-ui:sidebar')
	if (!sidebarContext) {
		throw new Error('SidebarContext not found. Ensure provideSidebar() is called first.')
	}

	const sidebar = sidebarContext['navigation-sidebar']
	return {
		...getSidebarProperties(sidebar),
		sidebar,
	}
}

export function useSidebarRight() {
	const sidebarContext = inject<SidebarContext>('nolas-ui:sidebar')
	if (!sidebarContext) {
		throw new Error('SidebarContext not found. Ensure provideSidebar() is called first.')
	}

	const sidebar = sidebarContext.sidedrawer
	return {
		...getSidebarProperties(sidebar),
		sidebar,
	}
}

export function useSidebar() {
	const sidebar = inject<SidebarInstance>('nolas-ui:sidebarInstance')
	if (!sidebar) {
		throw new Error('Sidebar not found. Ensure provideSidebar() is called first.')
	}

	return getSidebarProperties(sidebar)
}
