<script setup lang="ts">
import { RadioGroupIndicator, RadioGroupItem, type RadioGroupItemProps } from 'reka-ui'

import { cn } from '../../utils'

const props = defineProps<RadioGroupItemProps & { class?: string; disabled?: boolean }>()
</script>

<template>
  <RadioGroupItem
    v-bind="props"
    :class="
      cn(
        'aspect-square h-5 w-5 min-w-5 rounded-full border border-gray-450 focus:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
        props.class
      )
    "
    :disabled="props.disabled"
  >
    <RadioGroupIndicator class="relative flex items-center justify-center">
      <span
        class="absolute inset-1/2 h-2.5 w-2.5 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-brand-550"
      />
    </RadioGroupIndicator>
  </RadioGroupItem>
</template>
