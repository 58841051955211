import { cva, type VariantProps } from 'class-variance-authority'

export { default as Label } from './label.vue'

export const labelVariants = cva('inline-flex items-center gap-x-1 font-semibold', {
	variants: {
		size: {
			xs: 'text-xs',
			sm: 'text-sm',
			md: 'text-base',
			lg: 'text-lg',
			xl: 'text-xl',
			'2xl': 'text-2xl',
			'3xl': 'text-3xl',
		},
		weight: {
			thin: 'font-thin',
			extralight: 'font-extralight',
			light: 'font-light',
			normal: 'font-normal',
			medium: 'font-medium',
			semibold: 'font-semibold',
			bold: 'font-bold',
			extrabold: 'font-extrabold',
			black: 'font-black',
		},
	},
	defaultVariants: {
		size: 'sm',
		weight: 'semibold',
	},
})

type Variants = VariantProps<typeof labelVariants>
export interface LabelFontProps {
	size?: Variants['size']
	weight?: Variants['weight']
}
